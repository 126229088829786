<div *ngIf="mode === 'view'; else formModeTemplate">
    <div *ngIf="!disabled" class="pi-control-edit-mode">
        <app-pi-form-view-response
            [element]="element"
            [theFormControl]="formControl"
            [displayValue]="stringValue"
        ></app-pi-form-view-response>
    </div>
</div>
<ng-template #formModeTemplate>
    <div *ngIf="!disabled" class="pi-control-form-mode">
        <label [attr.for]="elementUID"
            ><app-element-title [element]="element"></app-element-title
        ></label>
        <div class="pe-4">
            <textarea
                class="p-1 form-control"
                [name]="elementUID"
                [value]="_value ? _value : null"
                [attr.id]="elementUID"
                [attr.aria-label]="element.title"
                [attr.disabled]="element?.readOnly"
                [rows]="element.rows || 4"
                [cols]="element.cols || 50"
                [placeholder]="getText(element.placeHolder) || ''"
                (change)="textInput($event)"
            ></textarea>
        </div>
        <div class="bg-light my-1 p-3">
            <div class="color-white">Preview</div>
            <div class="border border-1 bg-white">
                <div [innerHtml]="_value ? _value : 'nothing entered'"></div>
            </div>
            <app-validator
                [control]="formControl"
                [customErrorMessages]="customErrorMessages"
            ></app-validator>
        </div>
    </div>
</ng-template>
