<ag-grid-angular
    class="ag-theme-quartz"
    domLayout="autoHeight"
    [rowData]="rowData"
    [defaultColDef]="defaultColDef"
    (gridReady)="onReady($event)"
    [columnDefs]="columnDefs"
    [pagination]="pagination"
    [paginationPageSize]="paginationPageSize"
    [rowSelection]="rowSelection"
    [dataTypeDefinitions]="dataTypeDefinitions"
    (selectionChanged)="onSelect($event)"
    [paginationPageSizeSelector]="paginationPageSizeSelector"
    (filterChanged)="onFilterChanged($event)"
    (firstDataRendered)="onFirstDataRendered($event)"
    [enableCellTextSelection]="enableCellTextSelection"
    (cellKeyDown)="copyCellData($event)"
    [floatingFilter]="showFilterRow"
    tooltipMouseTrack=true
    tooltipShowDelay=0
    tooltipHideDelay=2000
    enableBrowserTooltips=false
    [suppressRowClickSelection]="suppressRowClickSelection"
    (sortChanged)="onSortChanged($event)"
   
></ag-grid-angular>
