<div class="card">
    <div class="card-header">Date Comparison Validators</div>
    <div class="card-body">
        <ng-container *ngFor="let v of dateCompareValidators; index as i">
            <div class="d-flex flex-row justify-content-between align-item-end">
                <label class="form-label col-3">
                    Must be:
                    <ng-select
                        [items]="operations"
                        class="pi-form-white p-1"
                        [(ngModel)]="v.operation"
                        name="operation"
                        (change)="update()"
                    >
                    </ng-select>
                </label>

                <label class="form-label col-3">
                    Relative to:
                    <ng-select
                        [items]="parentList"
                        bindLabel="name"
                        class="pi-form-white p-1"
                        [searchFn]="elementSearchFn"
                        [(ngModel)]="v.compareValueName"
                        bindValue="name"
                        (change)="update()"
                    >
                        <ng-template ng-option-tmp let-item="item">
                            {{ item.name }} : <small>{{ item.title }}</small>
                        </ng-template>
                    </ng-select>
                </label>
                <label class="form-label">
                    Optional error text override:
                    <input
                        class="form-control"
                        type="text"
                        name="errorText"
                        [(ngModel)]="v.errorText"
                        (change)="update()"
                    />
                </label>
                <button type="button" class="btn btn-danger" (click)="remove(index)">Remove</button>
            </div>
            <hr />
        </ng-container>
        <button type="button" class="btn btn-success" (click)="add()">Add</button>
    </div>
</div>
