import { Component, Injector, OnInit } from '@angular/core';
import { AbstractControl, NG_VALUE_ACCESSOR, ValidatorFn } from '@angular/forms';
import { AbstractPiControlComponent } from '../abstract-pi-control/abstract-pi-control.component';
import { CommonService } from '@h20-services/common.service';
import { NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { PartialDate } from '../models/PartialDate';
import { IFormPartialDate } from '@h20-services/models/forms/types/IFormPartialDate';
import { UuidService } from '@h20-services/uuid.service';

@Component({
    selector: 'app-pi-control-partial-date',
    templateUrl: './pi-control-partial-date.component.html',
    styleUrls: ['./pi-control-partial-date.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PiControlPartialDateComponent,
        },
    ],
})
export class PiControlPartialDateComponent
    extends AbstractPiControlComponent<IFormPartialDate, PartialDate>
    implements OnInit
{
    constructor(
        public injector: Injector,
        protected com_svc: CommonService,
        protected uuid_svc: UuidService,
        protected translate: TranslateService,
        private calendar: NgbCalendar,
        private date_parser: NgbDateParserFormatter
    ) {
        super(injector, com_svc, uuid_svc, translate);
    }

    minDate: PartialDate;
    maxDate: PartialDate;
    validateDelay = 2000;

    yearOptions: any[];
    monthOptions: any = [
        { value: 1, text: 'Surveys.January' },
        { value: 2, text: 'Surveys.February' },
        { value: 3, text: 'Surveys.March' },
        { value: 4, text: 'Surveys.April' },
        { value: 5, text: 'Surveys.May' },
        { value: 6, text: 'Surveys.June' },
        { value: 7, text: 'Surveys.July' },
        { value: 8, text: 'Surveys.August' },
        { value: 9, text: 'Surveys.September' },
        { value: 10, text: 'Surveys.October' },
        { value: 11, text: 'Surveys.November' },
        { value: 12, text: 'Surveys.December' },
    ];
    currentMonthOptions: any = this.monthOptions;
    currentDayOptions: any[] = this.range(1, 31);

    requiredStar = ' <span class="tiny-text fa-solid fa-asterisk pulse-warning"> </span>&nbsp;';
    yearText: string;
    monthText: string;
    dayText: string;
    unkText: string;

    currentDateValue: PartialDate;
    currentYearDisplayValue: any = this.getSelectText();
    yearDisplayMuted: boolean = false;
    currentMonthDisplayValue: any = this.getSelectText();
    monthDisplayMuted: boolean = false;
    currentDayDisplayValue: any = this.getSelectText();
    dayDisplayMuted: boolean = false;
    dayIsDisabled: boolean = false;
    monthIsDisabled: boolean = false;

    ngOnInit() {
        //super.ngOnInit();  don't use - will add incorrect validators
        this.setMinMax();
        this.formControl.addValidators(this.validators());

        this.currentDateValue = new PartialDate();

        this.yearText = this.getYearPromptText();
        this.monthText = this.getMonthPromptText();
        this.dayText = this.getDayPromptText();
        this.unkText = this.getUnkText();

        //need year list
        let minYear = this.calendar.getPrev(this.calendar.getToday(), 'y', 150).year;
        let maxYear = this.calendar.getNext(this.calendar.getToday(), 'y', 5).year;

        if (this.minDate) {
            minYear = this.minDate.year;
        }
        if (this.maxDate) {
            maxYear = this.maxDate.year;
        }
        this.yearOptions = this.range(minYear, maxYear).reverse();
    }

    writeValue(value: PartialDate) {
        //if we are setting to non null value for the first time
        if (!this._value && value) {
            //update the display
            this.currentDateValue.setDateFromDate(value);

            this.updateMonthOptions();
            this.confirmMonthSelectionAvailableOrClear(); //shouldn't be possible but confirm matches min/max
            this.updateDayOptions();
            this.confirmDaySelectionAvailableOrClear(); //shouldn't be possible but confirm matches min/max
            this._value = new PartialDate();
            this._value.setDateFromDate(this.currentDateValue);

            //these controls can start with invalid values (ex. no year yet)
            //we need to update this to set the progress bar correctly on load
            this.formControl.updateValueAndValidity();
        } else if (value) {
            this._value.setDateFromDate(value);
        }

        this.updateDisplayValues();
        this.setStringValue();
    }

    updateDisplayValues() {
        if (this._value) {
            if (this._value.day == PartialDate.UNKNOWN) {
                this.currentDayDisplayValue = this.getUnkText();
                this.dayDisplayMuted = false;
            } else if (this._value.day === PartialDate.EMPTY) {
                this.currentDayDisplayValue = this.getSelectText();
                this.dayDisplayMuted = false;
            } else {
                if (this._value.day) {
                    this.currentDayDisplayValue = this._value.day;
                    this.dayDisplayMuted = false;
                }
            }

            if (this._value.month == PartialDate.UNKNOWN) {
                this.currentMonthDisplayValue = this.getUnkText();
                this.monthDisplayMuted = false;
            } else if (this._value.month === PartialDate.EMPTY) {
                this.monthDisplayMuted = false;
                this.currentMonthDisplayValue = this.getSelectText();
                if (this.dayIsDisabled) this.enableDay();
            } else {
                if (this._value.month) {
                    let monthItem = this.monthOptions.find(
                        (monthItem) => monthItem.value === this._value.month
                    );
                    if (monthItem) {
                        //may be undefined
                        this.currentMonthDisplayValue = monthItem.text; //this.translate.instant(monthItem.text);
                        if (this.dayIsDisabled) this.enableDay();
                        this.monthDisplayMuted = false;
                    }
                }
            }

            if (this._value.year == PartialDate.UNKNOWN) {
                {
                    this.currentYearDisplayValue = this.getUnkText();
                    this.yearDisplayMuted = false;
                }
            } else if (this._value.year === PartialDate.EMPTY) {
                {
                    this.currentYearDisplayValue = this.getSelectText();
                    this.yearDisplayMuted = false;
                    if (this.dayIsDisabled) this.enableDay();
                    if (this.monthIsDisabled) this.enableMonth();
                }
            } else {
                this.currentYearDisplayValue = this._value.year;
                if (this.dayIsDisabled) this.enableDay();
                if (this.monthIsDisabled) this.enableMonth();
                this.yearDisplayMuted = false;
            }

            if (
                this._value.year === PartialDate.UNKNOWN ||
                this._value.month === PartialDate.UNKNOWN
            ) {
                this.disableDay();
                this.dayDisplayMuted = true;
            }
            if (this._value.year === PartialDate.UNKNOWN) {
                this.disableMonth();
                this.monthDisplayMuted = true;
            }
        }
    }

    setStringValue() {
        if (this._value) this.stringValue = this._value.toString();
    }

    yearSelected(year: any) {
        //allow deselect
        if (this.currentYearDisplayValue === year) {
            this.currentDateValue.clearYear();
        } else {
            this.currentDateValue.setYear(year);
        }

        //don't need to do these every update - just on year update
        this.updateMonthOptions();
        this.confirmMonthSelectionAvailableOrClear();
        this.updateDayOptions();
        this.confirmDaySelectionAvailableOrClear();

        this.writeValue(this.currentDateValue);
        this.notifyValueChange();
    }

    monthSelected(month: any) {
        //allow deselect
        if (this.currentDateValue.month === month.value) {
            this.currentDateValue.clearMonth();
        } else {
            this.currentDateValue.setMonth(month.value);
        }

        //don't need to do this on every update - just for month selected
        this.updateDayOptions();
        this.confirmDaySelectionAvailableOrClear();

        this.writeValue(this.currentDateValue);
        this.notifyValueChange();
    }

    daySelected(day: any) {
        if (this.currentDayDisplayValue === day) {
            this.currentDateValue.clearDay();
        } else {
            this.currentDateValue.setDay(day);
        }
        this.writeValue(this.currentDateValue);
        this.notifyValueChange();
    }

    yearUnknown() {
        if (this.currentDateValue.isYearUnknown()) {
            this.currentDateValue.clearYear();
        } else {
            this.currentDateValue.setYearUnknown();
        }

        this.writeValue(this.currentDateValue);
        this.notifyValueChange();
    }

    monthUnknown() {
        if (this.currentDateValue.isMonthUnknown()) {
            this.currentDateValue.clearMonth();
        } else {
            this.currentDateValue.setMonthUnknown();
        }
        this.writeValue(this.currentDateValue);
        this.notifyValueChange();
    }

    dayUnknown() {
        if (this.currentDateValue.isDayUnknown()) {
            this.currentDateValue.clearDay();
        } else {
            this.currentDateValue.setDayUnknown();
        }
        this.writeValue(this.currentDateValue);
        this.notifyValueChange();
    }

    updateMonthOptions() {
        if (this.currentDateValue.hasYearValue) {
            let filteredMonthOptions = this.monthOptions;

            if (this.maxDate && this.currentDateValue.year === this.maxDate.year) {
                //check the max month
                filteredMonthOptions = filteredMonthOptions.filter(
                    (monthItem) => monthItem.value <= this.maxDate.month
                );
            }

            if (this.minDate && this.currentDateValue.year === this.minDate.year) {
                //check the min month
                filteredMonthOptions = filteredMonthOptions.filter(
                    (monthItem) => monthItem.value >= this.minDate.month
                );
            }

            this.currentMonthOptions = filteredMonthOptions;
        } else {
            this.currentMonthOptions = this.monthOptions;
        }
    }

    getDaysInMonth(year, month) {
        // day 0 is the last day in the previous month
        return new Date(year, month, 0).getDate();
    }

    confirmMonthSelectionAvailableOrClear() {
        if (this.currentDateValue.hasMonthValue && !this.currentDateValue.isMonthUnknown()) {
            if (
                !this.currentMonthOptions.find(
                    (monthItem) => monthItem.value === this.currentDateValue.month
                )
            ) {
                this.currentDateValue.clearMonth();
            }
        }
    }

    updateDayOptions() {
        let rangeMax = 31;
        if (this.currentDateValue.hasYearValue && this.currentDateValue.hasMonthValue) {
            rangeMax = this.getDaysInMonth(this.currentDateValue.year, this.currentDateValue.month);
        }
        if (
            this.maxDate &&
            this.currentDateValue.hasYearValue &&
            this.currentDateValue.hasMonthValue &&
            this.currentDateValue.year === this.maxDate.year &&
            this.currentDateValue.month === this.maxDate.month
        ) {
            this.currentDayOptions = this.range(1, this.maxDate.day);
        } else if (
            this.minDate &&
            this.currentDateValue.hasYearValue &&
            this.currentDateValue.hasMonthValue &&
            this.currentDateValue.year === this.minDate.year &&
            this.currentDateValue.month === this.minDate.month
        ) {
            this.currentDayOptions = this.range(this.minDate.day, rangeMax);
        } else {
            this.currentDayOptions = this.range(1, rangeMax);
        }
    }

    confirmDaySelectionAvailableOrClear() {
        if (this.currentDateValue.hasDayValue && !this.currentDateValue.isDayUnknown()) {
            if (
                this.currentDayOptions[0] > this.currentDateValue.day ||
                this.currentDayOptions[this.currentDayOptions.length - 1] <
                    this.currentDateValue.day
            ) {
                this.currentDateValue.clearDay();
            }
        }
    }

    disableMonth() {
        this.monthIsDisabled = true;
        this.currentDateValue.clearMonth();
        this.currentMonthDisplayValue = this.getNAText();
    }

    disableDay() {
        this.dayIsDisabled = true;
        this.currentDateValue.clearDay();
        this.currentDayDisplayValue = this.getNAText();
    }

    enableMonth() {
        this.monthIsDisabled = false;
        this.currentMonthDisplayValue = this.getSelectText();
    }

    enableDay() {
        this.dayIsDisabled = false;
        this.currentDayDisplayValue = this.getSelectText();
    }

    range(start, end) {
        return Array.apply(0, Array(end - start + 1)).map((element, index) => index + start);
    }

    getYearPromptText() {
        let required = this.element.isRequired ? this.requiredStar : '';
        return this.translate.instant('Surveys.Year') + required;
    }

    getMonthPromptText() {
        let required = this.element.isMonthRequired ? this.requiredStar : '';
        return this.translate.instant('Surveys.Month') + required;
    }

    getDayPromptText() {
        let required = this.element.isDayRequired ? this.requiredStar : '';
        return this.translate.instant('Surveys.Day') + required;
    }

    getUnkText() {
        return this.element.unkLabel ? this.element.unkLabel : 'UNK';
    }

    getSelectText() {
        return 'Registry.SelectResponse';
    }

    getNAText() {
        return this.translate.instant('Registry.NoAnswer');
    }

    setMinMax() {
        const currentDate = this.calendar.getToday();
        let elementMin = this.element.min?.toString();
        let elementMax = this.element.max?.toString();
        if (elementMin) {
            this.minDate = new PartialDate();
            if (elementMin === '0') {
                this.minDate.setDateFromDate(currentDate);
            } else if (elementMin.search('d') !== -1) {
                const min = parseInt(elementMin.split('d')[0]);
                this.minDate.setDateFromDate(this.calendar.getPrev(currentDate, 'd', min));
            } else if (elementMin.search('y') !== -1) {
                const min = parseInt(elementMin.split('y')[0]);
                this.minDate.setDateFromDate(this.calendar.getPrev(currentDate, 'y', min));
            }
        }

        if (elementMax) {
            this.maxDate = new PartialDate();
            if (elementMax === '0') {
                this.maxDate.setDateFromDate(currentDate);
            } else if (elementMax.search('d') !== -1) {
                const max = parseInt(elementMax.split('d')[0]);
                this.maxDate.setDateFromDate(this.calendar.getNext(currentDate, 'd', max));
            } else if (elementMax.search('y') !== -1) {
                const max = parseInt(elementMax.split('y')[0]);
                this.maxDate.setDateFromDate(this.calendar.getNext(currentDate, 'y', max));
            }
        }
    }

    hasRequiredError(controlDate): boolean {
        let isRequiredError: boolean = false;
        //all required pieces must have values
        if (this.element.isRequired && !controlDate.hasYearValue()) {
            isRequiredError = true;
        }
        //has a month, and month is required
        if (
            this.element.isRequired &&
            !this.element.removeMonth &&
            this.element.isMonthRequired &&
            !controlDate.hasMonthValue() &&
            !controlDate.isYearUnknown()
        ) {
            isRequiredError = true;
        }
        //has a month, and month is required, when the question is optional
        if (
            !this.element.isRequired && // optional question
            !this.element.removeMonth && // has a month field
            this.element.isMonthRequired && // month is required
            (controlDate.hasYearValue() || controlDate.hasDayValue()) && //if they have started entering a date
            !controlDate.hasMonthValue() && // there is no month value yet
            !controlDate.isYearUnknown() // and the year has not been set to unknown
        ) {
            isRequiredError = true;
        }
        //has a month, and month is required, when the question is optional
        if (
            !this.element.isRequired && // optional question
            !this.element.removeMonth && // has a month field
            this.element.isMonthRequired && // month is required
            controlDate.hasMonthValue() && // there is a month value
            !controlDate.hasYearValue() && //if there is no year
            !controlDate.isYearUnknown() // and the year has not been set to unknown
        ) {
            isRequiredError = true;
        }

        // confirm day field
        if (
            this.element.isRequired && //question is required
            !this.element.removeMonth &&
            !this.element.removeDay && // there is a day field
            this.element.isDayRequired && // the day is required
            !controlDate.hasDayValue() && // there is no value for the day yet
            !(controlDate.isYearUnknown() || controlDate.isMonthUnknown()) // neither or year or month are unknown
        ) {
            isRequiredError = true;
        }

        //when the question is optional but day is required
        if (
            !this.element.isRequired && // question is optional
            !this.element.removeMonth && // there is a day field allowed
            !this.element.removeDay && // there is a day field
            this.element.isDayRequired && // the day is required
            (controlDate.hasYearValue() || controlDate.hasMonthValue()) && //the user has started entering some data
            !controlDate.hasDayValue() && // there is no day value yet
            !(controlDate.isYearUnknown() || controlDate.isMonthUnknown()) // neither the year nor month are unknowns
        ) {
            isRequiredError = true;
        }

        //when the question is optional but day is required
        if (
            !this.element.isRequired && // question is optional
            !this.element.removeMonth && // there is a day field allowed
            !this.element.removeDay && // there is a day field
            this.element.isDayRequired && // the day is required
            controlDate.hasDayValue() && // there is no day value yet
            !(controlDate.hasYearValue() && controlDate.hasMonthValue()) && //the user hasn't entered year and month
            !(controlDate.isYearUnknown() || controlDate.isMonthUnknown()) // neither the year nor month are unknowns
        ) {
            isRequiredError = true;
        }
        return isRequiredError;
    }

    validators() {
        const rangeValidator: ValidatorFn = (control: AbstractControl) => {
            const errors: any = {};
            if (control.value) {
                let minDateCompare = new PartialDate();
                minDateCompare.setDateFromDate(control.value);
                let maxDateCompare = new PartialDate();
                maxDateCompare.setDateFromDate(control.value);
                if (minDateCompare) {
                    if (!minDateCompare.day) minDateCompare.day = this.minDate?.day;
                    if (!minDateCompare.month) minDateCompare.month = this.minDate?.month;
                    if (this.minDate?.isAfter(minDateCompare)) {
                        errors.min = {};
                        errors.min.min = this.date_parser.format(this.minDate);
                    }
                }
                if (maxDateCompare) {
                    if (!maxDateCompare.day) maxDateCompare.day = this.maxDate?.day;
                    if (!maxDateCompare.month) maxDateCompare.month = this.maxDate?.month;
                    if (this.maxDate?.isBefore(maxDateCompare)) {
                        errors.max = {};
                        errors.max.max = this.date_parser.format(this.maxDate);
                    }
                }
            }
            return Object.keys(errors).length > 0 ? errors : null;
        };

        //if the question is required
        const requiredValidator: ValidatorFn = (control: AbstractControl) => {
            const errors: any = {};

            let controlDate = new PartialDate();
            controlDate.setDateFromAny(control.value);

            if (this.hasRequiredError(controlDate)) {
                errors.required = {};
            }
            return Object.keys(errors).length > 0 ? errors : null;
        };

        const compareDateValidatorFn: ValidatorFn = (control: AbstractControl) => {
            let errors: any = {};
            let controlDate = new PartialDate();
            controlDate.setDateFromAny(control.value);
            if (!this.hasRequiredError(controlDate)) {
                errors = this.compareDateValidator();
            }
            return Object.keys(errors).length > 0 ? errors : null;
        };
        return [requiredValidator, rangeValidator, compareDateValidatorFn];
    }

    getTranslation(value: any) {
        return typeof value === 'string' ? this.translate.instant(value) : value;
    }
}
