<div *ngIf="mode === 'view'; else formModeTemplate">
    <div *ngIf="!disabled" class="pi-control-edit-mode">
        <app-pi-form-view-response
            [element]="element"
            [theFormControl]="formControl"
            [displayValue]="stringValue"
        ></app-pi-form-view-response>
    </div>
</div>
<ng-template #formModeTemplate>
    <div *ngIf="!disabled && controlFormGroup">
        <div [formGroup]="controlFormGroup" class="row pi-control-form-mode">
            <app-element-title [element]="element"></app-element-title>

            <div *ngFor="let item of element.items" class="mt-2">
                <app-pi-control-text
                    [element]="item"
                    [formControl]="controlFormGroup.controls[item.name]"
                ></app-pi-control-text>
            </div>
            <app-validator [control]="formControl"></app-validator>
        </div></div
></ng-template>
