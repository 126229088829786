import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from '@angular/forms';
import { IFormElement } from '@h20-services/models/forms/IFormElement';
import { AbstractPiControlComponent } from '../abstract-pi-control/abstract-pi-control.component';

enum TimeFormats {
    hour24 = '24 hours',
    hour12 = '12 hours',
}
@Component({
    selector: 'app-pi-control-time',
    templateUrl: './pi-control-time.component.html',
    styleUrls: ['./pi-control-time.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PiControlTimeComponent,
        },
    ],
})
export class PiControlTimeComponent
    extends AbstractPiControlComponent<IFormElement, any>
    implements OnInit
{
    selectedTimeFormat = localStorage.getItem('prefTimeFormat');
    meridian = false;
    pickerValue = null;

    ngOnInit() {
        this.meridian = !this.selectedTimeFormat || this.selectedTimeFormat === TimeFormats.hour12;
        const currentTime = new Date();
        //super.ngOnInit();  will add incorrect validators
        if (this.element.isRequired) {
            this.formControl.addValidators(Validators.required);
            if (this.element.requiredErrorText !== undefined) {
                this.customErrorMessages['required'] = this.element.requiredErrorText;
            }
        }
        this.formControl.addValidators(this.validators());
    }
    timeInput(newTime) {
        this.markAsTouched();
        if (!newTime) {
            this.pickerValue = {
                hour: 1,
                minute: Number.parseInt('00'),
            };
        } else this.pickerValue = newTime;
        this.writeValue(newTime?.hour ? this.formatTime(newTime) : null);
        this.notifyValueChange();
    }

    formatTime(time): string {
        const hour = this.padZero(time?.hour);
        const minute = this.padZero(time?.minute);
        return `${hour}:${minute}`;
    }

    padZero(value: number): string {
        return value < 10 ? '0' + value : value.toString();
    }

    hour(value) {
        const hour = Number.parseInt(value?.split(':')[0]);
        if (hour > 12) return hour - 12;
        return hour;
    }

    hour24(value) {
        return Number.parseInt(value.split(':')[0]);
    }

    minute(value) {
        return Number.parseInt(value.split(':')[1]);
    }

    minuteText(value) {
        return value?.split(':')[1];
    }

    amPm(value) {
        const amPmSwitch = Math.floor(Number.parseInt(value.split(':')[0]) / 12);
        const amPm = ['AM', 'PM'];
        return amPm[amPmSwitch];
    }

    timeString(value) {
        if (value) {
            return this.hour(value) + ':' + this.minuteText(value) + ' ' + this.amPm(value);
        }
    }

    setStringValue(): void {
        if (this.selectedTimeFormat === TimeFormats.hour12) {
            this.stringValue = this.timeString(this._value);
        } else {
            this.stringValue = this.hour(this._value) + ':' + this.minuteText(this._value);
        }

        if(this._value){
            this.pickerValue = {
                hour: this.hour24(this._value),
                minute: this.minute(this._value),
            };
        }
    }

    validators() {
        /* Currently, Min max validator will NOT cross midnight.
         *    The function assumes but does not enforce that the min time is
         *    before the max time in a 24 day 00:00 - 23:59
         * min 20:00 and max 02:00 will NOT accept any times and have inconsistent messaging
         *      rather than accepting times between 10pm and 2am as one might expect
         * */
        const minMaxValidator: ValidatorFn = (formGroup: UntypedFormGroup) => {
            const errors: any = {};
            const currentValue = this.formControl.value;
            if (currentValue) {
                if (this.element.min)
                    if (
                        this.hour24(this.element.min) > this.hour24(currentValue) ||
                        (this.hour24(this.element.min) == this.hour24(currentValue) &&
                            this.minute(this.element.min) > this.minute(currentValue))
                    ) {
                        errors.min = {};
                        errors.min.min = this.timeString(this.element.min);
                        if (this.element.minErrorText)
                            this.customErrorMessages['min'] = this.element.minErrorText;
                    }
                if (this.element.max)
                    if (
                        this.hour24(this.element.max) < this.hour24(currentValue) ||
                        (this.hour24(this.element.max) == this.hour24(currentValue) &&
                            this.minute(this.element.max) < this.minute(currentValue))
                    ) {
                        errors.max = {};
                        errors.max.max = this.timeString(this.element.max);
                        if (this.element.maxErrorText)
                            this.customErrorMessages['max'] = this.element.maxErrorText;
                    }
            }
            return Object.keys(errors).length > 0 ? errors : null;
        };
        const compareDateValidatorFn: ValidatorFn = () => {
            const errors: any = this.compareDateValidator();
            return Object.keys(errors).length > 0 ? errors : null;
        };
        return [minMaxValidator, compareDateValidatorFn];
    }
}
