<div *ngIf="mode === 'view'; else formModeTemplate">
    <div *ngIf="!disabled" class="pi-control-edit-mode">
        <app-pi-form-view-response
            [element]="element"
            [theFormControl]="formControl"
            [displayValue]="stringValue"
        ></app-pi-form-view-response>
    </div>
</div>
<ng-template #formModeTemplate>
    <div *ngIf="!disabled" class="row pi-control-form-mode">
        <app-element-title [element]="element"></app-element-title>

        <!-- Specific range for EQ-5D-5L Survey -->
            <div class="slider-element__rightEQ mt-3 mb-3">
                <div class="slider-left-desc">
                    <div
                        class="html-element"
                        [innerHTML]="getText(element.html)"
                        tabindex="0"
                        [attr.aria-label]="getText(element.html)"
                    ></div>

                    <div class="borderBoxEQ">
                        <span class="borderBoxCaption" *ngIf="element.description">{{
                            getText(element.description)
                        }}</span>
                        <span>{{ _value }}</span>
                    </div>
                </div>

                <div class="slideContainerBox">
                    <div class="slidecontainer sliderVerticalEQ">
                        <div class="slideContainerBG">
                            <ng-container *ngFor="let item of [].constructor(100); let i = index">
                                <div class="slideContainerBG__item" *ngIf="i % 10 === 0"></div>
                                <div class="slideContainerBG__item2" *ngIf="i % 10 !== 0"></div>
                            </ng-container>
                        </div>
                        <input
                            #eqRange
                            type="range"
                            [min]="element.min"
                            [max]="element.max"
                            [value]="_value || 50"
                            class="slider"
                            [attr.aria-label]="getText(element.title)"
                            id="eqrange"
                            (mousemove)="showHoverVal($event)"
                            (mouseleave)="hideHoverVal($event)"
                            (change)="onSlide(eqRange.value)"
                        />
                        <div class="slideContainerNum">
                            <ng-container
                                *ngFor="
                                    let item of [100, 90, 80, 70, 60, 50, 40, 30, 20, 10, 0];
                                    let i = index
                                "
                            >
                                <div class="sideContanerNum__max">
                                    {{ item }}
                                </div>
                            </ng-container>
                        </div>
                        <div class="slideContainerTop_Item">{{ getText(element.maxLabel) }}</div>
                        <div class="slideContainerBottom_Item">{{ getText(element.minLabel) }}</div>
                        <span
                            class="slider-hoverLabelEQ align-middle"
                            id="hover-labelEQ"
                            [ngClass]="hoverLabelShow === false ? 'd-none' : ''"
                        >
                            <i class="fa-solid fa-arrow-alt-left blue-arrow-sliderEQ"></i>
                            <span class="slider-hoverNumberEQ">{{ hoverLabelEQ }}</span>
                        </span>
                    </div>
                </div>
            </div>
        <!-- EQ-5D-5L range ends here -->

        <app-validator
             [control]="formControl"
            [customErrorMessages]="customErrorMessages"
            [warningControl]="warningControl"
            [customWarningMessages]="customWarningMessages"
        ></app-validator></div
></ng-template>
