import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '@h20-services/common.service';
import { IFormHtml } from '@h20-services/models/forms/types/IFormHtml';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-base-tile',
    template: ` <p>base tile works!</p> `,
    styleUrls: [],
})
export class BaseTileComponent {
    baseColours = [
        '#7cb5ec',
        '#434348',
        '#90ed7d',
        '#f7a35c',
        '#8085e9',
        '#f15c80',
        '#e4d354',
        '#2b908f',
        '#f45b5b',
        '#91e8e1',
    ];

    constructor(protected com_svc: CommonService) {}

    reloadChart() {}
}
