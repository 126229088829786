// PulseParticipant.ts
// Copyright 2023 Pulse Infoframe Inc

export class PulseParticipant {

    created:string = '';
    userID:string = '';
    preferredName:string = '';

    constructor(rdsSrc) {
        this.userID = rdsSrc.id || '';
        this.preferredName = rdsSrc.preferred_name || '';
        this.created = rdsSrc.created || '';
    }

    public getUserID() {
        return this.userID;
    }
    
    public getPreferredName() :string {
        return this.preferredName;
    }

    public static getLastCreated( ary:PulseParticipant[]) : PulseParticipant {
        if (!ary)
            return null;
        // set the last created prt
        ary.sort( (x, y) => +new Date(x.created) - +new Date(y.created) );
        return ary[ ary.length -1];
        
    }

}