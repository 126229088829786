<div *ngIf="mode === 'view'; else formModeTemplate">
    <div *ngIf="!disabled" class="pi-control-edit-mode">
        <app-pi-form-view-response
            [element]="element"
            [theFormControl]="formControl"
            [displayValue]="stringValue"
        ></app-pi-form-view-response>
    </div>
</div>
<ng-template #formModeTemplate>
    <div *ngIf="!disabled" class="w-100 row pi-control-form-mode">
        <label
            class="form-label"
            [ngClass]="
                !element.orientation || element.orientation === 'horizontal'
                    ? 'col-12 col-md-6 col-lg-8'
                    : 'col-12'
            "
            [attr.for]="element.id"
        >
            <app-element-title [element]="element"></app-element-title>
        </label>
        <div class="col-12 col-md-6 col-lg-4">
            <ngb-timepicker
                [(ngModel)]="pickerValue"
                [meridian]="meridian"
                spinners="true"
                size="medium"
                [disabled]="disabled || element?.readOnly"
                [placeholder]="element.placeHolder || ''"
                [id]="element.id"
                [aria-label]="getText(element.title)"
                (ngModelChange)="timeInput($event)"
                [min]="element.min"
                [max]="element.max"
            ></ngb-timepicker>
        </div>
        <app-validator
            [control]="formControl"
            [customErrorMessages]="customErrorMessages"
            [warningControl]="warningControl"
            [customWarningMessages]="customWarningMessages"
        ></app-validator></div
></ng-template>
