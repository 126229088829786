import { Injectable } from '@angular/core';
import { environment as env } from '@environment/environment';
import { Observable } from 'rxjs';
import { IScheduleData } from './models/schedule/IScheduleData';
import { IScheduleListData } from './models/schedule/IScheduleListData';
import { RequestService } from './request.service';

@Injectable({
    providedIn: 'root',
})
export class ScheduleService {
    constructor(private reqSvc: RequestService) {}

    listSchedules(): Observable<IScheduleListData[]> {
        const req: any = { operation: 'list' };
        return this.reqSvc.post(env.awsConfig.API.scheduleConfiguration, req);
    }

    createSchedule(
        site_ID: string,
        title: string,
        registry: string,
        graph: any
    ): Observable<string> {
        const req: any = {
            operation: 'create',
            data: {
                siteID: site_ID,
                title: title,
                registry: registry,
                graph: graph,
            },
        };
        return this.reqSvc.post(env.awsConfig.API.scheduleConfiguration, req);
    }

    getBySiteID(site_ID: string): Observable<IScheduleData> {
        const req: any = {
            operation: 'getBySiteID',
            data: {
                siteID: site_ID,
            },
        };
        return this.reqSvc.post(env.awsConfig.API.scheduleConfiguration, req);
    }

    updateSchedule(
        site_ID: string,
        title: string,
        registry: string,
        graph: any
    ): Observable<string> {
        const req: any = {
            operation: 'update',
            data: {
                siteID: site_ID,
                title: title,
                registry: registry,
                graph: graph,
            },
        };
        return this.reqSvc.post(env.awsConfig.API.scheduleConfiguration, req);
    }

    deleteSchedule(site_ID: string): Observable<string> {
        const req: any = {
            operation: 'delete',
            data: {
                siteID: site_ID,
            },
        };
        return this.reqSvc.post(env.awsConfig.API.scheduleConfiguration, req);
    }
}
