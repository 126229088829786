import { Component, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IFormElement } from '@h20-services/models/forms/IFormElement';
import { AbstractPiControlComponent } from '../abstract-pi-control/abstract-pi-control.component';

@Component({
    selector: 'app-pi-control-text',
    templateUrl: './pi-control-text.component.html',
    styleUrls: ['./pi-control-text.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PiControlTextComponent,
        },
    ],
})
export class PiControlTextComponent extends AbstractPiControlComponent<IFormElement, any> {
    textInput(text: any) {
        this.markAsTouched();
        this.writeValue(text.target.value);
        this.notifyValueChange();
    }
}
