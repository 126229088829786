import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import MiniSearch, { Options, SearchResult } from 'minisearch';
import { FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';

type BareSurvey = {
    title: string;
    pages: Array<{
        name: string;
        elements: Array<{
            type: 'panel';
            name: string;
            elements: Array<Question>;
        }>;
    }>;
};
type Choice = {
    text: string;
    value: string;
};
type Question = {
    '$src-reg': string;
    '$src-surv': string;
    type: string;
    name: string;
    title: string;
    isRequired: string;
    choices: Array<Choice>;
    validators: Array<any>;
    $id: string;
    $type: 'Question';
    $name: string;
    $version: string;
    $tags: Record<string, string>;
};
type QuestionSet = {
    questions: Array<Question>;
    $id: string;
    $type: 'QSet';
    $name: string;
    $version: string;
    $tags: Record<string, string>;
};

type QwikSearchResult = {
    item: any;
    show: boolean;
    toggle: () => void;
    name: string;
    $tags: Record<string, string>;
    control: FormControl;
    id: string;
};
const miniSearchOpts: Options = {
    fields: ['$name'],
    idField: '$id',
    storeFields: ['$name', '$tags', '$type'],
    searchOptions: { fuzzy: 0.2, prefix: true },
};

@Component({
    selector: 'app-fragment-search',
    templateUrl: './fragment-search.component.html',
    styleUrls: ['./fragment-search.component.scss'],
})
export class FragmentSearchComponent implements OnInit {
    constructor(private http: HttpClient) {}

    private static readonly QUESTION_SOURCE_URI = 'assets/survey-builder/qlib/questions.0.0.1.json';
    private static readonly QSET_SOURCE_URI = 'assets/survey-builder/qlib/questionSets.0.0.1.json';

    private static questionDb: Array<Question>;
    private static qSetDb: Array<QuestionSet>;

    loading = false;
    loadFailed = false;

    results: Array<QwikSearchResult> = [];
    ms: MiniSearch<Question | QuestionSet>;
    @Output() addElementEvent = new EventEmitter<object>();
    ngOnInit() {
        if (!FragmentSearchComponent.qSetDb || !FragmentSearchComponent.questionDb) {
            this.reloadDb();
        } else {
            this.ms = new MiniSearch(miniSearchOpts);
            this.ms.addAll(FragmentSearchComponent.questionDb);
            this.ms.addAll(FragmentSearchComponent.qSetDb);
        }
    }

    reloadDb() {
        this.loading = true;
        this.loadFailed = false;
        const questionReq = this.http.get<Array<Question>>(
            FragmentSearchComponent.QUESTION_SOURCE_URI,
            { headers: { 'Cache-Control': 'public, max-age=31536000, immutable' } }
        );
        const qSetReq = this.http.get<Array<QuestionSet>>(FragmentSearchComponent.QSET_SOURCE_URI, {
            headers: { 'Cache-Control': 'public, max-age=31536000, immutable' },
        });
        forkJoin({ q: questionReq, qSet: qSetReq }).subscribe({
            next: (x) => this.updateDbFromResponse(x),
            error: (x) => {
                console.error(x);
                this.loadFailed = true;
                this.loading = false;
            },
        });
    }

    updateDbFromResponse(newDbResp: { q: Array<Question>; qSet: Array<QuestionSet> }) {
        FragmentSearchComponent.questionDb = newDbResp.q;
        FragmentSearchComponent.qSetDb = newDbResp.qSet;
        this.ms = new MiniSearch(miniSearchOpts);
        this.ms.addAll(FragmentSearchComponent.questionDb);
        this.ms.addAll(FragmentSearchComponent.qSetDb);
        this.loading = false;
    }

    searchInput(event) {
        const searchText = event?.target?.value;

        if (!searchText) {
            this.results = [];
            return;
        }

        this.results = this.ms.search(searchText).map((v: SearchResult) => {
            return {
                item: undefined,
                control: undefined,
                name: v.$name,
                id: v.id,
                show: false,
                toggle: function () {
                    this.show = !this.show;
                    if (this.show) {
                        const questionOrSet = FragmentSearchComponent.getQuestionOrSetById(this.id);
                        if (questionOrSet.$type === 'QSet') {
                            this.item =
                                FragmentSearchComponent.WrapQuestionSetForPreview(questionOrSet);
                        } else {
                            this.item = questionOrSet;
                            this.control = new FormControl();
                        }
                    } else {
                        this.item = undefined;
                        this.control = undefined;
                    }
                },
                $tags: v.$tags,
                $type: v.$type,
            };
        });
    }

    private static WrapQuestionSetForPreview(qSet: QuestionSet): BareSurvey {
        return {
            title: 'Preview',
            pages: [
                {
                    name: 'page1',
                    elements: [
                        {
                            type: 'panel',
                            name: 'fakepanel',
                            elements: qSet.questions,
                        },
                    ],
                },
            ],
        };
    }

    private static getQuestionOrSetById(id: string): QuestionSet | Question {
        if (id.startsWith('QSET#')) {
            return FragmentSearchComponent.qSetDb.find((a) => {
                return a.$id === id;
            });
        } else {
            return FragmentSearchComponent.questionDb.find((a) => {
                return a.$id === id;
            });
        }
    }

    sendPrefab(id: string) {
        const prefab = FragmentSearchComponent.getQuestionOrSetById(id);

        if (prefab) {
            if (prefab.$type === 'QSet') {
                this.addElementEvent.emit(prefab.questions);
            } else {
                this.addElementEvent.emit([prefab]);
            }
        }
    }
}
