import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IRegistryConfiguration } from '@h20-services/models/registry_configuration/IRegistryConfiguration';
import {
    ICategory,
    IDownload,
    ILink,
    IMedia,
    IRegistryLibrary,
    ISubcat,
} from '@h20-services/models/registry_configuration/IRegistryLibrary';
import { ConfigService } from '@h20-services/config.service';
import { SiteService } from '@h20-services/site.service';
import { UserRole } from '@h20-services/models/role';

@Component({
    selector: 'app-library',
    templateUrl: './library.component.html',
    styleUrls: ['./library.component.scss'],
})
export class LibraryComponent implements OnInit {
    isInit: boolean;
    loading: boolean;
    saveLoading: boolean;

    linksModal: boolean;
    linkTemplate: ILink = {
        linkHeading: '',
        linkUrl: '',
        linkTitle: '',
        linkUrlPrefix: '',
        video: false,
    };

    mediaModal: boolean;
    mediaTemplate: IMedia = {
        mediaDescript: '',
        mediaFile: '',
    };

    downloadModal: boolean;
    downloadTemplate: IDownload = {
        downloadTitle: '',
        downloadUrl: '',
    };

    listRegConfigs: IRegistryConfiguration[];
    listRegistries: string[];
    listSites: any[];
    listRoles: string[] = [];

    selectedSite: string;
    selectedOrganization: string = 'default';
    selectedRole: string = 'default';
    selectedLibrary: any;

    selectedCategory: ICategory;
    selectedSubCat: ISubcat;
    Categories: ICategory[];
    categorySubCat: ISubcat[] = [];
    urlLinks: string[];
    registry_id: any;
    translate = { currentLang: 'en' };
    listLanguages = ['en'];
    listPages = ['page1'];
    pageName: string = 'page1';

    message: string;
    isNewLibrary: boolean = false;

    constructor(
        private config_svc: ConfigService,
        private sanitizer: DomSanitizer,
        private siteSvc: SiteService
    ) {}

    ngOnInit(): void {
        this.loading = true;
        this.isInit = false;

        this.config_svc
            .listRegistryConfig()
            .toPromise()
            .then((regRes: IRegistryConfiguration[]) => {
                this.listRegConfigs = regRes;
                this.listRegistries = regRes.map((reg) => reg.registry_id);
                this.loading = false;
                this.isInit = true;
            });

        for (const r in UserRole) {
            this.listRoles.push(UserRole[r]);
        }
    }

    getText(strOrLangs: any): string {
        if (!strOrLangs) return '';
        return strOrLangs['en'] || strOrLangs['default'];
    }

    async reloadRegistry(registry_id: string): Promise<void> {
        this.isInit = false;
        this.message = '';
        const reg = this.listRegConfigs.find((reg) => reg.registry_id === registry_id);
        this.registry_id = registry_id;

        if (reg.languages) this.listLanguages = JSON.parse(reg.languages);
        this.translate = { currentLang: 'en' };

        this.listSites = await this.siteSvc.getSitesByRegistry(this.registry_id).toPromise();
        if (!this.selectedSite || this.selectedSite === '') this.message = 'Please select a site.';
        else this.reloadSite(this.listSites[0].name);
        this.isInit = true;
    }

    reloadSite(site: string): void {
        this.message = '';
        this.selectedSite = site;
        this.reloadListPages();
        this.reloadContent();
    }

    reloadOrganization(org: string): void {
        this.selectedOrganization = org;
        this.reloadListPages();
        this.reloadContent();
    }

    reloadRole(role: string): void {
        this.selectedRole = role;
        this.reloadListPages();
        this.reloadContent();
    }

    reloadLanguage(language: string): void {
        this.translate = { currentLang: language };
        this.reloadListPages();
        this.reloadContent();
    }

    reloadPage(page: string): void {
        this.pageName = page;
        this.reloadContent();
    }

    async reloadListPages(): Promise<void> {
        this.listPages = [];
        this.pageName = 'page1';
        const layoutRole = this.selectedRole === 'default' ? 'patient' : this.selectedRole;
        const layoutConfig = await this.config_svc
            .getLayoutConfig_XX(
                this.registry_id,
                this.selectedOrganization,
                this.selectedSite,
                layoutRole
            )
            .toPromise();

        if (layoutConfig.Item && layoutConfig.Item.menu_bar) {
            const menuBarItem = JSON.parse(layoutConfig.Item.menu_bar)[this.translate.currentLang];
            for (const item of menuBarItem) {
                if (item.id.split('_')[0] === 'library') {
                    this.listPages.push(item.id.split('_')[1]);
                }
            }
        }
        if (this.listPages.length === 0) this.listPages = ['page1'];
    }

    async reloadContent(): Promise<void> {
        this.isInit = false;
        this.isNewLibrary = false;
        this.selectedCategory = null;
        this.categorySubCat = null;
        this.selectedLibrary = null;

        try {
            const libConfig = await this.config_svc
                .getLibraryConfig(
                    this.registry_id,
                    this.selectedOrganization,
                    this.selectedSite,
                    this.selectedRole
                )
                .toPromise();

            if (libConfig.Item && libConfig.Item.library) {
                this.selectedLibrary = libConfig.Item;
                let libraryConfig: IRegistryLibrary = JSON.parse(this.selectedLibrary.library);
                if (libraryConfig[this.translate.currentLang]) {
                    libraryConfig = JSON.parse(this.selectedLibrary.library)[
                        this.translate.currentLang
                    ][this.pageName];
                    const lib: IRegistryLibrary = this.config_svc.replacePath(libraryConfig);
                    this.Categories = lib.Categories;
                } else {
                    // For a new language library
                    this.Categories = [];
                }
            } else {
                // For a new library
                this.Categories = [];
            }
        } catch (err) {
            console.error(err);
            this.Categories = [];
        }
        this.isInit = true;
    }

    async saveLibrary(): Promise<void> {
        if (!this.registry_id || !this.selectedSite)
            this.message = 'Please select a registry and a site.';
        else {
            this.saveLoading = true;
            let libraryConfig: IRegistryLibrary = JSON.parse(this.selectedLibrary.library);
            if (libraryConfig[this.translate.currentLang]) {
                libraryConfig[this.translate.currentLang][this.pageName].Categories =
                    this.Categories;
            } else {
                const newLangLibrary = { [this.pageName]: { Categories: this.Categories } };
                libraryConfig[this.translate.currentLang] = newLangLibrary;
            }
            this.selectedLibrary.library = JSON.stringify(libraryConfig);

            if (this.isNewLibrary) {
                this.config_svc
                    .createLibraryConfig(
                        this.registry_id,
                        this.selectedOrganization,
                        this.selectedSite,
                        this.selectedRole,
                        this.selectedLibrary
                    )
                    .toPromise()
                    .then(() => {
                        this.saveLoading = false;
                        this.reloadContent();
                        this.isNewLibrary = false;
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            } else {
                this.config_svc
                    .updateLibraryConfig(
                        this.registry_id,
                        this.selectedOrganization,
                        this.selectedSite,
                        this.selectedRole,
                        this.selectedLibrary,
                        'library',
                        this.selectedLibrary.library
                    )
                    .toPromise()
                    .then(() => {
                        this.saveLoading = false;
                        this.reloadContent();
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }
            this.saveLoading = false;
        }
    }

    onSelect(section: ICategory): void {
        this.selectedCategory = section;
        this.categorySubCat = section.Subcats;
        this.urlLinks = this.corrigirUrlYoutube();
    }

    goToLink(url: string) {
        window.open(url, '_blank');
    }

    showFor(): boolean {
        return true;
    }

    createLibrary(): void {
        this.isNewLibrary = true;
        this.selectedLibrary = { library: {} };
        const newLibrary = {
            [this.translate.currentLang]: { [this.pageName]: { ['Categories']: {} } },
        };
        this.selectedLibrary.library = JSON.stringify(newLibrary);
    }

    corrigirUrlYoutube(): any[] {
        var arr = [];
        for (var i = 0; i < this.categorySubCat.length; i++) {
            arr[i] = new Array();
            for (var ind = 0; ind < this.categorySubCat[i].Links?.length; ind++) {
                arr[i][ind] = this.sanitizer.bypassSecurityTrustResourceUrl(
                    this.getText(this.categorySubCat[i].Links[ind].linkUrl)
                );
            }
        }
        return arr;
    }

    addCategory() {
        this.Categories.push({
            catId: `${this.Categories.length + 1}`,
            catTitle: '',
            catTooltip: '',
            catDescription: '',
            Subcats: [],
            catDateModified: new Date().toLocaleDateString('en-US'),
        });
        this.selectedCategory = this.Categories[this.Categories.length - 1];
    }

    onSectionChange(evt: string): void {
        this.selectedCategory.catTitle[this.translate.currentLang] = evt;
    }

    removeCategory(section): void {
        this.Categories.splice(this.Categories.indexOf(section), 1);

        this.selectedCategory = null;
        this.categorySubCat = [];
    }

    addSubCategory(subCats: ISubcat[]): void {
        subCats.push({
            subId: `${subCats.length + 1}`,
            subTitle: '',
            subHeading: '',
            subDateAdded: new Date().toLocaleDateString('en-US'),
            linkExternalUrl: '',
            libSource: '',
            imgThumb: '',
            imgAlt: '',
            subContent: '',
        });
    }

    removeSubcategory(subCats: ISubcat[], subCat: ISubcat): void {
        subCats.splice(subCats.indexOf(subCat), 1);
    }

    addLink(subCat: ISubcat): void {
        if (!subCat.Links) subCat.Links = [];

        this.selectedSubCat = subCat;
        this.linksModal = true;
    }

    saveLink(ctx): void {
        ctx.selectedSubCat.Links.push(ctx.linkTemplate);
        ctx.linkTemplate = {
            linkHeading: { en: '', default: '' },
            linkUrl: { en: '', default: '' },
            linkTitle: { en: '', default: '' },
            linkUrlPrefix: { en: '', default: '' },
            video: false,
        };

        ctx.linksModal = false;
    }

    removeLink(subCat: ISubcat, link: any): void {
        subCat.Links.splice(subCat.Links.indexOf(link), 1);

        if (subCat.Links.length === 0) {
            delete subCat.Links;
        }
    }

    addMedia(subCat: ISubcat): void {
        if (!subCat.Media) subCat.Media = [];

        this.selectedSubCat = subCat;
        this.mediaModal = true;
    }

    saveMedia(ctx): void {
        ctx.selectedSubCat.Media.push(ctx.mediaTemplate);
        ctx.mediaTemplate = {
            mediaDescript: { en: '', default: '' },
            mediaFile: { en: '', default: '' },
        };

        ctx.mediaModal = false;
    }

    removeMedia(subCat: ISubcat, media: any): void {
        subCat.Media.splice(subCat.Media.indexOf(media), 1);

        if (subCat.Media.length === 0) {
            delete subCat.Media;
        }
    }

    addDownload(subCat: ISubcat): void {
        if (!subCat.Download) subCat.Download = [];

        this.selectedSubCat = subCat;
        this.downloadModal = true;
    }

    saveDownload(ctx): void {
        ctx.selectedSubCat.Download.push(ctx.downloadTemplate);
        ctx.downloadTemplate = {
            downloadTitle: { en: '', default: '' },
            downloadUrl: { en: '', default: '' },
        };

        ctx.downloadModal = false;
    }

    removeDownload(subCat: ISubcat, download: any): void {
        subCat.Download.splice(subCat.Download.indexOf(download), 1);

        if (subCat.Download.length === 0) {
            delete subCat.Download;
        }
    }
}
