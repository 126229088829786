import { Component, OnInit } from '@angular/core';
import { JsonEditorOptions } from 'ang-jsoneditor';
import { EnvType } from '@h20-services/common.service';
import { ConfigService } from '@h20-services/config.service';
import { DashboardService } from '@h20-services/dashboard.service';
import { IDashboardConfigData } from '@h20-services/models/config/IDashboardConfig';
import { IRegistryConfiguration } from '@h20-services/models/registry_configuration/IRegistryConfiguration';

@Component({
    selector: 'app-manage-dashboard',
    templateUrl: './dashboard-management.component.html',
    styleUrls: ['./dashboard-management.component.scss'],
})
export class DashboardManagementComponent implements OnInit {
    isInit: boolean;
    loading: boolean;
    saving: boolean;
    registriesLoading = true;
    envs = [EnvType.Devops];
    lstSiteRole;
    targetEnv: string = EnvType.Devops;
    selectedSiteRole;
    siteLoading = false;
    lstRegistries;
    highchartsConfig;
    tableauConfig;
    tempHighchartsChange;
    options = new JsonEditorOptions();
    tileList = ['Map', 'HTML', 'Pie Chart', 'Bar Chart', 'Counter'];
    showTableauConfig = false;
    showHighchartsConfig = false;

    listRegConfigs: IRegistryConfiguration[];
    listRegistries: string[];

    selectedRegistry: IRegistryConfiguration;
    selectedRegistryId: string;
    dashboardConfigs: IDashboardConfigData[];
    selectedConfig: any;
    selectedConfigKey: string;

    constructor(private config_svc: ConfigService, private dashboardSvc: DashboardService) {}

    ngOnInit(): void {
        this.isInit = false;
        this.loading = true;
        this.options.mode = 'code';
        this.options.modes = ['code', 'text', 'tree', 'view'];

        this.loadRegistries();
    }

    loadRegistries() {
        this.registriesLoading = true;
        return this.config_svc.listRegistryConfig(this.targetEnv).subscribe(
            (res) => {
                this.lstRegistries = [...res];
                this.registriesLoading = false;
            },
            (err: any) => {
                this.setErrorHandler(err, this.targetEnv);
                this.registriesLoading = false;
            }
        );
    }

    registryChange(value: any): void {
        this.siteLoading = true;
        this.selectedRegistry = this.lstRegistries.find((reg) => reg.registry_id === value);
        this.selectedRegistryId = this.selectedRegistry.registry_id;
        this.dashboardSvc.listDashboardConfigs(this.targetEnv).subscribe(
            (res) => {
                this.dashboardConfigs = res;
                this.lstSiteRole = [];
                res.forEach((item) => {
                    let temp = item['registry_id#organization#site_id#role'].split('#');
                    if (temp[0] == this.selectedRegistry.registry_id) {
                        this.lstSiteRole.push({
                            name: temp[1] + '#' + temp[2] + '#' + temp[3],
                        });
                    }
                });
                this.siteLoading = false;
            },
            (err: any) => {
                this.setErrorHandler(err, this.targetEnv);
                this.siteLoading = false;
            }
        );
    }

    changeEnv(env) {
        //Only use dev rn
    }

    siteChange(value) {
        this.selectedConfigKey = `${this.selectedRegistry.registry_id}#${value}`;
        this.highchartsConfig = {
            rows: [],
        };
        this.tableauConfig = {
            views: [],
        };
        this.selectedConfig = null;
        this.dashboardConfigs.forEach((dc) => {
            if (dc['registry_id#organization#site_id#role'] === this.selectedConfigKey) {
                this.selectedConfig = dc;
                if (dc.highcharts_config) {
                    this.highchartsConfig = dc.highcharts_config;
                }
                if (dc.tableau_config) {
                    this.tableauConfig = dc.tableau_config;
                }
            }
        });
        this.loading = false;
    }

    setErrorHandler(err, env) {
        console.error(err);
        alert(`An error occurred on ${env}`);
    }

    addTile() {
        const tileSelected = (<HTMLSelectElement>document.getElementById('tileSelect')).value;
        const tile = this.buildTile(tileSelected);
        let temp = [...this.highchartsConfig.rows, tile];
        this.highchartsConfig.rows = temp;
    }

    buildTile(selectedTile: string) {
        switch (selectedTile) {
            case 'Pie Chart':
                return {
                    type: 'pie',
                    title: {
                        en: '',
                        default: '',
                    },
                    subTitle: {
                        en: '',
                        default: '',
                    },
                    colors: [],
                    query: '',
                };
            case 'HTML':
                return {
                    type: 'html',
                    html: '',
                };
            case 'Map':
                return {
                    type: 'map',
                    title: {
                        en: '',
                        default: '',
                    },
                    subTitle: {
                        en: '',
                        default: '',
                    },
                    mapNavigation: {
                        enableMouseWheelZoom: false,
                        enabled: true,
                        buttonOptions: {
                            alignTo: 'spacingBox',
                        },
                    },
                    enableLegend: true,
                    enableDataLabels: false,
                    minColor: '',
                    query: '',
                    hoverColor: '',
                    maxColor: '',
                    map: 'usandca',
                };
            case 'Counter':
                return {
                    type: 'counter',
                    title: {
                        en: '',
                        default: '',
                    },
                    subTitle: {
                        en: '',
                        default: '',
                    },
                    query: '',
                };
            case 'Bar Chart':
                return {
                    type: 'bar',
                    title: {
                        en: '',
                        default: '',
                    },
                    subTitle: {
                        en: '',
                        default: '',
                    },
                    colors: [],
                    xAxisTitle: {
                        en: '',
                        default: '',
                    },
                    yAxisTitle: {
                        en: '',
                        default: '',
                    },
                    legendEnabled: false,
                    query: '',
                };
            default:
                break;
        }
    }

    jsonEdit(event) {
        if (Array.isArray(event)) {
            this.tempHighchartsChange = event;
        }
    }

    saveHighcharts() {
        this.highchartsConfig.rows =
            this.tempHighchartsChange === undefined
                ? this.highchartsConfig.rows
                : this.tempHighchartsChange;

        if (this.highchartsConfig?.rows?.length > 0) {
            if (!this.selectedConfig) {
                this.selectedConfig = {
                    'registry_id#organization#site_id#role': this.selectedConfigKey,
                };
                this.selectedConfig['save_mode'] = 'create';
            } else {
                this.selectedConfig['save_mode'] = 'update';
            }
            if (this.selectedConfig.dashboard_type) {
                if (!this.selectedConfig.dashboard_type.hasOwnProperty('highcharts')) {
                    this.selectedConfig['dashboard_type']['highcharts'] = true;
                }
            } else {
                this.selectedConfig['dashboard_type'] = { highcharts: true };
            }
            this.selectedConfig.highcharts_config = this.highchartsConfig;
            this.dashboardSvc
                .saveHighchartsConfig(this.selectedConfig, this.targetEnv)
                .toPromise()
                .then((resp) => {})
                .catch((err) => {
                    this.setErrorHandler(err, this.targetEnv);
                });
        }
    }

    addConfig() {
        const orgSiteRole = prompt(
            'Please enter Organization#Site#Role:',
            `default#${this.selectedRegistryId}#role`
        );
        if (orgSiteRole) {
            this.lstSiteRole.push({ name: orgSiteRole });
        }
    }

    addTableauView() {
        this.tableauConfig.views.push({ visualUrl: '', viewName: '' });
    }

    removeTableauView(index) {
        this.tableauConfig.views.splice(index, 1);
    }

    saveTableau() {
        if (this.tableauConfig?.views?.length > 0) {
            if (!this.selectedConfig) {
                this.selectedConfig = {
                    'registry_id#organization#site_id#role': this.selectedConfigKey,
                };
                this.selectedConfig['save_mode'] = 'create';
            } else {
                this.selectedConfig['save_mode'] = 'update';
            }
            if (this.selectedConfig.dashboard_type) {
                if (!this.selectedConfig.dashboard_type.hasOwnProperty('tableau')) {
                    this.selectedConfig['dashboard_type']['tableau'] = true;
                }
            } else {
                this.selectedConfig['dashboard_type'] = { tableau: true };
            }
            this.selectedConfig.tableau_config = this.tableauConfig;
            this.dashboardSvc
                .saveTableauConfig(this.selectedConfig, this.targetEnv)
                .toPromise()
                .then((resp) => {})
                .catch((err) => {
                    this.setErrorHandler(err, this.targetEnv);
                });
        }
    }
}
