import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import Auth from '@aws-amplify/auth';
import { ClaimService } from 'src/h20-services/claim.service';
import { AuthService } from 'src/h20-services/auth.service';
import jwt_decode from 'jwt-decode';
import { PulseAuth } from 'src/h20-services/models/PulseAuth';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard  {
    role = 'guest';
    constructor(
        private router: Router,
        private claimService: ClaimService,
        private auth: AuthService
    ) {}
    userClaims;

    // I've left the modified original below; the Observable was giving me the agita in
    // trying to route to /info for someone not logged in or /dashboard if someone is. That'd
    // be my lack of understanding about how to return that Observable and I'd love to have
    // someone explain it to me in small words of a limited vocab, because it bothers me
    // I couldn't figure that out.

    canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        return new Promise((resolve) => {
            Auth.currentSession()
                .then((authSession) => {
                    const token = authSession.getIdToken().getJwtToken();
                    const guardClaims = route.data.claims as Array<string>;

                    if (!token || !guardClaims) {
                        // no jwt (not logged in); or no roles specified for this guard
                        console.warn('Access denied; not logged in and/or route misconfigured.');
                        this.router.navigate(['/login']);
                        resolve(false);
                    }

                    this.userClaims = this.claimService.getUserClaims();

                    if (!this.userClaims) {
                        console.error('Misconfigured user; No roles for user. Login not possible');
                        return false;
                    }

                    if (this.userClaims && Object.keys(this.userClaims).length === 0) {
                        this.auth.getPulseAuth().then((currAuthUser: PulseAuth) => {
                            this.claimService
                                .getAllClaimsByUser(currAuthUser)
                                .toPromise()
                                .then((res: any) => {
                                    this.claimService.setUserClaims(res);
                                    this.userClaims = this.claimService.getUserClaims();

                                    var isClaimOn = true;
                                    guardClaims.forEach((cl: string) => {
                                        // Check for the key which is the claim's name
                                        if (cl in this.userClaims) {
                                            isClaimOn = this.userClaims[cl];
                                        } else if ('all' in this.userClaims) isClaimOn = true;
                                    });

                                    if (isClaimOn) {
                                        resolve(true);
                                    } else {
                                        console.warn('Access denied.');
                                        this.router.navigate(['/login']);
                                        resolve(false);
                                    }
                                })
                                .catch((err: Error) => {
                                    console.error(err);
                                    this.router.navigate(['/login']);
                                    resolve(false);
                                });
                        });
                    } else {
                        var isClaimOn = false;
                        guardClaims.forEach((cl: string) => {
                            if (cl in this.userClaims) {
                                isClaimOn = this.userClaims[cl];
                            }
                        });

                        if (isClaimOn) {
                            resolve(true);
                        } else {
                            console.warn('Access denied.');
                            this.router.navigate(['/login']);
                            resolve(false);
                        }
                    }
                })
                .catch((err) => {
                    console.error(err);
                    this.router.navigate(['/login']);
                    resolve(false);
                });
        });
    }

    searchData(state: RouterStateSnapshot, dataKey: string): string | null {
        if (state.root.data && state.root.data[dataKey]) {
            return state.root.data[dataKey];
        }
        let child: ActivatedRouteSnapshot | null;
        child = state.root.firstChild;
        while (child != null) {
            if (child.data && child.data[dataKey]) {
                return child.data[dataKey];
            }
            child = child.firstChild;
        }
        return null;
    }
    getDecodedAccessToken(token: string): any {
        try {
            return jwt_decode(token);
        } catch (err) {
            console.error(err);
            return null;
        }
    }
}
