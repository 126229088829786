import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslationImportComponent } from './translation-import/translation-import.component';
import { TranslationExportComponent } from './translation-export/translation-export.component';
import { TranslationIORoutingModule } from './translation-io-routing.module';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { DiffComponent } from '@h20-shared/diff/diff.component';
import { SharedModule } from '@h20-shared/shared.module';

@NgModule({
    declarations: [TranslationImportComponent, TranslationExportComponent, DiffComponent],
    imports: [CommonModule, NgJsonEditorModule, TranslationIORoutingModule, SharedModule],
})
export class TranslationIOModule {}
