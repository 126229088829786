<div *ngIf="mode === 'view'; else formModeTemplate">
    <div *ngIf="!disabled" class="pi-control-edit-mode">
        <!--TODO post6.0.x - this is reimplemented here because 
            the displayValue would not update for linked vocabularies -->
        <div class="row border-bottom">
            <div class="col-12 col-md-8">
                <app-element-title [element]="element"></app-element-title>
            </div>
            <div class="col-12 col-md-4 text-end">
                <div
                    *ngIf="
                        !formControl.value || formControl?.value?.length === 0;
                        else valueTemplate
                    "
                    class=""
                >
                    {{ 'Registry.NoAnswer' | translate }}
                </div>
                <ng-template #valueTemplate>
                    <div *ngFor="let choice of element.choices">
                        <div
                            *ngIf="_value === choice.value"
                            [innerHTML]="getText(choice.text)"
                        ></div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>
<ng-template #formModeTemplate>
    <div *ngIf="!disabled" class="w-100 row pi-control-form-mode" role="radiogroup">
        <div
            *ngIf="
                !element.orientation || element.orientation === 'vertical';
                else horizontalLayout
            "
        >
            <app-element-title [element]="element"></app-element-title>
            <div *ngIf="element.noChoicesError" class="alert alert-warning" role="alert">
                <i class="fa-regular fa-circle-question"></i>
                &nbsp; {{ getText(element.noChoicesError) }}
            </div>
            <div
                class="form-check ms-3"
                [ngClass]="element.choices?.length > 6 ? 'add-vertical-scroll' : ''"
            >
                <app-loading-component [loading]="loading">
                    <div class="" *ngFor="let choice of element.choices">
                        <input
                            type="radio"
                            [value]="choice.value"
                            [attr.aria-label]="getText(choice.text)"
                            [attr.id]="elementUID + choice.value"
                            [name]="elementUID"
                            class="form-check-input"
                            [attr.checked]="_value === choice.value ? '' : null"
                            (click)="selectionMade($event, choice)"
                        />
                        <div class="w-100">
                            <label
                                class="form-check-label"
                                [attr.for]="elementUID + choice.value"
                                [innerHTML]="getText(choice.text)"
                            ></label
                            ><span
                                *ngIf="choice.tooltip"
                                [tooltip]="getText(choice.tooltip)"
                                [options]="tooltipOptions"
                                class="ms-2"
                            >
                                <i aria-label="Info icon" class="fa-solid fa-info-circle"></i>
                            </span>
                        </div>
                    </div>
                </app-loading-component>
            </div>
        </div>

        <ng-template #horizontalLayout>
            <div class="d-flex align-items-end">
                <app-element-title class="horizontal-label" [element]="element"></app-element-title>

                <div
                    class="form-horizontal d-flex flex-column align-items-center"
                    *ngFor="let choice of element.choices"
                    (click)="selectionMade($event, choice)"
                >
                    <div class="w-100 text-center">
                        <label
                            *ngIf="element.labelPosition !== 'none'"
                            class="form-check-label"
                            [attr.for]="elementUID + choice.value"
                            [innerHTML]="getText(choice.text)"
                        ></label
                        ><span
                            *ngIf="choice.tooltip"
                            [tooltip]="getText(choice.tooltip)"
                            [options]="tooltipOptions"
                            class="ms-2"
                        >
                            <i aria-label="Info icon" class="fa-solid fa-info-circle"></i>
                        </span>
                    </div>
                    <input
                        type="radio"
                        class="form-check-input horizontal-radio"
                        [value]="choice.value"
                        [attr.aria-label]="getText(choice.text)"
                        [attr.id]="elementUID + choice.value"
                        [name]="elementUID"
                        [attr.checked]="_value === choice.value ? '' : null"
                    />
                </div>
            </div>
        </ng-template>

        <app-validator
            [control]="formControl"
            [customErrorMessages]="customErrorMessages"
            [warningControl]="warningControl"
            [customWarningMessages]="customWarningMessages"
        ></app-validator>
    </div>
</ng-template>
