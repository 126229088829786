<div class="container-fluid panel-control">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <h1>Registration Content:</h1>
                    <label
                        >Select Registry:
                        <select
                            class="form-control"
                            (change)="reloadRegistrationContent($event.target.value)"
                        >
                            <option selected disabled>select</option>
                            <option *ngFor="let reg of listRegistries">{{ reg }}</option>
                        </select></label
                    >
                    <app-button
                        *ngIf="isInit"
                        class="mx-5"
                        (action)="saveWebAssets()"
                        [buttonText]="'Registry.Save'"
                    />
                    <hr />
                    <app-loading-component [loading]="loading"></app-loading-component>

                    <div *ngIf="isInit">
                        <ul class="nav-flex">
                            <li>
                                <app-button
                                    (action)="onclick_Branding()"
                                    [buttonText]="'Registry.brandingPlatform'"
                                />
                            </li>
                            <li>
                                <app-button
                                    (action)="onclick_Library()"
                                    [buttonText]="'Registry.registrationDetails'"
                                />
                            </li>
                        </ul>
                    </div>

                    <div *ngIf="branding_platform">
                        <div class="row right-panel">
                            <div *ngFor="let section of brandingPlatformSections">
                                <div>
                                    {{ section[0] }} :
                                    <div *ngFor="let sec of section[1]; let i = index" class="tab">
                                        <input
                                            class="form-control"
                                            [id]="section[0] + '_' + i"
                                            value="{{ sec[0] !== '' ? sec[0] : '' }}"
                                            style="text-indent: 2em"
                                            placeholder="name"
                                            (change)="onTextChange(section[0], 0, $event, i)"
                                        />
                                        <input
                                            class="form-control"
                                            [value]="sec[1] !== undefined ? sec[1] : ''"
                                            style="text-indent: 2em"
                                            placeholder="value"
                                            (change)="onTextChange(section[0], 1, $event, i)"
                                        />
                                    </div>
                                </div>
                                <button (click)="addSubSection(section[0])">Add</button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="registrationDetails">
                        <div class="row right-panel">
                            <div class="row right-panel">
                                <div *ngFor="let section of registrationDetailsSections">
                                    <div>
                                        <h4>{{ section[0] }} :</h4>
                                        <div
                                            *ngFor="let sec of section[1]; let i = index"
                                            class="tab"
                                        >
                                            <input
                                                class="form-control"
                                                [id]="section[0] + '_' + i"
                                                value="{{ sec[0] !== '' ? sec[0] : '' }}"
                                                style="text-indent: 2em"
                                                placeholder="name"
                                                (change)="
                                                    onTextChange_RegistrationDetails(
                                                        section[0],
                                                        0,
                                                        $event,
                                                        i
                                                    )
                                                "
                                            />

                                            <div *ngIf="sec[1] === undefined || !sec[1].elements">
                                                <input
                                                    class="form-control"
                                                    [value]="sec[1] !== undefined ? sec[1] : ''"
                                                    style="text-indent: 2em"
                                                    placeholder="value"
                                                    (change)="
                                                        onTextChange_RegistrationDetails(
                                                            section[0],
                                                            1,
                                                            $event,
                                                            i
                                                        )
                                                    "
                                                />
                                            </div>
                                            <div *ngIf="sec[1] !== undefined && sec[1].elements">
                                                <div
                                                    *ngFor="
                                                        let elem of sec[1].elements;
                                                        let elemindex = index
                                                    "
                                                >
                                                    Icon:
                                                    <input
                                                        class="form-control"
                                                        [value]="elem.icon"
                                                        (change)="
                                                            onTextChange_RegistrationDetails(
                                                                sec[1].elements,
                                                                1,
                                                                $event,
                                                                i
                                                            )
                                                        "
                                                    />
                                                    <h4>Subtitle:</h4>
                                                    <div
                                                        *ngFor="
                                                            let subtitle of elem.subtitle;
                                                            let subtitleindex = index
                                                        "
                                                    >
                                                        <input
                                                            class="form-control"
                                                            [value]="subtitle[0]"
                                                            (change)="
                                                                onTextChange_RegistrationDetails_subtitle(
                                                                    'subtitle',
                                                                    $event,
                                                                    subtitleindex,
                                                                    elemindex
                                                                )
                                                            "
                                                        />
                                                        <input
                                                            class="form-control"
                                                            [value]="subtitle[1]"
                                                            (change)="
                                                                onTextChange_RegistrationDetails_subtitle(
                                                                    'subtitle',
                                                                    $event,
                                                                    i,
                                                                    elemindex
                                                                )
                                                            "
                                                        />
                                                    </div>
                                                    <h4>Title:</h4>
                                                    <div
                                                        *ngFor="
                                                            let title of elem.title;
                                                            let titleindex = index
                                                        "
                                                    >
                                                        <input
                                                            class="form-control"
                                                            [value]="title[0]"
                                                            (change)="
                                                                onTextChange_RegistrationDetails(
                                                                    'title',
                                                                    1,
                                                                    $event,
                                                                    titleindex
                                                                )
                                                            "
                                                        />
                                                        <input
                                                            class="form-control"
                                                            [value]="title[1]"
                                                            (change)="
                                                                onTextChange_RegistrationDetails(
                                                                    'title',
                                                                    1,
                                                                    $event,
                                                                    i
                                                                )
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button (click)="addRegistrationDetailsSections(section[0])">
                                        Add
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
