import { Component, OnInit } from '@angular/core';
import { ConfigService } from '@h20-services/config.service';
@Component({
    selector: 'app-webassets',
    templateUrl: './webassets.component.html',
    styleUrls: ['./webassets.component.scss'],
})
export class WebassetsComponent implements OnInit {
    listRegistries: string[];
    loading: boolean;
    brandingPlatformSections: any[];
    librarySections: any[];
    isInit: boolean;
    branding_platform: boolean;
    registrationDetails: boolean;
    library: boolean;
    registrationDetailsSections: any[];
    attributeName: any;
    registry: any;
    constructor(private config_svc: ConfigService) {}

    ngOnInit(): void {
        this.isInit = false;
        this.loading = true;
        this.config_svc
            .listRegistryConfig()
            .toPromise()
            .then((regRes) => {
                this.listRegistries = regRes.map((reg) => reg.registry_id);
                this.loading = false;
            });
    }
    /*
    testFunction(v:any[]){
        let result:any[];
        result =v.map(section=>{
            this.test += "<div>" 
            if(section[1] instanceof Object){
                this.test += section[0];
                section[1] = Object.entries(section[1]);
                if(section[0] == 'infoSections'|| section[0] == 'elements'){
                   this.testFunction(section[1]);
                }else{
                this.testFunction(section[1]);}
            }else{
                
                this.test += section[0];
                section[1] =Object.entries({"":section[1]});
               
                this.test += ": " + section[1][0][1];
            }
            this.test += "</div>";
            return section;
        });
    }

    */

    reloadRegistrationContent(registry) {
        this.isInit = true;
        this.loading = true;
        this.registry = registry;
        this.config_svc
            .getConfigurations(registry)
            .then((res) => {
                this.brandingPlatformSections = Object.entries(
                    JSON.parse(res.Item.branding_platform)
                ).map((section) => {
                    if (section[1] instanceof Object) {
                        section[1] = Object.entries(section[1]);
                    } else {
                        section[1] = Object.entries({ '': section[1] });
                    }
                    return section;
                });

                if (res.Item.registrationDetails) {
                    this.registrationDetailsSections = Object.entries(
                        JSON.parse(res.Item.registrationDetails)
                    ).map((section) => {
                        if (section[1] instanceof Object) {
                            section[1] = Object.entries(section[1]);
                            Object.entries(section[1]).map((sec) => {
                                if (sec[1][1].elements) {
                                    sec[1][1].elements.map((se) => {
                                        if (se.subtitle) {
                                            se.subtitle = Object.entries(se.subtitle);
                                        }
                                        if (se.title) {
                                            se.title = Object.entries(se.title);
                                        }
                                    });
                                }
                            });
                        } else {
                            section[1] = Object.entries({ '': section[1] });
                        }
                        return section;
                    });
                }
            })
            .catch((err) => {})
            .finally(() => {
                this.loading = false;
            });
    }

    saveWebAssets() {
        let j_config = {};
        if (this.branding_platform) {
            this.attributeName = 'branding_platform';
            this.brandingPlatformSections.map((sec) => {
                if (sec[1][0][0] == '') {
                    j_config[sec[0]] = sec[1][0][1];
                } else if (sec[1][0][0] !== '') {
                    let config = {};
                    sec[1].map((en) => {
                        config[en[0]] = en[1];
                    });
                    j_config[sec[0]] = config;
                }
            });
        } else if (this.registrationDetails) {
            this.attributeName = 'registrationDetails';
            this.registrationDetailsSections.map((sec) => {
                if (sec[1][0][0] == '') {
                    j_config[sec[0]] = sec[1][0][1];
                } else if (sec[1][0][0] !== '') {
                    if (sec[0] == 'infoSections') {
                        let config = [];
                        j_config[sec[0]] = config;
                        sec[1].map((m) => {
                            m[1].elements.map((el) => {
                                if (el.subtitle) {
                                    let subtitles = el.subtitle;
                                    let tempSubTitles = {};
                                    subtitles.map((e) => {
                                        tempSubTitles[e[0]] = e[1];
                                    });
                                    el.subtitle = tempSubTitles;
                                }

                                if (el.title) {
                                    let title = el.title;
                                    let tempTitle = {};
                                    title.map((e) => {
                                        tempTitle[e[0]] = e[1];
                                    });
                                    el.title = tempTitle;
                                }
                            });
                            config.push(m[1]);
                        });
                        j_config[sec[0]] = config;
                    } else {
                        let config = {};
                        sec[1].map((en) => {
                            config[en[0]] = en[1];
                        });
                        j_config[sec[0]] = config;
                    }
                }
            });
        }
        this.config_svc
            .updateWebAssets({
                registry_id: this.registry,
                attributeValue: JSON.stringify(j_config),
                attributeName: this.attributeName,
            })
            .subscribe((re) => {
                alert('Update Successful!');
            });
    }

    addSubSection(subSectionName: any) {
        this.brandingPlatformSections = this.brandingPlatformSections.map((sec) => {
            if (sec[0] == subSectionName) {
                sec[1].push({ '': '' });
            }
            return sec;
        });
    }

    addRegistrationDetailsSections(sectionName: any) {
        this.registrationDetailsSections = this.registrationDetailsSections.map((sec) => {
            if (sec[0] == sectionName) {
                sec[1].push(Object.entries({ '': '' }));
            }
            return sec;
        });
    }

    onTextChange(section, valueIndex, event, index) {
        this.brandingPlatformSections = this.brandingPlatformSections.map((sec) => {
            if (sec[0] == section) {
                sec[1][index][valueIndex] = event.target.value;
            }
            return sec;
        });
    }

    onTextChange_RegistrationDetails(section, valueIndex, event, index) {
        this.registrationDetailsSections = this.registrationDetailsSections.map((sec) => {
            if (sec[0] == section) {
                sec[1][index][valueIndex] = event.target.value;
            }
            return sec;
        });
    }

    onTextChange_RegistrationDetails_subtitle(section, event, index, elemindex) {
        Object.entries(this.registrationDetailsSections[6][1]).map((sec) => {
            sec[1][1].elements[elemindex][section][index][1] = event.target.value;
            return sec;
        });
    }

    onclick_Branding() {
        this.branding_platform = true;
        this.registrationDetails = false;
    }

    onclick_Library() {
        this.branding_platform = false;
        this.registrationDetails = true;
    }
}
