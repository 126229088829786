<div class="container panel-control">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <h1>Attribute Definitions</h1>
                    <div class="card-body">
                        <div [formGroup]="getAttributeForm">
                            <div class="w-50">
                                Select Env
                                <select
                                    class="form-control"
                                    aria-label="targetEnv"
                                    id="targetEnv"
                                    formControlName="targetEnv"
                                    (change)="getAttributes()"
                                >
                                    <option
                                        *ngFor="let targetEnv of envs"
                                        [value]="targetEnv"
                                        [selected]="targetEnv === getAttributeForm.value.targetEnv"
                                    >
                                        {{ targetEnv }}
                                    </option>
                                </select>
                            </div>
                            <div class="w-50">
                                Attribute
                                <select
                                    class="form-control"
                                    aria-label="Site"
                                    id="site"
                                    formControlName="attribute"
                                    (change)="attributeSelected($event.target.value)"
                                >
                                    <option value="" disabled>Select an Attribute</option>
                                    <option
                                        *ngFor="let attribute of attributes"
                                        [value]="attribute.id"
                                        [selected]="
                                            attribute.id === getAttributeForm.value.attribute.id
                                        "
                                    >
                                        {{ attribute.name }} ({{
                                            getAttributeForm.value.targetEnv
                                        }})
                                    </option>
                                </select>
                            </div>
                            <div class="w-50">
                                <label>ID:</label>
                                <br />
                                <strong>{{ attribute.id || ' ' }}</strong>
                            </div>
                            <div class="w-50">
                                <label>Name:</label>
                                <input
                                    class="form-control"
                                    [value]="attribute.name"
                                    (change)="valueChanged('name', $event.target.value)"
                                />
                            </div>
                            <div class="w-50">
                                <label>Class:</label>
                                <input
                                    class="form-control"
                                    [value]="attribute.className"
                                    (change)="valueChanged('className', $event.target.value)"
                                />
                            </div>
                            <div class="w-50">
                                <label>Type:</label>
                                <ul>
                                    <label *ngFor="let type of attributeTypes">
                                        <input
                                            type="radio"
                                            class="mx-3"
                                            (click)="valueChanged('type', type)"
                                            [checked]="type === attribute.type"
                                        />
                                        {{ type }}
                                    </label>
                                </ul>
                            </div>
                            <div class="w-50">
                                <strong>Assignment Condition (MySQL):</strong> <br />Query must be
                                logically equivalent to: <br />(SELECT 'true' or 'false' AS result);
                                <textarea
                                    class="form-control"
                                    style="height: 200px"
                                    [value]="attribute.assignment_condition"
                                    (change)="
                                        valueChanged('assignment_condition', $event.target.value)
                                    "
                                ></textarea>
                            </div>
                            <br />
                            <div class="w-50">
                                <strong>Assignment Value (MySQL):</strong> <br />Query must be
                                logically equivalent to: <br />(SELECT 'value' AS result) <br /><b
                                    >NOTE: query must not end with ';'</b
                                >
                                <textarea
                                    class="form-control"
                                    style="height: 200px"
                                    [value]="attribute.assignment_value"
                                    (change)="valueChanged('assignment_value', $event.target.value)"
                                ></textarea>
                            </div>
                            <br />
                            <div class="w-50">
                                <label><strong>Description:</strong></label>
                                <textarea
                                    class="form-control"
                                    style="height: 200px"
                                    [value]="attribute.description"
                                    (change)="valueChanged('description', $event.target.value)"
                                ></textarea>
                            </div>
                        </div>
                        <br />
                        <div>
                            <app-loading-component [loading]="loading" [text]="'loading'">
                                <app-button
                                    (action)="clearAttributeForm()"
                                    [buttonText]="'Registry.Clear'"
                                />
                                <app-button
                                    class="mx-2"
                                    (action)="saveUserAttributeDefinition()"
                                    [buttonText]="'Registry.Save'"
                                />
                            </app-loading-component>
                        </div>
                        <br />
                        <div>
                            Test a user against this Condition
                            <br />
                            <div class="w-50">
                                <label>User ID:</label>
                                <input
                                    class="form-control"
                                    [value]="userId"
                                    (change)="userIdChanged($event.target.value)"
                                />
                            </div>
                            <br />
                            <app-loading-component [loading]="loading" [text]="'loading'">
                                <app-button
                                    (action)="evaluateUserAttribute()"
                                    [buttonText]="'Registry.Test'"
                                />
                                <app-button
                                    class="mx-2"
                                    (action)="setUserAttribute()"
                                    [buttonText]="'Registry.Set'"
                                />
                            </app-loading-component>
                            <br />
                            Test uses stored values (save changes before testing)
                            <br />
                            <div class="w-50" *ngIf="assignment_test_result">
                                <label>Result: </label>
                                <strong>{{ assignment_test_result }}</strong>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="formError text-danger">
                        {{ message }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
