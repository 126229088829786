import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-featured-icon',
    templateUrl: './featured-icon.component.html',
    styleUrls: ['./featured-icon.component.scss'],
})
export class FeaturedIconComponent {
    @Input() iconName: string;
    @Input() type: 'primary' | 'secondary' | 'error' | 'warning' | 'success';
    @Input() size: 'small' | 'medium' | 'large' | 'xlarge';
    @Input() customStyle: any;
    @Input() rounded: boolean;

    getIcon() {
        return `${this.iconName}`;
    }

    getBackgroundStyles() {
        if (this.rounded) {
            return `icon-${this.type}`;
        } else {
            return `square-${this.type}`;
        }
    }
    getSizeClass() {
        return `icon-${this.size}`;
    }

    getBackgroundSize() {
        return `background-${this.size}`;
    }

    getStyles() {
        return this.customStyle ? this.customStyle : {};
    }
}
