import { Component } from '@angular/core';
import { IFilterAngularComp } from 'ag-grid-angular';
import { IDoesFilterPassParams, IFilterParams } from 'ag-grid-community';

@Component({
    selector: 'app-custom-filter',
    templateUrl: './custom-filter.component.html',
    styleUrls: ['./custom-filter.component.scss'],
})
export class CustomFilterComponent implements IFilterAngularComp {
    private params!: IFilterParams;
    public selectedValues: Set<string> = new Set();
    public availableValues: { value: string; icon: string; text: string }[] = [];
    public additionalParams: Record<string, unknown> = {};
    isVisible = false;

    agInit(params: IFilterParams): void {
        this.params = params;
        this.additionalParams = params?.colDef?.filterParams || {};

        const getStatusIcon = this.additionalParams['data'] as (value: string) => {
            iconClass: string;
        };

        let uniqueValues: Set<string> | string[] = this.additionalParams?.options as
            | Set<string>
            | string[];

        if (Array.isArray(uniqueValues)) {
            uniqueValues = new Set(uniqueValues);
        }

        this.availableValues = Array.from(uniqueValues).map((value) => ({
            value,
            icon: this.additionalParams?.iconClass ? getStatusIcon(value)?.iconClass : null,
            text: this.formatText(value),
        }));
    }

    isFilterActive(): boolean {
        return this.selectedValues.size > 0;
    }

    doesFilterPass(params: IDoesFilterPassParams): boolean {
        const value = params.data[this.params.colDef.field as string];
        return this.selectedValues.size === 0 || this.selectedValues.has(value);
    }

    getModel(): any {
        return this.isFilterActive() ? { values: Array.from(this.selectedValues) } : null;
    }

    setModel(model: any): void {
        this.selectedValues = model ? new Set(model.values) : new Set();
    }

    onCheckboxChange(value: string, event: any): void {
        if (event.target.checked) {
            this.selectedValues.add(value);
        } else {
            this.selectedValues.delete(value);
        }
        this.params.filterChangedCallback();
    }

    formatText(value: string): string {
        return value
            .toLowerCase()
            .replace(/_/g, ' ')
            .replace(/\b\w/g, (match) => match.toUpperCase());
    }

    getModelAsString(model: any): string {
        if (model && model.values) {
            return `${model.values.join(', ')}`;
        }
        return '';
    }

    afterGuiAttached(params: any): void {
        this.isVisible = true;
    }

    getPopupParent(): HTMLElement {
        return document.body;
    }

    onToggleVisibility() {
        this.isVisible = !this.isVisible;
    }
}
