import { Component, Injector, OnInit } from '@angular/core';
import {
    Validators,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormGroup,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { CommonService } from '@h20-services/common.service';
import { FormService } from '@h20-services/form.service';
import { IFormDynamicPanel } from '@h20-services/models/forms/types/IFormDynamicPanel';
import { TranslateService } from '@ngx-translate/core';
import { AbstractPiControlComponent } from '../abstract-pi-control/abstract-pi-control.component';
import { UuidService } from '@h20-services/uuid.service';

@Component({
    selector: 'app-pi-control-dynamic-panel',
    templateUrl: './pi-control-dynamic-panel.component.html',
    styleUrls: ['./pi-control-dynamic-panel.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PiControlDynamicPanelComponent,
        },
    ],
})
export class PiControlDynamicPanelComponent
    extends AbstractPiControlComponent<IFormDynamicPanel, any[]>
    implements OnInit
{
    controlFormArray: UntypedFormArray = this.fb.array([]);
    columnVariables: string[] = [];

    modalOpen: boolean;
    currentGroup: UntypedFormGroup; //current Form Group open in the modal
    dynamicPanel: any[];

    getText = this.com_svc.getText;
    listening$: any;
    loadingData: boolean = true;
    currentIndex: number = -1;
    datatable_messages = {
        emptyMessage: this.translate.instant('TableTitle.message'),
        totalMessage: this.translate.instant('TableTitle.total'),
        selectedMessage: this.translate.instant('TableTitle.selected'),
    };
    constructor(
        public injector: Injector,
        protected com_svc: CommonService,
        protected uuid_svc: UuidService,
        protected translate: TranslateService,
        private fb: UntypedFormBuilder,
        private form_svc: FormService
    ) {
        super(injector, com_svc, uuid_svc, translate);
    }

    colDefs = [
        {
            headerName: this.dynamicPanelColumnName(this.columnVariables[0]),
            field: 'external_identifier',
        },
        {
            headerName: this.translate.instant('TableTitle.actions'),
            field: 'id',
            cellDataType: 'iconBtns',
            cellRendererParams: (params) => {
                return {
                    actions: [
                        {
                            isVisible: !this.element.readOnly,
                            label: this.translate.instant('Registry.Edit'),
                            onClick: () => this.openPopup(params.rowIndex),
                            iconClass: 'fas fa-pencil fa-lg',
                        },
                        {
                            isVisible: true,
                            label: this.translate.instant('Registry.Remove'),
                            onClick: () => this.removeFormAnswer(params.rowIndex),
                            iconClass: 'far fa-trash-alt fa-lg',
                        },
                    ],
                };
            },
        },
    ];

    getGridApi(api): void {
        const newDefs = [
            {
                headerName: this.dynamicPanelColumnName(this.columnVariables[0]),
                field: 'external_identifier',
                flex: 1,
                valueFormatter: (params) =>
                    this.dynamicPanelItemText(params.data, this.columnVariables[0]),
            },
            {
                headerName: this.translate.instant('TableTitle.actions'),
                flex: 1,
                cellDataType: 'iconBtns',
                field: 'id',
                cellRendererParams: (params) => {
                    return {
                        actions: [
                            {
                                isVisible: !this.element.readOnly,
                                label: this.translate.instant('Registry.Edit'),
                                onClick: () => this.openPopup(params.rowIndex),
                                iconClass: 'fas fa-pencil fa-lg',
                            },
                            {
                                isVisible: true,
                                label: this.translate.instant('Registry.Remove'),
                                onClick: () => this.removeFormAnswer(params.rowIndex),
                                iconClass: 'far fa-trash-alt fa-lg',
                            },
                        ],
                    };
                },
            },
        ];
        api.setGridOption('columnDefs', newDefs);
    }

    ngOnInit(): void {
        if (this.element.columnvariables) {
            this.columnVariables = JSON.parse(this.element.columnvariables);
        } else {
            const question = this.element.templateElements[0];
            if(question) this.columnVariables.push(this.getText(question.name));
        }
        this.dynamicPanel = [{ elements: this.element.templateElements }];

        if (this.element.isRequired) {
            this.formControl.addValidators(Validators.required);
            if (this.element.requiredErrorText !== undefined) {
                this.customErrorMessages['required'] = this.element.requiredErrorText;
            }
        }
        if(!this._value) this._value = [];
        this.loadingData = false;
    }

    // when we write a new value we must create the form group for each item
    //overrides base class function
    writeValue(value: any[]): void {
        if (value) {
            let newControlArray = [];

            value.forEach((val, index) => {
                // No push if the panel has an array with empty object ({})
                if (Object.keys(val).length !== 0) {
                    newControlArray.push(this.createFormGroup());
                    newControlArray[index].patchValue(val);
                    this.processMiniFormLogic(newControlArray[index]);
                }
            });

            this.controlFormArray = this.fb.array(newControlArray);
            this._value = this.controlFormArray.value;
        }
    }

    createFormGroup(): UntypedFormGroup {
        const controls = {};
        this.element.templateElements.forEach((templateElement) => {
            controls[templateElement.name] = this.form_svc.createControl(
                templateElement,
                this.logicalContext
            );
        });
        return new UntypedFormGroup(controls);
    }

    openPopup(idx?: number): void {
        if (idx === undefined) {
            this.currentIndex = -1;
            this.currentGroup = this.createFormGroup();
        } else {
            this.currentIndex = idx;
            this.currentGroup = this.controlFormArray.at(idx) as UntypedFormGroup;
        }
        this.processMiniFormLogic(this.currentGroup); // process in either case to disable dependent values
        if (this.listening$) this.listening$.unsubscribe();
        this.listening$ = this.currentGroup.valueChanges.subscribe((resp) => {
            this.onMiniFormChanges();
        });

        this.modalOpen = true;
        document.getElementById('modalTarget').focus();
    }

    onMiniFormChanges() {
        this.listening$.unsubscribe(); //unsubscribe from form changes because
        this.processMiniFormLogic(this.currentGroup); //<-- updates disabled values
        //restore the listener
        this.listening$ = this.currentGroup.valueChanges.subscribe((resp) => {
            this.onMiniFormChanges();
        });
    }

    processMiniFormLogic(theForm: UntypedFormGroup) {
        //combine current form values with logical context values - allow current form to overwrite context
        let values = { ...this.logicalContext, ...theForm.value };

        for (const elt of this.element.templateElements) {
            const ctrl = theForm.controls[elt.name];
            if (this.form_svc.processVisibilityLogic(elt, values)) ctrl.enable();
            else ctrl.disable();
        }
    }

    saveFunc(ctx): boolean {
        if (ctx.currentGroup.valid) {
            //push new group if its new
            if (ctx.currentIndex < 0)
                (ctx.controlFormArray as UntypedFormArray).push(ctx.currentGroup);
            // else ctx.controlFormArray[ctx.currentIndex] = ctx.currentGroup; //already updated
            ctx._value = ctx.controlFormArray.value;

            ctx.notifyValueChange();
            ctx.listening$.unsubscribe();
            ctx.modalOpen = false;
            return true;
        }
        ctx.currentGroup.markAllAsTouched();
        return false;
    }

    closeFunc(ctx): boolean {
        ctx.listening$.unsubscribe();
        ctx.modalOpen = false;
        return true;
    }

    dynamicPanelColumnName(questionName: string): string {
        let question = this.element?.templateElements?.find((t) => t.name === questionName);
        return `${question?this.getText(question.title):''}`;
    }

    dynamicPanelItemText(item, questionName: string): string {
        let question = this.element.templateElements.find((t) => t.name === questionName);
        const answer_val = item[questionName];

        let answer_str = '';
        if (answer_val) {
            answer_str = answer_val;
            if (question.choices) {
                if (question.type) {
                    if (question.type == 'checkbox') {
                        answer_str = '';
                        Object.keys(answer_val).forEach((key) => {
                            if (answer_val[key]) {
                                //when true it was selected
                                answer_str += this.getText(
                                    question.choices[key].text //keys *should* match any form data value unless the form has changed
                                );
                                answer_str += '. ';
                            }
                        });
                    } else if (question.type == 'multitext') {
                        answer_str = '';
                        answer_val.forEach((element) => {
                            answer_str += this.getText(element);
                            answer_str += ' ';
                        });
                    } else {
                        answer_str = this.getText(
                            question.choices.find((c) => c.value == answer_val).text
                        );
                    }
                }
            }

            if (question.type == 'partial-date') {
                answer_str = '';
                for (let i in answer_val) {
                    if (answer_val[i] !== 0) {
                        answer_str += this.getText(answer_val[i]);
                        answer_str += '-';
                    }
                }
                if (answer_str !== '') answer_str = answer_str.slice(0, -1);
            }
        }
        return answer_str;
    }

    removeFormAnswer(idx: number): void {
        this.controlFormArray.removeAt(idx);
        this._value = this.controlFormArray.value;
        this.notifyValueChange();
    }

    panelChanges(event: any) {
        console.error('panel changes may not be handled');
    }

    getTranslation() {
        return (this.datatable_messages = {
            emptyMessage: this.translate.instant('TableTitle.message'),
            totalMessage: this.translate.instant('TableTitle.total'),
            selectedMessage: this.translate.instant('TableTitle.selected'),
        });
    }

    getFormHeader() {
        if (this.element.panelAddText) {
            return this.com_svc.getText(this.element.panelAddText) ?? this.element.panelAddText;
        } else return this.com_svc.getText(this.element.title) ?? this.element.title;
    }
}
