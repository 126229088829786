<div class="w-100">
    <label *ngIf="!loading && !loadFailed; else loadStatus" class="form-label w-100">
        Search for existing questions
        <input type="text" class="form-control" (input)="searchInput($event)" />
    </label>
    <ng-template #loadStatus
        ><span>{{
            loadFailed ? 'Failed to load choice library.' : 'Loading choice library...'
        }}</span>
        <app-button
            *ngIf="loadFailed"
            (action)="reloadDb()"
            [design]="'secondary'"
            [innerText]="'Registry.Retry'"
        />
    </ng-template>
    <div class="card mb-2" *ngFor="let result of results">
        <div class="card-body">
            <div class="d-flex align-items-baseline justify-content-between">
                <p class="flex-fill me-auto">
                    <ng-container *ngIf="result.$type === 'QSet'">
                        <span class="badge bg-secondary">Question Set</span><br />
                    </ng-container>
                    {{ result.name }}
                </p>
                <app-button
                    *ngIf="loadFailed"
                    class="ms-4"
                    (action)="result.toggle()"
                    [design]="'secondary'"
                    [buttonText]="
                        result.item === undefined ? 'Registry.Preview' : 'Registry.UnPreview'
                    "
                />
                <app-button
                    type="button"
                    class="ms-4"
                    (action)="sendPrefab(result.id)"
                    [buttonText]="'Registry.Add'"
                />
            </div>
            <div class="w-100 card mt-2" *ngIf="result.item">
                <div class="card-header">Preview</div>
                <div class="card-body">
                    <ng-container *ngIf="result.$type === 'Question'; else previewSurvey">
                        <app-pi-control-switch
                            [theFormControl]="result.control"
                            [element]="result.item"
                        ></app-pi-control-switch>
                    </ng-container>
                    <ng-template #previewSurvey>
                        <app-survey-tester [survey]="result.item"></app-survey-tester>
                    </ng-template>
                </div>
            </div>
        </div>
        <div class="card-footer text-muted" *ngIf="result.$tags" style="font-size: xx-small">
            <dl class="font-monospace">
                <div *ngFor="let tag of result.$tags | keyvalue">
                    <dt class="d-inline me-2">{{ tag.key }}:</dt>
                    <dd class="d-inline">{{ tag.value }}</dd>
                </div>
            </dl>
        </div>
    </div>
</div>
