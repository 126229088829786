import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrySiteComponent } from './registry-site/registry-site.component';
import { TaskPrototypeComponent } from './task-prototype/task-prototype.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegistrySiteManagementRoutingModule } from './registry-site-management-routing.module';
import { SharedModule } from '@h20-shared/shared.module';
import { TaskSetComponent } from './task-set/task-set.component';

@NgModule({
    declarations: [RegistrySiteComponent, TaskPrototypeComponent, TaskSetComponent],
    imports: [
        CommonModule,
        NgxDatatableModule,
        RegistrySiteManagementRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
    ],
})
export class RegistrySiteManagementModule {}
