import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-tableau',
    templateUrl: './tableau.component.html',
    styleUrls: ['./tableau.component.scss'],
})
export class TableauComponent {
    @Input() dashboardIndex = 0;
    @Input() vizUrl;

    @Input() jwt;
    VizIndex = 'Tableau-Viz-' + this.dashboardIndex;

    constructor() {}
}
