import { Component, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IFormElement } from '@h20-services/models/forms/IFormElement';
import { AbstractPiControlComponent } from '../abstract-pi-control/abstract-pi-control.component';

@Component({
    selector: 'app-pi-control-html',
    templateUrl: './pi-control-html.component.html',
    styleUrls: ['./pi-control-html.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PiControlHtmlComponent,
        },
    ],
})
export class PiControlHtmlComponent extends AbstractPiControlComponent<IFormElement, any> {
    @Input()
    set value(value: any) {
        this._value = null;
    }
}
