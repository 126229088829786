<div class="custom-filter" *ngIf="isVisible">
    <div *ngFor="let option of availableValues">
        <label>
            <input
                type="checkbox"
                [value]="option.value"
                (change)="onCheckboxChange(option.value, $event)"
                [checked]="selectedValues.has(option.value)"
            />
            <i
                *ngIf="additionalParams?.iconClass"
                [class]="additionalParams?.iconClass + ' ' + option.icon"
            ></i>
            {{ option.text }}
        </label>
    </div>
</div>
