<label
    *ngIf="!isEditing"
    class="btn btn-primary label-theme text-nowrap select-file-button"
    attr.aria-label="{{ 'Registry.SelectFile' | translate }}"
>
    {{ 'Registry.SelectFile' | translate }}
    <input
        id="uploadedFile"
        class="hidden small-text"
        [disabled]="isEditing"
        type="file"
        accept="supportedFileTypes ? supportedFileTypes.map(type => '.' + type).join(',') : null"
        (change)="onFileSelected($event)"
    />
</label>
<span *ngIf="uploadedFile" class="badge text-dark ms-2" id="uploadedFileName">
    {{ uploadedFile?.name || uploadedFile?.fileName }}
</span>

<div>
    <span class="text-muted" style="font-size: 80%">
        {{ 'Registry.UploadedFileTypes' | translate }}</span
    >
    <br />
    <span style="font-weight: bold; font-size: 80%">
        {{ 'UserSettings.Disclaimer' | translate }}</span
    >
    <div *ngIf="minFileSize && maxFileSize">
        Between {{ minFileSize }} KB and {{ maxFileSize }} KB in size
    </div>
</div>
<div class="text-danger pt-2" style="font-size: 80%" *ngIf="uploadFileWarningMsg">
    {{ uploadFileWarningMsg }}
</div>
