import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@h20-services/auth.service';
import { PulseAuth } from '@h20-services/models/PulseAuth';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
    constructor(private auth: AuthService, private route: Router) {}

    ngOnInit(): void {
        this.auth
            .getPulseAuth()
            .then((user: PulseAuth) => {})
            .catch((err) => {
                localStorage.clear();
                this.route.navigate(['/login']);
            });
    }
}
