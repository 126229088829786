import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ScheduleEditorComponent } from './schedule-editor/schedule-editor.component';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        component: ScheduleEditorComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ScheduleManagementRoutingModule {}
