import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-highcharts-tile-types',
    templateUrl: './highcharts-tile-types.component.html',
    styleUrls: ['./highcharts-tile-types.component.scss'],
})
export class HighchartsTileTypesComponent {
    @Input() tile;

    constructor() {}
}
