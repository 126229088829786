import { Injectable } from '@angular/core';
import { Hub } from 'aws-amplify';
import { environment as env } from '@environment/environment';
import { ConfigService } from './config.service';
import { AuthService } from './auth.service';
import { RequestService } from './request.service';
import { CommonService } from './common.service';
import { PulseAuth } from './models/PulseAuth';


@Injectable({
    providedIn: 'root',
})
export class ClaimService {
    userClaims: any = {};
    

    constructor(
        private reqSvc: RequestService,
        private configSvc: ConfigService,
        private commonSvc: CommonService,
        
    ) {
        Hub.listen('auth', (data) => {
            if (data.payload.event === AuthService.SIGN_IN_EVENT) {
            } else if (data.payload.event === AuthService.SIGN_OUT_EVENT) {
                this.clearSession();
            }
        });
        
    }

    clearSession() {
        this.userClaims = {};
    }

    setUserClaims(claims) {
        this.userClaims = claims;
    }

    getUserClaims(): any {
        return this.userClaims;
    }

    getAllClaimsByUser(user: PulseAuth, logInfo: any = null): any {
        const req: any = {
            operation: 'getAllClaimsByUser2',
            data: {
                user_id: user.getUserID(),
            },
        };
        return this.reqSvc.post(env.awsConfig.API.writeToDBClaim, req);
    }

    getAllClaimsPulseAuth(pulseAuth: PulseAuth, logInfo: any = null): any {
        const req: any = {
            operation: 'getAllClaimsByUser2',
            data: {
                user_id: pulseAuth.getUserID(),
            },
        };
        return this.reqSvc.post(env.awsConfig.API.writeToDBClaim, req);
    }

    getManagedSitesByUser(pulseAuth: PulseAuth): any {
        let dataToSend = {
            registry_id: this.configSvc.getTenant(),
            user_id: pulseAuth.getUserID(),
            username: pulseAuth.getEmailAddr(),
        };

        const req: any = {
            operation: 'getManagedSitesByUser',
            data: dataToSend,
        };
        return this.reqSvc.post(env.awsConfig.API.writeToDBClaim, req);
    }

    getManagedRoles(pulseAuth: PulseAuth): any {
        const req: any = {
            operation: 'getManagedRolesByUser',
            data: {
                registry_id: this.configSvc.getTenant(),
                user_id: pulseAuth.getUserID(),
                username: pulseAuth.getEmailAddr(),
            },
        };
        return this.reqSvc.post(env.awsConfig.API.writeToDBClaim, req);
    }

    getManagedRoles2(userID: string, emailAddr: string, registryID: string): any {
        const req: any = {
            operation: 'getManagedRolesByUser',
            data: {
                registry_id: registryID,
                user_id: userID,
                username: emailAddr,
            },
        };
        return this.reqSvc.post(env.awsConfig.API.writeToDBClaim, req);
    }

    getRoleClaimByRole(registry_id, role_claim_role, envName?: any): any {
        const targetEnv = this.commonSvc.getTargetEnv(envName);
        let dataToSend = {
            registry_id: registry_id,
            role_claim_role: role_claim_role,
        };

        const req: any = {
            operation: 'getRoleClaimByRole',
            data: dataToSend,
        };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBClaim, req, targetEnv);
    }

    createRoleClaim(registry_id, site_id, role_id, claims): any {
        let dataToSend = {
            registry_id: registry_id,
            site_id: site_id,
            role: role_id,
            claims: claims,
        };

        const req: any = {
            operation: 'createRoleClaim',
            data: dataToSend,
        };
        return this.reqSvc.post(env.awsConfig.API.writeToDBClaim, req);
    }

    listRoleClaims(envName?: any): any {
        const targetEnv = this.commonSvc.getTargetEnv(envName);
        const req: any = {
            operation: 'listRoleClaims',
        };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBClaim, req, targetEnv);
    }

    updateRoleClaim(registry_id, role_claim_role, claims, envName?: any): any {
        const targetEnv = this.commonSvc.getTargetEnv(envName);
        let dataToSend = {
            registry_id: registry_id,
            role_claim_role: role_claim_role,
            claims: claims,
        };

        const req: any = {
            operation: 'updateRoleClaim',
            data: dataToSend,
        };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBClaim, req, targetEnv);
    }

    updateRoleClaim_DeployVersion(registry_id, site_id, role_id, claims, site): any {
        let dataToSend = {
            registry_id: registry_id,
            site_id: site_id,
            role: role_id,
            claims: claims,
        };

        const req: any = {
            operation: 'updateRoleClaim',
            data: dataToSend,
        };
        return this.reqSvc.post(site.awsConfig.API.writeToDBClaim, req, site);
    }

    getRoleClaimByRole_DeployVersion(registry_id, site_id, role_id, site): any {
        let dataToSend = {
            registry_id: registry_id,
            site_id: site_id,
            role: role_id,
        };

        const req: any = {
            operation: 'getRoleClaimByRole',
            data: dataToSend,
        };
        return this.reqSvc.post(site.awsConfig.API.writeToDBClaim, req, site);
    }

    /* -------------------- claim CRUD Functions --------------------------------- */
    listClaim(envName?: any): any {
        const targetEnv = this.commonSvc.getTargetEnv(envName);
        const req: any = {
            operation: 'listClaims',
        };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBClaim, req, targetEnv);
    }

    createClaim(claim_name, claim_description, claim_required_by, envName?: any): any {
        const targetEnv = this.commonSvc.getTargetEnv(envName);
        let dataToSend = {
            claim_name: claim_name,
            claim_description: claim_description,
            claim_required_by: claim_required_by,
        };
        const req: any = {
            operation: 'createClaim',
            data: dataToSend,
        };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBClaim, req, targetEnv);
    }

    updateClaim(claim_id, claim_name, claim_description, claim_required_by, envName?: any): any {
        const targetEnv = this.commonSvc.getTargetEnv(envName);
        let dataToSend = {
            claim_id: claim_id,
            claim_name: claim_name,
            claim_description: claim_description,
            claim_required_by: claim_required_by,
        };
        const req: any = {
            operation: 'updateClaim',
            data: dataToSend,
        };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBClaim, req, targetEnv);
    }

    deleteClaim(claim_id, envName?: any): any {
        const targetEnv = this.commonSvc.getTargetEnv(envName);
        let dataToSend = {
            claim_id: claim_id,
        };
        const req: any = {
            operation: 'deleteClaim',
            data: dataToSend,
        };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBClaim, req, targetEnv);
    }

    
}
