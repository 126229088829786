<div *ngIf="mode === 'view'; else formModeTemplate">
    <div *ngIf="!disabled" class="pi-control-edit-mode">
        <app-pi-form-view-response
            [element]="element"
            [theFormControl]="formControl"
            [displayValue]="stringValue"
        ></app-pi-form-view-response>
    </div>
</div>
<ng-template #formModeTemplate
    ><div *ngIf="!disabled" class="w-100 row pi-control-form-mode">
        <label
            class="form-label"
            [ngClass]="
                !element.orientation || element.orientation === 'horizontal'
                    ? 'col-12 col-md-6 col-lg-8'
                    : 'col-12'
            "
            [attr.for]="elementUID"
        >
            <app-element-title [element]="element"></app-element-title>
        </label>
        <div class="col-12 col-md-6 col-lg-4">
            <input
                [attr.id]="elementUID"
                [attr.aria-label]="element.title"
                class="p-1 form-control"
                type="email"
                [name]="elementUID"
                [value]="_value ? _value : null"
                [attr.disabled]="disabled || element?.readOnly"
                [placeholder]="element.placeHolder || ''"
                (change)="textInput($event)"
            />
        </div>

        <app-validator
            [control]="formControl"
            [customErrorMessages]="customErrorMessages"
            [warningControl]="warningControl"
            [customWarningMessages]="customWarningMessages"
        ></app-validator></div
></ng-template>
