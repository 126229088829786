<div *ngIf="mode === 'view'; else formModeTemplate">
    <div *ngIf="!disabled && controlFormArray" class="pi-control-edit-mode">
        <div class="accordion" [attr.id]="'view-form-accordion-' + elementUID">
            <div class="accordion-item" *ngFor="let formValue of _value; index as entryIdx">
                <h2 class="accordion-header" [attr.id]="'heading' + elementUID + entryIdx">
                    <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#entry' + elementUID + entryIdx"
                        aria-expanded="false"
                        [attr.aria-controls]="'#entry' + elementUID + entryIdx"
                    >
                        <div *ngFor="let columnVariable of columnVariables">
                            <div class="text-wrap">
                                {{ dynamicPanelItemText(formValue, columnVariable) }}
                            </div>
                        </div>
                    </button>
                </h2>
                <div
                    [attr.id]="'entry' + elementUID + entryIdx"
                    class="accordion-collapse collapse"
                    [attr.aria-labelledby]="'#heading' + elementUID + entryIdx"
                >
                    <div class="accordion-body">
                        <app-pi-form-layout-flat
                            class="clinical-form-widget"
                            [panels]="dynamicPanel"
                            [theFormGroup]="controlFormArray.at(entryIdx)"
                            mode="view"
                        >
                        </app-pi-form-layout-flat>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #formModeTemplate>
    <div *ngIf="!disabled" class="pi-control-form-mode row">
        <app-element-title [element]="element"></app-element-title>

        <div class="w-100">
            <app-table-component
                *ngIf="!loadingData"
                (getGridApi)="getGridApi($event)"
                [filterModelKey]="'pi_control_dynamic_panel'"
                [rowData]="_value"
                [columnDefs]="colDefs"
                [showFilterRow]="false"
            >
            </app-table-component>
        </div>
        <div>
            <app-button
                [iconConfig]="{ iconClasses: 'fa-solid fa-regular fa-plus' }"
                title="{{ 'Surveys.AddAnswer' | translate }}"
                (action)="openPopup()"
            ></app-button>
            <div
                class="pt-2 text-muted"
                [innerHtml]="'Surveys.DynamicPanelDefaultHelp' | translate"
            ></div>
        </div>

        <app-modal
            *ngIf="modalOpen"
            [(visible)]="modalOpen"
            [header]="getFormHeader()"
            [context]="this"
            [saveFunc]="saveFunc"
            [closeFunc]="closeFunc"
            class="dynamic-panel-modal"
            [saveBtnText]="'Surveys.Save'"
            tabindex="-1"
            id="modalTarget"
        >
            <div *ngIf="currentGroup">
                <app-pi-form-layout-flat
                    class=""
                    [panels]="dynamicPanel"
                    [theFormGroup]="currentGroup"
                    (changes)="panelChanges($event)"
                >
                </app-pi-form-layout-flat>
            </div>
        </app-modal>
    </div>

    <app-validator
        [control]="formControl"
        [customErrorMessages]="customErrorMessages"
    ></app-validator>
</ng-template>
