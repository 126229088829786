import { Component, Injector, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import MiniSearch, { Options, SearchResult } from 'minisearch';
import { AbstractPiControlComponent } from '../abstract-pi-control/abstract-pi-control.component';
import { IFormElement } from '@h20-services/models/forms/IFormElement';
import { CommonService } from '@h20-services/common.service';
import { UuidService } from '@h20-services/uuid.service';
import { TranslateService } from '@ngx-translate/core';

type Choice = { text: string | Record<string, string>; value: string };

type ChoiceLibraryEntry = {
    choices: Array<Choice>;
    $id: string;
    $name: string;
};
type ChoiceLibrary = Array<ChoiceLibraryEntry>;

type ChoiceSearchResult = {
    item: Array<Choice>;
    show: boolean;
    toggle: () => void;
    name: string;
    id: string;
};
const miniSearchOpts: Options = {
    fields: ['$name'],
    idField: '$id',
    storeFields: ['$name'],
    searchOptions: { fuzzy: 0.2, prefix: true },
};
@Component({
    selector: 'app-surveybuilder-choice-manager',
    templateUrl: './surveybuilder-choice-manager.component.html',
    styleUrls: ['./surveybuilder-choice-manager.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: SurveyBuilderChoiceManagerComponent,
        },
    ],
})
export class SurveyBuilderChoiceManagerComponent
    extends AbstractPiControlComponent<IFormElement, any>
    implements OnInit
{
    constructor(
        private http: HttpClient,
        public injector: Injector,
        protected com_svc: CommonService,
        protected uuid_svc: UuidService,
        protected translate: TranslateService
    ) {
        super(injector, com_svc, uuid_svc, translate);
    }
    private static readonly CHOICESET_SOURCE_URI =
        'assets/survey-builder/qlib/choiceSets.0.0.1.json';

    private static choiceDb: ChoiceLibrary;
    loading = false;
    loadFailed = false;
    choices: Array<Choice> = [];
    results: Array<ChoiceSearchResult>;
    ms: MiniSearch<any>;
    searchText = '';

    ngOnInit(): void {
        if (!SurveyBuilderChoiceManagerComponent.choiceDb) {
            this.reloadDb();
        } else {
            this.ms = new MiniSearch(miniSearchOpts);
            this.ms.addAll(SurveyBuilderChoiceManagerComponent.choiceDb);
        }
    }

    reloadDb() {
        this.loading = true;
        this.loadFailed = false;
        this.http
            .get<ChoiceLibrary>(SurveyBuilderChoiceManagerComponent.CHOICESET_SOURCE_URI, {
                headers: {
                    'Cache-Control': 'public, max-age=31536000, immutable',
                },
            })
            .subscribe({
                next: (x) => this.updateDbFromResponse(x),
                error: (x) => {
                    console.error(x);
                    this.loadFailed = true;
                    this.loading = false;
                },
            });
    }

    updateDbFromResponse(newDbResp: ChoiceLibrary) {
        SurveyBuilderChoiceManagerComponent.choiceDb = newDbResp;
        this.ms = new MiniSearch(miniSearchOpts);
        this.ms.addAll(SurveyBuilderChoiceManagerComponent.choiceDb);
        this.loading = false;
    }

    writeValue(val: Array<Choice>) {
        super.writeValue(val);

        this.choices = val;
    }

    remove(index: number) {
        this.choices.splice(index, 1);
        this.update();
    }

    add() {
        if (!this.choices) {
            this.choices = [{ text: '', value: '' }];

            return;
        }

        this.choices.push({ text: '', value: '' });
    }

    update() {
        super.writeValue(this.choices);

        super.notifyValueChange();
    }

    setFromDb(id) {
        const newChoices = SurveyBuilderChoiceManagerComponent.choiceDb.find((a) => {
            return a.$id === id;
        })?.choices;
        if (newChoices) {
            this.choices = newChoices;
        }
        this.update();
        this.searchInput('');
    }

    searchInput(event: string) {
        this.searchText = event;

        if (!this.searchText) {
            this.results = [];
            return;
        }

        this.results = this.ms.search(this.searchText).map((v: SearchResult) => {
            return {
                item: undefined,
                name: v.$name,
                show: false,
                toggle: function () {
                    this.show = !this.show;
                    if (this.show) {
                        this.item = SurveyBuilderChoiceManagerComponent.choiceDb.find((a) => {
                            return a.$id === this.id;
                        })?.choices;
                    } else {
                        this.item = undefined;
                    }
                },
                ...v,
            };
        });
    }
}
