import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { JsonEditorOptions } from 'ang-jsoneditor';

@Component({
    selector: 'app-survey-tester',
    templateUrl: './survey-tester.component.html',
    styleUrls: ['./survey-tester.component.scss'],
})
export class SurveyTesterComponent implements OnInit, OnChanges {
    @Input() survey;
    currentSurvey;
    currentSurveyData;
    currentLayout = 'flat';
    jsonEditorOptions: JsonEditorOptions;

    ngOnInit() {
        this.jsonEditorOptions = new JsonEditorOptions();
        this.jsonEditorOptions.modes = ['view'];
        this.jsonEditorOptions.mode = 'view';
    }

    ngOnChanges() {
        if (this.survey && this.survey.pages) this.currentSurvey = this.survey;
    }

    onFormChanges(formGroup) {
        this.currentSurveyData = formGroup.value;
    }

    toggleLayout() {
        if (this.currentLayout == 'flat') {
            this.currentLayout = 'one-by-one';
        } else if (this.currentLayout == 'one-by-one') {
            this.currentLayout = 'flat';
        }
    }
    check() {
        console.error(this.currentSurvey, this.survey);
    }
}
