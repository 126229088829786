import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SurveysComponent } from './surveys/surveys.component';
import { DeploySurveyComponent } from './deploy-survey/deploy-survey.component';
import { SurveyBuilderModule } from '../survey-builder/survey-builder.module';

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                pathMatch: 'full',
                component: SurveysComponent,
                data: {
                    claims: ['surveyBuilderSurvey_view'],
                },
            },
            {
                path: 'build',
                loadChildren: () => SurveyBuilderModule,
            },
            {
                path: 'deploysurvey',
                component: DeploySurveyComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class SurveyRoutingModule {}
