export enum Roles {
	user = 'h20ComposerUser',
    developer = 'h20ComposerAdmin',
    developerProduction = 'productionDeployment_fullAccess',
}	

export enum RoleLabels {
    user = 'User Role',
    developer= 'Developer Lite Role',
    developerProduction= 'Developer Prod Role'
}