<div class="container panel-control">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <h1>Delete Patient For Retention Period</h1>
                    <div class="card-body">
                        <div [formGroup]="getPatientForm">
                            <div class="w-50">
                                Select Env
                                <select
                                    class="form-control"
                                    aria-label="targetEnv"
                                    id="targetEnv"
                                    formControlName="targetEnv"
                                >
                                    <option
                                        *ngFor="let targetEnv of envs"
                                        [value]="targetEnv"
                                        [selected]="targetEnv === getPatientForm.value.targetEnv"
                                    >
                                        {{ targetEnv }}
                                    </option>
                                </select>
                            </div>
                            <div class="w-50">
                                Site
                                <select
                                    class="form-control"
                                    aria-label="Site"
                                    id="site"
                                    formControlName="site"
                                >
                                    <option value="" disabled>Pick a Site</option>
                                    <option
                                        *ngFor="let site of sites"
                                        [value]="site"
                                        [selected]="site === getPatientForm.value.site"
                                    >
                                        {{ site }}
                                    </option>
                                </select>
                            </div>
                            <div class="w-50">
                                Standard Date
                                <input
                                    type="date"
                                    formControlName="standardDate"
                                    class="form-control"
                                />
                                <span class="fst-italic"
                                    >*Patients who haven't signed until 'Standard date - 3 month'
                                    are eligible.</span
                                >
                            </div>
                        </div>
                        <br />
                        <div>
                            <app-loading-component [loading]="loading" [text]="'loading'">
                                <app-button
                                    (action)="getRetentionPeriodPatients()"
                                    [buttonText]="'Registry.GetTargetPatients'"
                                />
                                <app-button
                                    [design]="'secondary'"
                                    class="mx-3"
                                    (action)="clearGetPatientForm()"
                                    [buttonText]="'Registry.Clear'"
                                />
                            </app-loading-component>
                            <div>
                                <br />
                                Number of patients:
                                <span class="fst-italic">{{ totalCount }} </span><br />
                                Target Environment: <span class="fst-italic">{{ targetEnv }}</span
                                ><br />
                                Target Site: <span class="fst-italic">{{ targetSite }}</span
                                ><br />
                                Standard Date - 3 Month:
                                <span class="fst-italic">{{ dateForDisplay }}</span>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <app-loading-component [loading]="loadingDeleteS3" [text]="'loading'">
                            <app-button
                                class="mx-3"
                                (action)="deleteS3Files()"
                                [disabled]="isDeleteS3Disabled"
                                [buttonText]="'Registry.Delete_S3_Files'"
                            />
                        </app-loading-component>
                    </div>
                    <br />
                    <div>
                        <app-loading-component [loading]="loadingDeleteDB" [text]="'loading'">
                            <app-button
                                class="mx-3"
                                (action)="deletePatientsFromDB()"
                                [disabled]="isDeleteDBDisabled"
                                [buttonText]="'Registry.DeleteFromDb'"
                            />
                        </app-loading-component>
                    </div>
                    <hr />
                    <div class="formError text-danger">
                        {{ message }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
