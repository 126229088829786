import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from '@environment/environment';
import { IEmailMapping } from './models/emails/email_mapping';
import { RequestService } from './request.service';

@Injectable({
    providedIn: 'root',
})
export class EmailMappingService {
    constructor(private reqSvc: RequestService) {}

    list(): Observable<any> {
        const req: any = { operation: 'list' };

        return this.reqSvc.post(env.awsConfig.API.emailMapping, req);
    }

    create(emailMapping: IEmailMapping) {
        const req: any = {
            operation: 'create',
            data: {
                item: emailMapping,
            },
        };

        return this.reqSvc.post(env.awsConfig.API.emailMapping, req);
    }

    update(emailMapping: IEmailMapping) {
        const req: any = {
            operation: 'update',
            data: {
                item: emailMapping,
            },
        };

        return this.reqSvc.post(env.awsConfig.API.emailMapping, req);
    }

    deleteTemplate(emailMapping: IEmailMapping) {
        const req: any = {
            operation: 'deleteByTemplate',
            data: {
                item: emailMapping,
            },
        };

        return this.reqSvc.post(env.awsConfig.API.emailMapping, req);
    }
}
