import { Component, EventEmitter, OnInit, Output, OnDestroy, Input } from '@angular/core';
import { AuthService } from '@h20-services/auth.service';
import { PulseAuth } from '@h20-services/models/PulseAuth';
import { TaskService } from '@h20-services/task.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-assign-survey-task',
    templateUrl: './assign-survey-task.component.html',
    styleUrls: ['./assign-survey-task.component.scss'],
})
export class AssignSurveyTaskComponent implements OnInit, OnDestroy {
    subscriptions: Subscription[] = [];
    loading: boolean;

    @Input() patient_ID: string;
    @Input() patient_Site_ID: string;
    @Output() toggleDialog = new EventEmitter<boolean>();

    taskList: any[];
    selectedTask: any;
    private pulseAuth: PulseAuth;
    constructor(private task_svc: TaskService, private auth_svc: AuthService) {}

    ngOnDestroy(): void {
        this.subscriptions.map((sub) => {
            sub.unsubscribe();
        });
    }

    ngOnInit(): void {
        this.loading = true;

        this.auth_svc.getPulseAuth().then((pulseAuth: PulseAuth) => {
            this.pulseAuth = pulseAuth;
            const task_sub$ = this.task_svc.listTaskPrototypes().subscribe((tasks) => {
                this.taskList = tasks.filter((t) => {
                    return t.site_ID == this.patient_Site_ID;
                });

                this.loading = false;
            });
            this.subscriptions.push(task_sub$);
        });
    }

    hideDialog() {
        this.toggleDialog.emit(false);
    }

    changeTaskSelection(task) {
        this.selectedTask = this.taskList.find((i) => i.label == task);
    }

    createTask(context) {
        const assign_sub$ = context.task_svc
            .assignTaskFromPrototype(
                context.selectedTask.id,
                context.patient_ID,
                context.pulseAuth.authenticationID
            )
            .subscribe((res) => {
                context.hideDialog();
            });
        context.subscriptions.push(assign_sub$);
    }
}
