import {
    Component,
    Input,
    OnInit,
    OnChanges,
    Output,
    EventEmitter,
    ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CommonService } from '@h20-services/common.service';
import { FormService } from '@h20-services/form.service';
import { IFormElement } from '@h20-services/models/forms/IFormElement';
import { IFormEntryType } from '@h20-services/models/forms/types/IFormEntryType';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-pi-form-layout-flat',
    templateUrl: './pi-form-layout-flat.component.html',
    styleUrls: ['./pi-form-layout-flat.component.scss'],
})
export class PiFormLayoutFlatComponent implements OnInit {
    @Input() panels: any;
    @Input() theFormGroup: UntypedFormGroup;
    @Input() progressType: string = 'none';
    @Input() mode: string = 'edit';
    @Input() formData: any;
    @Input() logicalContext: any;
    @Input() startTabIndex: number = 1;
    @Input() entryType: IFormEntryType;
    @Input() parentList: any[]; //specific to survey builder - logic builder

    @Output() changes: EventEmitter<any> = new EventEmitter();
    @Input() patientId: any;
    @Input() surveyId: any;

    elementList: IFormElement[] = [];

    constructor(
        private frm_svc: FormService,
        private com_svc: CommonService,
        private translate: TranslateService
    ) {}
    getText = this.com_svc.getText;
    ngOnInit(): void {
        this.panels.forEach((panel) => {
            if (panel.elements && panel.elements.length > 0) {
                panel.layout = this.createLayout(panel.elements);
                this.elementList = panel.elements;
            }
        });
    }

    createLayout(elements: IFormElement[]): any {
        let rowFill = 0;
        let colToFill = 'none';
        let pageRowIndex = 0;
        let colRowIndex = 0;
        let colRowFill = 0;

        const theLayout = { pageRows: [] };
        theLayout.pageRows[pageRowIndex] = [];

        for (var e = 0; e < elements.length /*increment is handled in the loop*/; ) {
            var currentE = elements[e];
            // this element has a column
            if (currentE.inColumn) {
                //start or add to existing column
                if (currentE.inColumn == colToFill) {
                    //we are in the correct column
                    //fill a plain column row
                    if (this.needANewRow(colRowFill, currentE)) {
                        //need a new column row
                        colRowIndex += 1;
                        //no fill yet
                        colRowFill = 0;
                        //create the row column array
                        theLayout.pageRows[pageRowIndex][colToFill][colRowIndex] = [];
                        //no element added
                    } else {
                        //it will fit so let's add it
                        theLayout.pageRows[pageRowIndex][colToFill][colRowIndex].push(currentE);
                        //populate the fill
                        colRowFill += this.getColFill(currentE);
                        //we added an element
                        e += 1;
                    }
                } else if (currentE.inColumn == 'right') {
                    //was left or none is now right
                    //start the right column
                    colToFill = 'right';
                    //create the row array for the right column
                    theLayout.pageRows[pageRowIndex][colToFill] = [];
                    //on row 0
                    colRowIndex = 0;
                    //no fill yet
                    colRowFill = 0;
                    //create the row column array
                    theLayout.pageRows[pageRowIndex][colToFill][colRowIndex] = [];
                    //just add to it here
                    theLayout.pageRows[pageRowIndex][colToFill][colRowIndex].push(currentE);
                    //populate the fill
                    colRowFill += this.getColFill(currentE);
                    //we added an element
                    e += 1;
                } else if (currentE.inColumn == 'left') {
                    //must have been in right column or at the first row with a column in a bit
                    //new left column will always start a new page row unless its the first row
                    if (e != 0) {
                        //we need a new row with a new left column
                        pageRowIndex += 1;
                        theLayout.pageRows[pageRowIndex] = [];
                    }
                    colToFill = 'left';
                    //create the row array for the column
                    theLayout.pageRows[pageRowIndex][colToFill] = [];
                    //on row 0
                    colRowIndex = 0;
                    //no fill yet
                    colRowFill = 0;
                    //create the row column array
                    theLayout.pageRows[pageRowIndex][colToFill][colRowIndex] = [];
                    //no element added
                }
            } else {
                //there is no inColumn specified
                colToFill = 'none';
                //check if there are already columns on the current page row
                if (
                    theLayout.pageRows[pageRowIndex]['left'] ||
                    theLayout.pageRows[pageRowIndex]['right']
                ) {
                    //we need a new row
                    pageRowIndex += 1;
                    theLayout.pageRows[pageRowIndex] = [];
                    rowFill = 0;
                    //no element added
                } else {
                    //otherwise fill a plain row (not columned)
                    if (this.needANewRow(rowFill, currentE)) {
                        pageRowIndex += 1;
                        theLayout.pageRows[pageRowIndex] = [];
                        rowFill = 0;
                        //no element added
                    } else {
                        theLayout.pageRows[pageRowIndex].push(currentE);
                        rowFill += this.getColFill(currentE);
                        e += 1;
                    }
                }
            }
        }
        return theLayout;
    }

    needANewRow(rowFill: number, element: any) {
        return rowFill + this.getColFill(element) > 12;
    }

    getColFill(element: IFormElement) {
        if (!element.xWidth) {
            return 12;
        } else if (element.xWidth === 'half') {
            return 6;
        } else if (element.xWidth === 'third') {
            return 4;
        } else if (element.xWidth === 'quarter') {
            return 3;
        } else if (element.xWidth === 'two-thirds') {
            return 8;
        } else if (element.xWidth === 'three-quarters') {
            return 9;
        }
    }

    getColClass(element: IFormElement) {
        if (!element.xWidth) {
            return 'col-12';
        } else if (element.xWidth === 'half') {
            return 'col-12 col-md-6';
        } else if (element.xWidth === 'third') {
            return 'col-12 col-md-4';
        } else if (element.xWidth === 'quarter') {
            return 'col-12 col-md-3';
        } else if (element.xWidth === 'two-thirds') {
            return 'col-12 col-md-8';
        } else if (element.xWidth === 'three-quarters') {
            return 'col-12 col-md-9';
        }
    }

    progressChanges(resp): any {
        this.changes.emit(resp);
    }

    @Output() downloadButtonClicked: EventEmitter<string> = new EventEmitter<string>();

    downloadEventHandlerFunction(valueEmitted) {
        this.downloadButtonClicked.emit(valueEmitted);
    }

    @Output() stageFileUploadChanges: EventEmitter<any> = new EventEmitter();

    stageFileUploadChangesHandlerFunction(files) {
        this.stageFileUploadChanges.emit(files);
    }

    @Output() stageFileDeleteChanges: EventEmitter<any> = new EventEmitter();

    stageFileDeleteChangesHandlerFunction(files) {
        this.stageFileDeleteChanges.emit(files);
    }
}
