import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '@h20-services/common.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import { BaseTileComponent } from '../base-tile/base-tile.component';

@Component({
    selector: 'app-pie-tile',
    templateUrl: './pie-tile.component.html',
    styleUrls: ['./pie-tile.component.scss'],
})
export class PieTileComponent extends BaseTileComponent implements OnInit {
    @Input() tile;

    highcharts;
    chartOptions;

    constructor(com_svc: CommonService, private translate: TranslateService) {
        super(com_svc);
    }

    ngOnInit(): void {
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.reloadChart();
        });
        this.highcharts = Highcharts;
        this.reloadChart();
    }

    getText = this.com_svc.getText;

    reloadChart() {
        this.chartOptions = this.highcharts.Options = {
            chart: {
                type: 'pie',
            },
            colors: this.tile.colors || this.baseColours,
            title: {
                text: this.getText(this.tile.title),
                useHTML: true,
            },
            subtitle: {
                text: this.getText(this.tile.subTitle),
                useHTML: true,
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: this.tile.enableDataLabels,
                    },
                    showInLegend: this.tile.enableLegend,
                },
            },
            series: [
                {
                    type: 'pie',
                    name: this.getText(this.tile.title),
                    data: this.tile.data,
                },
            ],
            credits: {
                enabled: false,
            },
        };
    }
}
