import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-searchable-list',
    templateUrl: './searchable-list.component.html',
    styleUrls: ['./searchable-list.component.scss'],
})
export class SearchableListComponent {
    private _dataInput;

    get dataInput(): any {
        return this._dataInput;
    }

    @Input() set dataInput(data: any) {
        if (data) {
            this._dataInput = data;
        }
    }

    dataList = [];
    dataFromDB: any[] = [];
    lastkeydown1: number = 0;
    constructor() {}

    getUserInput($event) {
        let userInput = (<HTMLInputElement>document.getElementById('userInput')).value;
        if (userInput.length >= 1) {
            if ($event.timeStamp - this.lastkeydown1 > 200) {
                this.dataList = this.searchFromArray(this.dataInput, userInput);
            }
        }
    }

    searchFromArray(arr, regex) {
        let matches = [],
            i;
        for (i = 0; i < arr.length; i++) {
            if (arr[i].match(regex)) {
                matches.push(arr[i]);
            }
        }
        return matches;
    }
}
