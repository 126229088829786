<div class="h-100 w-100 logout d-flex flex-wrap justify-content-center align-content-center">
    <div class="logout-box w-50 w-lg-50">
        <div class="p-4 text-center">
            <img alt="Logo" class="mb-4 w-50" src="/assets/image/composerLogo.png" />
            <h1 class="font-family-headers pb-4 text-center">You have successfully logged out.</h1>
            <h3 class="message">
                Return to login.
                <button (click)="goLogIn()" class="btn btn-theme btn-sm btn-block text-uppercase">
                    click here
                </button>
            </h3>
        </div>
    </div>
</div>
