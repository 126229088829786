<div class="password-container d-flex flex-column align-items-start justify-content-between">
    <div class="password-field d-flex">
        <input
            type="password"
            id="password-input"
            [ngClass]="{
                'is-valid': passwordValid,
                'is-invalid':  !passwordValid && passwordValid !== undefined,
                'password-input':  passwordValid === undefined,
                'form-control': true
            }"
            placeholder="Password"
            (blur)="validate()"
        />
        <button
            class="password-toggle d-flex"
            attr.aria-label="Show Password"
            (click)="togglePasswordVisibility()"
            id="password-toggle"
        >
            <i
                [ngClass]="{ 
                'fa-solid':true,
                'fa-eye-slash': showCharacters,
                'fa-eye': !showCharacters,
            }"
                id="eye-icon"
            >
            </i>
            <span class="btn-text">{{ text | translate }}</span>
        </button>
    </div>
</div>