<h1 class="text-center mt-3 mb-3">Task Set Editor</h1>
<app-loading-component [loading]="loadingRegistries" [text]="'loading registry info'">
    <app-button
        (action)="createTaskSetToggle = true; resetTaskSetModel()"
        [buttonText]="'Registry.CreateNew'"
    />
</app-loading-component>
<hr />
<app-table-component [rowData]="lstTaskSets" [columnDefs]="colDefs" [filterModelKey]="'task_set'">
</app-table-component>

<ng-template #taskSetTemplate let-taskSetModel let-editID="editID">
    <div class="container-fluid mb-2" *ngIf="taskSetModel">
        <div>
            <div>
                <label class="mx-3">
                    Registry:
                    <select
                        (change)="registryChanged($event.target.value)"
                        class="form-select"
                        [readonly]="!editID"
                    >
                        <option [selected]="editID" disabled>Select One</option>
                        <option
                            *ngFor="let reg of lstRegistries"
                            [selected]="reg === splitIDs(taskSetModel['registry_id#site_id#id'])[0]"
                        >
                            {{ reg }}
                        </option>
                    </select>
                </label>
                <label class="mx-3" *ngIf="selectedRegistry">
                    Site:
                    <select
                        (change)="siteChanged($event.target.value)"
                        class="form-select"
                        [readonly]="!editID"
                    >
                        <option [selected]="editID" disabled>Select One</option>
                        <option
                            *ngFor="let site of lstSites"
                            [selected]="
                                site === splitIDs(taskSetModel['registry_id#site_id#id'])[1]
                            "
                        >
                            {{ site }}
                        </option>
                    </select>
                </label>
                <label class="mx-3" *ngIf="!editID">
                    ID:
                    <input
                        class="form-control"
                        [value]="splitIDs(taskSetModel['registry_id#site_id#id'])[2]"
                        [readonly]="!editID"
                    />
                </label>
            </div>
            <div>
                <label class="mx-3">
                    Task Actions:
                    <select (change)="setActionChanged($event.target.value)" class="form-select">
                        <option [selected]="editID" disabled>Select One</option>
                        <option
                            *ngFor="let action of lstTaskActions"
                            [selected]="reg === taskSetModel.action"
                        >
                            {{ action }}
                        </option>
                    </select>
                </label>
            </div>
            <div *ngIf="selectedSite">
                <div>
                    <label class="mx-3">
                        Task Set:
                        <ul>
                            <li *ngFor="let proto of taskSetModel.tasks">
                                {{ getProtoLabelByID(proto) }}
                                <i
                                    class="far fa-minus-circle text-end"
                                    (click)="removeTask(task)"
                                ></i>
                            </li>
                        </ul>
                    </label>
                </div>
                <div>
                    <label class="mx-3" *ngIf="selectedRegistry">
                        Task Set Tasks:
                        <select
                            (change)="selectedTaskChanged($event.target.value)"
                            class="form-select"
                        >
                            <option [selected]="editID" disabled>Select One</option>
                            <option *ngFor="let proto of lstFilteredPrototypes" [value]="proto.id">
                                {{ proto.site_ID }} - {{ proto.nice_label }} - {{ proto.type }}
                            </option>
                        </select>
                        <i class="far fa-regular fa-plus-circle text-end" (click)="addTask()"></i>
                    </label>
                </div>
            </div>
            <div *ngIf="selectedSite">
                <div>
                    <label class="mx-3">
                        Tasks to assign on set completion:
                        <ul>
                            <li *ngFor="let proto of taskSetModel.data">
                                {{ getProtoLabelByID(proto) }}
                                <i
                                    class="far fa-minus-circle text-end"
                                    (click)="removeData(task)"
                                ></i>
                            </li>
                        </ul>
                    </label>
                </div>
                <div>
                    <label class="mx-3" *ngIf="selectedRegistry">
                        Task Set Data:
                        <select
                            (change)="selectedTaskChanged($event.target.value)"
                            class="form-select"
                        >
                            <option [selected]="editID" disabled>Select One</option>
                            <option *ngFor="let proto of lstFilteredPrototypes" [value]="proto.id">
                                {{ proto.site_ID }} - {{ proto.nice_label }} - {{ proto.type }}
                            </option>
                        </select>
                        <i class="far fa-regular fa-plus-circle text-end" (click)="addData()"></i>
                    </label>
                </div>
            </div>
        </div>
    </div>
</ng-template>
