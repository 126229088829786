import { Injectable } from '@angular/core';
import { NgbDateStruct, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
    readonly DELIMITER = '/';
    dateFormat = localStorage.getItem('prefDateFormat') || 'mm/dd/yyyy';

    fromModel(value: string | null): NgbDateStruct | null {
        if (value) {
            const date = value.split(this.DELIMITER);
            if (this.dateFormat === 'dd/mm/yyyy') {
                return {
                    day: parseInt(date[0], 10),
                    month: parseInt(date[1], 10),
                    year: parseInt(date[2], 10),
                };
            } else if (this.dateFormat === 'yyyy/mm/dd') {
                return {
                    day: parseInt(date[2], 10),
                    month: parseInt(date[1], 10),
                    year: parseInt(date[0], 10),
                };
            } else if (this.dateFormat === 'mm/dd/yyyy') {
                return {
                    day: parseInt(date[1], 10),
                    month: parseInt(date[0], 10),
                    year: parseInt(date[2], 10),
                };
            }
        }
        return null;
    }
    toModel(date: NgbDateStruct | null): string | null {
        if (!date) {
            return null;
        }
        const formatHandlers = {
            'dd/mm/yyyy': () =>
                `${date.day}${this.DELIMITER}${date.month}${this.DELIMITER}${date.year}`,
            'yyyy/mm/dd': () =>
                `${date.year}${this.DELIMITER}${date.month}${this.DELIMITER}${date.day}`,
            'mm/dd/yyyy': () =>
                `${date.month}${this.DELIMITER}${date.day}${this.DELIMITER}${date.year}`,
        };
        const formatter = formatHandlers[this.dateFormat];
        return formatter ? formatter() : '';
    }
}

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
    readonly DELIMITER = '/';
    dateFormat = localStorage.getItem('prefDateFormat') || 'mm/dd/yyyy';

    parse(value: string): NgbDateStruct | null {
        if (value) {
            const date = value.split(this.DELIMITER);
            if (this.dateFormat === 'dd/mm/yyyy') {
                return {
                    day: parseInt(date[0], 10),
                    month: parseInt(date[1], 10),
                    year: parseInt(date[2], 10),
                };
            } else if (this.dateFormat === 'yyyy/mm/dd') {
                return {
                    day: parseInt(date[2], 10),
                    month: parseInt(date[1], 10),
                    year: parseInt(date[0], 10),
                };
            } else if (this.dateFormat === 'mm/dd/yyyy') {
                return {
                    day: parseInt(date[1], 10),
                    month: parseInt(date[0], 10),
                    year: parseInt(date[2], 10),
                };
            }
        }
        return null;
    }

    formatOrToModel(date: NgbDateStruct | null, isFormat: boolean): string | null {
        if (!date) {
            return null;
        }
        const formatHandlers = {
            'dd/mm/yyyy': () =>
                `${date.day}${this.DELIMITER}${date.month}${this.DELIMITER}${date.year}`,
            'yyyy/mm/dd': () =>
                `${date.year}${this.DELIMITER}${date.month}${this.DELIMITER}${date.day}`,
            'mm/dd/yyyy': () =>
                `${date.month}${this.DELIMITER}${date.day}${this.DELIMITER}${date.year}`,
        };

        const formatter = formatHandlers[this.dateFormat];
        if (formatter) {
            return formatter();
        }
        return isFormat ? '' : null;
    }

    format(date: NgbDateStruct | null): string {
        return this.formatOrToModel(date, true) || '';
    }

    toModel(date: NgbDateStruct | null): string | null {
        return this.formatOrToModel(date, false);
    }
}
