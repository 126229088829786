import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrationComponent } from './registration/registration.component';
import { ContentManagementRoutingModule } from './content-management-routing.module';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { SharedModule } from '@h20-shared/shared.module';
import { LibraryComponent } from './library/library.component';
import { DashboardManagementComponent } from './dashboard-management/dashboard-management.component';
import { NgJsonEditorModule } from 'ang-jsoneditor';

@NgModule({
    declarations: [
        RegistrationComponent,
        ThankYouComponent,
        LibraryComponent,
        DashboardManagementComponent,
    ],
    imports: [CommonModule, SharedModule, ContentManagementRoutingModule, NgJsonEditorModule],
})
export class ContentManagementModule {}
