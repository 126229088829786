import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsersComponent } from './users/users.component';
import { DeletePatientsComponent } from './delete-patients/delete-patients.component';
import { AttributeDefComponent } from './attribute-def/attribute-def.component';

const routes: Routes = [
    {
        path: '',
        component: UsersComponent,
    },
    {
        path: 'patients/delete',
        component: DeletePatientsComponent,
    },
    {
        path: 'attributes/definitions',
        component: AttributeDefComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class UserRoutingModule {}
