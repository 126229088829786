import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CommonService } from '@h20-services/common.service';
import { FormService } from '@h20-services/form.service';
import { SurveySessionService } from '@h20-services/survey-session.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-pi-form-layout-one-by-one',
    templateUrl: './pi-form-layout-one-by-one.component.html',
    styleUrls: ['./pi-form-layout-one-by-one.component.scss'],
})
export class PiFormLayoutOneByOneComponent implements OnInit {
    @Input() panels: any;
    @Input() theFormGroup: UntypedFormGroup;
    @Input() progressType: string = 'none';
    @Input() formData: any;
    @Input() logicalContext: any;
    @Input() startTabIndex: number = 1;

    activeIndex: number;
    activeElement: any;
    questionOpen: Date;
    private hasStoppedForError = false;
    elementList: any[] = [];
    flatElementList: any[] = [];
    constructor(
        private frm_svc: FormService,
        private com_svc: CommonService,
        private translate: TranslateService,
        private surveySessionSvc: SurveySessionService
    ) {}

    ngOnInit(): void {
        if (this.theFormGroup) {
            let elements = [];
            this.panels.forEach((panel) => {
                elements.push(panel.elements);
            });
            this.elementList = elements[0];
            this.flatElementList = elements.flat();
            // add control to each element
            this.flatElementList.forEach((el) => {
                el.control = this.theFormGroup.controls[el.name];
            });
            this.activeIndex = this.findNextEnabledControl(0);
            this.activeElement = this.flatElementList[this.activeIndex];
        }
        this.questionOpen = new Date();
    }

    findNextEnabledControl(startAt) {
        let index = startAt;
        while (
            index < this.flatElementList.length &&
            this.theFormGroup.controls[this.flatElementList[index].name].disabled
        ) {
            index++;
        }
        return index;
    }

    goToNextEnabledControl(startAt) {}

    goToPrevEnabledControl(startAt) {}

    findPrevEnabledControl(startAt) {
        let index = startAt;
        while (index > 0 && this.theFormGroup.controls[this.flatElementList[index].name].disabled) {
            index--;
        }
        return index;
    }

    existsNextEnabledControl() {
        return this.findNextEnabledControl(this.activeIndex + 1) >= this.flatElementList.length;
    }

    existsPrevEnabledControl() {
        return this.findPrevEnabledControl(this.activeIndex - 1) < 0;
    }

    onNext() {
        this.trackQuestionChange('next question');
        const curCtrl = this.theFormGroup.controls[this.activeElement.name];
        this.theFormGroup.controls[this.activeElement.name].markAsTouched();
        if (!this.hasStoppedForError && curCtrl?.errors) {
            this.hasStoppedForError = true;
            return;
        }
        this.hasStoppedForError = false;
        this.activeIndex = this.findNextEnabledControl(this.activeIndex + 1);
        this.activeElement = this.flatElementList[this.activeIndex];
        this.setTabTarget(this.activeElement.name);
        const progressElement = document.getElementById(`progress-${this.activeElement.name}`);
        progressElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }

    onPrev() {
        this.trackQuestionChange('previous question');
        this.hasStoppedForError = false;
        this.activeIndex = this.findPrevEnabledControl(this.activeIndex - 1);
        this.activeElement = this.flatElementList[this.activeIndex];
        this.setTabTarget(this.activeElement.name);
        const progressElement = document.getElementById(`progress-${this.activeElement.name}`);
        progressElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }

    trackQuestionChange(navigateTo) {
        const now = new Date();
        const trackingObj = {
            questionKey: this.activeElement.name,
            itemOpened: `${this.questionOpen.getUTCFullYear()}-${
                this.questionOpen.getUTCMonth() + 1
            }-${this.questionOpen.getUTCDate()} ${this.questionOpen.getUTCHours()}:${this.questionOpen.getUTCMinutes()}:${this.questionOpen.getUTCSeconds()}`,
            itemClosed: `${now.getUTCFullYear()}-${
                now.getUTCMonth() + 1
            }-${now.getUTCDate()} ${now.getUTCHours()}:${now.getUTCMinutes()}:${now.getUTCSeconds()}`,
            itemType: 'survey question',
            navigateTo: navigateTo,
            surveySessionId: this.surveySessionSvc.currentSession.id,
        };
        this.surveySessionSvc.trackSurveyNavigation(trackingObj).subscribe();
    }

    setActiveIndex(index) {
        if (index != this.activeIndex) this.hasStoppedForError = false;
        this.activeIndex = index;
        this.activeElement = this.flatElementList[this.activeIndex];
        this.setTabTarget(this.activeElement.name);
    }

    setActiveElement(elementName) {
        if (elementName != this.activeElement.name) this.hasStoppedForError = false;
        this.activeIndex = this.flatElementList.findIndex((el) => {
            return el.name == elementName;
        });
        this.activeElement = this.flatElementList[this.activeIndex];
        this.setTabTarget(this.activeElement.name);
    }

    /**
     * Used to reset the tabbing focus to the next question when question page is changed.
     * Because keyboard events (e.g. pressing enter on "next question" button) also manipulate focus,
     * this uses a setTimeout to ensure focus is moved
     * @param elementId id of the element to target
     */
    setTabTarget(elementId: string) {
        window.setTimeout(() => document.getElementById(elementId).focus(), 0);
    }

    // returns class names (which need to be defined in scss file).
    getDotClass(element, index) {
        if (index === this.activeIndex) return 'active-dot fas fa-circle';
        if (this.isComplete(element)) return 'completed-dot fas fa-circle-check';
        if (this.isError(element)) return 'error-dot fas fa-triangle-exclamation';
        if (this.isInfo(element)) return 'completed-dot fas fa-circle-info';
        //is empty
        return 'far fa-circle';
    }

    isEnabled(element) {
        return !this.theFormGroup.controls[element.name].disabled;
    }

    isActive(element) {
        return this.activeElement.name === element.name;
    }

    getClass(element) {}

    isError(element) {
        const ctrl = this.theFormGroup.controls[element.name];
        return element.type != 'html' && ctrl.touched && !ctrl.valid;
    }

    isEmpty(element) {
        const ctrl = this.theFormGroup.controls[element.name];
        return (
            element.type != 'html' &&
            (ctrl.untouched || (ctrl.touched && ctrl.valid && !ctrl.value))
        );
    }

    isComplete(element) {
        const ctrl = this.theFormGroup.controls[element.name];
        return (
            element.type != 'html' &&
            //ctrl.touched &&
            ctrl.valid &&
            ctrl.value
        );
    }

    isInfo(element) {
        const ctrl = this.theFormGroup.controls[element.name];
        return element.type === 'html';
    }

    getText = this.com_svc.getText;

    @Output() downloadButtonClicked: EventEmitter<string> = new EventEmitter<string>();

    downloadEventHandlerFunction(valueEmitted) {
        this.downloadButtonClicked.emit(valueEmitted);
    }
}
