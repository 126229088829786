<div *ngIf="mode === 'view'; else formModeTemplate">
    <div *ngIf="!disabled" class="pi-control-edit-mode">
        <app-pi-form-view-response
            [element]="element"
            [theFormControl]="formControl"
            [displayValue]="stringValue"
        ></app-pi-form-view-response>
    </div>
</div>
<ng-template #formModeTemplate>
    <div *ngIf="!disabled" class="pi-control-form-mode">
        <label [attr.for]="this.elementUID"
            ><app-element-title [element]="element"></app-element-title
        ></label>
        <div class="pe-4">
            <textarea
                class="p-1 form-control"
                [name]="this.elementUID"
                [value]="_value ? _value : null"
                [attr.id]="this.elementUID"
                [attr.aria-label]="element.title"
                [attr.disabled]="element?.readOnly"
                [rows]="element.rows || 4"
                [cols]="element.cols || 50"
                [placeholder]="getText(element.placeHolder) || ''"
                (change)="textInput($event)"
            ></textarea>
        </div>
        <app-validator
            [control]="formControl"
            [customErrorMessages]="customErrorMessages"
            [warningControl]="warningControl"
            [customWarningMessages]="customWarningMessages"
        ></app-validator>
    </div>
</ng-template>
