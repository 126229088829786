<div *ngIf="element.type === 'paneldynamic'">
    <app-element-layout-designer [(elements)]="element.templateElements">
    </app-element-layout-designer>
</div>
<app-pi-form
    *ngIf="designerForm && element && !hotReload"
    [formData]="element"
    [formDef]="designerForm"
    [options]="options"
    [parentList]="elements"
    (formChanges)="onFormChanges($event)"
    layout="flat"
></app-pi-form>
