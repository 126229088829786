import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColDef, GridApi } from 'ag-grid-community';
import { CustomCellRendererComponent } from './cell-renderer/custom-cell-renderer/custom-cell-renderer.component';
import { TranslateService } from '@ngx-translate/core';

const customDataTypes = [
    'icon',
    'actionBtn',
    'openAndActionBtns',
    'textWithIcon',
    'dropdown',
    'iconBtns',
];

@Component({
    selector: 'app-table-component',
    templateUrl: './table-component.component.html',
    styleUrls: ['./table-component.component.scss'],
})
export class TableComponent {
    gridApi: GridApi;
    gridColumnApi: any;
    @Input() showPatientSuccess: boolean = false; 
    @Input() columnDefs: ColDef;
    @Input() rowData: Array<unknown>;
    @Input() pagination: boolean = false;
    @Input() paginationPageSize: number = 10;
    @Input() rowSelection?: string = '';
    @Input() suppressRowClickSelection: boolean = false;
    @Input() enableCellTextSelection: boolean = true;
    @Input() showFilterRow = true;
    @Output() selectionChanged?: EventEmitter<unknown> = new EventEmitter<unknown>();
    @Output() getGridApi?: EventEmitter<GridApi> = new EventEmitter<GridApi>();
    @Output() sortingChanged?: EventEmitter<unknown> = new EventEmitter<unknown>();
    @Input() filterModelKey: string;

    constructor(public translate: TranslateService) {
        translate.onLangChange.subscribe(() => {
            this.gridApi?.refreshCells?.();
        });
    }

    public defaultColDef: ColDef = {
        suppressSizeToFit: false,
        resizable: true,
        autoHeight: true,
        floatingFilter: true,
        wrapText: true,
        filterParams: {
            filterOptions: ['contains'],
            maxNumConditions: 1,
            buttons: ['clear'],
            closeOnApply: true,
        },
        cellStyle: {
            height: 60,
        },
        cellRendererSelector: (params) => {
            if (customDataTypes?.includes(params?.colDef?.cellDataType?.toString())) {
                return {
                    component: CustomCellRendererComponent,
                    params: {
                        componentType: params.colDef?.cellDataType,
                        additionalParams: params.colDef?.cellRendererParams(params),
                    },
                };
            } else return undefined;
        },
    };
    ngOnInit() {
        document.addEventListener('keydown', this.globalKeyDownHandler);
        this.defaultColDef = {
            suppressKeyboardEvent: this.suppressKeyboardEvent,
            suppressSizeToFit: false,
            resizable: false,
            autoHeight: true,
            unSortIcon: true,
            floatingFilter: this.showFilterRow,
            wrapText: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
                buttons: ['clear'],
                closeOnApply: true,
            },
            cellStyle: {
                height: 60,
            },
            cellRendererSelector: (params) => {
                if (customDataTypes?.includes(params?.colDef?.cellDataType?.toString())) {
                    return {
                        component: CustomCellRendererComponent,
                        params: {
                            componentType: params.colDef?.cellDataType,
                            additionalParams: params.colDef?.cellRendererParams(params),
                        },
                    };
                } else return undefined;
            },
        };
    }

    public dataTypeDefinitions = {
        icon: {
            extendsDataType: 'text',
            baseDataType: 'text',
        },
        actionBtn: {
            extendsDataType: 'text',
            baseDataType: 'text',
        },
        openAndActionBtns: {
            extendsDataType: 'text',
            baseDataType: 'text',
        },
        textWithIcon: {
            extendsDataType: 'text',
            baseDataType: 'text',
        },
        iconBtns: {
            extendsDataType: 'text',
            baseDataType: 'text',
        },
        dropdown: {
            extendsDataType: 'text',
            baseDataType: 'text',
        },
    };

    public paginationPageSizeSelector = [10, 25, 50, 100];
   
    flashTopRow() {
        if (this.gridApi) {
            const topRowNode = this.gridApi.getDisplayedRowAtIndex(0);
            if (topRowNode) {
                this.gridApi.flashCells({ rowNodes: [topRowNode] });
            }
        }
    }

    onReady = (event) => {
        this.gridApi = event.api;
        this.gridColumnApi = event.columnApi;
        this.getGridApi?.emit(event.api);
        if(this.showPatientSuccess){
            this.flashTopRow();
        }
    };

    onSelect = (event) => {
        this.selectionChanged?.emit(event);
    };

    onSortChanged =(event) =>{
        this. sortingChanged?.emit(event);
    }

    onFilterChanged(params) {
        const filterModel = params.api.getFilterModel();
        const allFilterModels = JSON.parse(localStorage.getItem('filterModels')) || {};
        allFilterModels[this.filterModelKey] = filterModel;
        localStorage.setItem('filterModels', JSON.stringify(allFilterModels));
        this.manageCustomFilterVisibility(params);
    }

    onFirstDataRendered(params) {
        const allFilterModels = JSON.parse(localStorage.getItem('filterModels')) || {};
        const filterModel = allFilterModels[this.filterModelKey];
        if (filterModel) {
            params.api.setFilterModel(filterModel);
        }
        this.manageCustomFilterVisibility(params);
    }

    manageCustomFilterVisibility(params) {
        params.api.getColumnFilterInstance('yourColumnField', (filterComponent) => {
            if (filterComponent) {
                filterComponent.afterGuiAttached({ api: params.api });
                params.api.onFilterChanged();
            }
        });
    }

    suppressKeyboardEvent(params: any) {
        const e = params.event;
        if (e.code == 'Tab' || e.key == 'Tab') {
            let focusableChildrenOfParent = e.srcElement
                .closest('.ag-cell')
                .querySelectorAll(
                    'button, [href], :not(.ag-hidden) > input, select, textarea, [tabindex]:not([tabindex="-1"])'
                );

            if (
                focusableChildrenOfParent.length == 0 ||
                (e.shiftKey == false &&
                    e.srcElement ==
                        focusableChildrenOfParent[focusableChildrenOfParent.length - 1]) ||
                (e.shiftKey == true && e.srcElement == focusableChildrenOfParent[0]) ||
                (e.shiftKey == true && e.srcElement.classList.contains('ag-cell'))
            )
                return false;
            return true;
        }
        return false;
    }

    globalKeyDownHandler = (event: KeyboardEvent) => {
        const isCtrlOrCmdPressed = event.ctrlKey || event.metaKey;
        const isCKey = event.key.toLowerCase() === 'c';
        if (isCtrlOrCmdPressed && isCKey) {
            this.copyCellData(event);
            event.preventDefault();
        }
    };

    copyCellData(event: KeyboardEvent) {
        if (!event) {
            return;
        }
        const isCtrlOrCmdPressed = event.ctrlKey || event.metaKey;
        const isCKey = event.key?.toLowerCase() === 'c';

        if (this.enableCellTextSelection && isCtrlOrCmdPressed && isCKey) {
            const focusedCell = this.gridApi.getFocusedCell();
            if (focusedCell) {
                const rowIndex = focusedCell.rowIndex;
                const colId = focusedCell.column.getId();
                const cellValue = this.gridApi.getValue(
                    colId,
                    this.gridApi.getDisplayedRowAtIndex(rowIndex)
                );
                const cellElement = document.querySelector(
                    `.ag-row[row-index="${rowIndex}"] .ag-cell[col-id="${colId}"]`
                );
                if (cellElement) {
                    cellElement.classList.add('ag-cell-clicked');
                }
                const copyHandler = (event: ClipboardEvent) => {
                    if (event.clipboardData) {
                        event.clipboardData.setData('text/plain', cellValue);
                        event.preventDefault();
                    }
                    document.removeEventListener('copy', copyHandler);
                };
                document.addEventListener('copy', copyHandler);
                const success = document.execCommand('copy');
            }
        }
    }
}
