import { Component } from '@angular/core';


@Component({
    selector: 'app-layout-centered',
    templateUrl: './layout-centered.component.html',
    styleUrls: ['./layout-centered.component.scss'],
})
export class LayoutCenteredComponent {
    constructor() {}
}
