import { Component, Injector, OnInit } from '@angular/core';
import { AbstractControl, NG_VALUE_ACCESSOR, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CommonService } from '@h20-services/common.service';
import { IFormElement } from '@h20-services/models/forms/IFormElement';
import { TranslateService } from '@ngx-translate/core';
import { AbstractPiControlComponent } from '../abstract-pi-control/abstract-pi-control.component';
import { SurveyService } from '@h20-services/survey.service';
import { IFormChoice } from '@h20-services/models/forms/IFormChoice';
import { RequestService } from '@h20-services/request.service';
import { UuidService } from '@h20-services/uuid.service';

/**
 * Drop text is the same visually as a dropdown, but the value
 * saved is the text that is displayed rather than the value
 * of the choice - used by Composer Survey Builder
 */
@Component({
    selector: 'app-pi-control-drop-text',
    templateUrl: './pi-control-drop-text.component.html',
    styleUrls: ['./pi-control-drop-text.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PiControlDropTextComponent,
        },
    ],
})
export class PiControlDropTextComponent
    extends AbstractPiControlComponent<IFormElement, any>
    implements OnInit
{
    loading = false;

    //we need to use a stand for the form value so that we can deselect
    currentChoice: any;

    constructor(
        public injector: Injector,
        protected com_svc: CommonService,
        protected uuid_svc: UuidService,
        private req_svc: RequestService,
        protected translate: TranslateService,
        private survey_svc: SurveyService
    ) {
        super(injector, com_svc, uuid_svc, translate);
    }

    ngOnInit() {
        super.ngOnInit();
        if (this.element.hasOwnProperty('noChoicesError')) {
            this.formControl.addValidators(this.noChoicesValidator);
            this.formControl.markAsTouched();
        }

        this.currentChoice = this.getValue(this._value);
    }

    writeValue(value: any): void {
        //override to sync the drop-text UI on load
        //don't set current choice unless it has a value - for null currentChoice should be '' so it shows 'Select Response'
        //we have this here so when the initial value loads, it will show that in the UI component
        if (value !== null) {
            this.currentChoice = this.getValue(value);
            super.writeValue(value);
        } else super.writeValue(null);
    }

    selectionMade(event: any) {
        //need to write the value to show in the current selection including ''
        this.markAsTouched();
        // if the value is 'Select Response' - set the form value to null
        if (event.target.value === '') {
            this.writeValue(null);
        } else this.writeValue(this.getStringValue(event.target.value));
        this.notifyValueChange();
    }

    getStringValue(value) {
        if (!this.element.choices) return;
        const choice = this.element.choices.find((c) => c.value == value);
        return this.getText(choice?.text);
    }

    getValue(choiceText) {
        if (!this.element.choices) return;
        const choice = this.element.choices.find((c) => c.text == choiceText);
        return this.getText(choice?.value);
    }

    noChoicesValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            //always returns
            return { noChoices: { value: this.translate.instant('Surveys.PleaseCloseForm') } };
        };
    }
}
