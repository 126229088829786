import { Injectable } from '@angular/core';
import { environment as env } from '@environment/environment';
import { Observable, throwError } from 'rxjs';
import { UserService } from './user.service';
import { AuthService } from './auth.service';
import { ConfigService } from './config.service';
import { ParticipantService } from './participant.service';
import { DataEntryRoles, UserRole } from './models/role';
import { RequestService } from './request.service';
import { CommonService } from './common.service';
import { UuidService } from './uuid.service';
import { lastValueFrom } from 'rxjs';
import { IFileData } from './models/IFileWithMetaData';

@Injectable({
    providedIn: 'root',
})
export class CDNService {
    options = {
        bucket: env.awsConfig.Storage.AWSS3.bucket,
        region: 'us-east-1',
        cacheControl: 'no-cache',
    };

    user: any;

    constructor(
        private reqSvc: RequestService,
        private auth: AuthService,
        private usr: UserService,
        private uuidsvc: UuidService,
        private configsvc: ConfigService,
        private prtSvc: ParticipantService,
        private commonSvc: CommonService
    ) {}

    // Switch survey from diagnostics upload file survey to the corresponding diagnostics for ASF
    // TODO: special function for ASF. Need to refactor hardcoding
    surveyID_diagnosisFileUpload = '9f1a0491-9315-4903-99ad-7ffdcb538273';
    surveyID_diagnostics = 'f974c9c5-2355-4439-b803-2768548d6107'; // asf patient
    surveyID_diagnosticsMinor = 'd7f6e987-685b-4604-b4f3-b6e92d3746f4'; // asf minor patient

    getNKFDiagnosticsSurvey(userType: string, siteId: string, authentication_ID?: string): string {
        let diagnosticsSurveyId;

        if (userType === UserRole.Patient) {
            diagnosticsSurveyId = this.surveyID_diagnostics;
        } else if (userType === UserRole.Proxy && authentication_ID && authentication_ID !== '')
            diagnosticsSurveyId = this.surveyID_diagnostics; //The selected prt is himself.
        else diagnosticsSurveyId = this.surveyID_diagnosticsMinor;

        return diagnosticsSurveyId;
    }

    saveDeactivatePatientReason(response: any): any {
        const req = {
            content: response,
            operation: 'deactivate',
        };
        return this.reqSvc.post(env.awsConfig.API.writeToDBActivationStatus, req);
    }

    async addActivationAttr() {
        const req = {
            operation: 'activate',
        };
        return await lastValueFrom(
            this.reqSvc.post(env.awsConfig.API.writeToDBActivationStatus, req)
        );
    }

    saveScoreSurveyToDb(response: any): any {
        const req = {
            operation: 'calculateSurveyScore',
            survey_submission: response,
            survey_submission_ID: response.survey_submission_ID,
        };

        return this.reqSvc.post(env.awsConfig.API.scoringSubmission, req);
    }

    getSurveyScoringInstructions(survey_ID: any): any {
        const req = {
            operation: 'getSurveyScoringInstructions',
            survey_ID: survey_ID,
        };
        return this.reqSvc.post(env.awsConfig.API.scoringSubmission, req);
    }

    //TODO parameters should be agnostic to filename structure
    getUserSurveyResponse(filename: string) {
        const req = {
            bucket: env.awsConfig.Storage.AWSS3.bucket,
            filename: filename,
        };

        return this.reqSvc.post(env.awsConfig.API.readFromBucketURL, req);
    }

    writeMediaFile(content: any, filename: any) {
        const req = {
            filename: filename,
            content: content,
        };

        // Send survey response to lambda
        return this.reqSvc.post(env.awsConfig.API.writeToBucketURL, req);
    }

    getMediaFile(filename: string) {
        const req = {
            bucket: env.awsConfig.Storage.AWSS3.bucket,
            filename: filename,
        };

        return this.reqSvc.post(env.awsConfig.API.readFromBucketURL, req);
    }

    listUserResponsesByUser(user: any) {
        const req = {
            prefix: `${user.site_ID}/private/patient-submissions/${user.id}/`,
            bucket: env.awsConfig.Storage.AWSS3.bucket,
        };
        return this.reqSvc.post(env.awsConfig.API.listBucketItemsURL, req);
    }

    getParticipantId(patientId: string, currAuthUser: any) {
        let participantId = patientId;

        if (this.prtSvc.getCurrentParticipant())
            participantId = this.prtSvc.getCurrentParticipant();
        else {
            participantId =
                currAuthUser.type != UserRole.Patient && currAuthUser.type != UserRole.Proxy
                    ? patientId
                    : currAuthUser.id;
        }

        return participantId;
    }

    generateUploadUrl(file_data: IFileData, patientId: string): Promise<any> {
        const req = {
            operation: 'generateUploadUrl',
            data: file_data,
            patientId: patientId,
        };

        let url = this.reqSvc
            .post(env.awsConfig.API.writeFileToBucket, req)
            .toPromise()
            .catch((e: Error) => {
                return throwError(e);
            });

        return url;
    }

    uploadFileToUrl(url: string, file: File) {
        return this.reqSvc.put(url, file).catch((e: Error) => {
            return throwError(() => e);
        });
    }

   
    async generateDownloadUrl(filePaths: string | string[]): Promise<any[]> {
        const req = {
            operation: 'generateDownloadUrl',
            data: {
                filenames: filePaths, 
            },
        };
        try {
            const response = await this.reqSvc.post(env.awsConfig.API.writeFileToBucket, req).toPromise();
            return response;
        } catch (e: unknown) {
            return [];
        }
    }
    
    setPresignedUploadSuccess(file_id: string): Promise<any> {
        const req = {
            operation: 'presignedUploadSuccess',
            data: { file_id: file_id },
        };

        return this.reqSvc
            .post(env.awsConfig.API.writeFileToBucket, req)
            .toPromise()
            .catch((e: Error) => {
                return throwError(() => e);
            });
    }

    downloadFileFromUrl(url: string | string[], fileName: string | string[]) {
        if (Array.isArray(url) && Array.isArray(fileName)) {
           
            url.forEach((singleUrl, index) => {
                const currentFileName = fileName[index];
                this.reqSvc
                    .get(singleUrl)
                    .then((fileBlob: Blob) => {
                        this.downloadBlob(currentFileName, fileBlob);
                    })
                    .catch((e: Error) => {
                        return throwError(e);
                    });
            });
        }else if (typeof url === 'string') {
            const singleFileName = Array.isArray(fileName) && fileName.length === 1 ? fileName[0] : fileName as string;
            
            this.reqSvc
                .get(url)
                .then((fileBlob: Blob) => {
                    this.downloadBlob(singleFileName, fileBlob);
                })
                .catch((e: Error) => {
                    return throwError(e);
                });
        } else {
            throw new Error("url and fileName must both be either strings or arrays of equal length.");
        }
    }
    

    downloadBlob(name: string, blob: Blob) {
        const link = document.createElement('a');
        // Browsers that support HTML5 download attribute
        if (link.download !== undefined && blob) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', name);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    //submit uploaded file to partial
    savePartialUserSurveyFile(patientId: string, survey: string, key: string, file: File) {
        // Get authenticated user
        this.auth.getPulseAuth().then((pulseAuth) => {
            var currPrtId = this.prtSvc.getCurrentParticipant()
                ? this.prtSvc.getCurrentParticipant()
                : pulseAuth.getUserID();
            this.usr
                .getUser(currPrtId)
                .toPromise()
                .then((userRes: any) => {
                    let r = new FileReader();
                    r.onload = (e) => {
                        // Process individual data objects
                        if (userRes) {
                            this.user = { ...userRes[0] };
                        }
                        const patient = this.user.id;
                        const site = this.user.site_ID;

                        if (
                            (key === 'genetic_test_patient_copy_file' ||
                                key === 'audiogram_file') &&
                            window.location.toString().includes('/profile/')
                        ) {
                            survey = this.getNKFDiagnosticsSurvey(
                                pulseAuth.getType(),
                                site,
                                this.user.authentication_ID
                            );
                        }

                        let filePath = '';
                        const fileName = this.uuidsvc.uuid();
                        const fileExt = file.name.substring(
                            file.name.indexOf('.'),
                            file.name.length
                        );
                        if (DataEntryRoles.includes(pulseAuth.getType())) {
                            let tempPatId = this.prtSvc.getClinicalEntryPatient()
                                ? this.prtSvc.getClinicalEntryPatient()
                                : patient;
                            filePath = `${site}/private/clinician-submissions/${tempPatId}/${survey}/partial/${key}/${file.name}`;
                        } else {
                            filePath = `${site}/private/patient-submissions/${patient}/file-uploads/${fileName}${fileExt}`;
                        }

                        const now = new Date();

                        const file_data: IFileData = {
                            content_type: file.type,
                            name: file.name,
                            type: file.name.substring(file.name.lastIndexOf('.')),
                            question_key: key,
                            submission_origin: 'partial',
                            upload_date: `${now.getUTCFullYear()}-${
                                now.getUTCMonth() + 1
                            }-${now.getUTCDate()}`,
                        };

                        this.generateUploadUrl(file_data, this.user.id)
                            .then((uploadUrl) => {
                                this.uploadFileToUrl(uploadUrl, file);
                                // Upload json file with the file information that can not be included in Tags
                                const fileMetaData = {
                                    FileName: file.name,
                                };
                                const req = {
                                    filename: `${site}/private/patient-submissions/${patient}/file-uploads/${fileName}-metadata.json`,
                                    content: fileMetaData,
                                };

                                // Send survey response to lambda
                                return this.reqSvc.post(env.awsConfig.API.writeToBucketURL, req);
                            })
                            .catch((e: Error) => {
                                console.error('partial submitUserSurveys err', e);
                                return throwError(e);
                            });
                    };
                    r.readAsDataURL(file);
                });
        });
    }

    // download uploaded file from s3
    async downloadUserSurveyFile(
        survey: string,
        patient_ID: string,
        site_ID: string,
        fileName: string,
        key: string = ''
    ) {
        const currAuthUser = await this.auth.getPulseAuth();

        let filePath = `${site_ID}/private/patient-submissions/${patient_ID}/${survey}/${patient_ID}/${fileName}`;

        if (DataEntryRoles.includes(currAuthUser.getType())) {
            filePath = `${site_ID}/private/clinician-submissions/${patient_ID}/${survey}/partial/${key}/${fileName}`;
        }

        this.generateDownloadUrl(filePath)
            .then((downloadUrl) => {
                this.downloadFileFromUrl(downloadUrl, fileName);
            })
            .catch((e: Error) => {
                console.error('downloadUserSurveyFile err', e);
                return throwError(e);
            });
    }

    // download uploaded partial file from s3
    downloadUserSurveyPartialFile(
        survey: string,
        patient_ID: string,
        site_ID: string,
        key: string,
        fileName: string
    ) {
        const filePath = `${site_ID}/private/patient-submissions/${patient_ID}/${survey}/partial/${key}/${fileName}`; //${site}/private/patient-submissions/${user}/${survey}/${user}/${fileName}

        this.generateDownloadUrl(filePath)
            .then((downloadUrl) => {
                this.downloadFileFromUrl(downloadUrl, fileName);
            })
            .catch((e: Error) => {
                console.error('downloadUserSurveyFile err', e);
                return throwError(e);
            });
    }

    // remove uploaded file from the s3
    removeS3Object(objectPath: string) {
        const req = {
            operation: 'removeFile',
            data: {
                filename: objectPath,
            },
        };
        return this.reqSvc
            .post(env.awsConfig.API.writeFileToBucket, req)
            .toPromise()
            .catch((e: Error) => {
                console.error('file removal issue.', e);
                return throwError(e);
            });
    }

    // remove uploaded file from the s3
    removeUserSurveyFile(patientId: string, survey: string, key: string, fileName: string) {
        this.auth.getPulseAuth().then((currAuthUser) => {
            var currPrtId: string = this.getParticipantId(patientId, currAuthUser);

            this.usr
                .getUser(currPrtId)
                .toPromise()
                .then((userRes: any) => {
                    if (userRes) {
                        this.user = { ...userRes[0] };
                    }

                    if (
                        (key === 'genetic_test_patient_copy_file' || key === 'audiogram_file') &&
                        window.location.toString().includes('/profile/')
                    ) {
                        survey = this.getNKFDiagnosticsSurvey(
                            currAuthUser.getType(),
                            this.user.site_ID,
                            this.user.authentication_ID
                        );
                    }

                    const patient = this.user.id;
                    const site = this.user.site_ID;

                    let filePath = '';
                    if (DataEntryRoles.includes(currAuthUser.getType())) {
                        let tempPatId = this.prtSvc.getClinicalEntryPatient()
                            ? this.prtSvc.getClinicalEntryPatient()
                            : patient;
                        filePath = `${site}/private/clinician-submissions/${tempPatId}/${survey}/partial/${key}/${fileName}`;
                    } else {
                        filePath = `${site}/private/patient-submissions/${patient}/${survey}/partial/${key}/${fileName}`;
                    }

                    const req = {
                        operation: 'removeFile',
                        data: {
                            filename: filePath,
                        },
                    };
                    this.reqSvc
                        .post(env.awsConfig.API.writeFileToBucket, req)
                        .toPromise()
                        .catch((e: Error) => {
                            console.error('file removal issue.', e);
                            return throwError(e);
                        });
                });
        });
    }

    // remove all files of a specific user from the s3
    removeAllUserFiles(patient_ID, site_ID, envName?: any): any {
        const targetEnv = this.commonSvc.getTargetEnv(envName);
        const req = {
            operation: 'removeAllFiles',
            data: {
                folderName: `${site_ID}/private/patient-submissions/${patient_ID}`,
            },
        };

        return this.reqSvc.post(targetEnv.awsConfig.API.writeFileToBucket, req, targetEnv);
    }

    // remove all clinician-submissions of a specific patient in s3
    removeAllUserFilesInClinician(patient_ID, site_ID, envName?: any): any {
        const targetEnv = this.commonSvc.getTargetEnv(envName);
        const req = {
            operation: 'removeAllFiles',
            data: {
                folderName: `${site_ID}/private/clinician-submissions/${patient_ID}`,
            },
        };

        return this.reqSvc.post(targetEnv.awsConfig.API.writeFileToBucket, req, targetEnv);
    }

    // copy uploaded file in the s3 to submitted folder
    submitUserSurveyFile(survey: string, key: string, fileName: string, userId: string, userType) {
        this.usr
            .getUser(userId)
            .toPromise()
            .then((userRes: any) => {
                if (userRes) {
                    this.user = { ...userRes[0] };
                }

                if (survey === this.surveyID_diagnosisFileUpload) {
                    survey = this.getNKFDiagnosticsSurvey(userType, this.user.site_ID);
                }

                const patient = this.user.id;
                const site = this.user.site_ID;

                let origfilePath = DataEntryRoles.includes(userType)
                    ? `${site}/private/clinician-submissions/${patient}/${survey}/partial/${key}/${fileName}`
                    : `${site}/private/patient-submissions/${patient}/${survey}/partial/${key}/${fileName}`;

                let desfilePath = DataEntryRoles.includes(userType)
                    ? `${site}/private/clinician-submissions/${patient}/${survey}/${key}/${fileName}`
                    : `${site}/private/patient-submissions/${patient}/${survey}/${patient}/${fileName}`;

                const req = {
                    operation: 'copyFile',
                    data: {
                        origfilename: origfilePath,
                        filename: desfilePath,
                    },
                };
                this.reqSvc
                    .post(env.awsConfig.API.writeFileToBucket, req)
                    .toPromise()
                    .catch((e: Error) => {
                        console.error('file removal issue.', e);
                        return throwError(e);
                    });
            });
    }

    // Submits a partial clinician survey response to S3 through a lambda
    savePartialClinicianSurveyResponses(response: any): any {
        const patient = response.id;
        const survey = response.survey_ID;
        const user = response.user_ID;
        const site = response.site_ID;
        let submission_ID;
        if (response.submission_ID) {
            submission_ID = response.submission_ID;
        }

        const req = {
            filename: response.caseReview // The Case Review Assessments will be in a separate folder because it needs to know which user's form it is.
                ? `${site}/private/clinician-submissions/assessment-submissions/${user}/${patient}/${survey}/partial.json`
                : `${site}/private/clinician-submissions/${patient}/${survey}/${submission_ID}/partial.json`,
            content: response,
        };
        // Send survey response to lambda
        return this.reqSvc.post(env.awsConfig.API.writeToBucketURL, req);
    }

    //TODO parameters should be agnostic to filename structure
    getPartialUserSurveyResponse(patientId: string, surveyId: string, site_ID: string): any {
        const req = {
            bucket: env.awsConfig.Storage.AWSS3.bucket,
            filename: `${site_ID}/private/patient-submissions/${patientId}/${surveyId}/partial.json`,
        };

        return this.reqSvc.post(env.awsConfig.API.readFromBucketURL, req);
    }

    //TODO parameters should be agnostic to filename structure
    getLastUserSurveyResponse(patientId: string, surveyId: string, site_ID: string): any {
        const req = {
            bucket: env.awsConfig.Storage.AWSS3.bucket,
            filename: `${site_ID}/private/patient-submissions/${patientId}/${surveyId}/lastSubmission.json`,
        };

        return this.reqSvc.post(env.awsConfig.API.readFromBucketURL, req);
    }

    getPartialClinicianSurveyResponse(
        patientId: string,
        surveyId: string,
        userId: string,
        site_ID: string,
        caseReview?: boolean,
        submission_ID?: string
    ): any {
        const req = {
            bucket: env.awsConfig.Storage.AWSS3.bucket,
            filename: caseReview // The Case Review Assessments will be in a separate folder because it needs to know which user's form it is.
                ? `${site_ID}/private/clinician-submissions/assessment-submissions/${userId}/${patientId}/${surveyId}/partial.json`
                : `${site_ID}/private/clinician-submissions/${patientId}/${surveyId}/${submission_ID}/partial.json`,
        };

        return this.reqSvc.post(env.awsConfig.API.readFromBucketURL, req);
    }

    getClinicianSurveyResponse(
        patientId: string,
        surveyId: string,
        site_ID: string,
        submission_ID: string
    ): any {
        const req = {
            bucket: env.awsConfig.Storage.AWSS3.bucket,
            filename: `${site_ID}/private/clinician-submissions/${patientId}/${surveyId}/${submission_ID}/submission.json`,
        };

        return this.reqSvc.post(env.awsConfig.API.readFromBucketURL, req);
    }

    saveChangeLog(patient_ID: string, site_ID: string, changeLog: any): any {
        const req = {
            filename: `${site_ID}/private/clinician-submissions/${patient_ID}/change_log.json`,
            content: changeLog,
        };

        // Send survey response to lambda
        return this.reqSvc.post(env.awsConfig.API.writeToBucketURL, req);
    }

    getChangeLog(patient_ID: string, site_ID: string) {
        const req = {
            bucket: env.awsConfig.Storage.AWSS3.bucket,
            filename: `${site_ID}/private/clinician-submissions/${patient_ID}/change_log.json`,
        };

        return this.reqSvc.post(env.awsConfig.API.readFromBucketURL, req);
    }

    // Submit any local storage data to the bucket now that we are deleting it.
    savePatientBackup(data: any): any {
        const patient = data.id;
        const site = data.site_ID;
        const req = {
            filename: `${site}/private/patient-submissions/${patient}/backupPatientData.json`,
            content: data,
        };

        // Send survey response to lambda
        return this.reqSvc.post(env.awsConfig.API.writeToBucketURL, req);
    }

    uploadFile(response) {
        const patient = response.id; //auth user who can be patient or proxy but not used for the path
        const survey = response.survey_ID;
        //const user = response.user_ID; //auth user but not used
        const site = response.site_ID;

        let fileNamePath = `${site}/private/patient-submissions/${patient}/${survey}/${patient}/${response.UploadFile.name}`;
        if (response.clinicianEntry) {
            fileNamePath = `${site}/private/clinician-submissions/${patient}/${survey}/${response.UploadFile.name}`;
        }

        const req = {
            filename: fileNamePath,
            content: response.UploadFile,
        };

        // Send survey response to lambda
        return this.reqSvc.post(env.awsConfig.API.writeToBucketURL, req);
    }

    //TODO parameters should be agnostic to filename structure
    getPatientBackup(patientId: string, site_ID: string): any {
        const req = {
            bucket: env.awsConfig.Storage.AWSS3.bucket,
            filename: `${site_ID}/private/patient-submissions/${patientId}/backupPatientData.json`,
        };

        return this.reqSvc.post(env.awsConfig.API.readFromBucketURL, req);
    }

    getLastSubmissionData(patientId: string, surveyId: string, site_ID: string): any {
        const patient = patientId;
        const survey = surveyId;
        const req = {
            bucket: env.awsConfig.Storage.AWSS3.bucket,
            lastModified: 'lastModified',
            prefix: `${site_ID}/private/patient-submissions/${patient}/${survey}/${patient}`,
        };
        return this.reqSvc.post(env.awsConfig.API.listBucketItemsURL, req);
    }

    getLangFromDeployedS3Bucket(filename: string): Observable<any> {
        const req = {
            deployBucketName: env.awsConfig.Storage.AWSS3.deployed_ui_bucket,
            filename: `assets/i18n/${filename}`,
        };

        return this.reqSvc.post(env.awsConfig.API.readFromBucketComposer, req);
    }

    listLangsFromDeployedS3Bucket(): Observable<any> {
        const req = {
            deployBucketName: env.awsConfig.Storage.AWSS3.deployed_ui_bucket,
            prefix: `assets/i18n`,
        };

        return this.reqSvc.post(env.awsConfig.API.listBucketItemsComposer, req);
    }

    listSESTemplateBackup_TemplateNames(): Observable<any> {
        const req = {
            prefix: `SESTemplates/`,
            bucket: env.awsConfig.Storage.AWSS3.bucket,
        };
        return this.reqSvc.post(env.awsConfig.API.listBucketItemsURL, req);
    }

    GetSESTemplateBackup(dateStamp: string, templateName: string): Observable<string> {
        const req = {
            bucket: env.awsConfig.Storage.AWSS3.bucket,
            filename: `SESTemplates/${templateName}/${dateStamp}/${templateName}.json`,
        };

        return this.reqSvc.post(env.awsConfig.API.readFromBucketURL, req);
    }

    loadVocabularyLinks(patientId: string, site_ID: string): any {
        const req = {
            bucket: env.awsConfig.Storage.AWSS3.bucket,
            filename: `${site_ID}/private/clinician-submissions/${patientId}/vocabularies.json`,
        };

        return this.reqSvc.post(env.awsConfig.API.readFromBucketURL, req);
    }

    saveVocabularyLinks(patientId: string, site_ID: string, vocabularyContent: any): any {
        const req = {
            filename: `${site_ID}/private/clinician-submissions/${patientId}/vocabularies.json`,
            content: vocabularyContent,
        };

        return this.reqSvc.post(env.awsConfig.API.writeToBucketURL, req);
    }
}
