import { Component, OnInit } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, ValidatorFn, ValidationErrors } from '@angular/forms';
import { IFormPhone } from '@h20-services/models/forms/types/IFormPhone';
import { AbstractPiControlComponent } from '../abstract-pi-control/abstract-pi-control.component';
import parsePhoneNumber, {
    CountryCallingCode,
    CountryCode,
    PhoneNumber,
    getCountries,
    getCountryCallingCode,
} from 'libphonenumber-js/min';

@Component({
    selector: 'app-pi-control-phone',
    templateUrl: './pi-control-phone.component.html',
    styleUrls: ['./pi-control-phone.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PiControlPhoneComponent,
        },
    ],
})
export class PiControlPhoneComponent
    extends AbstractPiControlComponent<IFormPhone, any>
    implements OnInit
{
    private countryCodeToNameMap = new Map([
        ['AC', 'Ascension Island'],
        ['AF', 'Afghanistan'],
        ['AX', 'Aland Islands'],
        ['AL', 'Albania'],
        ['DZ', 'Algeria'],
        ['AS', 'American Samoa'],
        ['AD', 'Andorra'],
        ['AO', 'Angola'],
        ['AI', 'Anguilla'],
        ['AQ', 'Antarctica'],
        ['AG', 'Antigua And Barbuda'],
        ['AR', 'Argentina'],
        ['AM', 'Armenia'],
        ['AW', 'Aruba'],
        ['AU', 'Australia'],
        ['AT', 'Austria'],
        ['AZ', 'Azerbaijan'],
        ['BS', 'Bahamas'],
        ['BH', 'Bahrain'],
        ['BD', 'Bangladesh'],
        ['BB', 'Barbados'],
        ['BY', 'Belarus'],
        ['BE', 'Belgium'],
        ['BZ', 'Belize'],
        ['BJ', 'Benin'],
        ['BM', 'Bermuda'],
        ['BT', 'Bhutan'],
        ['BO', 'Bolivia'],
        ['BQ', 'Bonaire, Sint Eustatius and Saba'],
        ['BA', 'Bosnia And Herzegovina'],
        ['BW', 'Botswana'],
        ['BV', 'Bouvet Island'],
        ['BR', 'Brazil'],
        ['IO', 'British Indian Ocean Territory'],
        ['BN', 'Brunei Darussalam'],
        ['BG', 'Bulgaria'],
        ['BF', 'Burkina Faso'],
        ['BI', 'Burundi'],
        ['KH', 'Cambodia'],
        ['CM', 'Cameroon'],
        ['CA', 'Canada'],
        ['CV', 'Cape Verde'],
        ['KY', 'Cayman Islands'],
        ['CF', 'Central African Republic'],
        ['TD', 'Chad'],
        ['CL', 'Chile'],
        ['CN', 'China'],
        ['CX', 'Christmas Island'],
        ['CC', 'Cocos (Keeling) Islands'],
        ['CO', 'Colombia'],
        ['KM', 'Comoros'],
        ['CG', 'Congo'],
        ['CD', 'Congo, Democratic Republic'],
        ['CK', 'Cook Islands'],
        ['CR', 'Costa Rica'],
        ['CI', "Cote D'Ivoire"],
        ['HR', 'Croatia'],
        ['CU', 'Cuba'],
        ['CW', 'Curaçao'],
        ['CY', 'Cyprus'],
        ['CZ', 'Czech Republic'],
        ['DK', 'Denmark'],
        ['DJ', 'Djibouti'],
        ['DM', 'Dominica'],
        ['DO', 'Dominican Republic'],
        ['EC', 'Ecuador'],
        ['EG', 'Egypt'],
        ['SV', 'El Salvador'],
        ['GQ', 'Equatorial Guinea'],
        ['ER', 'Eritrea'],
        ['EE', 'Estonia'],
        ['ET', 'Ethiopia'],
        ['FK', 'Falkland Islands (Malvinas)'],
        ['FO', 'Faroe Islands'],
        ['FJ', 'Fiji'],
        ['FI', 'Finland'],
        ['FR', 'France'],
        ['GF', 'French Guiana'],
        ['PF', 'French Polynesia'],
        ['TF', 'French Southern Territories'],
        ['GA', 'Gabon'],
        ['GM', 'Gambia'],
        ['GE', 'Georgia'],
        ['DE', 'Germany'],
        ['GH', 'Ghana'],
        ['GI', 'Gibraltar'],
        ['GR', 'Greece'],
        ['GL', 'Greenland'],
        ['GD', 'Grenada'],
        ['GP', 'Guadeloupe'],
        ['GU', 'Guam'],
        ['GT', 'Guatemala'],
        ['GG', 'Guernsey'],
        ['GN', 'Guinea'],
        ['GW', 'Guinea-Bissau'],
        ['GY', 'Guyana'],
        ['HT', 'Haiti'],
        ['HM', 'Heard Island & Mcdonald Islands'],
        ['VA', 'Holy See (Vatican City State)'],
        ['HN', 'Honduras'],
        ['HK', 'Hong Kong'],
        ['HU', 'Hungary'],
        ['IS', 'Iceland'],
        ['IN', 'India'],
        ['ID', 'Indonesia'],
        ['IR', 'Iran, Islamic Republic Of'],
        ['IQ', 'Iraq'],
        ['IE', 'Ireland'],
        ['IM', 'Isle Of Man'],
        ['IL', 'Israel'],
        ['IT', 'Italy'],
        ['JM', 'Jamaica'],
        ['JP', 'Japan'],
        ['JE', 'Jersey'],
        ['JO', 'Jordan'],
        ['KZ', 'Kazakhstan'],
        ['KE', 'Kenya'],
        ['KI', 'Kiribati'],
        ['KR', 'Korea'],
        ['KP', 'North Korea'],
        ['XK', 'Kosovo'],
        ['KW', 'Kuwait'],
        ['KG', 'Kyrgyzstan'],
        ['LA', "Lao People's Democratic Republic"],
        ['LV', 'Latvia'],
        ['LB', 'Lebanon'],
        ['LS', 'Lesotho'],
        ['LR', 'Liberia'],
        ['LY', 'Libyan Arab Jamahiriya'],
        ['LI', 'Liechtenstein'],
        ['LT', 'Lithuania'],
        ['LU', 'Luxembourg'],
        ['MO', 'Macao'],
        ['MK', 'Macedonia'],
        ['MG', 'Madagascar'],
        ['MW', 'Malawi'],
        ['MY', 'Malaysia'],
        ['MV', 'Maldives'],
        ['ML', 'Mali'],
        ['MT', 'Malta'],
        ['MH', 'Marshall Islands'],
        ['MQ', 'Martinique'],
        ['MR', 'Mauritania'],
        ['MU', 'Mauritius'],
        ['YT', 'Mayotte'],
        ['MX', 'Mexico'],
        ['FM', 'Micronesia, Federated States Of'],
        ['MD', 'Moldova'],
        ['MC', 'Monaco'],
        ['MN', 'Mongolia'],
        ['ME', 'Montenegro'],
        ['MS', 'Montserrat'],
        ['MA', 'Morocco'],
        ['MZ', 'Mozambique'],
        ['MM', 'Myanmar'],
        ['NA', 'Namibia'],
        ['NR', 'Nauru'],
        ['NP', 'Nepal'],
        ['NL', 'Netherlands'],
        ['AN', 'Netherlands Antilles'],
        ['NC', 'New Caledonia'],
        ['NZ', 'New Zealand'],
        ['NI', 'Nicaragua'],
        ['NE', 'Niger'],
        ['NG', 'Nigeria'],
        ['NU', 'Niue'],
        ['NF', 'Norfolk Island'],
        ['MP', 'Northern Mariana Islands'],
        ['NO', 'Norway'],
        ['OM', 'Oman'],
        ['PK', 'Pakistan'],
        ['PW', 'Palau'],
        ['PS', 'Palestinian Territory, Occupied'],
        ['PA', 'Panama'],
        ['PG', 'Papua New Guinea'],
        ['PY', 'Paraguay'],
        ['PE', 'Peru'],
        ['PH', 'Philippines'],
        ['PN', 'Pitcairn'],
        ['PL', 'Poland'],
        ['PT', 'Portugal'],
        ['PR', 'Puerto Rico'],
        ['QA', 'Qatar'],
        ['RE', 'Reunion'],
        ['RO', 'Romania'],
        ['RU', 'Russian Federation'],
        ['RW', 'Rwanda'],
        ['BL', 'Saint Barthelemy'],
        ['SH', 'Saint Helena'],
        ['KN', 'Saint Kitts And Nevis'],
        ['LC', 'Saint Lucia'],
        ['MF', 'Saint Martin'],
        ['PM', 'Saint Pierre And Miquelon'],
        ['VC', 'Saint Vincent And Grenadines'],
        ['WS', 'Samoa'],
        ['SM', 'San Marino'],
        ['ST', 'Sao Tome And Principe'],
        ['SA', 'Saudi Arabia'],
        ['SN', 'Senegal'],
        ['RS', 'Serbia'],
        ['SC', 'Seychelles'],
        ['SL', 'Sierra Leone'],
        ['SG', 'Singapore'],
        ['SX', 'Sint Maarten'],
        ['SK', 'Slovakia'],
        ['SI', 'Slovenia'],
        ['SB', 'Solomon Islands'],
        ['SO', 'Somalia'],
        ['ZA', 'South Africa'],
        ['GS', 'South Georgia And Sandwich Isl.'],
        ['SS', 'South Sudan'],
        ['ES', 'Spain'],
        ['LK', 'Sri Lanka'],
        ['SD', 'Sudan'],
        ['SR', 'Suriname'],
        ['SJ', 'Svalbard And Jan Mayen'],
        ['SZ', 'Swaziland'],
        ['SE', 'Sweden'],
        ['CH', 'Switzerland'],
        ['SY', 'Syrian Arab Republic'],
        ['TW', 'Taiwan'],
        ['TJ', 'Tajikistan'],
        ['TZ', 'Tanzania'],
        ['TH', 'Thailand'],
        ['TL', 'Timor-Leste'],
        ['TG', 'Togo'],
        ['TK', 'Tokelau'],
        ['TO', 'Tonga'],
        ['TT', 'Trinidad And Tobago'],
        ['TA', 'Tristan da Cunha'],
        ['TN', 'Tunisia'],
        ['TR', 'Turkey'],
        ['TM', 'Turkmenistan'],
        ['TC', 'Turks And Caicos Islands'],
        ['TV', 'Tuvalu'],
        ['UG', 'Uganda'],
        ['UA', 'Ukraine'],
        ['AE', 'United Arab Emirates'],
        ['GB', 'United Kingdom'],
        ['US', 'United States'],
        ['UM', 'United States Outlying Islands'],
        ['UY', 'Uruguay'],
        ['UZ', 'Uzbekistan'],
        ['VU', 'Vanuatu'],
        ['VE', 'Venezuela'],
        ['VN', 'Vietnam'],
        ['VG', 'Virgin Islands, British'],
        ['VI', 'Virgin Islands, U.S.'],
        ['WF', 'Wallis And Futuna'],
        ['EH', 'Western Sahara'],
        ['YE', 'Yemen'],
        ['ZM', 'Zambia'],
        ['ZW', 'Zimbabwe'],
    ]);
    protected countries: DialingCountry[] = getCountries().map((c) => ({
        code: c,
        friendlyName: this.countryCodeToNameMap.get(c),
        callingCode: getCountryCallingCode(c),
    }));

    selectedCountryCode: CountryCode = null; //ISO 3166-1 alpha-2 country code (e.g. US, CA)
    textFieldState: string = null;
    formattedDisplay: string = null;
    phoneNumber: PhoneNumber = undefined;

    ngOnInit(): void {
        super.ngOnInit();

        if (this.element.mode === 'set') {
            //show only specific countries
            const allowList = this.element.include_list.split(',');
            this.countries = this.countries.filter((c) => {
                return allowList.includes(c.code);
            });
        } else if (this.element.mode === 'exclude') {
            //exclude specific countries
            const rejectList = this.element.exclude_list.split(',');
            this.countries = this.countries.filter((c) => {
                return !rejectList.includes(c.code);
            });
        }
        //alpha-sort countries
        this.countries.sort((a, b) => a.friendlyName.localeCompare(b.friendlyName));
        //push priority countries (if any) to top of list, in order
        if (this.element.primary_countries && this.element.primary_countries.length > 0) {
            const priority_countries = this.element.primary_countries.split(',');
            let prioritized_countries = [];
            for (const cc of priority_countries) {
                const pop_country = this.countries.find((c) => c.code === cc);
                prioritized_countries.push(pop_country);
            }
            this.countries = [
                ...prioritized_countries,
                ...this.countries.filter((c) => {
                    return !prioritized_countries.includes(c.code);
                }),
            ];
        }

        this.formControl.addValidators(this.validators());
    }

    restoreCountry(code: CountryCode) {
        this.countries.push({
            code: code,
            friendlyName: this.countryCodeToNameMap.get(code),
            callingCode: getCountryCallingCode(code),
        });
    }

    textInput(event: Event) {
        this.markAsTouched();
        const target = event.target as HTMLInputElement;
        this.writeValue(target.value);
    }

    selectInput(event: Event) {
        //don't mark as touched here - validator shouldn't complain until actual value is entered, probably?
        const target = event.target as HTMLSelectElement;
        this.selectedCountryCode = target.value as CountryCode;
        this.writeValue();
    }

    /**
     * tries to generate a _value based on the current inputs
     * @param text new text from the user, if the input being updated is the text field
     */
    writeValue(text: string = null) {
        const oldNumber = this.phoneNumber;
        text = text ?? this.textFieldState;
        if (text) {
            this.phoneNumber = parsePhoneNumber(text, this.selectedCountryCode);
            //if phone number is invalid we want to store it without formatting it
            if (!(this.phoneNumber?.isPossible() ?? false)) {
                super.writeValue(text);
                this.notifyValueChange();
                return;
            }
        }
        if (oldNumber === undefined) {
            if (this.phoneNumber === undefined) {
                return; //no change
            }
        } else if (oldNumber.isEqual(this.phoneNumber)) {
            return; //no change
        }
        if (this.phoneNumber) {
            if (
                this.phoneNumber.country &&
                !this.countries.some((c) => c.code === this.phoneNumber.country)
            ) {
                this.restoreCountry(this.phoneNumber.country);
            }
            this.selectedCountryCode = this.phoneNumber.country ?? this.selectedCountryCode;
            super.writeValue(this.phoneNumber.format('E.164'));
            this.formattedDisplay = this.phoneNumber.format('INTERNATIONAL');
            this.textFieldState = this.phoneNumber.format('NATIONAL');
            this.notifyValueChange();
        }
    }

    validators() {
        /**
         * Tests whether a phone number parses and is theoretically a dialable number.
         * Remember, the only way to truly know whether a phone number is dialable is to dial it.
         */
        const phoneNumberParseValidator: ValidatorFn = (control: FormControl): ValidationErrors => {
            if (!control.value) {
                return null; //avoid duplicating isRequired
            }
            const number = parsePhoneNumber(control.value);
            if (!(number?.isPossible() ?? false)) {
                return { phoneValidationFailure: 'NOT_POSSIBLE' };
            }
            return null;
        };
        return [phoneNumberParseValidator];
    }
}
interface DialingCountry {
    code: CountryCode;
    friendlyName: string;
    callingCode: CountryCallingCode;
}
