import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '@h20-services/user.service';
import { BadgeTooltip } from '@h20-shared/button/button.component';
import _ from 'underscore';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { CognitoService } from '@h20-services/cognito.service';
import { ClaimService } from '@h20-services/claim.service';
import { SiteService } from '@h20-services/site.service';
import { AuthService } from '@h20-services/auth.service';
import { PulseAuth } from '@h20-services/models/PulseAuth';
import { composerUtils } from '@core/composerUtils';
import { CustomFilterComponent } from '@h20-shared/table-component/custom-filter/custom-filter.component';
import { ToastService } from '@h20-shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
    @ViewChild(DatatableComponent) table: DatatableComponent;
    filtersOn = 0;

    constructor(
        private userSvc: UserService,
        private router: Router,
        private fb: FormBuilder,
        private cogsvc: CognitoService,
        private claimService: ClaimService,
        private sitesvc: SiteService,
        private auth: AuthService,
        private toastService: ToastService,
        private translate: TranslateService
    ) {}

    users: any[] = [];
    temp: any[] = [];
    managed_roles: any[] = [];
    managed_sites: any[] = [];
    userEditing;

    totalCount = 0;
    limit = 10;
    offset = 0;
    page = 1;
    selected;
    loading = true;
    //surveyID: string = 'cd38a05f-78d9-46c3-bf1f-2467a490eade';

    filterForm: FormGroup = this.fb.group({
        status: this.fb.group({
            active: [false],
            inactive: [false],
        }),
        role: [''],
        email: [''],
        created: [''],
    });

    // Create the form controls
    newUserForm: FormGroup;
    viewNewUserPanel = false;
    deleteUserPanel = false;
    survey;
    createUserError = false;
    createUserErrorMessage = '';

    hasCreateUser = false;
    currentUser: any = {};
    user: any = {};
    isActivatedUser = false;

    resetPasswordForm: FormGroup;
    viewResetPasswordPanel = false;
    resetPasswordError = false;
    resetPasswordErrorMessage = '';

    options = {
        view: 'scroller', // initial view
        faller: true, // allow faller view
        scroller: true, // allow scroller view
        progress: true, // show progress bar
    };

    userRoles = {
        siteAdmin: false,
    };
    userClaims = null;
    colDefs = this.getTableColumns();

    ngOnInit(): void {
        this.auth.getPulseAuth().then((currAuthUser: PulseAuth) => {
            this.currentUser.id = currAuthUser.getUserID();
            this.userSvc.getUser(this.currentUser.id).subscribe((userRes: any) => {
                this.user = { ...userRes[0] };
                //this.user.site_ID = 'geisinger'; // For testing only
                this.claimService
                    .getManagedRoles(currAuthUser)
                    .subscribe((managedUsersRes: any) => {
                        this.managed_roles = managedUsersRes;
                        this.getUsers();
                    });
            });
        });

        this.userRoles.siteAdmin = this.cogsvc.isSiteAdmin();

        this.userClaims = this.claimService.getUserClaims();

        this.newUserForm = this.fb.group(
            {
                nickname: ['', [Validators.required]],
                username: ['', [Validators.required]],
                password: ['', [Validators.required]],
                confirmPassword: [''],
                role: [''],
                selector: [''],
                siteid: [''],
            },
            { validator: this.checkPasswords }
        );

        this.filterForm.valueChanges.subscribe((data: any) => {
            this.filterUsers(data);
        });
        this.colDefs = this.getTableColumns();
    }

    getTableColumns() {
        return [
            {
                headerName: 'Status',
                field: 'status',
                cellDataType: 'icon',
                filter: CustomFilterComponent,
                filterParams: {
                    iconClass: 'fa-solid fa-square',
                    data: this.getStatus.bind(this),
                    options: ['active', 'inactive'],
                },
                cellRendererParams: (params) => {
                    return this.getStatus(params.value);
                },
            },
            {
                headerName: 'Role',
                field: 'type',
                filter: 'agTextColumnFilter',
            },
            {
                headerName: 'Name',
                field: 'preferred_name',
                filter: 'agTextColumnFilter',
            },
            {
                headerName: 'Email/Username',
                field: 'email',
                filter: 'agTextColumnFilter',
            },
            {
                headerName: 'Created Date',
                field: 'created',
                filter: 'agDateColumnFilter',
                filterParams: {
                    filterOptions: ['equals'],
                    comparator: (filterDate, cellValue) =>
                        composerUtils.dateFilter(filterDate, cellValue),
                },
                filterValueGetter: (params) => {
                    return new Date(params.data.created);
                },
            },
            {
                headerName: 'Actions',
                field: 'id',
                cellDataType: 'openAndActionBtns',
                cellRendererParams: (params) => {
                    return {
                        viewIcon: false,
                        actions: [
                            {
                                label: 'Edit',
                                isVisible:
                                    this.viewActions(params.data) && this.userClaims?.usersUpdate,
                                onClick: () => this.editUser(params.data),
                            },
                            {
                                label: 'Reset Password',
                                isVisible:
                                    this.viewActions(params.data) && this.userClaims?.usersUpdate,
                                onClick: () => this.resetPassword(params.data),
                            },
                            {
                                label: 'Deactivate',
                                isVisible:
                                    this.viewActions(params.data) && this.userClaims?.usersUpdate,
                                onClick: () => this.deactivateUser(params.data),
                            },
                            {
                                label: 'Activate',
                                isVisible:
                                    !this.viewActions(params.data) && this.userClaims?.usersUpdate,
                                onClick: () => this.activateUser(params.data),
                            },
                            {
                                label: 'Delete',
                                isVisible:
                                    !this.viewActions(params.data) && this.userClaims?.usersUpdate,
                                onClick: () => this.deleteUser(params.data),
                            },
                        ],
                    };
                },
            },
        ];
    }

    getStatus(value) {
        if (value === 'active') {
            return {
                iconClass: 'fa-solid fa-square active',
                title: 'User is active',
                ['aria-label']: 'user is active',
            };
        } else if (value === 'inactive') {
            return {
                iconClass: 'fa-solid fa-square inactive',
                title: 'User is inactive',
                ['aria-label']: 'user is inactive',
            };
        }
    }

    // Password match validation
    checkPasswords = (group: FormGroup): any => {
        const inputs = group.value;
        const confirm = group.get('confirmPassword');
        const pass = group.get('password');
        let flag = null;
        const lower = /[a-z]/g.test(inputs.password);
        const upper = /[A-Z]/g.test(inputs.password);
        const special = /[@~`!#$%\^&*+=\-\[\]\\';,./{}|\\":<>\?]/g.test(inputs.password);
        const num = /[0-9]/g.test(inputs.password);
        const len = inputs.password.length > 8 ? true : false;

        // Don't ask.
        if (!lower || !upper || !special || !num || !len) {
            flag = { noMatch: true };
            pass.setErrors(flag);
        } else {
            pass.setErrors(flag);
        }
        if (inputs.password !== inputs.confirmPassword) {
            flag = { notSame: true };
            confirm.setErrors(flag);
        } else {
            confirm.setErrors(flag);
        }

        return flag;
    };

    onPage(event): any {
        this.offset = event.pageSize * event.offset;
    }

    getUsers() {
        this.loading = true;
        this.userSvc.getUsersByType('composer').subscribe((usersResp: any) => {
            this.totalCount = usersResp.length;
            this.temp = usersResp;

            this.userSvc.getUsersByType('composer-admin').subscribe((usersResp: any) => {
                this.temp = [...this.temp, ...usersResp];
                this.filterUsers(this.filterForm.value);
                this.loading = false;
            });
        });
    }

    select(event): void {
        this.selected = event.selected;
    }

    createUser(event): void {
        this.newUserForm = this.fb.group(
            {
                nickname: ['', [Validators.required]],
                username: ['', [Validators.required]],
                password: ['', [Validators.required]],
                confirmPassword: [''],
                role: [''],
                selector: [''],
                siteid: [''],
            },
            { validator: this.checkPasswords }
        );

        this.hasCreateUser = true;
        this.viewNewUserPanel = true;
        this.createUserError = false;
    }

    saveNewUser(context): void {
        if (!context.newUserForm.value.role) context.newUserForm.value.role = context.user.type;
        if (!context.newUserForm.value.siteid || context.newUserForm.value.role !== 'site-admin')
            context.newUserForm.value.siteid = context.user.site_ID;

        if (!context.newUserForm.value.mfaRequired) {
            context.newUserForm.value.mfaRequired = false;
        }

        context.userSvc
            .createUserWithPassword(context.newUserForm.value, context.currentUser.id)
            .toPromise()
            .then((resp: any) => {
                context.viewNewUserPanel = false;
                context.createUserError = false;
                context.createUserErrorMessage = '';
                context.getUsers();
            })
            .catch((err) => {
                context.createUserError = true;
                context.createUserErrorMessage = err.error.message;
            });
    }

    cancelNewUser(): void {
        this.viewNewUserPanel = false;
        this.createUserError = false;
        this.createUserErrorMessage = '';
    }

    doomscrollerResponse(event): void {}

    config(event): void {}

    bulkDeactivate(event): void {}

    bulkDelete(event): void {}

    bulkUndo(event): void {}

    activate(row): void {}

    view(row): void {
        this.router.navigate(['/', 'users', row.id]);
    }

    viewActions(row): boolean {
        this.isActivatedUser = row.status === 'active';
        return this.isActivatedUser;
    }

    activateUser(row): void {
        this.userSvc
            .enableUserAdmin(row.id, row.email, this.currentUser.id)
            .toPromise()
            .then((resp: any) => {
                this.getUsers();
            })
            .catch((err) => {
                // console.log(err.error.message);
            });
    }

    deactivateUser(row): void {
        this.userSvc
            .disableUserAdmin(row.id, row.email, this.currentUser.id)
            .toPromise()
            .then((resp: any) => {
                this.getUsers();
            })
            .catch((err) => {
                // console.log(err.error.message);
            });
    }

    editUser(row): void {
        this.newUserForm = this.fb.group({
            nickname: [row.preferred_name, [Validators.required]],
            username: [row.email, [Validators.required]],
            password: [''],
            confirmPassword: [''],
            role: [row.type],
            selector: [''],
            siteid: [row.site_ID],
        });

        this.userSvc
            .getUser(row.id)
            .toPromise()
            .then((userRes: any) => {
                this.userEditing = userRes[0];
            })
            .catch((err: any) => {
                //  console.log(err);
            });

        this.hasCreateUser = false;
        this.viewNewUserPanel = true;
        this.createUserError = false;
    }

    saveEditUser(context): void {
        this.loading = true;
        // Can't change site if the role isnt site-admin
        if (context.newUserForm.value.role !== 'site-admin')
            context.newUserForm.value.siteid = context.userEditing.site_ID;

        // Change Cognito group
        if (context.userEditing.type !== context.newUserForm.value.role) {
            context.userSvc
                .removeUserFromGroup(context.newUserForm.value.username, context.userEditing.type)
                .toPromise()
                .then((resp: any) => {
                    context.createUserError = false;
                    this.loading = false;
                })
                .catch((err) => {
                    context.createUserError = true;
                    context.createUserErrorMessage = err.error.message;
                    this.loading = false;
                });

            context.userSvc
                .addUserToGroup(context.newUserForm.value.username, context.newUserForm.value.role)
                .toPromise()
                .then((resp: any) => {
                    context.createUserError = false;
                    this.loading = false;
                })
                .catch((err) => {
                    context.createUserError = true;
                    context.createUserErrorMessage = err.error.message;
                    this.loading = false;
                });
        }

        //Change cognito nickname, RDS Contact preferred_name, RDS user type/site_ID
        context.userSvc
            .updateUserAdmin(
                context.userEditing.id,
                context.newUserForm.value,
                context.currentUser.id
            )
            .toPromise()
            .then((resp: any) => {
                context.viewNewUserPanel = false;
                context.createUserError = false;
                context.createUserErrorMessage = '';
                context.getUsers();
                this.loading = false;
            })
            .catch((err) => {
                context.createUserError = true;
                context.createUserErrorMessage = err.error.message;
                this.loading = false;
            });
    }

    cancelResetPassword(): void {
        this.viewResetPasswordPanel = false;
        this.resetPasswordError = false;
        this.resetPasswordErrorMessage = '';
    }

    resetPassword(row): void {
        this.resetPasswordForm = this.fb.group(
            {
                username: [row.email, [Validators.required]],
                password: ['', [Validators.required]],
                confirmPassword: [''],
            },
            { validator: this.checkPasswords }
        );

        this.viewResetPasswordPanel = true;
        this.resetPasswordError = false;
    }

    saveResetPassword(context): void {
        this.loading = true;
        context.userSvc
            .setUserPassword(context.resetPasswordForm.value)
            .toPromise()
            .then((resp: any) => {
                context.viewResetPasswordPanel = false;
                context.resetPasswordError = false;
                context.resetPasswordErrorMessage = '';
                context.getUsers();
                this.loading = false;
            })
            .catch((err) => {
                context.resetPasswordError = true;
                context.resetPasswordErrorMessage = err.error.message;
                this.loading = false;
            });
    }

    deleteUser(row): void {
        this.deleteUserPanel = true;
        this.userEditing = row;
        /*
        if (
            confirm(
                `This action is not reversible. Are you sure you want to delete the user ${row.name}?`
            )
        ) {
            this.userSvc
                .deleteUserAdmin(row.id, row.email)
                .toPromise()
                .then((resp: any) => {
                    this.getUsers();
                })
                .catch((err) => {
                    console.log(err.error.message);
                });
        }
        */
    }

    undo(event): void {}

    delete(event): void {}

    filterUsers(filterData): void {
        // count the number of filters on
        this.filtersOn = this.countActiveFilters(filterData);

        // all status on if none are selected or all are selected
        const statusAll =
            (!filterData.status.active && !filterData.status.inactive) ||
            (filterData.status.active && filterData.status.inactive);

        // filter the users
        this.users = this.temp.filter((user: any) => {
            const statusFlag = statusAll || filterData.status.active === (user.status === 'active');

            const roleFlag = composerUtils.filterStartsWith(user.type, filterData.role);
            const emailFlag = composerUtils.filterStartsWith(user.email, filterData.email);
            const createdFlag = composerUtils.filterDateStartsWith(
                user.created,
                filterData.created
            );
            return statusFlag && emailFlag && createdFlag && roleFlag;
        });

        this.totalCount = this.users.length;
    }

    private countActiveFilters(data): number {
        let count = 0;

        // count status selected
        ['active', 'inactive'].forEach((status: any) => {
            if (data.status[status]) {
                count++;
            }
        });

        // add counts for text fields.
        ['role', 'email', 'created'].forEach((field: any) => {
            if (data[field] && data[field] !== '') {
                count++;
            }
        });
        return count;
    }

    cancelDeleteDialog(): void {
        this.deleteUserPanel = false;
    }

    deleteUserDialog(context): void {
        context.userSvc
            .deleteUserAdmin(
                context.userEditing.id,
                context.userEditing.email,
                context.currentUser.id
            )
            .toPromise()
            .then((resp: any) => {
                context.getUsers();
            })
            .catch((err) => {
                // console.log(err.error.message);
            });
        context.deleteUserPanel = false;
    }

    getSurvey(): any {
        var survey = {
            title: 'New User',
            pages: [
                {
                    name: 'page1',
                    elements: [
                        {
                            type: 'panel',
                            name: 'panel1',
                            elements: [
                                {
                                    type: 'text',
                                    name: 'username',
                                    title: 'username',
                                    isRequired: true,
                                },
                                {
                                    type: 'text',
                                    name: 'nickname',
                                    title: 'nickname',
                                    isRequired: true,
                                },
                                {
                                    type: 'text',
                                    name: 'password',
                                    title: 'password',
                                    isRequired: true,
                                },
                            ],
                        },
                    ],
                },
            ],
            survey_type: 'form',
        };
        return survey;
    }

    showToast() {
        this.toastService.show({
            body: this.translate.instant('Toast.FilterOptions'),
        });
    }
}

$(window).on('scroll', function () {
    var scrollHeight = $(document).height();
    var scrollPosition = $(window).height() + $(window).scrollTop();
    if ((scrollHeight - scrollPosition) / scrollHeight === 0) {
        // when scroll to bottom of the page
        $('.datatable-row-wrapper:last-child .datatable-body-cell')
            .find('.dropdown-menu')
            .css({ 'margin-top': '-140px' });
    }
});
