<app-modal
    [footerText]="'Consent.ParticipationVoluntary'"
    [saveBtnText]="'Add Claim'"
    *ngIf="viewNewClaimPanel"
    [(visible)]="viewNewClaimPanel"
    [header]="'Add Permission'"
    [context]="this"
    [saveFunc]="addNewClaim"
    [closeFunc]="cancelNewClaim"
    [modalWidth]="'40vw'"
    [modalHeight]="'60vh'"
    [saveBtnDisabled]="!nuForm.valid"
>
    <div class="container-fluid" [formGroup]="newClaimForm" #nuForm="ngForm">
        <div class="form-group row mt-2">
            <div class="form-group row mt-2">
                <div class="col">
                    <label>Registry: {{ selectedRegistryId }} </label>
                </div>
            </div>

            <div class="form-group row mt-2">
                <div class="col">
                    <label> Site#Role: {{ selectedSiteRole }} </label>
                </div>
            </div>

            <div class="form-group row mt-2">
                <div class="col">
                    <label>
                        Select Claim
                        <select
                            class="form-control"
                            aria-label="Claim"
                            id="claimName"
                            formControlName="claimName"
                        >
                            <option value="">--Please select--</option>
                            <option *ngFor="let claim of newClaims" [value]="claim.name">
                                {{ claim.id + ': ' + claim.name + ' (' + claim.description + ')' }}
                            </option>
                        </select>
                    </label>
                </div>
            </div>
        </div>
    </div>
</app-modal>

<div class="container-fluid panel-control">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <h1>Permissions</h1>
                    <form>
                        <div class="form-group">
                            <app-loading-component
                                [loading]="registriesLoading"
                                [text]="'loading registries'"
                            >
                                <app-loading-component
                                    *ngIf="selectedRegistry"
                                    [loading]="siteLoading"
                                    [text]="'loading sites & roles'"
                                >
                                    <label class="mx-3">
                                        Site#Role
                                        <select
                                            class="w-100 form-control"
                                            id="sideId"
                                            [ngModel]="selectedSiteRole"
                                            (change)="siteChange($event.target.value)"
                                            name="sideId"
                                        >
                                            <option value="" selected disabled>
                                                --Please select--
                                            </option>
                                            <option
                                                *ngFor="let site of lstSiteRole"
                                                [value]="site.name"
                                            >
                                                {{ site.name }}
                                            </option>
                                        </select>
                                    </label>
                                    <app-button
                                        *ngIf="selectedSiteRole"
                                        (action)="showNewClaimPanel()"
                                        [buttonText]="'Registry.AddPermission'"
                                    />
                                </app-loading-component>
                            </app-loading-component>
                        </div>
                    </form>

                    <div class="ag-theme-quartz" style="width: 100%; margin-top: 15px">
                        <app-table-component
                            [rowData]="roleClaims"
                            [columnDefs]="colDefs"
                            pagination="true"
                            [filterModelKey]="'permissions'"
                            [showFilterRow]="false"
                        ></app-table-component>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
