

<div class="container-fluid panel-control">
    <!-- Table -->
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <h1>Deploy Tracking</h1>
                    <form>
                        <div class="form-group">
                            <app-loading-component [loading]="loading" [text]="'loading'">
                            </app-loading-component>
                        </div>
                    </form>
                    <app-table-component
                        rowSelection="multiple"
                        [rowData]="deployTrackings"
                        [columnDefs]="colDefs"
                        [pagination]="true"
                        (selectionChanged)="onSelect($event)"
                        [filterModelKey]="'deploy_tracking'"
                    >
                    </app-table-component>
                </div>
            </div>
        </div>
    </div>
</div>
