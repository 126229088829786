<div class="password-container d-flex flex-column align-items-start">
    <div class="field-section">
        <h5 class="password-title required-label">{{"Login.CreatePassword" | translate}}</h5>
        <div class="password-field d-flex">
            <input
                [attr.type]="showCharacters ? 'text' : 'password'"
                id="password-input"
                class="password-input form-control"
                [ngClass]="{
                    'is-valid': passwordValid,
                    'is-invalid': !passwordValid && passwordValid !== undefined  && !enteredPreviously,
                }"
                [attr.placeholder]="'Login.CreatePassword'| translate"
                (input)="validate()"
                (keyup.enter)="emitParams(passwordInput)"
                (blur)="onPasswordBlur()"
                [(ngModel)]="passwordInput"
                required
            />
            <button
                class="password-toggle d-flex"
                [attr.aria-label]="'Login.ShowPassword' | translate"
                (click)="togglePasswordVisibility()"
                id="password-toggle"
            >
                <i
                    class="fa-regular"
                    [ngClass]="{
              'fa-eye-slash': showCharacters,
              'fa-eye': !showCharacters,
          }"
                    id="eye-icon"
                >
                </i>
                <span class="btn-text">{{ text | translate }}</span>
            </button>
        </div>
        <h6
            class="please-create-text"
            [ngStyle]="{ 
              opacity: passwordValid ? 0 : 1, 
              color: createPasswordText === 'Login.PleaseCreatePassword' ? '#344054' : '#b42318'}"
        >
            {{ createPasswordText | translate}}
        </h6>
    </div>
    <div class="field-section">
        <h5 class="password-title required-label">{{ "Login.ConfirmPassword" | translate}}</h5>
        <div class="password-field d-flex">
            <input
                [attr.type]="showConfirmCharacters ? 'confirmText' : 'password'"
                id="confirm-password-input"
                class="password-input form-control"
                [ngClass]="{
                    'is-valid': confirmPasswordValid,
                    'is-invalid': !confirmPasswordValid && confirmPasswordValid !== undefined && !enteredPreviously
                }"
                [attr.placeholder]="'Login.ConfirmPassword'| translate"
                (input)="validateConfirm()"
                (blur)="updateState()"
                [(ngModel)]="confirmPasswordInput"
                required
            />
            <button
                class="password-toggle d-flex"
                [attr.aria-label]="'Login.ShowPassword' | translate"
                (click)="toggleConfirmPasswordVisibility()"
                id="confirm-password-toggle"
            >
                <i
                    class="fa-regular"
                    [ngClass]="{
                        'fa-eye-slash': showConfirmCharacters,
                        'fa-eye': !showConfirmCharacters,
                    }"
                    id="eye-icon"
                >
                </i>
                <span class="btn-text">{{ confirmText | translate }}</span>
            </button>
        </div>
        <h6
            *ngIf="confirmPasswordInput.length > 0"
            class="password-must-match-text"
            [ngStyle]="{ 
              opacity: !confirmPasswordValid && confirmPasswordValid!== undefined ? 1 : 0, 
              color:  '#b42318'
            }"
        >
            {{ "Login.PasswordsMustMatch" | translate }}
        </h6>
    </div>
    <div class="field-section">
        <h6 class="validation-title mt-3">{{"Login.PasswordMustContain" | translate}}</h6>
        <div class="password-validation-container row">
            <div class="col-5 check">
                <div class="validation-text">
                    <i
                        class="fa-duotone fa-solid fa-circle-check"
                        [style.--fa-secondary-color]="hasRequiredLength ? '#067647' : '#98a2b3'"
                        [style.--fa-secondary-opacity]="2"
                        [style.--fa-primary-color]="'#fcfcfc'"
                    ></i>
                    <span [attr.aria-label]="charLen | translate"
                        >{{charLen | translate}}</span
                    >
                </div>
                <div class="validation-text">
                    <i
                        class="fa-duotone fa-solid fa-circle-check"
                        [style.--fa-secondary-color]="containsUpperCase ? '#067647' : '#98a2b3'"
                        [style.--fa-secondary-opacity]="2"
                        [style.--fa-primary-color]="'#fcfcfc'"
                    ></i>
                    <span [attr.aria-label]="'Login.NeedsUppercaseLetter' | translate"
                        >{{"Login.NeedsUppercaseLetter"|translate}}</span
                    >
                </div>
                <div class="validation-text">
                    <i
                        class="fa-duotone fa-solid fa-circle-check"
                        [style.--fa-secondary-color]="containsSpecialChar ? '#067647' : '#98a2b3'"
                        [style.--fa-secondary-opacity]="2"
                        [style.--fa-primary-color]="'#fcfcfc'"
                    ></i>
                    <span [attr.aria-label]="'Login.NeedsSpecialChar' | translate"
                        >{{"Login.NeedsSpecialChar"|translate}}</span
                    >
                </div>
            </div>
            <div class="col-5 check">
                <div class="validation-text">
                    <i
                        class="fa-duotone fa-solid fa-circle-check"
                        [style.--fa-secondary-color]="containsNumber ? '#067647' : '#98a2b3'"
                        [style.--fa-secondary-opacity]="2"
                        [style.--fa-primary-color]="'#fcfcfc'"
                    ></i>
                    <span [attr.aria-label]="'Login.NeedsNumber' | translate"
                        >{{"Login.NeedsNumber" |translate}}</span
                    >
                </div>
                <div class="validation-text">
                    <i
                        class="fa-duotone fa-solid fa-circle-check"
                        [style.--fa-secondary-color]="containsLowerCase ? '#067647' : '#98a2b3'"
                        [style.--fa-secondary-opacity]="2"
                        [style.--fa-primary-color]="'#fcfcfc'"
                    ></i>
                    <span [attr.aria-label]="'Login.NeedsLowercaseLetter' | translate"
                        >{{"Login.NeedsLowercaseLetter" | translate}}</span
                    >
                </div>
            </div>
        </div>
    </div>
</div>
