import { Component, Input, OnInit, Query } from '@angular/core';
import { MessageCenterService } from '@h20-services/message-center.service';
import { AuthService } from '@h20-services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ParticipantService } from '@h20-services/participant.service';
import { TaskStatus } from '@h20-services/models/status';
import { Router } from '@angular/router';
import { ITask } from '@h20-services/models/tasks/ITask';
import { PulseAuth } from '@h20-services/models/PulseAuth';
//NOTE TODO this violates the shared modules principle of not being tied to our services etc.
@Component({
    selector: 'app-message-center',
    templateUrl: './message-center.component.html',
    styleUrls: ['./message-center.component.scss'],
})
export class MessageCenterComponent implements OnInit {
    get tasks(): any {
        return this.JSON;
    }
    @Input() set tasks(tasks: any) {
        this.JSON = tasks.filter((task: ITask) => task.status === 'new' || task.status === 'opened');
        if (this.JSON.length > 0) {
            this.showStatus = true;
            this.activeSlideStatus = this.JSON[0].status;
        } else {
            this.showStatus = false;
        }
    }

    showStatus = true;
    @Input() loading;
    JSON = [];
    text;
    currentParticipantId: string;
    activeSlide = 0;
    activeSlideStatus = '';
    constructor(
        private router: Router,
        private auth: AuthService,
        private svc: MessageCenterService,
        public translate: TranslateService,
        private prtSvc: ParticipantService
    ) {}

    ngOnInit(): void {
        this.auth.getPulseAuth().then((pulseAuth: PulseAuth) => {
            if (pulseAuth) {
                // FIXME getCurrentParticipant
                this.currentParticipantId =
                    this.prtSvc.getCurrentParticipant() || pulseAuth.getUserID();
            }
        });
    }

    processClicks(event, task): void {
        // Remove tasks that do not have routes.  Tasks with routes go to the destination which decides whether to dismissed.
        if (!task.task_configuration) {
            task.status = TaskStatus.Completed;
            this.svc.updateTask(task.id, task.status).subscribe((res: any) => {});
        }
    }

    clickProgress(i): any {
        this.activeSlide = i;
        this.activeSlideStatus = this.JSON[this.activeSlide].status;
    }

    getText(strOrLangs): string {
        try {
            this.text = JSON.parse(strOrLangs);
        } catch {
            this.text = strOrLangs;
        }

        if (this.text === undefined) {
            return strOrLangs;
        }
        if (this.text[this.translate.currentLang] === undefined) {
            return strOrLangs;
        }

        if (!strOrLangs) return '';
        return this.text[this.translate.currentLang] || strOrLangs;
    }

    openTask(task): void {
        let survey_ID = task.task_configuration;
        let task_ID = task.id;
        this.router.navigate(['surveys', survey_ID, 'task', task_ID]);
    }
}
