<div class="container-fluid">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12 col-md-6 d-flex flex-column align-items-center mb-5">
                                <h3 *ngIf="!isNewTemplate" class="text-center mt-3 mb-3">
                                    Update Template
                                </h3>
                                <h3 *ngIf="isNewTemplate" class="text-center mt-3 mb-3">
                                    Create Template
                                </h3>
                                <div *ngIf="loading">Loading...</div>
                                <form *ngIf="!loading" id="updateForm">
                                    <a id="dupe_text" *ngIf="duplicate"
                                        ><strong
                                            >WARNING: This template already exists, you will be
                                            overriding the existing template once you save</strong
                                        ></a
                                    >
                                    <div class="info d-flex flex-row g-2 justify-content-between">
                                        <div>
                                            <label for="registry">Registry</label>
                                            <select
                                                id="registry"
                                                name="registry"
                                                [(ngModel)]="selectedRegistry"
                                                (change)="registryChange($event.target.value)"
                                                [disabled]="disabled"
                                            >
                                                <option
                                                    *ngFor="let registry of lstRegistries"
                                                    [value]="registry"
                                                >
                                                    {{ registry }}
                                                </option>
                                            </select>
                                        </div>

                                        <div class="px-2">
                                            <label for="organization">Organization</label>
                                            <select
                                                id="organization"
                                                name="organization"
                                                [(ngModel)]="selectedOrganization"
                                                (change)="onOrgChange($event.target.value)"
                                                [disabled]="disabled"
                                            >
                                                <option
                                                    *ngFor="let org of lstOrganizations"
                                                    [value]="org"
                                                >
                                                    {{ org }}
                                                </option>
                                            </select>
                                        </div>
                                        <div>
                                            <label for="site">Site</label>
                                            <select
                                                id="site"
                                                name="site"
                                                [(ngModel)]="selectedSite"
                                                (change)="onSiteChange($event.target.value)"
                                                [disabled]="disabled"
                                            >
                                                <option
                                                    *ngFor="let site of lstSites"
                                                    [value]="site"
                                                >
                                                    {{ site }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="info d-flex justify-content-between">
                                        <div>
                                            <label for="temp_types">Template Types</label>
                                            <select
                                                id="temp_types"
                                                name="temp_types"
                                                [(ngModel)]="selected_template_type"
                                                (change)="onTemplateTypeChange($event.target.value)"
                                                [disabled]="disabled"
                                            >
                                                <option
                                                    *ngFor="let temp of lst_template_types"
                                                    [value]="temp"
                                                >
                                                    {{ temp }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="p-1"></div>
                                        <div>
                                            <label for="languages">Languages</label>
                                            <select
                                                id="languages"
                                                name="languages"
                                                [(ngModel)]="selected_language"
                                                (change)="onLanguageChange($event.target.value)"
                                                [disabled]="disabled"
                                            >
                                                <option
                                                    *ngFor="let lang of lst_languages"
                                                    [value]="lang"
                                                >
                                                    {{ lang }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-fields">
                                        <label for="subject">Subject</label>
                                        <div id="editor">
                                            <textarea
                                                type="text"
                                                id="subject"
                                                name="subject"
                                                class="form-control"
                                                [(ngModel)]="template.subject"
                                                (input)="adjustTextareaHeight($event)"
                                                (change)="onSubjectChange($event.target.value)"
                                            ></textarea>
                                        </div>
                                        <div
                                            class="d-flex w-100 justify-content-end form-check form-switch"
                                        >
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                id="htmlModeToggle"
                                                (change)="onToggleChange($event)"
                                            />
                                            <label class="form-check-label" for="htmlModeToggle">
                                                {{ htmlMode ? 'HTML' : 'RTE' }}
                                            </label>
                                        </div>
                                        <div *ngIf="!htmlMode" class="mt-3">
                                            <label for="bodyTop">Body (Top)</label>
                                            <quill-editor
                                                [(ngModel)]="bodyHtmlTop"
                                                [styles]="{ height: '200px' }"
                                                [ngModelOptions]="{ standalone: true }"
                                                (onContentChanged)="onBodyTopChange($event)"
                                                id="editor-container-top"
                                            ></quill-editor>
                                        </div>
                                        <div *ngIf="htmlMode" class="mt-3">
                                            <label for="bodyTop">Body (Top)</label>
                                            <textarea
                                                id="html_code_top"
                                                name="body_html_top"
                                                class="form-control scrollable-textarea"
                                                [(ngModel)]="bodyHtmlTop"
                                                rows="10"
                                                (input)="onBodyTopChange($event)"
                                            ></textarea>
                                        </div>

                                        <div class="form-check mb-3" >
                                            <input class="form-check-input" type="checkbox" id="hasCTA" [(ngModel)]="hasCTA" [checked]="hasCTA" (change)="toggleCTA()">
                                            <label class="form-check-label" for="hasCTA">
                                              CTA Button
                                            </label>
                                          </div>
                                        <div class="d-flex justify-content-between">
                                            <div class="form-group"  [disabled]="!hasCTA">
                                                <label for="buttonText">Button Text</label>
                                                <input
                                                    type="text"
                                                    id="buttonText"
                                                    name="buttonText"
                                                    [(ngModel)]="buttonText"
                                                    class="form-control h-46px"
                                                    (ngModelChange)="onCtaChange()"  [disabled]="!hasCTA"
                                                />
                                            </div>

                                            <div class="form-group"  [disabled]="!hasCTA">
                                                <label for="buttonLinkType">Link Type</label>
                                                <select
                                                    id="buttonLinkType"
                                                    name="buttonLinkType"
                                                    [(ngModel)]="buttonLinkType"
                                                    class="form-control h-46px"
                                                    (ngModelChange)="onCtaChange()"  [disabled]="!hasCTA"
                                                >
                                                    <option value="activate">Activate</option>
                                                    <option value="login">Login</option>
                                                </select>
                                            </div>

                                            <div class="form-group">
                                                <label for="buttonColor">Button Color</label>
                                                <input
                                                    type="color"
                                                    id="buttonColor"
                                                    name="buttonColor"
                                                    [(ngModel)]="buttonColor"  [disabled]="!hasCTA"
                                                    class="form-control h-46px"
                                                    (change)="onCtaChange()"
                                                />
                                            </div>
                                            <div class="form-group">
                                                <label for="buttonTextColor">Text Color</label>
                                                <input
                                                    type="color"
                                                    id="buttonTextColor"
                                                    name="buttonTextColor"
                                                    [(ngModel)]="buttonTextColor"  [disabled]="!hasCTA"
                                                    class="form-control h-46px"
                                                    (change)="onCtaChange()"
                                                />
                                            </div>
                                        </div>

                                        <div *ngIf="!htmlMode" class="mt-5">
                                            <label for="bodyBottom">Body (Bottom)</label>
                                            <quill-editor
                                                [(ngModel)]="bodyHtmlBottom"
                                                [styles]="{ height: '200px' }"
                                                [ngModelOptions]="{ standalone: true }"
                                                (onContentChanged)="onBodyBottomChange($event)"
                                                id="editor-container-bottom"  [disabled]="!hasCTA"
                                            ></quill-editor>
                                        </div>
                                        <div *ngIf="htmlMode" class="mt-5">
                                            <label for="bodyBottom">Body (Bottom)</label>
                                            <textarea
                                                id="html_code_bottom"
                                                name="body_html_bottom"
                                                class="form-control scrollable-textarea"
                                                [(ngModel)]="bodyHtmlBottom"
                                                rows="10"
                                                (input)="onBodyBottomChange($event)"  [disabled]="!hasCTA"
                                            ></textarea>
                                        </div>

                                        <div class="form-actions">
                                            <button
                                                *ngIf="!isNewTemplate"
                                                class="btn btn-primary"
                                                (click)="submit()"
                                            >
                                                Update Template
                                            </button>
                                            <button
                                                *ngIf="isNewTemplate"
                                                class="btn btn-primary"
                                                (click)="submit()"
                                            >
                                                Create Template
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="col-12 col-md-6 inner-html py-5">
                                <div [innerHTML]="safePreviewHtml"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
