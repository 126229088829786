import { IScheduleEmailPolicy } from "./IScheduleEmailPolicy";
import { IScheduleRepeatPolicy } from "./IScheduleRepeatPolicy";
import { IScheduleTask } from "./IScheduleTask";
import { IScheduleTaskPolicy } from "./IScheduleTaskPolicy";

export class ScheduleEvents extends Array<ScheduleEvent> {}
export class ScheduleEvent {
    id: string;
    name: string;
    evaluationRules: EvaluationRules;
    repeatPolicy: {
        dateBoundIterations: boolean,
        policies?: IScheduleRepeatPolicy[]
    };
    emailPolicy?: IScheduleEmailPolicy;
    taskPolicy?: IScheduleTaskPolicy;
    tasks?: {
        [task_type: string]: IScheduleTask
    };
    taskLabel?: string;
    dynamicActions: any[];

    constructor(){
        this.id = '';
        this.name = '';
        this.evaluationRules = [];
    }
}

class EvaluationRules extends Array<EvaluationRule> {}
export class EvaluationRule {
    testField: string;
    testValue: string;
    ifTest: string;

    constructor(){
        this.testField = '';
        this.testValue = '';
        this.ifTest = '';
    }
}