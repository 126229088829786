<div *ngIf="mode === 'view'; else formModeTemplate">
    <div *ngIf="!disabled" class="pi-control-edit-mode">
        <app-pi-form-view-response
            [element]="element"
            [theFormControl]="formControl"
            [displayValue]="stringValue"
        ></app-pi-form-view-response>
    </div>
</div>
<ng-template #formModeTemplate
    ><div *ngIf="!disabled">
        <div class="row pi-control-form-mode">
            <div class="col">
                <label>{{ getText(element.title) }}: </label>
                <app-required [element]="element"></app-required>
                <div style="width: 20em" id="div-svg-content">
                    <object
                        #nodeperson
                        (load)="svgLoaded()"
                        id="nodeperson"
                        type="image/svgxml"
                        data="assets/image/lymph_node_sites_V9.svg"
                    ></object>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <h5 class="mb-0 px-0 col">Featured</h5>
                            <button
                                type="button"
                                class="btn-close float-end"
                                aria-label="Close"
                                (click)="closeModal()"
                            ></button>
                        </div>
                        <div class="row">
                            <h6 class="px-0">Region: {{ region }}</h6>
                        </div>
                    </div>
                    <div class="card-body">
                        <form [formGroup]="modalForm">
                            <div class="row">
                                <div class="input-group mb-2 rounded modalInput">
                                    <span class="input-group-text" id="basic-addon1">Size:</span>
                                    <input
                                        formControlName="size"
                                        type="text"
                                        class="form-control text-end"
                                        placeholder="Username"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="input-group mb-2 rounded modalInput">
                                    <span class="input-group-text" id="basic-addon1">FDG AV:</span>
                                    <input
                                        formControlName="fdgAv"
                                        type="text"
                                        class="form-control text-end"
                                        placeholder="Username"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-check form-switch">
                                        <input
                                            formControlName="found"
                                            class="form-check-input"
                                            type="checkbox"
                                            id="flexSwitchCheckDefault"
                                        />
                                        <label class="form-check-label" for="flexSwitchCheckDefault"
                                            >Found:</label
                                        >
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-check form-switch">
                                        <input
                                            formControlName="assessed"
                                            class="form-check-input"
                                            type="checkbox"
                                            id="flexSwitchCheckDefault"
                                        />
                                        <label class="form-check-label" for="flexSwitchCheckDefault"
                                            >Assessed:</label
                                        >
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <app-validator [control]="control"></app-validator>
    </div>
</ng-template>
