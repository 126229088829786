export enum DeployType {
    release = 'release',
    config_release = 'config_release',    
    hotfix = 'hotfix',
}

export const DeployTypeList = [
    DeployType.release,    
    DeployType.config_release,
    DeployType.hotfix,
];
