import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionsComponent } from './permissions/permissions.component';
import { ClaimsComponent } from './claims/claims.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PermissionManagementRoutingModule } from './permission-management-routing.module';
import { SharedModule } from '@h20-shared/shared.module';
@NgModule({
    declarations: [PermissionsComponent, ClaimsComponent],
    imports: [
        CommonModule,
        NgxDatatableModule,
        PermissionManagementRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
    ],
})
export class PermissionManagementModule {}
