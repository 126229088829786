import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { Auth } from '@aws-amplify/auth';
import { Hub } from 'aws-amplify';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class CognitoService {
    decoded: any;
    userRoles = {
        patient: false,
        proxy: false,
        admin: false,
        investigator: false,
        'nkf-admin': false,
        'site-admin': false,
    };

    constructor() {
        //get the current one upfront (helpful in local)
        this.getCurrentSession();
        // Hook up to signin and signout
        Hub.listen('auth', (data) => {
            if (data.payload.event === AuthService.SIGN_IN_EVENT) {
                this.getCurrentSession();
            } else if (data.payload.event === AuthService.SIGN_OUT_EVENT) {
                this.clearSession();
            }
        });
    }

    getCurrentSession() {
        // console.log('getAuth')
        Auth.currentSession().then((authSession) => {
            const token = authSession.getIdToken().getJwtToken();

            let cognitoGroups = [] as Array<string>;

            this.decoded = this.getDecodedAccessToken(token);
            if (this.decoded['cognito:groups']) {
                cognitoGroups = this.decoded['cognito:groups'];
            }

            // Workaround for the patient profile; if I've got a token and no decoded but do have this, I'm a patient.
            // Want to play as an admin? Just add 'admin' to the array.
            if (
                !this.decoded['cognito:groups'] &&
                token &&
                this.decoded.profile &&
                this.decoded.profile === 'patient'
            ) {
                cognitoGroups = ['patient'];
            }

            cognitoGroups.forEach((r: string) => {
                this.userRoles[r] = true;
            });
        });
    }

    clearSession() {
        this.decoded = null;
        this.userRoles.patient = false;
        this.userRoles.proxy = false;
        this.userRoles.admin = false;
        this.userRoles.investigator = false;
        this.userRoles['nkf-admin'] = false;
        this.userRoles['site-admin'] = false;
    }

    isPatient(): boolean {
        return this.userRoles.patient;
    }

    isProxy(): boolean {
        return this.userRoles.proxy;
    }

    isAdmin(): boolean {
        return this.userRoles.admin;
    }

    isInvestigator(): boolean {
        return this.userRoles.investigator;
    }

    isNKFAdmin(): boolean {
        return this.userRoles['nkf-admin'];
    }

    isSiteAdmin(): boolean {
        return this.userRoles['site-admin'];
    }

    nickname(): string {
        return this.decoded?.nickname ? this.decoded.nickname : '';
    }

    getDecodedAccessToken(token: string): any {
        try {
            return jwt_decode(token);
        } catch (err) {
            console.error(err);
            return null;
        }
    }
}
