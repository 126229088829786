import { mapTo, Observable, of } from 'rxjs';

function generateCacheKey(args: any[]): string {
    const keyParts: string[] = [];
    for (const arg of args) {
        if (typeof arg === 'object') {
            let identifier;
            try {
                identifier = arg.id || JSON.stringify(arg);
            } catch (e) {}

            if (identifier) {
                keyParts.push(identifier);
            }
        } else {
            keyParts.push(String(arg));
        }
    }
    return keyParts.join(':');
}

enum CachedValueType {
    observable,
    promise,
    value,
}

interface CacheData {
    type: CachedValueType;
    data: any;
}

export function Cacheable() {
    const cache: Map<string, CacheData> = new Map();

    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
        const originalMethod = descriptor.value;

        descriptor.value = function (...args: any[]) {
            const cacheKey = generateCacheKey(args);

            // Check if data is already in cache
            if (cache.has(cacheKey)) {
                const data = cache.get(cacheKey);

                switch (data.type) {
                    case CachedValueType.observable:
                        return of(data.data);
                    case CachedValueType.promise:
                        return new Promise((res) => res(data.data));
                    case CachedValueType.value:
                        return data.data;
                }

                return data.data;
            }

            const result = originalMethod.apply(this, args);

            if (result instanceof Observable) {
                // If the result is an Observable, add caching behavior
                const cachedObservable = result.pipe(
                    mapTo((resolvedResult: any) => {
                        // Store result in cache
                        cache.set(cacheKey, {
                            type: CachedValueType.observable,
                            data: resolvedResult,
                        });

                        return resolvedResult;
                    })
                );

                return cachedObservable;
            } else if (result instanceof Promise) {
                const cachedPromise = result.then((resolvedResult: any) => {
                    cache.set(cacheKey, {
                        type: CachedValueType.promise,
                        data: resolvedResult,
                    });

                    return resolvedResult;
                });

                return cachedPromise;
            } else {
                cache.set(cacheKey, {
                    data: result,
                    type: CachedValueType.value,
                });

                return result;
            }
        };

        return descriptor;
    };
}
