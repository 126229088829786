import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DeployService } from '@h20-services/deploy.service';
import { CommonService } from '@h20-services/common.service';
import { AuthService } from '@h20-services/auth.service';
import { DeployStatus, DeployStatusList } from '@h20-services/enums/deploy/DeployStatus';
import { DeployTypeList } from '@h20-services/enums/deploy/DeployType';
import { IDeployTracking } from '@h20-services/models/deploy/IDeployTracking';
import { IConfigTable } from '@h20-services/models/deploy/IConfigTable';
import { PulseAuth } from '@h20-services/models/PulseAuth';
import { composerUtils } from '@core/composerUtils';
import { CustomFilterComponent } from '@h20-shared/table-component/custom-filter/custom-filter.component';
import { ToastService } from '@h20-shared/services/toast.service';
import { ConfigService } from '@h20-services/config.service';
import { EnvironmentService } from '@h20-services/environment.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-deploy-tracking',
    templateUrl: './deploy-tracking.component.html',
    styleUrls: ['./deploy-tracking.component.scss'],
})
export class DeployTrackingComponent implements OnInit {
    constructor(
        private fb: FormBuilder,
        private deploySvc: DeployService,
        private auth: AuthService,
        private commonSvc: CommonService,
        private toastService: ToastService,
        private config_svc: ConfigService,
        private environmentSvc: EnvironmentService,
        private translate: TranslateService
    ) {}

    sourceEnvs = this.commonSvc.listSourceEnvs();
    targetEnvs = this.commonSvc.listTargetEnvs();
    authUser: PulseAuth; // login user

    message: string = '';
    scheduleMsg: string = '';
    showResultMsg: boolean = false;

    //registry selector
    allRegistries: any[];
    listRegistries: string[];

    deployTrackings: any[];
    tempDeployTrackings: any[];
    totalCount = 0;
    deployTrackingData: any;
    loading: boolean = false;

    resultMsgList: string[] = []; // deploy result message

    deployStatusList: string[] = DeployStatusList;
    deployTypeList: string[] = DeployTypeList;
    selectedItems = [];
    filtersOn = 0;
    filterForm: FormGroup = this.fb.group({
        deploy_status: this.fb.group({
            [DeployStatus.pending]: [false],
            [DeployStatus.ready]: [false],
            [DeployStatus.complete]: [false],
            [DeployStatus.cancel]: [false],
        }),
        destination_env: [''],
        release_version: [''],
        created: [''],
    });

    colDefs = [
        /*  This is no longer used but is a good example for future reference of 
            how to use the custom filter
        {
            headerName: 'status',
            field: 'deploy_status',
            filter: CustomFilterComponent,
            filterParams: {
                iconClass: 'fa-solid fa-circle',
                data: this.getDeployStatus.bind(this),
                options: this.deployStatusList,
            },
        }, */
        {
            headerName: 'Registry',
            field: 'registry_id',
            filter: 'agTextColumnFilter',
        },
        {
            headerName: 'Site',
            field: 'site_id',
            filter: 'agTextColumnFilter',
        },
        {
            headerName: 'pkey',
            field: 'primary_key',
        },
        {
            headerName: 'Version',
            field: 'sort_key',
        },
        {
            headerName: 'Table',
            field: 'table_key',
            filter: 'agTextColumnFilter',
        },
        {
            headerName: 'deployed',
            field: 'deployed',
            filter: 'agDateColumnFilter',
            filterParams: {
                filterOptions: ['inRange'],
                comparator: (filterDate, cellValue) => 
                    composerUtils.agDateRange(filterDate, cellValue),
                
            },
            
        },
        {
            headerName: 'source',
            field: 'source_env',
        },
        {
            headerName: 'dest',
            field: 'destination_env',
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['startsWith'],
            },
        },
    ];
    //----------------------------

    ngOnInit(): void {
        this.loadDeployTrackings().finally(() => {
            this.environmentSvc.currentRegistry.subscribe((environment) => {
                this.loadRegistrationContent(environment);
            });
        });
        this.auth.getPulseAuth().then((currAuthUser: PulseAuth) => {
            this.authUser = currAuthUser;
        });
        this.filterForm.valueChanges.subscribe((filterData: any) => {
            this.filterDeployTrackings(filterData);
        });

        this.config_svc
            .listRegistryConfig()
            .toPromise()
            .then((regRes) => {
                this.listRegistries = regRes.map((reg) => reg.registry_id);
                
                this.loading = false;
            });
    }

    onSelect(event) {
        this.selectedItems = event.api.getSelectedRows();
    }

    loadRows() {
        return this.deployTrackings.map((entry) => {
            const result = { ...entry };
            if (entry.primary_key_name && entry.primary_key) {
                const keyParts = entry.primary_key_name.split('#');
                const valueParts = String(entry.primary_key).split('#');
                if (valueParts.length === keyParts.length) {
                    keyParts.forEach((part, index) => {
                        result[part] = valueParts[index];
                    });
                }
            }
            return result;
        });
    }

    loadDeployTrackings() {
        return new Promise((resolve, reject) => {
            this.loading = true;
            this.deploySvc.listDeployTracking().subscribe(
                (res) => {
                    this.tempDeployTrackings = res;
                    this.totalCount = res.length;
                    this.filterDeployTrackings(this.filterForm.value);
                    this.deployTrackings = this.loadRows();
                    this.loading = false;
                    this.allRegistries = this.deployTrackings;
                    resolve(res);
                },
                (err: any) => {
                    this.setErrorHandler(err);
                    this.loading = false;
                    reject(err);
                }
            );
        });
    }

    filterDeployTrackings(filterData): void {
        this.filtersOn = this.countActiveFilters(filterData);
        const statusAll =
            !filterData.deploy_status[DeployStatus.pending] &&
            !filterData.deploy_status[DeployStatus.ready] &&
            !filterData.deploy_status[DeployStatus.complete] &&
            !filterData.deploy_status[DeployStatus.cancel];

        this.deployTrackings = this.tempDeployTrackings.filter((dep: any) => {
            const statusFlag = statusAll || filterData.deploy_status[dep.deploy_status];

            const createdFlag = composerUtils.filterDateStartsWith(dep.created, filterData.created);
            const destinationEnvFlag = composerUtils.filterStartsWith(
                dep.destination_env,
                filterData.destination_env
            );
            const releaseVersionFlag = composerUtils.filterEquals(
                dep.release_version,
                filterData.release_version
            );
            return statusFlag && createdFlag && destinationEnvFlag && releaseVersionFlag;
        });

        this.deployTrackings = this.deployTrackings.sort((a, b) => {
            return a.created - b.created;
        });
        this.totalCount = this.deployTrackings.length;
    }

    private static filterColumnNames = ['created', 'destination_env', 'release_version'];

    countActiveFilters(filterData): number {
        let count = 0;
        DeployStatusList.forEach((status: any) => {
            if (filterData.deploy_status[status]) {
                count++;
            }
        });
        DeployTrackingComponent.filterColumnNames.forEach((field: any) => {
            if (filterData[field] && filterData[field] !== '') {
                count++;
            }
        });
        return count;
    }
    // ------------------------------------

    setErrorHandler(err: any, msg?: string) {
        console.error(err);
        if (msg) this.message = msg;
    }

    showToast() {
        this.toastService.show({
            body: this.translate.instant('Toast.FilterOptions'),
        });
    }

    loadRegistrationContent(registry) {
        this.deployTrackings = this.allRegistries?.filter((value) => {
            return value.registry_id === registry;
        });
    }
}
