export class composerUtils {
    public static filterEquals(objstr: string, filterStr: string): boolean {
        if (!filterStr) return true;
        if (!objstr) return false;
        return objstr.toLowerCase() == filterStr.toLowerCase();
    }

    public static filterStartsWith(objstr: string, filterStr: string): boolean {
        if (!filterStr) return true;
        if (!objstr) return false;
        return objstr.toLowerCase().startsWith(filterStr.toLowerCase());
    }

    public static filterIncludes(objstr: string, filterStr: string): boolean {
        if (!filterStr) return true;
        if (!objstr) return false;
        return this.getText(objstr).toLowerCase().includes(filterStr.toLowerCase());
    }

    public static filterDateStartsWith(objDate: any, filterDate: any): boolean {
        if (!filterDate) return true;
        if (!objDate) return false;
        return objDate.toString().startsWith(filterDate.toString());
    }

    public static getText(strOrLangs): string {
        if (!strOrLangs) return '';
        return strOrLangs['default'] || strOrLangs['en'] || strOrLangs;
    }

    public static dateFilter(filterDate, cellValue) {
        const cellDate = new Date(cellValue);
        const filterDateOnly = new Date(
            filterDate.getFullYear(),
            filterDate.getMonth(),
            filterDate.getDate()
        );
        const cellDateOnly = new Date(
            cellDate.getFullYear(),
            cellDate.getMonth(),
            cellDate.getDate()
        );

        if (cellDateOnly < filterDateOnly) {
            return -1;
        } else if (cellDateOnly > filterDateOnly) {
            return 1;
        } else {
            return 0;
        }
    }

    public static dateRange(filterDate1, filterDate2, cellValue) {
        const date1 = new Date(filterDate1);
        const date2 = new Date(filterDate2);
        const cellDate = new Date(cellValue);
        date1.setHours(0, 0, 0, 0);
        date2.setHours(0, 0, 0, 0);
        cellDate.setHours(0, 0, 0, 0);

        if (cellDate >= date1 && cellDate <= date2) {
            return 0;
        } else if (cellDate < date1) {
            return -1;
        } else {
            return 1;
        }
    }

    // user this function for column def date range
    public static agDateRange(filterDate, cellValue) {
        const dateTimeParts = cellValue.split(' ');
        const dateParts = dateTimeParts[0].split('-');
        const cellDate = new Date(
            Number(dateParts[0]),
            Number(dateParts[1]) - 1,
            Number(dateParts[2])
        );
        if (cellDate < filterDate) {
            return -1;
        } else if (cellDate > filterDate) {
            return 1;
        } else {
            return 0;
        }
    }
}
