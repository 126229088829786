<app-pi-form-progress-bar
    *ngIf="progressType === 'progress-bar'"
    [panels]="panels"
    [theFormGroup]="theFormGroup"
    [activeElement]="activeElement"
    class="w-100"
></app-pi-form-progress-bar>
<div *ngFor="let panel of panels" class="mb-5">
    <h5 *ngIf="panel.title" class="text-secondary px-2">{{ getText(panel.title) }}</h5>
    <div *ngIf="panel.layout && theFormGroup" class="container-fluid">
        <form [formGroup]="theFormGroup">
            <div *ngFor="let pageRow of panel.layout.pageRows" class="row gx-3">
                <!-- IF - Checking for columns-->
                <ng-container *ngIf="pageRow.left || pageRow.right; else noColumns">
                    <div *ngIf="pageRow.left" class="col-12 col-md-6 p-0">
                        <div *ngFor="let colRow of pageRow.left" class="row gx-3">
                            <div *ngFor="let element of colRow" [ngClass]="getColClass(element)">
                                <app-pi-control-switch
                                    [element]="element"
                                    [theFormControl]="theFormGroup.controls[element.name]"
                                    [formControlList]="theFormGroup.controls"
                                    [elementList]="elementList"
                                    [hideDisabled]="false"
                                    [mode]="mode"
                                    [attr.id]="element.name"
                                    [formData]="formData"
                                    [entryType]="entryType"
                                    [parentList]="parentList"
                                    (downloadButtonClicked)="downloadEventHandlerFunction($event)"
                                    (stageFileUploadChanges)="
                                        stageFileUploadChangesHandlerFunction($event)
                                    "
                                    (stageFileDeleteChanges)="
                                        stageFileDeleteChangesHandlerFunction($event)
                                    "
                                    [patientId]="patientId"
                                    [surveyId]="surveyId"
                                >
                                </app-pi-control-switch>
                            </div>
                        </div>
                    </div>
                    <div
                        *ngIf="pageRow.right"
                        class="col-12 col-md-6 p-0"
                        [ngClass]="pageRow.left ? '' : 'offset-md-6'"
                    >
                        <div *ngFor="let colRow of pageRow.right" class="row gx-3">
                            <div *ngFor="let element of colRow" [ngClass]="getColClass(element)">
                                <app-pi-control-switch
                                    [element]="element"
                                    [theFormControl]="theFormGroup.controls[element.name]"
                                    [formControlList]="theFormGroup.controls"
                                    [elementList]="elementList"
                                    [hideDisabled]="false"
                                    [mode]="mode"
                                    [attr.id]="element.name"
                                    [formData]="formData"
                                    [entryType]="entryType"
                                    [parentList]="parentList"
                                    (downloadButtonClicked)="downloadEventHandlerFunction($event)"
                                    (stageFileUploadChanges)="
                                        stageFileUploadChangesHandlerFunction($event)
                                    "
                                    (stageFileDeleteChanges)="
                                        stageFileDeleteChangesHandlerFunction($event)
                                    "
                                    [patientId]="patientId"
                                    [surveyId]="surveyId"
                                >
                                </app-pi-control-switch>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <!-- ELSE -->
                <div #noColumns *ngFor="let element of pageRow" [ngClass]="getColClass(element)">
                    <app-pi-control-switch
                        [element]="element"
                        [theFormControl]="theFormGroup.controls[element.name]"
                        [formControlList]="theFormGroup.controls"
                        [elementList]="elementList"
                        [hideDisabled]="false"
                        [mode]="mode"
                        [attr.id]="element.name"
                        [formData]="formData"
                        [entryType]="entryType"
                        [parentList]="parentList"
                        (downloadButtonClicked)="downloadEventHandlerFunction($event)"
                        (stageFileUploadChanges)="stageFileUploadChangesHandlerFunction($event)"
                        (stageFileDeleteChanges)="stageFileDeleteChangesHandlerFunction($event)"
                        [patientId]="patientId"
                        [surveyId]="surveyId"
                    >
                    </app-pi-control-switch>
                </div>
            </div>
        </form>
    </div>
</div>
