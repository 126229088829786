import {
    Component,
    Input,
    ViewChild,
    ElementRef,
    Renderer2,
    AfterViewInit,
    OnDestroy,
    Output,
    EventEmitter,
    ChangeDetectorRef,
} from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'app-tray',
    templateUrl: './tray.component.html',
    styleUrls: ['./tray.component.scss'],
})
export class TrayComponent implements AfterViewInit, OnDestroy {
    @ViewChild('pageTray') tray: ElementRef;

    @Input() visible = false;
    @Input() trayTitle = '';
    @Input() trayTitleTooltip;
    @Output() innerToggle = new EventEmitter();
    navbar;
    toolbar;
    div;
    eventSubscription: Subscription;

    toggleTooltip = 'Close tray';

    @Input() filterForm: UntypedFormGroup;

    tooltipOptions = {
        placement: 'right',
        'show-delay': 300,
        'hide-delay': 150,
    };

    constructor(
        private elmRef: ElementRef,
        private renderer: Renderer2,
        private cdr: ChangeDetectorRef
    ) {}

    ngAfterViewInit(): void {
        this.div = this.elmRef.nativeElement.querySelector('.appPageTray');
        // this.toolbar = document.getElementById('page-toolbar');
        this.navbar = document.getElementById('pif-nav');
        let nav;
        // let bar;

        if (this.navbar) {
            nav = this.navbar.getBoundingClientRect();
            // bar = this.toolbar.getBoundingClientRect();
            // this.renderer.setStyle(this.div, 'z-index', '99');
            // this.renderer.setStyle(this.div, 'background-color', 'red');
            // this.renderer.setStyle(this.div, 'top', `${nav.bottom}px`);
            // this.renderer.setStyle(
            //     this.div,
            //     'height',
            //     `calc(100vh - ${nav.bottom}px)`
            // );
            // this.renderer.setProperty(this.div, 'innerHTML', `${this.visible} no ng10`);
        }

        this.eventSubscription = fromEvent(window, 'scroll').subscribe((e) => {
            this.onWindowScroll(e);
        });

        this.cdr.detectChanges();
    }

    toggle(): any {
        this.visible = !this.visible;
        this.innerToggle.emit(this.visible);
    }

    onWindowScroll($ev): any {
        // const status = document.getElementsByClassName('toolbar-fixed');
        // const bar = this.toolbar.getBoundingClientRect();
        // const nav = this.navbar.getBoundingClientRect();
        // if (status.length > 0) {
        //   this.renderer.setStyle(this.div, 'z-index', '40');
        //   this.renderer.setStyle(this.div, 'top', `${bar.bottom}px`);
        //   this.renderer.setStyle(this.div, 'height', `calc(100vh - ${bar.bottom}px)`);
        // } else {
        //   this.renderer.setStyle(this.div, 'z-index', '99');
        //   this.renderer.setStyle(this.div, 'top', `${nav.bottom}px`);
        //   this.renderer.setStyle(this.div, 'height', `calc(100vh - ${nav.bottom}px)`);
        // }
    }

    ngOnDestroy(): void {
        this.toggle();
        this.eventSubscription.unsubscribe();
    }
}
