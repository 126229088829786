import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface ToastButton {
    text: string;
    action: () => void;
    isBold?: boolean;
}

export interface ToastInfo {
    icon?: string | null;
    heading?: string;
    body: string;
    buttons?: ToastButton[];
    delay?: number;
    type?: 'success' | 'error' | 'warning' | 'info' | 'primary' | 'default';
    showDismiss?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    private toastsSubject = new BehaviorSubject<ToastInfo[]>([]);
    toasts$ = this.toastsSubject.asObservable();

    private iconMap = {
        success: 'fa-regular fa-circle-check',
        error: 'fa-regular fa-circle-exclamation',
        warning: 'fa-regular fa-triangle-exclamation',
        primary: 'fa-regular fa-circle-exclamation',
    };

    show(toastInfo: Partial<ToastInfo> & { body: string }) {
        const {
            type = 'default',
            heading = '',
            body,
            showDismiss = false,
            buttons = [],
            delay = 5000,
        } = toastInfo;

        let icon = null;
        if (type !== 'default' && this.iconMap[type]) {
            icon = this.iconMap[type];
        }
        const defaultButtons: ToastButton[] = showDismiss
            ? [
                  {
                      text: 'Dismiss',
                      action: () => {
                          this.remove(toast);
                      },
                  },
              ]
            : [];
        const toast: ToastInfo = {
            icon,
            heading,
            body,
            buttons: [...defaultButtons, ...buttons],
            delay,
            type,
        };
        const currentToasts = this.toastsSubject.getValue();
        this.toastsSubject.next([...currentToasts, toast]);
        setTimeout(() => this.remove(toast), delay);
    }

    remove(toast: ToastInfo) {
        const currentToasts = this.toastsSubject.getValue();
        this.toastsSubject.next(currentToasts.filter((t) => t !== toast));
    }
}
