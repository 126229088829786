import { Component, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IFormChoice } from '@h20-services/models/forms/IFormChoice';
import { IFormElement } from '@h20-services/models/forms/IFormElement';
import { IFormRating } from '@h20-services/models/forms/types/IFormRating';
import { AbstractPiControlComponent } from '../abstract-pi-control/abstract-pi-control.component';

@Component({
    selector: 'app-pi-control-rating',
    templateUrl: './pi-control-rating.component.html',
    styleUrls: ['./pi-control-rating.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PiControlRatingComponent,
        },
    ],
})
export class PiControlRatingComponent
    extends AbstractPiControlComponent<IFormRating, any>
    implements OnInit
{
    minRate: number;
    maxRate: number;
    stepRate: number;
    minRateDescription: string;
    maxRateDescription: string;
    rateValue: number;

    controlValues: number[];

    ngOnInit(): void {
        super.ngOnInit();

        this.minRate = this.element.rateMin == 0 ? 0 : this.element.rateMin || 1;
        this.maxRate = this.element.rateMax || 5;
        this.stepRate = this.element.rateStep || 1;
        this.minRateDescription = this.element.minRateDescription || 'lowest';
        this.maxRateDescription = this.element.maxRateDescription || 'highest';

        this.controlValues = [];
        for (let i = this.minRate; i <= this.maxRate; i += this.stepRate) {
            this.controlValues.push(i);
        }

        if (this.formControl.value) {
            this.rateValue = parseInt(this.formControl.value);
        }
    }
    selectionMade(choice: any) {
        this.markAsTouched();
        this.writeValue(choice);
        this.rateValue = choice;
        this.notifyValueChange();
    }
}
