import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-diff',
    templateUrl: './diff.component.html',
    styleUrls: ['./diff.component.scss'],
})
export class DiffComponent {
    @Input() before = '';
    @Input() after = '';
    constructor() {}
}
