import { Component, Injector, Input, OnInit } from '@angular/core';
import { AbstractControl, NG_VALUE_ACCESSOR, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CommonService } from '@h20-services/common.service';
import { IFormElement } from '@h20-services/models/forms/IFormElement';
import { TranslateService } from '@ngx-translate/core';
import { AbstractPiControlComponent } from '../abstract-pi-control/abstract-pi-control.component';
import { SurveyService } from '@h20-services/survey.service';
import { IFormChoice } from '@h20-services/models/forms/IFormChoice';
import { RequestService } from '@h20-services/request.service';
import { UuidService } from '@h20-services/uuid.service';
import { AuthService } from '@h20-services/auth.service';
import { PulseAuth } from '@h20-services/models/PulseAuth';
import { IFormDropdown } from '@h20-services/models/forms/types/IFormDropdown';

@Component({
    selector: 'app-pi-control-dropdown',
    templateUrl: './pi-control-dropdown.component.html',
    styleUrls: ['./pi-control-dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PiControlDropdownComponent,
        },
    ],
})
export class PiControlDropdownComponent
    extends AbstractPiControlComponent<IFormDropdown, any>
    implements OnInit
{
    @Input() patientId: any;
    @Input() surveyId: any;
    loading = false;
    urlChoices: any[];
    //we need to use a stand for the form value so that we can deselect
    currentChoice: any;

    constructor(
        public injector: Injector,
        protected com_svc: CommonService,
        protected uuid_svc: UuidService,
        private req_svc: RequestService,
        protected translate: TranslateService,
        private survey_svc: SurveyService,
        private authService: AuthService
    ) {
        super(injector, com_svc, uuid_svc, translate);
    }

    async ngOnInit() {
        super.ngOnInit();
        if (this.element.hasOwnProperty('noChoicesError')) {
            this.formControl.addValidators(this.noChoicesValidator);
            this.formControl.markAsTouched();
        }

        this.currentChoice = this._value;

        if (this.element.choicesByUrl && this.element.choicesByUrl.valueName) {
            if (this.element.choicesByUrl.path === 'sql') {
                //valueName is the survey id of AE,
                //tableName is table name in RDS
                this.survey_svc
                    .listFormOptions(
                        this.patientId,
                        this.element.choicesByUrl.valueName,
                        this.element.tableName
                    )
                    .subscribe((response) => {
                        if (response) {
                            this.element.choices = [...response];
                            const choice = this.element.choices.find((c) => c.value == this._value);
                            this.stringValue = this.getText(choice?.text);
                        }
                    });
            } else {
                const pulseAuth: PulseAuth = await this.authService.getPulseAuth();
                const params = `?site_ID=${pulseAuth.getSiteID()}`;
                this.survey_svc
                    .getChoicesFromTable(this.element.choicesByUrl.valueName, params)
                    .subscribe((doctors) => {
                        this.element.choices = [
                            ...doctors.filter(
                                (c) =>
                                    (c.status && c.status == 'active') ||
                                    !c.status ||
                                    c.value == this._value
                            ),
                            ...this.element.choices,
                        ];
                        const choice = this.element.choices.find((c) => c.value == this._value);
                        this.stringValue = this.getText(choice?.text);
                    });
            }
        }
    }

    async getChoicesByURL(): Promise<void> {
        //TODO This is never called
        this.req_svc.post(this.element.choicesByUrl.url, {}).subscribe((data: IFormChoice[]) => {
            this.element.choices = data;

            if (this.element.hasOtherOption) {
                this.element.choices.push({
                    value: -1,
                    text: {
                        [this.translate.currentLang]:
                            this.translate.instant('DeactivateReasons.Other'),
                    },
                });
            }
            this.loading = false;
        });
    }
    writeValue(value: any): void {
        //override to sync the dropdown UI on load
        //don't set current choice unless it has a value - for null currentChoice should be '' so it shows 'Select Response'
        //we have this here so when the initial value loads, it will show that in the UI component
        if (value !== null) this.currentChoice = value;
        super.writeValue(value);
    }

    selectionMade(event: any) {
        //need to write the value to show in the current selection including ''
        this.currentChoice = event.target.value;
        this.markAsTouched();
        // if the value is 'Select Response' - set the form value to null
        if (event.target.value === '') {
            this.writeValue(null);
        } else this.writeValue(event.target.value);
        this.notifyValueChange();
    }

    async setStringValue() {
        if (this._value === null || !this.element.choices) return;

        const choice = this.element.choices.find((c) => c.value == this._value);
        this.stringValue = this.getText(choice?.text);
    }

    noChoicesValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            //always returns
            return { noChoices: { value: this.translate.instant('Surveys.PleaseCloseForm') } };
        };
    }
}
