<div *ngIf="mode === 'view'; else formModeTemplate">
    <div *ngIf="!disabled" class="pi-control-edit-mode">
        <app-pi-form-view-response
            [element]="element"
            [theFormControl]="formControl"
            [displayValue]="stringValue"
        ></app-pi-form-view-response>
    </div>
</div>
<ng-template #formModeTemplate
    ><div *ngIf="!disabled" class="row pi-control-form-mode">
        <label
            *ngIf="element.titleLocation !== 'hidden'"
            class="form-label"
            [attr.for]="elementUID"
            [ngClass]="
                !element.orientation || element.orientation === 'horizontal'
                    ? 'col-12 col-md-6 col-lg-8'
                    : 'col-12'
            "
        >
            <app-element-title [element]="element"></app-element-title>
        </label>
        <div
            [ngClass]="
                !element.orientation ||
                element.orientation === 'horizontal' ||
                element.titleLocation !== 'hidden'
                    ? 'col-12 col-md-6 col-lg-4'
                    : 'col-12'
            "
        >
            <app-loading-component [loading]="loading">
                <select
                    [name]="elementUID"
                    (change)="selectionMade($event)"
                    class="form-control form-select"
                    [value]="currentChoice"
                    [attr.id]="elementUID"
                    [attr.aria-label]="getText(element.title)"
                    [attr.disabled]="element.readOnly"
                >
                    <option value="" [attr.selected]="currentChoice === '' ? '' : null">
                        {{ element.optionsCaption || 'Registry.SelectResponse' | translate }}
                    </option>
                    <option
                        *ngFor="let choice of element.choices"
                        [value]="choice.value || choice"
                        [attr.selected]="currentChoice === choice.value ? '' : null"
                    >
                        {{ choice.name || getText(choice.text) || choice }}
                    </option>
                    <!-- TODO NO SUPPORT FOR CHOICE LEVEL TOOLTIP-->
                </select>
            </app-loading-component>
        </div>
        <app-validator
            [control]="formControl"
            [customErrorMessages]="customErrorMessages"
            [warningControl]="warningControl"
            [customWarningMessages]="customWarningMessages"
        ></app-validator>
    </div>
</ng-template>
