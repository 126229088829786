export interface IFileWithMetaData {
    fileName: string;
    fileId: string;
    type: string;
    status: IFileStatus;
    uploadUser: string;
    key: string;
    submissionOrigin: string;
    uploadDate: string;
    name: string;
    id: string;
}

export interface IFileData {
    id?: string;
    name: string;
    content_type: string;
    type: string;
    status?: IFileStatus;
    submitter_id?: string;
    submission_id?: string;
    task_id?: string;
    question_key: string;
    submission_origin: string;
    upload_date: string;
    fileId?: string;
    description?: string;
}

export enum IFileStatus {
    Submitted = 'submitted',
    UnSubmitted = 'unsubmitted',
    Deleted = 'deleted',
}
