<app-button
    [iconConfig]="{ iconClasses: 'fa-regular fa-bars' }"
    data-bs-toggle="offcanvas"
    data-bs-target="#offcanvas"
    aria-controls="offcanvasEx"
    title="Open Menu"
/>

<nav
    class="offcanvas offcanvas-start"
    tabindex="-1"
    id="offcanvas"
    data-bs-keyboard="false"
    data-bs-backdrop="false"
    data-bs-scroll="true"
    aria-labelledby="offcanvasLabel"
>
    <div class="offcanvas-header border-bottom">
        <div class="pif-nav__logo">
            <a routerLink="/home">
                <img id="header_logo" src="/assets/image/composerLogo.png" alt="Pulse Logo" />
            </a>
        </div>
        <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            title="Close Menu"
        ></button>
    </div>
    <div class="offcanvas-body px-0">
        <!-- <div class="d-flex flex-column card pt-3"> -->
        <ul class="list-unstyled ps-0">
            <!-- SURVEY -->
            <li class="mb-1">
                <button
                    class="btn btn-toggle align-items-center font-family-headers font-size-18 rounded"
                    data-bs-toggle="collapse"
                    data-bs-target="#survey-collapse"
                    aria-expanded="true"
                >
                    Survey Management
                </button>
                <div class="collapse show" id="survey-collapse">
                    <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                        <li
                            *ngIf="userClaims.h20ComposerUser || userClaims.h20ComposerAdmin"
                            class="nav-flex topLvl"
                            routerLinkActive="current"
                        >
                            <a
                                class="font-family-headers font-size-18 rounded"
                                routerLink="/surveys"
                                >Survey Builder</a
                            >
                        </li>
                        <li
                            *ngIf="userClaims.h20ComposerAdmin"
                            class="nav-flex topLvl"
                            routerLinkActive="currnet"
                        >
                            <a
                                class="font-family-headers font-size-18 rounded"
                                routerLink="/surveys/deploysurvey"
                                >Deploy Survey</a
                            >
                        </li>
                    </ul>
                </div>
            </li>

            <!-- TRANSLATION -->
            <li class="mb-1">
                <button
                    class="btn btn-toggle align-items-center font-family-headers font-size-18 rounded collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#translation-collapse"
                    aria-expanded="false"
                >
                    Translation IO
                </button>
                <div class="collapse" id="translation-collapse">
                    <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                        <li
                            *ngIf="userClaims.h20ComposerAdmin"
                            class="nav-flex topLvl"
                            routerLinkActive="current"
                        >
                            <a
                                class="font-family-headers font-size-18 rounded"
                                routerLink="/translation-io/import"
                                >Import Translation File</a
                            >
                        </li>
                        <li class="nav-flex topLvl" routerLinkActive="current">
                            <a
                                class="font-family-headers font-size-18 rounded"
                                routerLink="/translation-io/export"
                                >Export Translation</a
                            >
                        </li>
                    </ul>
                </div>
            </li>

            <li class="border-top my-3"></li>

            <!-- SCHEDULE -->
            <li class="mb-1" *ngIf="userClaims.h20ComposerAdmin">
                <button
                    class="btn btn-toggle align-items-center font-family-headers font-size-18 rounded collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#schedule-collapse"
                    aria-expanded="false"
                >
                    Schedule Management
                </button>
                <div class="collapse" id="schedule-collapse">
                    <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                        <li class="nav-flex topLvl" routerLinkActive="current">
                            <a
                                class="font-family-headers font-size-18 rounded"
                                routerLink="/schedulemanagement"
                                >Schedule Editor</a
                            >
                        </li>
                    </ul>
                </div>
            </li>

            <!-- CONTENT -->
            <li class="mb-1" *ngIf="userClaims.h20ComposerAdmin">
                <button
                    class="btn btn-toggle align-items-center font-family-headers font-size-18 rounded collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#content-collapse"
                    aria-expanded="false"
                >
                    Content Management
                </button>
                <div class="collapse" id="content-collapse">
                    <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                        <li class="nav-flex topLvl" routerLinkActive="current">
                            <a
                                class="font-family-headers font-size-18 rounded"
                                routerLink="/content-management/manage-registration"
                                >Registration Content</a
                            >
                        </li>
                        <li class="nav-flex topLvl" routerLinkActive="current">
                            <a
                                class="font-family-headers font-size-18 rounded"
                                routerLink="/content-management/manage-thankyou"
                                >Thank you Page</a
                            >
                        </li>
                        <!-- ToDo: Fix issues -->
                        <!-- <li class="nav-flex topLvl" routerLinkActive="current">
                            <a
                                class="font-family-headers font-size-18 rounded"
                                routerLink="/content-management/manage-library"
                                >My Library</a
                            >
                        </li> -->
                        <li class="nav-flex topLvl" routerLinkActive="current">
                            <a
                                class="font-family-headers font-size-18 rounded"
                                routerLink="/emailtemplatemanager"
                                >Email Template Manager</a
                            >
                        </li>
                        <!-- ToDo: Might need to delete -->
                        <!-- <li class="nav-flex topLvl" routerLinkActive="current">
                            <a
                                class="font-family-headers font-size-18 rounded"
                                routerLink="/webassets"
                                >Web Asset</a
                            >
                        </li> -->
                        <!-- ToDo: Looks like we only use this in dev rn. Need to review -->
                        <!-- <li class="nav-flex topLvl" routerLinkActive="current">
                            <a
                                class="font-family-headers font-size-18 rounded"
                                routerLink="content-management/manage-dashboard"
                                >Dashboard Management</a
                            >
                        </li> -->
                    </ul>
                </div>
            </li>

            <!-- REGISTRY -->
            <li class="mb-1" *ngIf="userClaims.h20ComposerAdmin">
                <button
                    class="btn btn-toggle align-items-center font-family-headers font-size-18 rounded collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#registry-collapse"
                    aria-expanded="false"
                >
                    Registry Management
                </button>
                <div class="collapse" id="registry-collapse">
                    <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                        <li class="nav-flex topLvl" routerLinkActive="current">
                            <a
                                class="font-family-headers font-size-18 rounded"
                                routerLink="/registrysite"
                                >Registry & Site</a
                            >
                        </li>
                        <li class="nav-flex topLvl" routerLinkActive="current">
                            <a
                                class="font-family-headers font-size-18 rounded"
                                routerLink="/registrysite/taskprototype"
                                >Task Prototype</a
                            >
                        </li>
                        <!-- ToDo: Need to review if we still need this -->
                        <!-- <li class="nav-flex topLvl" routerLinkActive="current">
                            <a
                                class="font-family-headers font-size-18 rounded"
                                routerLink="/registrysite/taskset"
                                >Task Set</a
                            >
                        </li> -->
                    </ul>
                </div>
            </li>

            <!-- PERMISSION -->
            <li class="mb-1" *ngIf="userClaims.h20ComposerAdmin">
                <button
                    class="btn btn-toggle align-items-center font-family-headers font-size-18 rounded collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#permission-collapse"
                    aria-expanded="false"
                >
                    Permission Management
                </button>
                <div class="collapse" id="permission-collapse">
                    <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                        <li class="nav-flex topLvl" routerLinkActive="current">
                            <a
                                class="font-family-headers font-size-18 rounded"
                                routerLink="/permissionmanagement/claims"
                                >Claims</a
                            >
                        </li>
                        <li class="nav-flex topLvl" routerLinkActive="current">
                            <a
                                class="font-family-headers font-size-18 rounded"
                                routerLink="/permissionmanagement/permissions"
                                >Permissions</a
                            >
                        </li>
                    </ul>
                </div>
            </li>

            <!-- DEPLOY -->
            <li class="mb-1" *ngIf="userClaims.h20ComposerAdmin">
                <button
                    class="btn btn-toggle align-items-center font-family-headers font-size-18 rounded collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#deploy-collapse"
                    aria-expanded="false"
                >
                    Deploy Management
                </button>
                <div class="collapse" id="deploy-collapse">
                    <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                        <li class="nav-flex topLvl" routerLinkActive="current">
                            <a
                                class="font-family-headers font-size-18 rounded"
                                routerLink="/deploymanagement/manage-config"
                                >Manage Config</a
                            >
                        </li>
                        <li class="nav-flex topLvl" routerLinkActive="current">
                            <a
                                class="font-family-headers font-size-18 rounded"
                                routerLink="/deploymanagement/deploy-tracking"
                                >Deploy Tracking</a
                            >
                        </li>
                    </ul>
                </div>
            </li>
            <!-- USER -->
            <li class="mb-1">
                <button
                    class="btn btn-toggle align-items-center font-family-headers font-size-18 rounded collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#user-collapse"
                    aria-expanded="false"
                >
                    User
                </button>
                <div class="collapse" id="user-collapse">
                    <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                        <li
                            *ngIf="userClaims.h20ComposerAdmin"
                            class="nav-flex topLvl"
                            routerLinkActive="current"
                        >
                            <a
                                class="font-family-headers font-size-18 rounded"
                                routerLink="/usermanagement"
                                >User Management</a
                            >
                        </li>
                        <!-- ToDo: Need to review if we need this feature -->
                        <!-- <li
                            *ngIf="userClaims.h20ComposerAdmin"
                            class="nav-flex topLvl"
                            routerLinkActive="current"
                        >
                            <a
                                class="font-family-headers font-size-18 rounded"
                                routerLink="/usermanagement/patients/delete"
                                >Delete Patients</a
                            >
                        </li> -->
                        <li
                            *ngIf="userClaims.h20ComposerAdmin"
                            class="nav-flex topLvl"
                            routerLinkActive="current"
                        >
                            <a
                                class="font-family-headers font-size-18 rounded"
                                routerLink="/usermanagement/attributes/definitions"
                                >User Attribute Definitions</a
                            >
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
    </div>
</nav>
