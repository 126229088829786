export enum DeployStatus {
    pending = 'pending',
    ready = 'ready',
    complete = 'complete',
    cancel = 'cancel',
}

export const DeployStatusList = [
    DeployStatus.pending,
    DeployStatus.ready,
    DeployStatus.complete,
    DeployStatus.cancel,
];
