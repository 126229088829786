<h1 class="text-center mt-3 mb-3">Schedule Editor</h1>
<app-loading-component [loading]="schedulesLoading" [text]="'loading sites'">
    <div class="row">
        <div class="col-2">
            <label class="mx-3">
                Sites:
                <select (change)="loadSchedule($event.target.value)" class="form-select">
                    <option selected disabled>Pick a Site</option>
                    <option *ngFor="let site of lstSchedules">
                        {{ site }}
                    </option>
                </select>
            </label>
        </div>
        <div class="col-4 my-4">
            <app-button
                *ngIf="graphLoading === undefined"
                class="m-2"
                (action)="createScheduleModal = true"
                [buttonText]="'Registry.CreateNew'"
            />
            <app-button
                *ngIf="graphLoading !== undefined"
                (action)="parseGraph()"
                class="m-2"
                [buttonText]="'Registry.ParseGraph'"
            />
            <app-button
                *ngIf="graphLoading !== undefined"
                (action)="saveGraph()"
                class="m-2"
                [buttonText]="'Registry.Save'"
            />
            <app-button
                *ngIf="graphLoading !== undefined"
                (action)="deleteGraph()"
                class="m-2"
                [design]="'destructive-primary'"
                [buttonText]="'Registry.Delete'"
            />
        </div>
        <div *ngIf="graphParsingErrors">
            <span *ngFor="let err of graphParsingErrors" class="form-text text-muted text-danger">
                {{ err }}
                <br />
            </span>
        </div>
        <app-loading-component
            [loading]="loadingDelete"
            [text]="'Deleting schedule'"
        ></app-loading-component>
        <app-loading-component
            [loading]="loadingSave"
            [text]="'Saving schedule'"
        ></app-loading-component>
    </div>
</app-loading-component>
<hr />
<div *ngIf="graphLoading !== undefined" class="container-fluid row">
    <div class="my-2 text-center" *ngIf="!graphLoading">
        <label>
            Schedule Title:
            <input
                type="text"
                [value]="schedule.data.title"
                (change)="valueChanged(schedule, 'title', $event.target.value)"
                class="form-control"
            />
        </label>
        <label>
            Send Emails From:
            <input
                type="text"
                [value]="schedule.data.graph.fromAddress"
                (change)="dataValueChanged(schedule.data.graph, 'fromAddress', $event.target.value)"
                class="form-control"
            />
        </label>
        <label>
            <input
                type="checkbox"
                [checked]="schedule.data.graph.isActive"
                (change)="dataValueChanged(schedule.data.graph, 'isActive', $event.target.checked)"
            />
            Is Active
        </label>
    </div>
    <div *ngIf="schedule" class="row gx-0">
        <div class="col-4 card d-block mx-2">
            <div class="px-3">
                <label>
                    <input
                        type="checkbox"
                        [checked]="usingMasterSchedule"
                        (change)="setUsingMasterSchedule($event.currentTarget.checked)"
                    />
                    Use Master Schedule
                </label>
                <div *ngIf="usingMasterSchedule">
                    <select
                        class="form-select"
                        (change)="masterScheduleChanged($event.target.value)"
                    >
                        <option [selected]="true" disabled>Please Select</option>
                        <option
                            *ngFor="let site of lstSchedules"
                            [selected]="site === masterScheduleId"
                        >
                            {{ site }}
                        </option>
                    </select>
                    <i
                        class="far fa-arrows-rotate text-end"
                        (click)="masterScheduleChanged(masterScheduleId)"
                        >Refresh</i
                    >
                </div>
                <br />
                <br />
                <i
                    *ngIf="!usingMasterSchedule"
                    class="far fa-regular fa-plus-circle text-end"
                    (click)="addTaskMap()"
                >
                    Add Task Mapping
                </i>
                <br />
                <br />
                <ng-container *ngFor="let t of taskMap; let i = index">
                    <div *ngIf="t">
                        <div *ngIf="!usingMasterSchedule">
                            <i class="far fa-minus-circle text-end" (click)="removeTaskMap(i)"></i>
                            {{ t.label }}
                        </div>
                        <div *ngIf="usingMasterSchedule">
                            {{ t.label }}
                        </div>
                        <select
                            class="form-select"
                            (change)="taskMapChange(i, 'task', $event.target.value)"
                        >
                            <option [selected]="true" disabled>Please Select</option>
                            <option
                                *ngFor="let proto of lstSiteTasks"
                                [value]="proto.id"
                                [selected]="proto.id === t.task?.id"
                            >
                                {{ proto.site_ID }} - {{ proto.nice_label }} -
                                {{ proto.type }}
                            </option>
                        </select>
                    </div>
                </ng-container>
                <br />
            </div>
        </div>
        <div class="col-4 card d-block mx-2">
            <div class="px-3">
                <label *ngIf="usingMasterSchedule">
                    <input
                        type="checkbox"
                        [checked]="hasUniqueEmails"
                        (change)="setHasUniqueEmails($event.currentTarget.checked)"
                    />
                    Has Unique Emails
                </label>
                <br />
                <br />
                <i
                    *ngIf="!usingMasterSchedule"
                    class="far fa-regular fa-plus-circle text-end"
                    (click)="addEmailMap()"
                >
                    Add Email Mapping
                </i>
                <br />
                <br />
                <ng-container *ngFor="let e of emailMap; let i = index">
                    <div *ngIf="e">
                        <div *ngIf="!usingMasterSchedule">
                            <i class="far fa-minus-circle text-end" (click)="removeEmailMap(i)"></i>
                            {{ e.label }}
                        </div>
                        <div *ngIf="usingMasterSchedule">
                            {{ e.label }}
                        </div>
                        <select
                            class="form-select"
                            [disabled]="!hasUniqueEmails"
                            (change)="emailMapChange(i, 'email', $event.target.value)"
                        >
                            <option [selected]="true" disabled>Please Select</option>
                            <option
                                *ngFor="let template of templateTypeList"
                                [value]="template.id"
                                [selected]="template.id === e.email"
                            >
                                {{ template.id }}
                            </option>
                        </select>
                    </div>
                </ng-container>
                <br />
            </div>
        </div>
    </div>
    <div *ngIf="!usingMasterSchedule && schedule" class="row gx-0">
        <div class="col-4 card d-block mx-2">
            <div class="px-3">
                <input
                    class="form-control"
                    type="text"
                    placeholder="Name"
                    [value]="new_schedule_event.name"
                    (change)="changeScheduleEvent(new_schedule_event, 'name', $event.target.value)"
                    class="form-control"
                />
                <i class="far fa-regular fa-plus-circle text-end" (click)="addScheduleEvent()">
                    Add Schedule Event
                </i>
                <br /><br />
                <ng-container *ngFor="let s of schedule.data.graph.scheduleEvents; let i = index">
                    <div *ngIf="s">
                        <input
                            type="radio"
                            [id]="s.id"
                            [name]="'scheduleEventRadio'"
                            [value]="s.label"
                            (change)="changeSelectedEvent(s)"
                        />
                        <label [for]="'scheduleEvent' + i">{{ s.name }}</label>
                        <i
                            class="far fa-minus-circle text-end"
                            (click)="removeScheduleEvent(i)"
                        ></i>
                    </div>
                </ng-container>
                <br />
            </div>
        </div>
        <div *ngIf="selectedEvent" class="col-4 card d-block mx-2">
            <div class="px-3">
                <ng-container
                    [ngTemplateOutlet]="scheduleEventTemplate"
                    [ngTemplateOutletContext]="{ $implicit: selectedEvent }"
                ></ng-container>
            </div>
        </div>
    </div>
    <div *ngIf="!usingMasterSchedule">
        <div *ngIf="selectedEvent && !advancedMode" class="row gx-0">
            <app-loading-component
                [loading]="loadingSES"
                [text]="'loading email templates'"
            ></app-loading-component>
            <app-loading-component
                [loading]="loadingTasks"
                [text]="'loading task prototypes'"
            ></app-loading-component>
            <div>
                <div class="row gx-0">
                    <div class="col-4 card d-block mx-2">
                        Email Policies:
                        <i class="far fa-regular fa-plus-circle text-end" (click)="addEmailPolicy()"></i>
                        <div *ngIf="selectedEvent.emailPolicy">
                            <div
                                *ngFor="let policy of selectedEvent.emailPolicy.emails"
                                class="px-3"
                            >
                                <ng-container
                                    [ngTemplateOutlet]="emailPolicyTemplate"
                                    [ngTemplateOutletContext]="{ $implicit: policy }"
                                ></ng-container>
                                <hr
                                    *ngIf="
                                        policy !==
                                        selectedEvent.emailPolicy.emails[
                                            selectedEvent.emailPolicy.emails.length - 1
                                        ]
                                    "
                                />
                                <br
                                    *ngIf="
                                        policy ===
                                        selectedEvent.emailPolicy.emails[
                                            selectedEvent.emailPolicy.emails.length - 1
                                        ]
                                    "
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-3 card d-block">
                        Repeat Policy:
                        <i class="far fa-regular fa-plus-circle text-end" (click)="addRepeatPolicy()"></i>
                        <label *ngIf="selectedEvent.repeatPolicy">
                            Date Bound Iterations
                            <input
                                type="checkbox"
                                [checked]="selectedEvent.repeatPolicy?.dateBoundIterations"
                                (change)="
                                    policyChanged(
                                        this.selectedEvent.repeatPolicy,
                                        'dateBoundIterations',
                                        $event.currentTarget.checked
                                    )
                                "
                            />
                        </label>
                        <div
                            *ngFor="let repeat of selectedEvent.repeatPolicy?.policies"
                            class="px-3"
                        >
                            <ng-container
                                [ngTemplateOutlet]="repeatPolicyTemplate"
                                [ngTemplateOutletContext]="{
                                    $implicit: repeat,
                                }"
                            ></ng-container>
                        </div>
                    </div>
                    <div class="col-4 card d-block mx-2">
                        <div class="row-cols-1 card-body border rounded">
                            Task Templates:
                            <div *ngFor="let user_type of user_types">
                                <i
                                    *ngIf="
                                        !selectedEvent.tasks || !selectedEvent.tasks[user_type.type]
                                    "
                                    class="far fa-regular fa-plus-circle text-end"
                                    (click)="addTask(user_type.type)"
                                    >{{ user_type.label }}</i
                                >
                            </div>
                            <div *ngIf="selectedEvent.tasks" class="px-3">
                                <ng-container
                                    [ngTemplateOutlet]="taskTemplate"
                                    [ngTemplateOutletContext]="{
                                        $implicit: selectedEvent.tasks,
                                    }"
                                ></ng-container>
                            </div>
                        </div>
                        <hr />
                        <div class="row-cols-1 card-body border rounded">
                            Task Policy:
                            <i
                                *ngIf="!selectedEvent.taskPolicy"
                                class="far fa-regular fa-plus-circle text-end"
                                (click)="addTaskPolicy()"
                            ></i>
                            <div *ngIf="selectedEvent.taskPolicy" class="px-3">
                                <ng-container
                                    [ngTemplateOutlet]="taskPolicyTemplate"
                                    [ngTemplateOutletContext]="{
                                        $implicit: selectedEvent.taskPolicy,
                                    }"
                                ></ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row gx-0">
                    <div class="col-4 card d-block mx-2">
                        Dynamic Actions:
                        <i class="far fa-regular fa-plus-circle text-end" (click)="addDynamicAction()"></i>
                        <div *ngIf="selectedEvent.dynamicActions">
                            <div *ngFor="let action of selectedEvent.dynamicActions" class="px-3">
                                <ng-container
                                    [ngTemplateOutlet]="dynamicActionTemplate"
                                    [ngTemplateOutletContext]="{ $implicit: action }"
                                ></ng-container>
                                <hr
                                    *ngIf="
                                        action !==
                                        selectedEvent.dynamicActions[
                                            selectedEvent.dynamicActions.length - 1
                                        ]
                                    "
                                />
                                <br
                                    *ngIf="
                                        action ===
                                        selectedEvent.dynamicActions[
                                            selectedEvent.dynamicActions.length - 1
                                        ]
                                    "
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="text-center">
        <button *ngIf="!advancedMode" (click)="enableAdvancedMode()" class="btn btn-warning m-2">
            I know what I'm doing give me MORE!
        </button>
        <app-button
            *ngIf="advancedMode"
            class="m-2"
            (action)="loadGraphFromJSON()"
            [buttonText]="'Registry.UpdateGraphFromJSONEditor'"
        />
        <json-editor
            *ngIf="advancedMode"
            [options]="jsonEditorOptions"
            [data]="schedule.data"
        ></json-editor>
    </div>
</div>

<app-modal
    [(visible)]="createScheduleModal"
    [header]="'Create new schedule'"
    [saveFunc]="createSchedule"
    [context]="this"
    [saveBtnText]="'Registry.Create'"
>
    <div class="mx-2">
        <app-loading-component [loading]="loadingSites" [text]="'loading sites'" class="row-cols-1">
            <label>
                Site_ID:
                <select
                    class="form-select"
                    (change)="policyChanged(createScheduleModel, 'site_ID', $event.target.value)"
                >
                    <option [selected]="true" disabled>Please Select</option>
                    <option *ngFor="let site of lstSites">
                        {{ site }}
                    </option>
                </select>
            </label>
            <label class="mt-3">
                Schedule Title:
                <input
                    type="text"
                    (change)="policyChanged(createScheduleModel, 'title', $event.target.value)"
                    class="form-control"
                />
            </label>
            <label class="my-3">
                Clone From:
                <small class="form-text text-muted text-danger">
                    Only select one if you want to start with a full graph
                </small>
                <select
                    (change)="policyChanged(createScheduleModel, 'cloneSite', $event.target.value)"
                    class="form-select"
                >
                    <option selected disabled>Pick a Site</option>
                    <option *ngFor="let site of lstSchedules">
                        {{ site }}
                    </option>
                </select>
            </label>
        </app-loading-component>
    </div>
</app-modal>

<ng-template #scheduleEventTemplate let-scheduleEvent>
    <div class="container-fluid">
        <div class="row-cols-1 mb-2">
            <label>
                Event ID:
                <input
                    type="text"
                    [value]="scheduleEvent.id"
                    (change)="changeScheduleEvent(scheduleEvent, 'id', $event.target.value)"
                    class="form-control"
                />
            </label>
            <label>
                Event Name:
                <input
                    type="text"
                    [value]="scheduleEvent.name"
                    (change)="changeScheduleEvent(scheduleEvent, 'name', $event.target.value)"
                    class="form-control"
                />
            </label>
            <ng-container
                [ngTemplateOutlet]="evaluationRuleTemplate"
                [ngTemplateOutletContext]="{ $implicit: scheduleEvent }"
            ></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #evaluationRuleTemplate let-scheduleEvent>
    <div class="mx-2 my-1">
        Evaluation Rules:
        <i class="far fa-regular fa-plus-circle text-end" (click)="addEvaluationRule(scheduleEvent)"></i>
        <div *ngIf="scheduleEvent.evaluationRules">
            <div *ngFor="let rule of scheduleEvent.evaluationRules" class="px-3">
                <div class="row-cols-1 card-body border rounded">
                    <i
                        class="far fa-minus-circle text-end"
                        (click)="removeEvaluationRule(scheduleEvent.evaluationRules, rule)"
                    ></i>
                    <label>
                        Field to Test:
                        <select
                            [value]="rule.testField"
                            class="form-select"
                            (change)="evaluationRuleChange(rule, 'testField', $event.target.value)"
                        >
                            <option [selected]="rule.testField === ''" disabled>
                                Please Select
                            </option>
                            <option
                                *ngFor="let testField of s_evaluationField"
                                [selected]="testField === rule.testField"
                            >
                                {{ testField }}
                            </option>
                        </select>
                    </label>
                    <label>
                        Value to Test:
                        <input
                            type="text"
                            [value]="rule.testValue"
                            (change)="evaluationRuleChange(rule, 'testValue', $event.target.value)"
                            class="form-control"
                        />
                    </label>
                    <label>
                        ifTest:
                        <select
                            class="form-select"
                            [value]="rule.ifTest"
                            (change)="evaluationRuleChange(rule, 'ifTest', $event.target.value)"
                        >
                            <option [selected]="rule.ifTest === ''" disabled>Please Select</option>
                            <option *ngFor="let type of s_ifTest" [selected]="type === rule.ifTest">
                                {{ type }}
                            </option>
                        </select>
                    </label>
                </div>
                <hr
                    *ngIf="
                        rule !==
                        scheduleEvent.evaluationRules[scheduleEvent.evaluationRules.length - 1]
                    "
                />
                <br
                    *ngIf="
                        rule ===
                        scheduleEvent.evaluationRules[scheduleEvent.evaluationRules.length - 1]
                    "
                />
            </div>
        </div>
    </div>
</ng-template>

<ng-template #emailPolicyTemplate let-policy>
    <div class="row-cols-1 card-body border rounded">
        <i class="far fa-minus-circle text-end" (click)="removeEmailPolicy(policy)"></i>
        <label>
            <input
                type="checkbox"
                [checked]="policy.sendToAdmin"
                (change)="emailPolicyChange(policy, 'sendToAdmin', $event.currentTarget.checked)"
            />
            Send to Admin
        </label>
        <label>
            Schedule Intervals:
            <input
                type="text"
                [value]="policy.schedule"
                class="form-control"
                (change)="emailPolicyChange(policy, 'schedule', $event.target.value)"
            />
            <span *ngIf="policy.error" class="form-text text-muted text-danger">{{
                policy.error
            }}</span>
        </label>
        <label>
            Schedule Interval Type:
            <select
                [value]="policy.period"
                class="form-select"
                (change)="emailPolicyChange(policy, 'period', $event.target.value)"
            >
                <option [selected]="policy.period === ''" disabled>Please Select</option>
                <option *ngFor="let type of s_intervalType" [selected]="type === policy.period">
                    {{ type }}
                </option>
            </select>
        </label>
        <label>
            Time Field:
            <select
                [value]="policy.timeField"
                class="form-select"
                (change)="emailPolicyChange(policy, 'timeField', $event.target.value)"
            >
                <option [selected]="policy.timeField === ''" disabled>Please Select</option>
                <option
                    *ngFor="let type of s_participant_timeField"
                    [selected]="type === policy.timeField"
                >
                    {{ type }}
                </option>
                <option [selected]="'user_attribute' === policy.timeField">user_attribute</option>
            </select>
            <div *ngIf="'user_attribute' === policy.timeField">
                Attribute to test:
                <select
                    class="form-select"
                    (change)="emailPolicyChange(policy, 'attribute', $event.target.value)"
                >
                    <option [selected]="true" disabled>Please Select</option>
                    <option
                        *ngFor="let attribute of userAttributes"
                        [selected]="attribute.id === policy.attribute"
                        [value]="attribute.id"
                    >
                        {{ attribute.name }}
                    </option>
                </select>
            </div>
        </label>
        <label>
            Email Template Name:
            <select
                [value]="policy.emailKey"
                class="form-select"
                (change)="emailPolicyChange(policy, 'emailKey', $event.target.value)"
            >
                <option [selected]="policy.emailKey === ''" disabled>Please Select</option>
                <option
                    *ngFor="let template of emailMap"
                    [value]="template.id"
                    [selected]="template.id === policy.emailKey"
                >
                    {{ template.label }}
                </option>
            </select>
        </label>
        <label>
            ifTest:
            <select
                class="form-select"
                [value]="policy.ifTest"
                (change)="emailPolicyChange(policy, 'ifTest', $event.target.value)"
            >
                <option [selected]="policy.ifTest === ''" disabled>Please Select</option>
                <option *ngFor="let type of s_ifTest" [selected]="type === policy.ifTest">
                    {{ type }}
                </option>
            </select>
        </label>
        <label>
            <input
                type="checkbox"
                [checked]="policy.hasRelevanceWindow"
                (change)="
                    changeRelevanceWindow(
                        policy,
                        'hasRelevanceWindow',
                        $event.currentTarget.checked
                    )
                "
            />
            Has Relevance Window
        </label>
        <div *ngIf="policy.hasRelevanceWindow === true">
            <hr />
            Dont send reminders [interval] [period] after time field:
            <label>
                Interval:
                <input
                    type="text"
                    [value]="policy.relevanceInterval"
                    class="form-control"
                    (change)="
                        changeRelevanceWindow(policy, 'relevanceInterval', $event.target.value)
                    "
                />
            </label>
            <label>
                Period:
                <select
                    [value]="policy.relevancePeriod"
                    class="form-select"
                    (change)="changeRelevanceWindow(policy, 'relevancePeriod', $event.target.value)"
                >
                    <option [selected]="policy.relevancePeriod === ''" disabled>
                        Please Select
                    </option>
                    <option
                        *ngFor="let type of s_intervalType"
                        [selected]="type === policy.relevancePeriod"
                    >
                        {{ type }}
                    </option>
                </select>
            </label>
        </div>
    </div>
</ng-template>

<ng-template #repeatPolicyTemplate let-policy>
    <div class="row-cols-1 card-body border rounded">
        <i class="far fa-minus-circle text-end" (click)="removeRepeatPolicy(policy)"></i>
        <label>
            Evaluate Time Model
            <small class="form-text text-muted text-danger">
                This must use the task created date due to how our iteration calculation works.
            </small>
            <select
                class="form-select"
                [value]="policy.timeBase.model"
                (change)="repeatPolicyModelChanged(policy.timeBase, $event.target.value)"
            >
                <option [selected]="policy.timeBase.model === ''" disabled>Please Select</option>
                <option
                    *ngFor="let model of s_repeatPolicy_models"
                    [selected]="policy.timeBase.model === model"
                >
                    {{ model }}
                </option>
            </select>
        </label>
        <div *ngIf="policy.timeBase.model === 'baseline'">
            <label>
                Survey Set:
                <div *ngFor="let survey of lstScheduleTasks">
                    <label>
                        <input
                            type="checkbox"
                            [checked]="repeatPolicyIncludesSet(policy, survey)"
                            (change)="
                                repeatPolicySurveySetChanged(
                                    policy.timeBase.baseline.tasks,
                                    survey,
                                    $event.currentTarget.checked
                                )
                            "
                        />
                        {{ survey }}
                    </label>
                </div>
            </label>
            <label>
                Set Status
                <select
                    class="form-select"
                    [value]="policy.timeBase.baseline.status"
                    (change)="
                        policyChanged(policy.timeBase.baseline, 'status', $event.target.value)
                    "
                >
                    <option [selected]="policy.timeBase.baseline.status === ''" disabled>
                        Please Select
                    </option>
                    <option
                        *ngFor="let status of lstTaskStatus"
                        [selected]="policy.timeBase.baseline.status === status"
                    >
                        {{ status }}
                    </option>
                </select>
            </label>
        </div>
        <hr />
        <app-form-list-editor [model]="policy" [field]="'intervals'"
            >Repeat Interval(s)</app-form-list-editor
        >
        <small class="form-text text-muted text-danger">
            use -1 to not limit the max iterations
        </small>
        <label>
            Max Iterations:
            <input
                type="number"
                [value]="policy.maxIterations"
                class="form-control"
                (change)="policyChanged(policy, 'maxIterations', $event.target.value)"
            />
        </label>
        <label>
            Precedence:
            <input
                type="number"
                [value]="policy.precedence"
                class="form-control"
                (change)="policyChanged(policy, 'precedence', $event.target.value)"
            />
        </label>
    </div>
</ng-template>

<ng-template #taskExpirationConditionsTemplate let-condition>
    <div class="row-cols-1 card-body border rounded">
        <i class="far fa-minus-circle text-end" (click)="removeTaskExpiryCondition(condition)"></i>
        <label>
            Evaluate against self
            <input
                type="checkbox"
                [checked]="condition.selfEval"
                (change)="
                    taskExpiryConditionChanged(condition, 'selfEval', $event.currentTarget.checked)
                "
            />
        </label>
        <div *ngIf="condition.selfEval">
            <label>
                Expiry Date
                <select
                    class="form-select"
                    [value]="condition.expiryDate"
                    (change)="
                        taskExpiryConditionChanged(condition, 'expiryDate', $event.target.value)
                    "
                >
                    <option [selected]="condition.expiryDate === ''" disabled>Please Select</option>
                    <option
                        *ngFor="let field of s_task_timeField"
                        [selected]="condition.expiryDate === field"
                    >
                        {{ field }}
                    </option>
                </select>
            </label>
            <label>
                Comparison Relative To Given Date
                <select
                    class="form-select"
                    [value]="condition.comparisonRelativeToGivenDate"
                    (change)="
                        taskExpiryConditionChanged(
                            condition,
                            'comparisonRelativeToGivenDate',
                            $event.target.value
                        )
                    "
                >
                    <option [selected]="condition.comparisonRelativeToGivenDate === ''" disabled>
                        Please Select
                    </option>
                    <option
                        *ngFor="let comp of lstTaskDateComparison"
                        [selected]="condition.comparisonRelativeToGivenDate === comp"
                    >
                        {{ comp }}
                    </option>
                </select>
            </label>
            <app-form-list-editor [model]="condition" [field]="'intervals'"
                >Expiry Interval(s)</app-form-list-editor
            >
        </div>
        <div *ngIf="!condition.selfEval">
            <label>
                Survey Set:
                <div *ngFor="let survey of lstScheduleTasks">
                    <label>
                        <input
                            type="checkbox"
                            [checked]="taskExpiryConditionIncludesSet(condition, survey)"
                            (change)="
                                repeatPolicySurveySetChanged(
                                    condition.tasks,
                                    survey,
                                    $event.currentTarget.checked
                                )
                            "
                        />
                        {{ survey }}
                    </label>
                </div>
            </label>
            <label>
                Baseline status
                <select
                    class="form-select"
                    [value]="condition.taskStatus"
                    (change)="
                        taskExpiryConditionChanged(condition, 'taskStatus', $event.target.value)
                    "
                >
                    <option [selected]="condition.taskStatus === ''" disabled>Please Select</option>
                    <option
                        *ngFor="let status of lstTaskStatus"
                        [selected]="condition.taskStatus === status"
                    >
                        {{ status }}
                    </option>
                </select>
            </label>
        </div>
    </div>
</ng-template>

<ng-template #taskActivationTemplate let-activate>
    <div class="row-cols-1 card-body border rounded">
        <i class="far fa-minus-circle text-end" (click)="removeTaskActivation()"></i>
        <label>
            Survey Set:
            <div *ngFor="let survey of lstScheduleTasks">
                <label>
                    <input
                        type="checkbox"
                        [checked]="taskExpiryConditionIncludesSet(activate, survey)"
                        (change)="
                            repeatPolicySurveySetChanged(
                                activate.tasks,
                                survey,
                                $event.currentTarget.checked
                            )
                        "
                    />
                    {{ survey }}
                </label>
            </div>
        </label>
        <label>
            Set Status
            <select
                class="form-select"
                [value]="activate.status"
                (change)="policyChanged(activate, 'status', $event.target.value)"
            >
                <option [selected]="activate.status === ''" disabled>Please Select</option>
                <option
                    *ngFor="let status of lstTaskStatus"
                    [selected]="activate.status === status"
                >
                    {{ status }}
                </option>
            </select>
        </label>
    </div>
</ng-template>

<ng-template #taskPolicyTemplate let-policy>
    <div class="row-cols-1 card-body border rounded">
        <i class="far fa-minus-circle text-end" (click)="removeTaskPolicy()"></i>
        <label>
            Allow Task Creation
            <input
                type="checkbox"
                [checked]="policy.allowCreation"
                (change)="taskPolicyChange('allowCreation', $event.currentTarget.checked)"
            />
        </label>
        <label>
            Default Creation Status
            <select
                class="form-select"
                [value]="policy.defaultCreationStatus"
                (change)="taskPolicyChange('defaultCreationStatus', $event.target.value)"
            >
                <option [selected]="policy.defaultCreationStatus === ''" disabled>
                    Please Select
                </option>
                <option
                    *ngFor="let status of lstTaskStatus"
                    [selected]="status === policy.defaultCreationStatus"
                >
                    {{ status }}
                </option>
            </select>
        </label>
        <app-form-list-editor [model]="policy" [field]="'delayCreationIntervals'"
            >Delay Creation until Interval(s)</app-form-list-editor
        >
        <hr />
        <div>
            Activate If:
            <i
                *ngIf="!policy.activateIf"
                class="far fa-regular fa-plus-circle text-end"
                (click)="addTaskActivation()"
            ></i>
            <ng-container
                *ngIf="policy.activateIf"
                [ngTemplateOutlet]="taskActivationTemplate"
                [ngTemplateOutletContext]="{
                    $implicit: policy.activateIf,
                }"
            ></ng-container>
        </div>
        <hr />
        <div>
            Expiry Conditions:
            <i class="far fa-regular fa-plus-circle text-end" (click)="addTaskExpiry()"></i>
            <br />
            <input
                type="checkbox"
                [checked]="policy?.expiry?.hasTrigger"
                (change)="taskExpiryTriggerChanged('hasTrigger', $event.currentTarget.checked)"
            />
            Trigger User Attribute on Expiry
            <div *ngIf="policy?.expiry?.hasTrigger">
                <select
                    class="form-select"
                    (change)="taskExpiryTriggerChanged('attribute', $event.target.value)"
                >
                    <option [selected]="true" disabled>Please Select</option>
                    <option
                        *ngFor="let attribute of userAttributes"
                        [selected]="attribute.id === policy?.expiry?.attribute"
                        [value]="attribute.id"
                    >
                        {{ attribute.name }}
                    </option>
                </select>
            </div>
            <div *ngFor="let condition of policy?.expiry?.conditions">
                <ng-container
                    [ngTemplateOutlet]="taskExpirationConditionsTemplate"
                    [ngTemplateOutletContext]="{
                        $implicit: condition,
                    }"
                ></ng-container>
                <hr
                    *ngIf="
                        condition !== policy.expiry.conditions[policy.expiry.conditions.length - 1]
                    "
                />
                <br
                    *ngIf="
                        condition !== policy.expiry.conditions[policy.expiry.conditions.length - 1]
                    "
                />
            </div>
        </div>
    </div>
</ng-template>

<ng-template #taskTemplate let-tasks>
    <div class="row-cols-1" *ngFor="let user_type of user_types">
        <div class="card d-block p-2" *ngIf="tasks[user_type.type]">
            {{ user_type.label }} Task:
            <i class="far fa-minus-circle text-end" (click)="removeTask(user_type.type)"></i>
            <select
                [value]="tasks[user_type.type].id"
                class="form-select"
                (change)="taskChange(user_type.type, $event.target.value)"
            >
                <option
                    [selected]="
                        tasks[user_type.type].id === undefined || tasks[user_type.type].id === ''
                    "
                    disabled
                >
                    Please Select
                </option>
                <option
                    *ngFor="let proto of taskMap"
                    [value]="proto.id"
                    [selected]="proto.id === tasks[user_type.type].taskKey"
                >
                    {{ proto.label }}
                </option>
            </select>
            <ng-container
                [ngTemplateOutlet]="assignmentRuleTemplate"
                [ngTemplateOutletContext]="{ $implicit: tasks[user_type.type] }"
            ></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #dynamicActionTemplate let-action>
    <div class="row-cols-1 card-body border rounded">
        <i class="far fa-minus-circle text-end" (click)="removeDynamicAction(action)"></i>
        <label>
            Schedule Intervals:
            <input
                type="text"
                [value]="action.schedule"
                class="form-control"
                (change)="dynamicActionChange(action, 'schedule', $event.target.value)"
            />
            <span *ngIf="action.error" class="form-text text-muted text-danger">{{
                action.error
            }}</span>
        </label>
        <label>
            Schedule Interval Type:
            <select
                [value]="action.period"
                class="form-select"
                (change)="dynamicActionChange(action, 'period', $event.target.value)"
            >
                <option [selected]="action.period === ''" disabled>Please Select</option>
                <option *ngFor="let type of s_intervalType" [selected]="type === action.period">
                    {{ type }}
                </option>
            </select>
        </label>
        <label>
            Time Field:
            <select
                [value]="action.timeField"
                class="form-select"
                (change)="dynamicActionChange(action, 'timeField', $event.target.value)"
            >
                <option [selected]="action.timeField === ''" disabled>Please Select</option>
                <option
                    *ngFor="let type of s_participant_timeField"
                    [selected]="type === action.timeField"
                >
                    {{ type }}
                </option>
                <option [selected]="'user_attribute' === action.timeField">user_attribute</option>
            </select>
            <div *ngIf="'user_attribute' === action.timeField">
                Attribute to test:
                <select
                    class="form-select"
                    (change)="dynamicActionChange(action, 'attribute', $event.target.value)"
                >
                    <option [selected]="true" disabled>Please Select</option>
                    <option
                        *ngFor="let attribute of userAttributes"
                        [selected]="attribute.id === action.attribute"
                        [value]="attribute.id"
                    >
                        {{ attribute.name }}
                    </option>
                </select>
            </div>
        </label>
        <label>
            Action:
            <select
                [value]="action.action"
                class="form-select"
                (change)="dynamicActionChange(action, 'action', $event.target.value)"
            >
                <option [selected]="action.action === ''" disabled>Please Select</option>
                <option *ngFor="let action of lstActions" [selected]="action === action.action">
                    {{ action }}
                </option>
            </select>
        </label>
        <label>
            ifTest:
            <select
                class="form-select"
                [value]="action.ifTest"
                (change)="dynamicActionChange(action, 'ifTest', $event.target.value)"
            >
                <option [selected]="action.ifTest === ''" disabled>Please Select</option>
                <option *ngFor="let type of s_ifTest" [selected]="type === action.ifTest">
                    {{ type }}
                </option>
            </select>
        </label>
    </div>
</ng-template>

<ng-template #assignmentRuleTemplate let-task>
    <div class="mx-2 my-1">
        Assignment Rules:
        <i class="far fa-regular fa-plus-circle text-end" (click)="addAssignmentRule(task)"></i>
        <p>(previously task_set in MySQL)</p>
        <div *ngIf="task.assignmentRules">
            <div *ngFor="let rule of task.assignmentRules" class="px-3">
                <div class="row-cols-1 card-body border rounded">
                    <i
                        class="far fa-minus-circle text-end"
                        (click)="removeAssignmentRule(task.assignmentRules, rule)"
                    ></i>
                    <label>
                        Type:
                        <select
                            [value]="rule.type"
                            class="form-select"
                            (change)="assignmentRuleChange(rule, 'type', $event.target.value)"
                        >
                            <option [selected]="rule.type === ''" disabled>Please Select</option>
                            <option
                                *ngFor="let type of s_assignmentType"
                                [selected]="type === rule.type"
                            >
                                {{ type }}
                            </option>
                        </select>
                    </label>
                    <label>
                        Assign On:
                        <select
                            [value]="rule.assign_on"
                            class="form-select"
                            (change)="assignmentRuleChange(rule, 'assign_on', $event.target.value)"
                        >
                            <option [selected]="!rule.assign_on" disabled>Please Select</option>
                            <option
                                *ngFor="let assign_on of s_assignOn"
                                [selected]="assign_on === rule.assign_on"
                            >
                                {{ assign_on }}
                            </option>
                        </select>
                    </label>
                    <label *ngIf="rule.type !== 'initial'">
                        Attribute to test:
                        <select
                            class="form-select"
                            (change)="assignmentRuleChange(rule, 'attribute', $event.target.value)"
                        >
                            <option [selected]="true" disabled>Please Select</option>
                            <option
                                *ngFor="let attribute of userAttributes"
                                [selected]="attribute.id === rule.attribute"
                                [value]="attribute.id"
                            >
                                {{ attribute.name }}
                            </option>
                        </select>
                    </label>
                    <label>
                        ifTest:
                        <select
                            class="form-select"
                            [value]="rule.ifTest"
                            (change)="assignmentRuleChange(rule, 'ifTest', $event.target.value)"
                        >
                            <option [selected]="rule.ifTest === ''" disabled>Please Select</option>
                            <option *ngFor="let type of s_ifTest" [selected]="type === rule.ifTest">
                                {{ type }}
                            </option>
                        </select>
                    </label>
                </div>
                <hr *ngIf="rule !== task.assignmentRules[task.assignmentRules.length - 1]" />
                <br *ngIf="rule === task.assignmentRules[task.assignmentRules.length - 1]" />
            </div>
        </div>
    </div>
</ng-template>
