import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfigService } from '@h20-services/config.service';
import { EnvironmentService } from '@h20-services/environment.service';
import { SurveyService } from '@h20-services/survey.service';

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
    //#region Fields

    isInit: boolean;
    loading: boolean;
    saving: boolean;
    defaultLang: string = 'en';

    mainTitle: any;
    mainSubtitle: any;
    mainFinePrint: any;
    registerTitle: any;
    registerSubtitle: any;
    registerDisclaimer: any;
    thankYouTitle: any;
    thankYouSubtitle: any;
    thankYouNextSteps: any;

    selectedRegistry: string;
    selectedSurvey: string;
    selectedParticipantSurvey: string;
    listSurveys: any[];

    infoSections: any[];
    videoUrl: any;

    listRegistries: string[];
    listRegConfigs: any[];

    //#endregion

    constructor(
        private config_svc: ConfigService,
        private san: DomSanitizer,
        private survey_svc: SurveyService,
        private environmentSvc: EnvironmentService,
    ) {
    }

    ngOnInit(): void {
        this.isInit = false;
        this.loading = true;

        this.config_svc
            .listRegistryConfig()
            .toPromise()
            .then((regRes) => {
                this.listRegConfigs = regRes;
                this.listRegistries = regRes.map((reg) => reg.registry_id);
                this.environmentSvc.currentRegistry.subscribe((environment) => {
                    this.reloadRegistrationContent(environment);
                });
                this.loading = false;
            });
    }

    reloadRegistrationContent(registry) {
        this.isInit = true;
        this.loading = true;

        this.selectedRegistry = registry;
        this.config_svc
            .getRegistrationConfig(registry, false)
            .then((regRes) => {
                this.infoSections = regRes.infoSections.map((section) => {
                    section.title = this.setDefault(section.title);
                    section.subtitle = this.setDefault(section.subtitle);

                    if (section.video) {
                        this.videoUrl = this.san.bypassSecurityTrustResourceUrl(section.video);
                    }

                    section.elements.map((se) => {
                        se.title = this.setDefault(se.title);
                        se.subtitle = this.setDefault(se.subtitle);
                    });

                    return section;
                });
                this.selectedSurvey = regRes.registrationForm;
                this.selectedParticipantSurvey = regRes.registerParticipantForm;
                this.mainFinePrint = this.setDefault(regRes.mainFinePrint);
                this.mainSubtitle = this.setDefault(regRes.mainSubtitle);
                this.mainTitle = this.setDefault(regRes.mainTitle);
                this.registerTitle = this.setDefault(regRes.registerTitle);
                this.registerSubtitle = this.setDefault(regRes.registerSubtitle);
                this.registerDisclaimer = this.setDefault(regRes.registerDisclaimer);
                this.thankYouNextSteps = this.setDefault(regRes.thankYouNextSteps);
                this.thankYouSubtitle = this.setDefault(regRes.thankYouSubtitle);
                this.thankYouTitle = this.setDefault(regRes.thankYouTitle);
            })
            .catch((err) => {
                console.error('This registry has no registration details', registry);

                this.infoSections = [];

                this.mainFinePrint = this.setDefault();
                this.mainSubtitle = this.setDefault();
                this.mainTitle = this.setDefault();
                this.registerTitle = this.setDefault();
                this.registerSubtitle = this.setDefault();
                this.registerDisclaimer = this.setDefault();
                this.thankYouNextSteps = this.setDefault();
                this.thankYouSubtitle = this.setDefault();
                this.thankYouTitle = this.setDefault();
            })
            .finally(() => {
                this.survey_svc
                    .getSurveys()
                    .toPromise()
                    .then((surveyRes) => {
                        this.listSurveys = surveyRes.map((survey) => {
                            return { name: this.getText(survey.name), id: survey.id };
                        });
                        this.loading = false;
                    });
            });
    }

    setDefault(rhs: any = {}): any {
        let lhs = {};

        if (rhs) {
            lhs = rhs;
            if (!lhs.hasOwnProperty(this.defaultLang)) {
                lhs[this.defaultLang] = '';

                if (rhs.hasOwnProperty('en')) {
                    lhs[this.defaultLang] = rhs['en'];
                }
            }
        }

        return lhs;
    }

    isElementBlank(rhs: any = {}, config: any={}, elementName){
        if (rhs) {
            if (rhs.hasOwnProperty(this.defaultLang) && rhs[this.defaultLang] == '') {
                delete config[elementName];
            }else config[elementName] = rhs;
        }
    }

    surveyChange(survey_ID) {
        this.selectedSurvey = survey_ID;
    }

    participantSurveyChange(survey_ID) {
        this.selectedParticipantSurvey = survey_ID;
    }

    onTextChange(item, key, evt) {
        item[key] = evt.target.value;

        if (key == 'video') {
            this.videoUrl = this.san.bypassSecurityTrustResourceUrl(evt.target.value);
        }
    }

    getText(strOrLangs): string {
        if (!strOrLangs) return '';
        return strOrLangs['en'] || strOrLangs['default'] || strOrLangs;
    }

    addSection(idx) {
        let section = {};

        section['title'] = this.setDefault();
        section['subtitle'] = this.setDefault();
        section['video'] = '';

        section['elements'] = [];

        this.infoSections.splice(idx, 0, section);
    }

    addSectionElement(sectionidx, idx) {
        let se = {};

        se['title'] = this.setDefault();
        se['subtitle'] = this.setDefault();

        this.infoSections[sectionidx].elements.splice(idx, 0, se);
    }

    removeSection(idx) {
        this.infoSections.splice(idx, 1);
    }

    removeSectionElement(sectionidx, idx) {
        this.infoSections[sectionidx].elements.splice(idx, 1);
    }

    saveRegistrationContent() {
        this.saving = true;

        let config = this.listRegConfigs.find((i) => i.registry_id == this.selectedRegistry);
        let j_config = {};
        if (config && config.registrationDetails) {
            j_config = JSON.parse(config.registrationDetails);
        }

        j_config['mainTitle'] = this.mainTitle;
        j_config['mainSubtitle'] = this.mainSubtitle;
        j_config['mainFinePrint'] = this.mainFinePrint;
        this.isElementBlank(this.registerTitle, j_config, 'registerTitle')
        this.isElementBlank(this.registerSubtitle, j_config, 'registerSubtitle')
        this.isElementBlank(this.registerDisclaimer, j_config, 'registerDisclaimer')
        j_config['registrationForm'] = this.selectedSurvey;
        j_config['registerParticipantForm'] = this.selectedParticipantSurvey;

        j_config['thankYouTitle'] = this.thankYouTitle;
        j_config['thankYouSubtitle'] = this.thankYouSubtitle;
        j_config['thankYouNextSteps'] = this.thankYouNextSteps;

        j_config['infoSections'] = this.infoSections;    
        
        config.registrationDetails = JSON.stringify(j_config);
        this.config_svc
            .updateRegistryConfig(config, 'registrationDetails', config.registrationDetails)
            .toPromise()
            .then(() => {
                this.saving = false;
            });
    }
}
