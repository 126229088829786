import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ClaimService } from './claim.service';
import { Roles, RoleLabels } from './enums/roles/roles';

@Injectable({
  providedIn: 'root',
})
export class ComposerRoleService {
  private userClaims;
  private roleClaims;
  private role = new BehaviorSubject(localStorage.getItem('role'));
  currentRole = this.role.asObservable();

  
  private roleEntries =[];
  
  getRoleLabel(key: string): string {

    return RoleLabels[key] || key;
  }
  

  constructor(private claimSvc: ClaimService) {
  }

  public initializeRoles() {
    this.userClaims = this.claimSvc.getUserClaims();
    this.roleClaims = {};
    this.roleEntries = [];
    for (const role in Roles) {
      this.roleClaims[Roles[role]] = this.userClaims[Roles[role]];
      this.roleEntries.push({
        key: role,
        claim: this.roleClaims[Roles[role]],
        label: RoleLabels[role] || role
      });
    }

    if (this.role.value) {
      this.changeRole(this.role.value);
    }

    
  }

  public getRoleClaims() {
    return this.roleClaims;
  }

  public getRoleEntries(){
    return this.roleEntries;
  }

  changeRole(newRole: string) {
    if (this.roleClaims[Roles[newRole]]) {
      this.clearRoleClaims();
      switch (newRole) {
        case 'developer':
          this.userClaims.h20ComposerAdmin = true;
          break;
        case 'user':
          this.userClaims.h20ComposerUser = true;
          break;
        case 'developerProduction':
          this.userClaims.h20ComposerAdmin = true;
          this.userClaims.productionDeployment_fullAccess = true;
          break;
      }
      localStorage.setItem('role', newRole);  //this will save the current role on refresh
      this.role.next(newRole);
    }
  }

  clearRoleClaims(){
    for (const i in Roles) {
      if (Roles.hasOwnProperty(i)) {
        delete this.userClaims[Roles[i]];
      }
    }
  }
}


