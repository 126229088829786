import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DeployTrackingComponent } from './deploy-tracking/deploy-tracking.component';
import { ManageConfigComponent } from './manage-config/manage-config.component';
import { ManageConfigDetailComponent } from './manage-config-detail/manage-config-detail.component';
const routes: Routes = [
    {
        path: '',
        component: DeployTrackingComponent,
    },
    {
        path: 'deploy-tracking',
        component: DeployTrackingComponent,
    },
    {
        path: 'manage-config',
        component: ManageConfigComponent,
    },
    {
        path: 'manage-config/:mode/:targetEnv/:selectedTable/:partitionKeyName/:partitionKey',
        component: ManageConfigDetailComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class DeployManagementRoutingModule {}
