import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { RequestService } from './request.service';

@Injectable({
    providedIn: 'root',
})
export class RegistryService {
    constructor(
        private reqSvc: RequestService,
        private commonSvc: CommonService
    ) {}

    getRegistryByKey(registryKey, env: any = null) {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        const dataToSend = {
            registryKey: registryKey,
        };
        const req: any = { operation: 'getRegistryByKey', data: dataToSend };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBRegistry, req);
    }

    listRegistry(env: any = null) {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        const req: any = { operation: 'listRegistry' };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBRegistry, req);
    }

    listRegistryAndSite(env: any = null) {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        const req: any = { operation: 'listRegistryAndSite' };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBRegistry, req);
    }

    listRegistryType(env: any = null) {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        const req: any = { operation: 'listRegistryType' };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBRegistry, req);
    }

    addRegistryAndSite(registry: any, env: any = null) {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        const dataToSend = {
            registryID: registry.registry_ID || null,
            siteID: registry.site_ID || null,
            registryName: registry.registryName,
            registryKey: registry.registryKey,
            registryTypeID: registry.registryTypeID,
            siteName: registry.siteLabel,
            siteKey: registry.siteKey,
        };

        const req: any = { operation: 'addRegistryAndSite', data: dataToSend };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBRegistry, req);
    }

    deleteRegistryAndSite(registry: any, env: any = null) {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        const dataToSend = {
            registryID: registry.registry_ID,
        };

        const req: any = {
            operation: 'deleteRegistryAndSite',
            data: dataToSend,
        };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBRegistry, req);
    }
}
