import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'app-password-component',
    templateUrl: './password-component.html',
    styleUrls: ['./password-component.scss'],
})
export class PasswordComponent implements OnInit {

    @Output() passwordEvent = new EventEmitter<any>();
    @Input() showPassword: boolean;
    @Input() showUsername:boolean;

    constructor(
      public translate: TranslateService,
    ){}

    passwordInput:any;
    passwordToggle: any;
    usernameInput:any;
    eyeIcon:any;
    showCharacters = false;
    passwordValid: boolean;
    usernameValid:boolean;
    text = 'Login.Show';



    ngOnInit(): void {
        this.validate.bind(this);
        this.passwordInput =document.getElementById("password-input") as HTMLInputElement;
        this.passwordToggle  = document.getElementById("password-toggle");
        this.eyeIcon = document.getElementById("eye-icon");
    }
    
    emitParams(value):void {
        this.passwordEvent.emit(value);
    }

    togglePasswordVisibility() : void {
    
  
        if (this.passwordInput.type === 'password') {
            this.showCharacters = true;
            this.passwordInput.type = 'text';
            this.text= 'Login.Hide';
        } else {
            this.showCharacters = false;
            this.passwordInput.type = 'password';
            this.text = 'Login.Show';
        }
  }

  validate(): void {
    const REQUIRED_CHARACTER_LENGTH = 8;
    const password = this.passwordInput.value;
    
    if (password) {
      let passwordParams = {
        password: password,
        hasRequiredLength: password.length >= REQUIRED_CHARACTER_LENGTH,
        containsUpperCase: /[A-Z]/.test(password),
        containsSpecialChar: /[^\w\s]/.test(password),
        containsNumber: /\d/.test(password),
        containsLowerCase: /[a-z]/.test(password)
      };
  
      if (
        passwordParams.hasRequiredLength &&
        passwordParams.containsUpperCase &&
        passwordParams.containsSpecialChar &&
        passwordParams.containsNumber &&
        passwordParams.containsLowerCase
      ) {
        this.passwordValid = true;
      } else {
        this.passwordValid = false;
      }
  
      this.emitParams(passwordParams); 
    } else {
      this.passwordValid = false;
    }
  }
  
}
