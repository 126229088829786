import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TaskService } from '@h20-services/task.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { BadgeTooltip } from '@h20-shared/button/button.component';
import { SiteService } from '@h20-services/site.service';
import { CommonService, EnvType } from '@h20-services/common.service';
import { ITaskPrototype } from '@h20-services/models/tasks/ITaskPrototype';
import { composerUtils } from '@core/composerUtils';
import { EnvironmentService } from '@h20-services/environment.service';
import { ToastService } from '@h20-shared/services/toast.service';
import { ConfigService } from '@h20-services/config.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-task-prototype',
    templateUrl: './task-prototype.component.html',
    styleUrls: ['./task-prototype.component.scss'],
})
export class TaskPrototypeComponent implements OnInit {
    @ViewChild(DatatableComponent) table: DatatableComponent;

    constructor(
        private taskSvc: TaskService,
        private fb: FormBuilder,
        private siteSvc: SiteService, //private objectDiff: NgxObjectDiffService
        private commonSvc: CommonService,
        private environmentSvc: EnvironmentService,
        private toastService: ToastService,
        private config_svc: ConfigService,
        private translate: TranslateService
    ) {
        this.environmentSvc.currentSource.subscribe(() => {
            this.sourceEnv = sessionStorage.getItem('sourceEnv');
            this.changeSourceEnv(this.sourceEnv);
        });
        this.environmentSvc.currentDestination.subscribe(() => {
            this.destinationEnv = sessionStorage.getItem('destinationEnv');
            this.changeDestinationEnv(this.destinationEnv);
        });
    }

    loading: boolean = false;
    message: string = '';

    sourceEnvs = this.commonSvc.listSourceEnvs();
    destinationEnvs = this.commonSvc.listTargetEnvs();
    sourceEnv: string = EnvType.Devops;
    destinationEnv: string = EnvType.Preprod;

    taskPrototypesPerEnv: any;
    taskPrototypes: any[];
    sites: any[];
    taskTypes = ['survey'];
    types: string[];
    taskPrototypeId: string = null;
    selectedItems = [];

    prototypeForm: FormGroup;
    viewAddPrototypePanel: boolean = false;
    isNewPrototype: boolean = false;
    isDuplicatePrototype: boolean = false;

    //registry selector
    listRegistries: string[];
    listRegConfigs: any[];
    allRegistries: any[];
    selectedRegistry: string;
    loadingTable = false;

    // Variables for compare items
    viewCompareDeployPanel: boolean = false;
    compareLoading: boolean = false;
    sourceItemRest: any;
    destItemRest: any;
    sourceView;
    destView;
    diffView;
    diffValueChanges;

    //-- Filtering -----------------------------
    filtersOn = 0;
    tempPrototypes: any[] = [];
    filterPrototypes: any = [];

    filterForm: FormGroup = this.fb.group({
        siteFilter: [''],
        labelFilter: [''],
    });

    colDefs = this.getTableColumns();
    //-----------------------------------------

    ngOnInit(): void {
        this.loadingTable = true;
        this.environmentSvc.currentRegistry.subscribe((environment) => {
            this.selectedRegistry = environment;
            this.listTaskPrototypesAllEnv().finally(() => {
                this.allRegistries = this.taskPrototypes;
                this.loadingTable = false;
            });
        });

        this.listSites();
        this.filterForm.valueChanges.subscribe((filterData: any) => {
            this.filter(filterData);
        });
        this.config_svc
            .listRegistryConfig()
            .toPromise()
            .then((regRes) => {
                this.listRegConfigs = regRes;
                this.listRegistries = regRes.map((reg) => reg.registry_id);
            });
    }

    getTableColumns() {
        return [
            {
                headerName: '',
                field: '',
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
                checkboxSelection: true,
            },
            {
                headerName: 'ID',
                field: 'id',
            },
            {
                headerName: 'Site',
                field: 'site_ID',
                filter: 'agTextColumnFilter',
            },
            {
                headerName: 'Type',
                field: 'type',
            },
            {
                headerName: 'Task Type',
                field: 'task_type',
            },
            {
                headerName: 'Configuration',
                field: 'task_configuration',
            },
            {
                headerName: 'Label',
                field: 'label',
                valueFormatter: (params) => params.value?.substring(0, 50),
                filter: 'agTextColumnFilter',
            },
            {
                headerName: 'Task Priority',
                field: 'task_priority',
            },
            this.sourceEnv === 'devops' && {
                headerName: 'Duplicate',
                field: 'id',
                cellDataType: 'iconBtns',
                cellRendererParams: (params) => {
                    return {
                        actions: [
                            {
                                isVisible: true,
                                label: 'Edit Prototype icon',
                                iconClass: 'fas fa-copy',
                                onClick: () => this.duplicateTaskPrototype(params.data),
                            },
                        ],
                    };
                },
            },
            {
                headerName: 'Edit',
                field: 'id',
                cellDataType: 'iconBtns',
                cellRendererParams: (params) => {
                    return {
                        actions: [
                            {
                                isVisible: true,
                                label: 'Edit Prototype icon',
                                iconClass: 'fas fa-pencil-alt',
                                onClick: () => this.editTaskPrototype(params.data),
                            },
                        ],
                    };
                },
            },
            {
                headerName: 'Delete',
                field: 'id',
                cellDataType: 'iconBtns',
                cellRendererParams: (params) => {
                    return {
                        actions: [
                            {
                                isVisible: true,
                                label: 'Delete Prototype icon',
                                iconClass: 'fas fa-times',
                                onClick: () => this.deleteTaskPrototype(params.data),
                            },
                        ],
                    };
                },
            },
            {
                headerName: 'Match Env',
                field: 'existMatchEnvs',
            },
        ];
    }

    // Get task prototypes from all envs
    async listTaskPrototypesAllEnv(env?: any) {
        this.loading = true;
        let listEnvs = [];
        if (env) {
            listEnvs.push(env);
        } else {
            listEnvs = this.sourceEnvs;
        }
        this.taskPrototypesPerEnv = {};
        let errMsg = '';
        for (const env of listEnvs) {
            await this.taskSvc
                .listTaskPrototypesByRegistry(env, this.selectedRegistry)
                .then((prototypes: any) => {
                    if (prototypes) {
                        this.taskPrototypesPerEnv[env] = prototypes;
                    }
                })
                .catch((err) => {
                    errMsg += `Failed to check data in ${env}.  `;
                    this.setErrorHandler(err, env, errMsg);
                });
        }
        this.listTaskPrototypes(this.sourceEnv);
        this.loading = false;
    }

    removeProperty(item): any {
        const {
            ['created']: remove1,
            ['created_by_ID']: remove2,
            ['existMatchEnvs']: remove3,
            ['modified']: remove4,
            ['modified_by_ID']: remove5,
            ...rest
        } = item;
        return rest;
    }

    listTaskPrototypes(env) {
        this.loading = true;
        if (this.taskPrototypesPerEnv && this.taskPrototypesPerEnv[env]) {
            this.taskPrototypes = this.taskPrototypesPerEnv[env];

            // To check if prototypes exist in other envs
            const otherEnvs = this.sourceEnvs.filter((e) => e !== env);
            for (let i = 0; i < otherEnvs.length; i++) {
                let otherEnv = otherEnvs[i];
                if (this.taskPrototypesPerEnv[otherEnv]) {
                    this.taskPrototypes.map((proto) => {
                        // Set envNames if the data is the same in the different env
                        if (i === 0) proto['existMatchEnvs'] = '';
                        const otherProto = this.taskPrototypesPerEnv[otherEnv].find(
                            (o) => o.id === proto.id
                        );

                        let envNames = proto['existMatchEnvs'] || '';
                        if (otherProto) {
                            // let diff = this.objectDiff.diff(
                            //     this.removeProperty(otherProto),
                            //     this.removeProperty(proto)
                            // );
                            // if (diff.changed === 'equal') envNames += otherEnv + ',';
                        }
                        proto['existMatchEnvs'] = envNames;
                    });
                }
            }
            this.tempPrototypes = [...this.taskPrototypes];
            this.listTypes();
        } else {
            this.taskPrototypes = [];
            this.tempPrototypes = [];
        }
        this.loading = false;
    }

    listSites() {
        this.siteSvc.getSites('').subscribe((sitesRes: any) => {
            if (sitesRes) {
                this.sites = sitesRes;
            }
        });
    }

    listTypes() {
        let tempTypes = [];
        for (let prototype of this.taskPrototypes) {
            tempTypes.push(prototype.type);
        }
        this.types = [...new Set(tempTypes)];
    }

    changeSourceEnv(env) {
        this.message = '';
        this.selectedItems = [];
        this.sourceEnv = env;
        this.listTaskPrototypes(this.sourceEnv);
        this.colDefs = this.getTableColumns();
    }

    changeDestinationEnv(env) {
        this.destinationEnv = env;
    }

    setPrototypeForm(data?): any {
        return this.fb.group(
            {
                site_ID: [data?.site_ID || '', [Validators.required]],
                type: [data?.type || '', [Validators.required]],
                task_type: [data?.task_type || '', [Validators.required]],
                task_configuration: [data?.task_configuration || ''],
                message: [data?.message || ''],
                label: [data?.label || ''],
                task_priority: [data?.task_priority || ''],
            },
            { validator: this.checkNumbers }
        );
    }

    newTaskPrototype() {
        this.prototypeForm = this.setPrototypeForm();
        this.viewAddPrototypePanel = true;
        this.isNewPrototype = true;
    }

    cancelNewPrototype() {
        this.viewAddPrototypePanel = false;
        this.taskPrototypeId = null;
    }

    duplicateTaskPrototype(row) {
        this.prototypeForm = this.setPrototypeForm(row);
        this.viewAddPrototypePanel = true;
        this.isNewPrototype = true;
        this.isDuplicatePrototype = true;
    }

    addTaskPrototype(context) {
        const newPrototype: ITaskPrototype = { ...context.prototypeForm.value };
        context.taskSvc
            .createTaskPrototype(newPrototype, context.sourceEnv)
            .toPromise()
            .then((resp: any) => {
                context.viewAddPrototypePanel = false;
                context.isNewPrototype = false;
                context.listTaskPrototypesAllEnv();
            })
            .catch((err) => {
                context.setErrorHandler(
                    err,
                    context.sourceEnv,
                    `Failed to add a task prototype into ${context.sourceEnv}`
                );
            });
    }

    editTaskPrototype(row) {
        this.prototypeForm = this.setPrototypeForm(row);
        this.taskPrototypeId = row.id;
        this.viewAddPrototypePanel = true;
        this.isNewPrototype = false;
    }

    saveEditTaskPrototype(context) {
        const editPrototype: ITaskPrototype = { ...context.prototypeForm.value };
        editPrototype.id = context.taskPrototypeId;
        context.taskSvc
            .updateTaskPrototype(editPrototype, context.sourceEnv)
            .toPromise()
            .then((resp: any) => {
                context.viewAddPrototypePanel = false;
                context.isNewPrototype = false;
                context.listTaskPrototypesAllEnv();
            })
            .catch((err) => {
                context.setErrorHandler(
                    err,
                    context.sourceEnv,
                    `Failed to edit the task prototype in ${context.sourceEnv}`
                );
            });
    }

    deleteTaskPrototype(row) {
        if (confirm('Are you sure you want to delete?')) {
            this.taskSvc
                .deleteTaskPrototype(row.id, this.sourceEnv)
                .toPromise()
                .then((resp: any) => {
                    this.listTaskPrototypesAllEnv();
                })
                .catch((err) => {
                    this.setErrorHandler(
                        err,
                        this.sourceEnv,
                        `Failed to delete the task prototype in ${this.sourceEnv}`
                    );
                });
        }
    }

    onSelect({ selected }) {
        this.selectedItems = [];
        this.selectedItems.push(...selected);
    }

    async deployTaskPrototypes(context?): Promise<void> {
        if (!context) context = this;
        const deployItems = context.selectedItems;
        context.message = '';

        if (deployItems.length === 0) {
            context.message = 'Please select an item.';
            return;
        }

        try {
            if (context.sourceEnv === context.destinationEnv) {
                context.message = 'Source Env and Destination Env should be different.';
                return;
            }

            const confirmMsg = `Are you sure you want to deploy ${deployItems.length} items to ${context.destinationEnv}?`;
            if (!confirm(confirmMsg)) {
                return;
            }

            // do the deployments
            context.loading = true;
            for (let i = 0; i < deployItems.length; i++) {
                const deployItem: ITaskPrototype = deployItems[i];

                const existItem = await context.taskSvc
                    .getTaskPrototype(deployItem.id, context.destinationEnv)
                    .toPromise();

                if (existItem && existItem.length !== 0) {
                    await context.taskSvc
                        .updateTaskPrototype(deployItem, context.destinationEnv)
                        .toPromise();
                } else {
                    await context.taskSvc
                        .createTaskPrototype(deployItem, context.destinationEnv)
                        .toPromise();
                }

                if (i === deployItems.length - 1) {
                    context.selectedItems = [];
                    context.viewCompareDeployPanel = false;
                    context.listTaskPrototypesAllEnv();
                    context.message = `Deployed to ${context.destinationEnv} successfully.`;
                }
            }
        } catch (err) {
            context.setErrorHandler(
                err,
                context.destinationEnv,
                `Failed to deploy to ${context.destinationEnv}`
            );
            context.selectedItems = [];
            context.loading = false;
        }
    }

    openCompareDeploy() {
        this.message = '';
        if (this.selectedItems.length > 1) {
            this.message = 'Please select only one item.';
        } else if (this.selectedItems.length === 0) {
            this.message = 'Please seletct an item';
        } else {
            this.viewCompareDeployPanel = true;
            const selectedProto = this.removeProperty(this.selectedItems[0]);
            let existProto = null;
            if (this.taskPrototypesPerEnv[this.destinationEnv]) {
                existProto = this.taskPrototypesPerEnv[this.destinationEnv].find(
                    (o) => o.id === this.selectedItems[0].id
                );
            }
            const otherProto = existProto ? this.removeProperty(existProto) : {};

            // this.sourceView = this.objectDiff.objToJsonView(selectedProto);
            // this.destView = this.objectDiff.objToJsonView(otherProto);
            // let diff = this.objectDiff.diff(selectedProto, otherProto);
            // this.diffView = this.objectDiff.toJsonView(diff);
            // this.diffValueChanges = this.objectDiff.toJsonDiffView(diff);
        }
    }

    closeCompareItems() {
        this.viewCompareDeployPanel = false;
    }

    filter(filterData) {
        this.taskPrototypes = this.tempPrototypes.filter((prototype: any) => {
            const siteMatch = composerUtils.filterIncludes(
                prototype.site_ID,
                filterData.siteFilter
            );
            const labelMatch = composerUtils.filterIncludes(
                prototype.label,
                filterData.labelFilter
            );
            return siteMatch && labelMatch;
        });
    }

    checkNumbers = (group: FormGroup): any => {
        const inputs = group.value;
        const task_priority = group.get('task_priority');
        let flag = null;
        const num = /[0-9]/g.test(inputs.task_priority);

        if (!num) {
            flag = { noMatch: true };
            task_priority.setErrors(flag);
        }
        return flag;
    };

    // Helper functions
    setErrorHandler(err, env, msg?) {
        console.error(err);
        this.message = msg ? msg : `An error occurred on ${env}`;
    }

    showToast() {
        this.toastService.show({
            body: this.translate.instant('Toast.FilterOptions'),
        });
    }
}
