import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AuthService } from '@h20-services/auth.service';
import { ClaimService } from '@h20-services/claim.service';
import { CommonService } from '@h20-services/common.service';
import { PulseAuth } from '@h20-services/models/PulseAuth';
import { PatientService } from '@h20-services/patient.service';
import { SurveyService } from '@h20-services/survey.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-datatable-filters',
    templateUrl: './datatable-filters.component.html',
    styleUrls: ['./datatable-filters.component.scss'],
})
export class DatatableFiltersComponent implements OnInit {
    @Input() toggleFilters: boolean = false;

    private _data: any[];
    @Input() set data(value: any) {
        this._data = value;

        this.buildFormFiltersFromData();
        this.filterData(this._data);
    }
    get data() {
        return this._data;
    }

    /** Emits number of applied filters */
    @Output() ActiveFiltersCount = new EventEmitter<number>();

    @Output() FilteredData = new EventEmitter();

    @Output() InnerToggle = new EventEmitter();

    filteredData: any[];
    filterOption: any;

    filterForm: UntypedFormGroup;
    trayTitle: string = this.translate.instant('Filters.FilteringOptions');

    userClaims: any;
    pulseAuth: PulseAuth;
    surveys: any[];
    surveyQuestions: any[];
    selected_survey_ID: string;
    surveyQuestion_ID: string;
    surveyQuestion_Answers: string;

    constructor(
        private claimService: ClaimService,
        private fb: UntypedFormBuilder,
        private auth: AuthService,
        private surveyService: SurveyService,
        private commonService: CommonService,
        private patientService: PatientService,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        this.userClaims = this.claimService.getUserClaims();
        this.auth.getPulseAuth().then((pulseAuth: PulseAuth) => {
            this.pulseAuth = pulseAuth;
        });

        this.surveyService.getSurveysByRegistry().subscribe((resp) => {
            this.surveys = resp;
        });
    }

    getText = this.commonService.getText;

    buildFormFiltersFromData() {
        if (this.data && this.data.length > 0) {
            const form = {};
            Object.keys(this.data[0]).forEach((k) => {
                if (this.data[0][k] === undefined) return;

                if (k === 'status') {
                    form[k] = this.fb.group({
                        new: [false],
                        consent: [false],
                        active: [false],
                        withdrawn: [false],
                        ['confirming-eligibility']: [false],
                    });
                } else if (k === 'matched_state') {
                    form[k] = this.fb.group({
                        failed: [false],
                        pending: [false],
                        unmatched: [false],
                        matched: [false],
                    });
                } else if (['first_name', 'last_name'].includes(k)) {
                    form['name'] = [''];
                } else if (k === 'age') {
                    form['minAge'] = [''];
                    form['maxAge'] = [''];
                } else if (['proxy_first_name', 'proxy_last_name'].includes(k)) {
                    form['proxyName'] = [''];
                } else if (k === 'exclude_from_reporting') {
                    form['exclude_from_reporting'] = [false];
                } else {
                    form[k] = [''];
                }
            });

            this.filterOption = form;
            this.filterForm = this.fb.group(form);
            this.filterForm.valueChanges.subscribe((data: any) => {
                this.filterData(data);
            });
            this.filteredData = this.data;
            this.FilteredData.emit(this.filteredData);
        }
    }

    populateSurveyQuestions(): void {
        this.surveyQuestions = [];

        this.surveyService
            .getSurveyById(this.filterForm.controls.surveyFilter.value)
            .subscribe((resp) => {
                let surveyjs = JSON.parse(resp[0].json);
                surveyjs.pages.map((page) => {
                    page.elements.map((master_element) => {
                        master_element.elements.map((element) => {
                            if (!['html', 'panel'].includes(element.type))
                                this.surveyQuestions.push(element);
                        });
                    });
                });
                this.selected_survey_ID = this.filterForm.controls.surveyFilter.value;
            });
    }

    listSurveyQuestionAnswers(): void {
        this.surveyQuestion_ID = '';
        this.surveyQuestion_Answers = '';

        this.surveyQuestion_ID = this.filterForm.controls.surveyQuestionFilter.value;
        this.surveyQuestion_Answers = this.surveyQuestions.filter(
            (quest) => quest.name == this.surveyQuestion_ID
        )[0];
    }

    innerToggle(evt) {
        this.toggleFilters = evt;
        this.InnerToggle.emit(this.toggleFilters);
    }

    filterData(data): void {
        const activeFilters = Object.keys(data).filter((key) => {
            if (data[key] && typeof data[key] == 'object') {
                return Object.keys(data[key]).find((subKey) => data[key][subKey]);
            } else {
                return !!data[key];
            }
        });

        this.ActiveFiltersCount.emit(activeFilters.length);

        // all state and status on if none are selected
        const stateAll =
            !data.matched_state?.failed &&
            !data.matched_state?.unmatched &&
            !data.matched_state?.matched &&
            !data.matched_state?.pending;
        const statusAll =
            !data.status?.new &&
            !data.status?.consent &&
            !data.status?.active &&
            !data.status?.withdrawn &&
            !data.status?.['confirming-eligibility'];

        if (data.status?.new) data.status.pending = true;
        if (data.status?.withdrawn) data.status.inactive = true;

        // filter the patients
        this.filteredData = this.data.filter((patient: any) => {
            //Ckeck testers
            const excludeFromReportingFlag = !data.exclude_from_reporting
                ? patient.exclude_from_reporting !== 'test_user'
                : true;

            // check state
            const stateFlag = stateAll || data?.matched_state[patient.matched_state];

            // check status
            const statusFlag = statusAll || data?.status[patient.status];

            // check name
            const nameFlag =
                !data.name ||
                data.name === '' ||
                (
                    (patient.first_name ?? '').toLowerCase() +
                    ' ' +
                    (patient.last_name ?? '').toLowerCase()
                ).indexOf(data?.name?.toLowerCase()) > -1;
            // check proxy name
            const proxyNameFlag =
                !data.proxyName ||
                data.proxyName === '' ||
                (
                    (patient.proxy_first_name ?? '').toLowerCase() +
                    ' ' +
                    (patient.proxy_last_name ?? '').toLowerCase()
                ).indexOf(data?.proxyName?.toLowerCase()) > -1;
            // check email
            const emailFlag =
                !data.email ||
                data.email === '' ||
                (patient.email || '').toLowerCase().indexOf(data.email.toLowerCase()) > -1;

            // check dob
            const dobFlag =
                !data.dob ||
                data.dob === '' ||
                (patient.date_of_birth && patient.date_of_birth.substring(0, 10) === data.dob);

            // check modified
            const modifiedFlag =
                !data.modified ||
                data.modified === '' ||
                patient.modified.indexOf(data.modified) > -1;

            // check created
            const createdFlag =
                !data.created || data.created === '' || patient.created.indexOf(data.created) > -1;

            // check extID
            const extIDFlag =
                !data.externalID ||
                data.externalID === '' ||
                (patient.external_identifier || '')
                    .toString()
                    .startsWith((data.externalID || '').toString());

            // check intID
            const intIDFlag =
                !data.id ||
                data.id === '' ||
                (patient.id || '').toString().startsWith((data.id || '').toString());

            const ageRangeFlag =
                (!data.minAge && !data.maxAge) ||
                ((data.minAge === '' || patient.age >= data.minAge) &&
                    (data.maxAge === '' || patient.age <= data.maxAge));

            const siteFlag =
                !data.site_ID ||
                data.site_ID === '' ||
                patient.site_ID.toLowerCase().includes(data.site_ID.toLowerCase());

            const typeFlag =
                !data.type ||
                data.type === '' ||
                patient.type.toLowerCase().includes(data.type.toLowerCase());

            return (
                stateFlag &&
                statusFlag &&
                nameFlag &&
                proxyNameFlag &&
                emailFlag &&
                dobFlag &&
                createdFlag &&
                modifiedFlag &&
                extIDFlag &&
                intIDFlag &&
                ageRangeFlag &&
                siteFlag &&
                typeFlag &&
                excludeFromReportingFlag
            );
        });
        this.FilteredData.emit(this.filteredData);
    }

    filterPatientsAdvanced() {
        this.patientService
            .filterPatientsByAnswer(
                this.filterForm.controls.surveyQuestionFilter.value,
                this.filterForm.controls.surveyQuestionAnswerFilter.value
            )
            .subscribe((resp) => {
                let trimFilter = resp.map((pat) => pat.patient_ID);
                this.filteredData = this.data.filter((p) => trimFilter.includes(p.id));
                this.FilteredData.emit(this.filteredData);
            });
    }
}
