import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService, EnvType } from '@h20-services/common.service';
import { AttributeService, AttributeDef } from '@h20-services/attribute.service';
import { environment as env } from '@environment/environment';
@Component({
    selector: 'app-attribute-def',
    templateUrl: './attribute-def.component.html',
    styleUrls: ['./attribute-def.component.scss'],
})
export class AttributeDefComponent implements OnInit {
    constructor(
        private attr: AttributeService,
        private fb: FormBuilder,
        private commonSvc: CommonService
    ) {}

    loading: boolean = false;
    message: string = '';

    attributeTypes: string[] = ['text', 'number', 'date'];

    attribute: AttributeDef = new AttributeDef();
    attributes = [];

    envs = this.commonSvc.listTargetEnvs();
    defaultTargetEnv = env.development ? EnvType.Development : EnvType.Devops;

    getAttributeForm: FormGroup = this.fb.group({
        targetEnv: [this.defaultTargetEnv],
        attribute: [this.attribute],
    });

    ngOnInit(): void {
        this.getAttributes();
    }

    getAttributes() {
        this.attributes = [];

        this.attr
            .getUserAttributeDefinitions(this.getAttributeForm.value.targetEnv)
            .subscribe((resp: any) => {
                resp.forEach((pAry: any) => {
                    this.attributes.push(AttributeDef.buildAttribute(pAry));
                });
            });
    }

    attributeSelected(id: string): void {
        if (id == '') this.clearAttributeForm();
        this.attribute = this.attributes.find((attr) => attr.id === id);
        this.assignment_test_result = '';
    }

    valueChanged(field: string, value: string): void {
        this.attribute[field] = value;
    }

    saveUserAttributeDefinition() {
        this.attr
            .saveUserAttributeDefinition(
                this.getAttributeForm.value.targetEnv,
                this.attribute.toJson()
            )
            .subscribe((resp: any) => {
                if (resp.dangerous) resp.fields.forEach((field) => alert(field));
            });
    }

    clearAttributeForm() {
        this.attribute = new AttributeDef();
        this.assignment_test_result = '';

        this.getAttributeForm = this.fb.group({
            targetEnv: [this.getAttributeForm.value.targetEnv],
            attribute: [new AttributeDef()],
        });
    }

    /* For testing the attribute rules */
    userId: string = '';
    assignment_test_result: string = '';

    userIdChanged(value: string) {
        this.userId = value;
    }

    evaluateUserAttribute() {
        this.attr
            .evaluateUserAttribute(
                this.getAttributeForm.value.targetEnv,
                this.userId,
                this.attribute.id
            )
            .subscribe((resp: any) => {
                this.assignment_test_result = resp.result;
            });
    }

    setUserAttribute() {
        this.attr
            .setUserAttribute(this.getAttributeForm.value.targetEnv, this.userId, this.attribute.id)
            .subscribe((resp: any) => {});
    }
}
