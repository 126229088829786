import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { isUndefined } from 'underscore';

export enum ClauseLocation {
    BEFORE,
    AFTER,
}

export enum LogicNodeType {
    LOGIC,
    EXPRESSION,
}

export abstract class LogicTreeNode {
    // ABSTRACT class
    id: number;
    type: LogicNodeType;

    parent: LogicTreeNode;
    left: LogicTreeNode;
    right: LogicTreeNode;

    isRoot(): boolean {
        if (this.parent == null) return true;
        return false;
    }
    isLeftChild(): boolean {
        return this.parent.left.id === this.id;
    }
    abstract isValid(): boolean;
    abstract toString(): string;
}

export class LogicNode extends LogicTreeNode {
    logicControl;

    constructor(id, parent, logicControl) {
        super();
        this.id = id;
        this.parent = parent;
        this.logicControl = logicControl;
        this.type = LogicNodeType.LOGIC;
    }

    toString(): string {
        return this.left.toString() + ' ' + this.logicControl.value + ' ' + this.right.toString();
    }

    isValid(): boolean {
        return (
            this.logicControl.valid &&
            !this.logicControl.errors &&
            this.left &&
            this.left.isValid() &&
            this.right &&
            this.right.isValid()
        );
    }
}

export class ExpressionNode extends LogicTreeNode {
    //the element control is the dropdown of which element 
    //   (reference by the control's 'variable name') 
    //   this clause is based on
    elementControl : AbstractControl;
    //the compare operator control
    operatorControl :AbstractControl;
    //the value to use in the comparison 
    //   - typcially a modified version of the control you would normally see in the form
    valueControl :AbstractControl;
    options;

    constructor(id, parent, elementControl:AbstractControl, operatorControl:AbstractControl, valueControl: AbstractControl) {
        super();
        this.id = id;
        this.parent = parent;
        this.type = LogicNodeType.EXPRESSION;
        this.elementControl = elementControl;
        this.operatorControl = operatorControl;
        this.valueControl = valueControl;

        // we have the type
        const type = this.elementControl?.value?.type
        // handle any special cases of value 'stringifies' - so far this works for all
        if (type ) {
            let v = this.valueControl.value;
            this.valueControl.patchValue(parseValueControl(elementControl,v) )
        }
        // the visibleIf impelmentation cannot handle the array it seems
        // using single select you can combine options by using the and/or 
        // to add more options
        if(type === 'checkbox'){
            this.options = {'singleSelect': true}
        }
    }

    /**
     * The variable name of the control is surrounded by {}
     * 
     * @returns a string of the expression with the variable name {} quoted
     */
    toString(): string {
        return (
            '{' +
            this.elementControl.value.name +
            '} ' +
            this.operatorControl.value +
            ' ' +
            stringifyValueControl(this.valueControl, this.elementControl.value.type)
        );
    }


    isValid(): boolean {
        return this.elementControl.valid && this.operatorControl.valid && this.valueControl.valid;
    }
}

function stringifyValueControl(valueControl: AbstractControl, type: string): string {
    if (!valueControl) {
        return ''
    }
    
    // handle any special cases of value 'stringifies'
    if (type && type === 'checkbox') {
        let toReturn = '[';
        let first = true;
        //while the control right now is limited to one value - it would be handy to have 'in' comparison at some point
        // value has the form:
        // {  choiceValue1 : true/false,  choiceValue2 : true/false }
        for (let k in valueControl.value) {
            if (valueControl.value[k]) {
                if (first)
                    first = false;
                else
                    toReturn += ',';
                toReturn += k;
            }
        }
        return toReturn + ']';
    }

    return valueControl.value;
}

function parseValueControl(elementControl: any, valueControlValueString: string): any {
    let type = elementControl.value.type;
    if (!valueControlValueString)
        return null;
    //handle special cases - this creates the value from an array of checkbox values
    // to create the format expected for checkboxes.
    if (type === 'checkbox') {
        let choices = elementControl.value.choices;
        let valueArray = JSON.parse(valueControlValueString);
        let value = {};
        for (let c in choices){
            //think there are some GUID values so don't want to assume int
            let val = JSON.parse(choices[c].value);
            value[val] = valueArray.includes(val);
        }
        return value;
    }

    //this will type the numbers correclty
    return JSON.parse(valueControlValueString);
}

export class LogicFormTree {
    root: LogicTreeNode = null;
    maxId: number = 0;
    parentList: any[];
    logicForm: FormGroup;

    DEFAULT_COMPARE = '=';
    DEFAULT_LOGIC = 'and';

    constructor(treeString: string, parentList) {
        this.logicForm = new FormGroup([]);
        this.parentList = parentList;
        this.root = this.parseTreeStringRec(treeString, null);
    }

    getControlId(name: string, id: number): string {
        return name + '_' + id;
    }

    nextId(): number {
        this.maxId++;
        return this.maxId;
    }

    toString(): string {
        if (!this.root)
            return null
        return this.root.toString();
    }

    isEmpty() {
        return this.root == null;
    }

    parseTreeStringRec(treeString: string, parent: any): any {
        //returns the logic tree branch
        //adds controls to the form
        if (isUndefined(treeString) || !treeString) return null;
        //need to search outside of {} which are the variable name quote characters
        const andRegex = /and(?![^{]*})/g;
        const orRegex = /or(?![^{]*})/g;
        let nextId = this.nextId();
        // and operator
        if (treeString.search(andRegex) > -1) {
            let split = treeString.search(andRegex);
            let lhs = treeString.substring(0, split).trim();
            let rhs = treeString.substring(split + 3).trim();

            // create the control
            this.logicForm.addControl(this.getControlId('logic', nextId), new FormControl('and'));
            // create new node
            let toReturn = new LogicNode(
                nextId,
                parent,
                this.logicForm.controls[this.getControlId('logic', nextId)]
            );

            toReturn.left = this.parseTreeStringRec(lhs, toReturn);
            toReturn.right = this.parseTreeStringRec(rhs, toReturn);

            return toReturn;
        }
        // or operator
        else if (treeString.search(orRegex) > -1) {
            let split = treeString.search(orRegex);
            let lhs = treeString.substring(0, split).trim();
            let rhs = treeString.substring(split + 2).trim();

            // create the control
            this.logicForm.addControl(this.getControlId('logic', nextId), new FormControl('and'));
            // create new node
            let toReturn = new LogicNode(
                nextId,
                parent,
                this.logicForm.controls[this.getControlId('logic', nextId)]
            );

            toReturn.left = this.parseTreeStringRec(lhs, toReturn);
            toReturn.right = this.parseTreeStringRec(rhs, toReturn);

            return toReturn;
        } else {
            // leaf case
            let elementName = null;
            let compareOperator = null;
            let value = null;

            if (treeString.search('!=') > -1) {
                let split = treeString.search('!=');
                elementName = treeString
                    .substring(0, split)
                    .trim()
                    .replace('{', '')
                    .replace('}', '');
                compareOperator = '!=';
                value = treeString.substring(split + 2).trim();
            } else if (treeString.search('=') > -1) {
                let split = treeString.search('=');
                elementName = treeString
                    .substring(0, split)
                    .trim()
                    .replace('{', '')
                    .replace('}', '');
                compareOperator = '=';
                value = treeString.substring(split + 2).trim();
            }
            let element = this.parentList.find((item) => item.name === elementName);
            //create controls
            this.addTestToForm(nextId, element, compareOperator, value);

            // create node
            let leafValue = new ExpressionNode(
                nextId,
                parent,
                this.logicForm.controls[this.getControlId('element', nextId)],
                this.logicForm.controls[this.getControlId('compareOperator', nextId)],
                this.logicForm.controls[this.getControlId('value', nextId)]
            );

            return leafValue;
        }
    }

    addTestToForm(nodeId, element, compareOperator, value) {
        this.logicForm.addControl(
            this.getControlId('element', nodeId),
            new FormControl(element, Validators.required)
        );
        this.logicForm.addControl(
            this.getControlId('compareOperator', nodeId),
            new FormControl(compareOperator, Validators.required)
        );
        this.logicForm.addControl(
            this.getControlId('value', nodeId),
            new FormControl(value, Validators.required)
        );
    }

    addLogicToForm(nodeId, value) {
        this.logicForm.addControl(
            this.getControlId('logic', nodeId),
            new FormControl(value, Validators.required)
        );
    }

    /**
     * To start the form we add a test statement first to create
     * the initial expression node
     */
    addFirstTest() {
        //make the empty comparison
        let newTestId = this.nextId();
        this.addTestToForm(newTestId, null, this.DEFAULT_COMPARE, null);
        // create node
        let newTest = new ExpressionNode(
            newTestId,
            null,
            this.logicForm.controls[this.getControlId('element', newTestId)],
            this.logicForm.controls[this.getControlId('compareOperator', newTestId)],
            this.logicForm.controls[this.getControlId('value', newTestId)]
        );

        this.root = newTest;
    }
    /**
     * Add a test to the overall logical statement by inserting a new
     * test and logical operator either before or after the existing node (param)
     *
     * @param location ClauseLocation can be before the existing clause or after
     * @param node The node we are adding to in the statement - either before or after in the resulting logical statement
     */
    addTestWithLogic(location: ClauseLocation, node: LogicTreeNode) {
        //cannot be run unless we already have a node
        if (!node) return;

        //make the empty second comparison
        let newTestId = this.nextId();
        this.addTestToForm(newTestId, null, this.DEFAULT_COMPARE, null);
        // create node
        let newTest = new ExpressionNode(
            newTestId,
            null,
            this.logicForm.controls[this.getControlId('element', newTestId)],
            this.logicForm.controls[this.getControlId('compareOperator', newTestId)],
            this.logicForm.controls[this.getControlId('value', newTestId)]
        );

        //setup the logicOperator - defaults to and
        let newLogicId = this.nextId();

        //add the logicOperator control
        this.addLogicToForm(newLogicId, this.DEFAULT_LOGIC);
        // create new node
        let newLogicOperator = new LogicNode(
            newLogicId,
            null,
            this.logicForm.controls[this.getControlId('logic', newLogicId)]
        );
        //add the children
        if (location === ClauseLocation.BEFORE) {
            newLogicOperator.left = newTest;
            newLogicOperator.right = node;
        } else {
            newLogicOperator.left = node;
            newLogicOperator.right = newTest;
        }
        //update the parentNode's new child
        //if we are at the root (no parent)
        if (node.isRoot()) {
            this.root = newLogicOperator;
        } else if (node.isLeftChild()) {
            node.parent.left = newLogicOperator;
        } else {
            node.parent.right = newLogicOperator;
        }
        //setup new grandparent
        newLogicOperator.parent = node.parent;
        //update parents for new siblings
        node.parent = newLogicOperator;
        newTest.parent = newLogicOperator;
    }

    removeClause(node: any) {
        //at the root
        if (node.id == this.root.id) {
            this.clearTree();
        } else {
            //parent must be a logical operator
            //if the parent was the root
            if (node.parent.id == this.root.id) {
                //if I'm the left child
                if (node.parent.left.id == node.id) {
                    node.parent.right.parent = this.root;
                    this.root = node.parent.right;
                } else {
                    node.parent.left.parent = this.root;
                    this.root = node.parent.left;
                }
            }
            //otherwise the parent was the root
            else {
                let newChild = {};
                if (node.parent.left.id == node.id) {
                    newChild = node.parent.right;
                } else newChild = node.parent.left;
                let grandParent = node.parent.parent;
                if (grandParent.left.id == node.parent.id) {
                    grandParent.left = newChild;
                } else {
                    grandParent.right = newChild;
                }
            }
            //remove the logical operator from the form
            this.logicForm.removeControl(this.getControlId('logic', node.parent.id));
        }
        //remove my controls
        this.logicForm.removeControl(this.getControlId('element', node.id));
        this.logicForm.removeControl(this.getControlId('compareOperator', node.id));
        this.logicForm.removeControl(this.getControlId('value', node.id));
    }

    clearTree() {
        this.root = null;
    }

    isValid() {
        this.logicForm.markAllAsTouched();
        if (!this.root)
            return true
        return this.root.isValid();
    }
}
