<div *ngIf="surveyUnderConstruction">
    <div class="w-100">
        <div class="nav nav-tabs">
            <div class="nav-item">
                <a
                    href="#nav-designer"
                    aria-controls="nav-designer"
                    class="nav-link active"
                    id="nav-design-tab"
                    data-bs-toggle="tab"
                    role="tab"
                    aria-selected="true"
                    (click)="clickDesign()"
                >
                    Design</a
                >
            </div>
            <div class="nav-item">
                <a
                    href="#nav-test"
                    aria-controls="nav-test"
                    class="nav-link"
                    id="nav-test-tab"
                    data-bs-toggle="tab"
                    role="tab"
                    aria-selected="false"
                    (click)="clickTest()"
                    >Test</a
                >
            </div>
            <div class="nav-item" *ngIf="userClaims && userClaims.h20ComposerAdmin">
                <a
                    href="#nav-json"
                    aria-controls="nav-json"
                    class="nav-link"
                    id="nav-json-tab"
                    data-bs-toggle="tab"
                    role="tab"
                    aria-selected="false"
                    (click)="clickJsonEditor()"
                    >Edit Json</a
                >
            </div>
            <div class="ms-auto">
                <div class="d-flex">
                    <app-button
                        class="mx-3"
                        (action)="backToSurveys()"
                        [buttonText]="'Registry.BackToSurveys'"
                    />
                    <app-button
                        [disabled]="loading"
                        (action)="saveSurvey()"
                        [buttonText]="'Registry.SaveSurvey'"
                    />
                    <div *ngIf="saveSurveyMessage" class="text-muted">
                        {{ saveSurveyMessage }}
                    </div>
                </div>
            </div>
            <div class="ms-auto">
                <h5>{{ getText(surveyUnderConstruction.title) }}</h5>
            </div>
        </div>

        <div class="tab-content" id="nav-tabContent">
            <div
                class="tab-pane active show"
                id="nav-designer"
                role="tabpanel"
                aria-labelledby="nav-designer-tab"
            >
                <app-survey-designer
                    *mcRerender="update"
                    [survey]="surveyUnderConstruction"
                ></app-survey-designer>
            </div>
            <div class="tab-pane" id="nav-test" role="tabpanel" aria-labelledby="nav-test-tab">
                <app-survey-tester
                    *mcRerender="update"
                    [survey]="surveyUnderConstruction"
                ></app-survey-tester>
            </div>
            <div class="tab-pane" id="nav-json" role="tabpanel" aria-labelledby="nav-json-tab">
                <json-editor
                    [options]="jsonEditorOptions"
                    [data]="jsonSurveyUnderConstruction"
                    (change)="updateJsonEditorSurvey($event)"
                ></json-editor>
            </div>
        </div>
    </div>
</div>
