<div *ngIf="mode === 'view'; else formModeTemplate">
    <div *ngIf="!disabled" class="pi-control-view-mode">
        <app-pi-form-view-response
            [element]="element"
            [theFormControl]="formControl"
            [displayValue]="stringValue"
        ></app-pi-form-view-response>
    </div>
</div>
<ng-template #formModeTemplate
    ><div *ngIf="!disabled" class="row pi-control-form-mode">
        <app-element-title [element]="element"></app-element-title>

        <div class="w-100">
            <div class="html-element" [innerHTML]="element.html"></div>
            <div class="row align-items-center w-100">
                <div class="py-2 col-12 d-flex justify-content-center">
                    <ng-container
                        *ngFor="let choice of controlValues; let first = first; let last = last"
                    >
                        <label
                            class="rating-choice flex-grow-1 text-center"
                            [class.active]="rateValue === choice"
                        >
                            <input
                                type="radio"
                                [value]="choice"
                                [name]="elementUID"
                                [attr.aria-label]="choice"
                                (click)="selectionMade(choice)"
                            />
                            <span>{{ choice }}</span>
                        </label>
                    </ng-container>
                </div>

                <div *ngIf="minRateDescription" class="min-rate-description col-6 text-start">
                    {{ getText(minRateDescription) }}
                </div>
                <div *ngIf="maxRateDescription" class="max-rate-description col-6 text-end">
                    {{ getText(maxRateDescription) }}
                </div>
            </div>

            <app-validator
                [control]="formControl"
                [customErrorMessages]="customErrorMessages"
            ></app-validator>
        </div></div
></ng-template>
