<div class="bg-white p-3">
    <div class="d-flex">
        <div class="border-end">
            <ul class="nav flex-column nav-pills nav-justified">
                <li *ngFor="let panel of surveyPanels; let i = index" class="nav-item my-1">
                    <a
                        href="{{ '#nav-' + panel.name }}"
                        attr.aria-controls="{{ '#nav-' + panel.name }}"
                        class="nav-link panel-nav text-truncate border border-1"
                        [ngClass]="i === 0 ? 'active' : ''"
                        id="{{ 'nav-' + panel.name + '-tab' }}"
                        data-bs-toggle="tab"
                        role="tab"
                        aria-selected="true"
                    >
                        #{{ i + 1 }} {{ getText(panel.title) }}</a
                    >
                </li>

                <button class="btn btn-outline-primary" title="Add a panel" (click)="addPanel()">
                    <div class="fs-6 fas fa-regular fa-plus"></div>
                </button>
            </ul>
        </div>
        <div class="w-100">
            <div class="tab-content w-100" id="nav-tabContent">
                <ng-container *ngFor="let panel of surveyPanels; let i = index">
                    <div
                        class="tab-pane w-100"
                        [ngClass]="i === 0 ? 'active show' : ''"
                        id="{{ 'nav-' + panel.name }}"
                        role="tabpanel"
                        attr.aria-labelledby="{{ '#nav-' + panel.name + '-tab' }}"
                    >
                        <app-panel-designer
                            [panel]="panel"
                        >
                        </app-panel-designer>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="row px-2">
        <div class="accordion accordion-flush" id="formAccordion">
            <div class="accordion-item border-0">
                <div class="accordion-header" id="formAccordion-heading">
                    <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#formAccordion-collapse"
                        aria-expanded="false"
                        aria-controls="formAccordion-collapse"
                    >
                        <div class="w-100 d-flex justify-content-between align-items-center">
                            <h4>{{ getText(survey.title) }}</h4>
                            <div class="pe-3">Edit Form Details</div>
                        </div>
                    </button>
                </div>
                <div
                    id="formAccordion-collapse"
                    class="accordion-collapse collapse"
                    aria-labelledby="formAccordion-heading"
                    data-bs-parent="#formAccordion"
                >
                    <div class="accordion-body">
                        <app-pi-form
                            *ngIf="formForm"
                            [formData]="survey"
                            [formDef]="formForm"
                            layout="flat"
                            class="w-100"
                            (formChanges)="onFormChanges($event)"
                        ></app-pi-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
