import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfigService } from '@h20-services/config.service';
import { DDBEmailTemplates } from '@h20-services/ddb-email.service';
import { SurveyService } from '@h20-services/survey.service';
import { TaskService } from '@h20-services/task.service';
import * as XLSX from 'xlsx';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-translation-import',
    templateUrl: './translation-import.component.html',
    styleUrls: ['./translation-import.component.scss'],
})
export class TranslationImportComponent implements OnInit, OnDestroy {
    //#region Fields

    subscriptions: Subscription[] = [];

    loading: boolean = false;
    loadingTasks: boolean;
    loadingSES: boolean;
    loadingRegConfig: boolean;
    loadingSurveys: boolean;
    loadingMatches: boolean;

    uploadedFile: File;
    workbook: XLSX.WorkBook;
    sheetSelection = {};
    allowedType =
        this.translate.store.translations[this.translate.currentLang]['Surveys'].AllowedFileTypes;
    notAllowType =
        this.translate.store.translations[this.translate.currentLang]['Surveys']
            .NotAllowedFileTypes;
    sheetData = {};
    surveyIdsList: any[] = [];
    taskList: any[];
    sesTemplateItems: any[];
    registryConfigItems: any[];
    surveyList: any[];
    sheetItems = ['Tasks', 'Emails', 'Config'];
    disableReadFile: boolean = true;
    warningMessage = '';
    //#endregion

    constructor(
        private task_svc: TaskService,
        private ddb_email_svc: DDBEmailTemplates,
        private config_svc: ConfigService,
        private survey_svc: SurveyService,
        public translate: TranslateService
    ) {}

    ngOnDestroy(): void {
        this.subscriptions.map((sub) => {
            sub.unsubscribe();
        });
    }

    ngOnInit(): void {
        this.loadTasks();
        this.loadSESTemplates();
        this.loadRegConfig();
        this.loadSurveys();
        this.warningMessage = this.allowedType;
    }

    doneLoading() {
        this.loading =
            this.loadingRegConfig || this.loadingSES || this.loadingTasks || this.loadingSurveys;
    }

    loadTasks(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.loadingTasks = true;
            const taskProtoSub$ = this.task_svc.listTaskPrototypes().subscribe((tasks: any) => {
                this.taskList = tasks;
                this.taskList.map((task) => {
                    task.message = this.tryparsejson(task.message);
                });

                this.loadingTasks = false;
                this.doneLoading();
                resolve(this.taskList);
            });
            this.subscriptions.push(taskProtoSub$);
        });
    }

    loadSESTemplates(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.loadingSES = true;
            const sesTemplateSub$ = this.ddb_email_svc
                .listTemplates()
                .subscribe((templates: any) => {
                    if (templates) {
                        this.sesTemplateItems = templates;

                        this.loadingSES = false;
                        this.doneLoading();
                        resolve(this.sesTemplateItems);
                    }
                });
            this.subscriptions.push(sesTemplateSub$);
        });
    }

    loadRegConfig(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.loadingRegConfig = true;
            // Load registry_config Data
            const regSub$ = this.config_svc.listRegistryConfig().subscribe((registries) => {
                if (registries) {
                    this.registryConfigItems = registries;
                }
                this.loadingRegConfig = false;
                this.doneLoading();
                resolve(this.registryConfigItems);
            });
            this.subscriptions.push(regSub$);
        });
    }

    loadSurveys(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.loadingSurveys = true;
            const surveysSub$ = this.survey_svc.getSurveys().subscribe((surveys: any) => {
                this.surveyList = surveys;
                this.surveyList.map((i) => {
                    i.json = this.tryparsejson(i.json);
                });

                this.loadingSurveys = false;
                this.doneLoading();
                resolve(this.surveyList);
            });
            this.subscriptions.push(surveysSub$);
        });
    }

    //#region UI Functions

    changeFile(file: FileList) {
        let allowedExtensions = ['cvs', 'xlsx', 'xls'];
        let fileName = file[0].name.split('.');
        let fileExtension = fileName[fileName.length - 1];

        if (!allowedExtensions.includes(fileExtension)) {
            this.workbook = null;
            this.uploadedFile = null;
            this.disableReadFile = true;
            this.warningMessage = this.notAllowType;
            document.getElementById('warningMessage').style.color = 'red';

            return;
        }
        this.uploadedFile = file.item(0);
        this.disableReadFile = false;
    }

    clickFileButtom() {
        document.getElementById('warningMessage').style.color = 'black';
        this.warningMessage = this.allowedType;
    }

    submitFile() {
        if (!this.uploadedFile) {
            alert(
                'Sorry, this file type is not permitted. Only files with the following extensions are allowed: .xls, .xlsx, .csv'
            );
            return;
        }
        this.loading = true;
        this.uploadedFile.arrayBuffer().then((buff) => {
            this.workbook = XLSX.read(buff, { type: 'binary' });
            this.workbook.SheetNames.map((sheet) => {
                this.sheetSelection[sheet] = false;
            });

            this.sheetData = this.buildSheetData();

            this.loading = false;
            Object.values(this.sheetData).map((item) => {
                this.surveyIdsList.push(item['name']);
            });

            this.surveyIdsList = [...new Set(this.surveyIdsList)]; //Remove duplicates from an array

            this.survey_svc.getSurveysByIds(this.surveyIdsList).subscribe((response) => {
                this.surveyList.map((item) => {
                    response.map((res) => {
                        if (item.id == res.id) {
                            item.json = this.tryparsejson(res.json);
                        }
                    });
                });
            });
        });
    }

    changeSheet(item, checked) {
        this.sheetSelection[item] = checked;
    }

    buildSheetData() {
        let table = {};
        this.workbook.SheetNames.map((sheet) => {
            table[sheet] = this.parseSheetJson(
                XLSX.utils.sheet_to_json(this.workbook.Sheets[sheet])
            );
            table[sheet]['name'] = this.workbook.Sheets[sheet].A1.v;
        });
        return table;
    }

    tryMatch() {
        this.loadingMatches = true;
        Promise.all(
            Object.keys(this.sheetData).map((k) => {
                return new Promise((resolve, reject) => {
                    // console.log('k', k);
                    // console.log('this.sheetData[k]', this.sheetData[k]);

                    const match_key = 'matched';
                    this.sheetData[k].headers.push(match_key);
                    this.sheetData[k].headers = [...new Set(this.sheetData[k].headers)];

                    switch (k) {
                        case 'Tasks':
                            this.sheetData[k].data.map((task) => {
                                let db_task = this.taskList.find((t) => t.id == task.id);
                                task[match_key] = db_task ? true : false;

                                if (db_task) {
                                    task['label-before'] = db_task.label;

                                    this.sheetData[k].headers.map((head) => {
                                        if (
                                            head == 'default' &&
                                            typeof db_task.message == 'string'
                                        ) {
                                            task[head + '-before'] = db_task.message;
                                        } else if (
                                            !['id', 'label', match_key].includes(head) &&
                                            typeof db_task.message == 'string'
                                        ) {
                                            task[head + '-new'] = task[head];
                                        } else {
                                            if (
                                                typeof db_task.message == 'object' &&
                                                db_task.message.hasOwnProperty(head)
                                            )
                                                task[head + '-before'] = db_task.message[head];
                                        }
                                    });
                                    resolve(k);
                                }
                            });
                            break;

                        case 'Email':
                            this.sheetData[k].data.map((template) => {
                                let db_template = this.sesTemplateItems.find(
                                    (t) => t.Name == template.TemplateName
                                );
                                template[match_key] = db_template ? true : false;

                                if (db_template) {
                                    this.ddb_email_svc
                                        .getTemplate(template.TemplateName)
                                        .toPromise()
                                        .then((temp: any) => {
                                            Object.keys(template).map((k) => {
                                                template[k + '-before'] = temp.Template[k];
                                            });
                                            resolve(k);
                                        });
                                }
                            });
                            break;

                        case 'Config':
                            this.sheetData[k].data.map((configItem) => {
                                let split_key = configItem.key.split('.');
                                let db_config = this.registryConfigItems.find(
                                    (reg) => reg.registry_id == split_key[0]
                                );
                                db_config.library = this.tryparsejson(db_config.library);

                                if (db_config) {
                                    try {
                                        split_key.splice(0, 1);
                                        let item = this.moveLaterally(db_config, split_key);
                                        configItem[match_key] = true;

                                        Object.keys(configItem).map((k) => {
                                            if (['key', match_key].includes(k)) return;
                                            if (
                                                typeof item == 'object' &&
                                                item.hasOwnProperty(k) &&
                                                configItem[k]
                                            )
                                                configItem[k + '-before'] = item[k];
                                            else if (typeof item == 'string' && k == 'default')
                                                configItem[k + '-before'] = item;
                                            else if (
                                                typeof item == 'object' &&
                                                !item.hasOwnProperty(k) &&
                                                configItem[k]
                                            )
                                                configItem[k + '-new'] = configItem[k];
                                        });
                                    } catch (err) {
                                        // console.error(err);
                                        configItem[match_key] = false;
                                    }
                                    resolve(k);
                                }
                            });
                            break;

                        default:
                            // Assume non-matching cases are surveys
                            this.sheetData[k].data.map((surveyItem) => {
                                let survey = this.surveyList.find(
                                    (s) => s.id == this.sheetData[k].name
                                );
                                if (!survey) surveyItem[match_key] = false;
                                else {
                                    let db_item = this.mapSurveyKeyToItem(survey, surveyItem.key);

                                    surveyItem[match_key] = db_item ? true : false;

                                    if (db_item) {
                                        this.sheetData[k].headers.map((head) => {
                                            let item =
                                                db_item.title ||
                                                db_item.text ||
                                                db_item.html ||
                                                db_item;
                                            if (
                                                item &&
                                                typeof item == 'object' &&
                                                item.hasOwnProperty(head)
                                            )
                                                surveyItem[head + '-before'] = item[head];
                                            else if (
                                                item &&
                                                !['key', match_key].includes(head) &&
                                                typeof item == 'object' &&
                                                !item.hasOwnProperty(head) &&
                                                surveyItem[head]
                                            ) {
                                                surveyItem[head + '-new'] = surveyItem[head];
                                            } else if (
                                                item &&
                                                typeof item == 'string' &&
                                                head == 'default'
                                            ) {
                                                surveyItem[head + '-before'] = item;
                                            } else if (
                                                item &&
                                                typeof item == 'string' &&
                                                !['key', match_key].includes(head) &&
                                                surveyItem[head]
                                            ) {
                                                surveyItem[head + '-new'] = surveyItem[head];
                                            }
                                        });
                                        resolve(k);
                                    }
                                }
                            });
                            break;
                    }
                });
            })
        ).then((res) => {
            this.loadingMatches = false;
        });
    }

    importAll() {
        // const sheetItems = ['Tasks', 'Email Templates', 'Config'];
        let requests = [];
        if (this.sheetData.hasOwnProperty(this.sheetItems[0])) {
            this.sheetData[this.sheetItems[0]].data.map((task) => {
                let db_task = this.taskList.find((t) => t.id == task.id);
                if (db_task) {
                    requests.push(this.importTask(db_task, task));
                }
            });
        }

        if (this.sheetData.hasOwnProperty(this.sheetItems[1])) {
            this.sheetData[this.sheetItems[1]].data.map((template) => {
                let db_template = this.sesTemplateItems.find(
                    (t) => t.Name == template.TemplateName
                );

                if (db_template) {
                    this.ddb_email_svc
                        .getTemplate(db_template.Name)
                        .toPromise()
                        .then((sesRes: any) => {
                            requests.push(this.importEmailTemplate(sesRes.Template, template));
                        });
                }
            });
        }

        if (this.sheetData.hasOwnProperty(this.sheetItems[2])) {
            let transformRegistry = {};
            this.sheetData[this.sheetItems[2]].data.map((configItem) => {
                let regKey = configItem.key.split('.')[0];
                if (transformRegistry.hasOwnProperty(regKey)) {
                    transformRegistry[regKey].push(configItem);
                } else {
                    transformRegistry[regKey] = [configItem];
                }
            });
            Object.keys(transformRegistry).map((regName) => {
                let db_config = this.registryConfigItems.find((reg) => reg.registry_id == regName);

                if (db_config)
                    requests.push(this.importRegistryConfig(db_config, transformRegistry[regName]));
                else console.error('failed to find matching reg key');
            });
        }

        Object.keys(this.sheetData)
            .filter((k) => !this.sheetItems.includes(k))
            .map((k) => {
                let survey = this.surveyList.find((s) => s.id == this.sheetData[k].name);
                if (survey) {
                    requests.push(this.importSurvey(survey, k));
                }
            });

        Promise.all(requests).then((res) => {});
    }

    //#endregion

    //#region Utils

    parseSheetJson(data) {
        let headers = [];
        let keys = Object.keys(data[0]);
        keys.map((k) => {
            headers.push(data[0][k]);
        });

        let sData = [];
        data.splice(0, 1);
        data.map((dat) => {
            let item = {};
            keys.map((k, idx) => {
                item[headers[idx]] = dat[k];
            });
            sData.push(item);
        });
        return { headers: headers, data: sData };
    }

    tryparsejson(item) {
        try {
            return JSON.parse(item);
        } catch (err) {
            return item;
        }
    }

    hasDiff(a, b) {
        return a != b;
    }

    mapSurveyKeyToItem(survey: any, itemKey: string) {
        let db_item;
        survey.json.pages.map((page) => {
            if ('pages-title' == itemKey) {
                if (!db_item) db_item = page.title;
            }
            page.elements.map((panels) => {
                if (panels.name == itemKey) {
                    if (!db_item) db_item = panels.title;
                }

                panels.elements.map((element) => {
                    if (!db_item) db_item = this.lookupSurveyElementItem(element, itemKey);
                });
            });
        });
        return db_item;
    }

    mapSurveyKeyToItem2(survey: any, itemKey: string, sheetKey: string) {
        let db_item;
        survey.json.pages.map((page) => {
            page.elements.map((panels) => {
                if (panels.title) {
                    let newSurveyValues;
                    Object.keys(this.sheetData)
                        .filter((k) => !this.sheetItems.includes(k))
                        .map((k) => {
                            if (sheetKey == k) {
                                newSurveyValues = this.sheetData[k].data.find(
                                    (sa) => sa.key == panels.name
                                );
                            }
                        });

                    if (newSurveyValues) {
                        let objectArray = this.setDefaultValues(panels.title);
                        Object.entries(newSurveyValues).forEach(([key, value]) => {
                            if (key !== 'key') {
                                objectArray[key] = value;
                            }
                        });
                        panels.title = objectArray;
                    }
                }
                panels.elements.map((element) => {
                    if (!db_item)
                        db_item = this.lookupSurveyElementItem2(element, itemKey, sheetKey);
                });
            });
        });

        if (survey.json.title && itemKey === 'pages-title') {
            let newSurveyValues;
            Object.keys(this.sheetData)
                .filter((k) => !this.sheetItems.includes(k))
                .map((k) => {
                    if (sheetKey == k) {
                        newSurveyValues = this.sheetData[k].data.find((sa) => sa.key == itemKey);
                    }
                });
            if (newSurveyValues) {
                let objectArray = this.setDefaultValues(survey.json.title);
                Object.entries(newSurveyValues).forEach(([key, value]) => {
                    if (key !== 'key') {
                        objectArray[key] = value;
                    }
                });
                survey.json.title = objectArray;
            }
        }
        return db_item;
    }

    setDefaultValues(objectArray) {
        if (
            !objectArray['default'] &&
            !objectArray['en'] &&
            !objectArray['fr'] &&
            !objectArray['es'] &&
            !objectArray['ar'] &&
            !objectArray['hi'] &&
            !objectArray['de'] &&
            !objectArray['it'] &&
            !objectArray['pt'] &&
            !objectArray['nl'] &&
            !objectArray['el'] &&
            !objectArray['ru'] &&
            !objectArray['ja'] &&
            !objectArray['zh-CN'] &&
            !objectArray['zh-TW']
        ) {
            return { default: objectArray };
        } else return objectArray;
    }

    lookupSurveyElementItem2(element, itemkey: string, sheetKey) {
        let db_item;

        if (element.name == itemkey && !element.hasOwnProperty('choices')) {
            db_item = element;
            if (element.type && element.type == 'html') {
                let newSurveyValues;
                Object.keys(this.sheetData)
                    .filter((k) => !this.sheetItems.includes(k))
                    .map((k) => {
                        if (sheetKey == k) {
                            newSurveyValues = this.sheetData[k].data.find(
                                (sa) => sa.key == itemkey
                            );
                        }
                    });

                let objectArray = {};
                if (newSurveyValues) {
                    let objectArray = this.setDefaultValues(element.html);
                    Object.entries(newSurveyValues).forEach(([key, value]) => {
                        if (key !== 'key') {
                            objectArray[key] = value;
                        }
                    });

                    element.html = objectArray;
                }
            } else if (
                (element.type && element.type == 'text') ||
                element.type == 'comment' ||
                element.type == 'rating' ||
                element.type == 'file' ||
                element.type == 'multipletext' ||
                element.type == 'paneldynamic' ||
                element.type == 'radiogroup'
            ) {
                let newSurveyValues;
                Object.keys(this.sheetData)
                    .filter((k) => !this.sheetItems.includes(k))
                    .map((k) => {
                        if (sheetKey == k) {
                            newSurveyValues = this.sheetData[k].data.find(
                                (sa) => sa.key == itemkey
                            );
                        }
                    });

                if (newSurveyValues) {
                    let objectArray = this.setDefaultValues(element.title);
                    Object.entries(newSurveyValues).forEach(([key, value]) => {
                        if (key !== 'key') {
                            objectArray[key] = value;
                        }
                    });

                    element.title = objectArray;
                }
            }
        } else if (element.name == itemkey && element.hasOwnProperty('templateElements')) {
            element.choices.map((choice) => {
                if (choice.value == itemkey.split('-')[1]) {
                    let newSurveyValues;
                    Object.keys(this.sheetData)
                        .filter((k) => !this.sheetItems.includes(k))
                        .map((k) => {
                            if (sheetKey == k) {
                                newSurveyValues = this.sheetData[k].data.find(
                                    (sa) => sa.key == itemkey
                                );
                            }
                        });
                    if (newSurveyValues) {
                        let objectArray = this.setDefaultValues(choice.text);
                        Object.entries(newSurveyValues).forEach(([key, value]) => {
                            if (key !== 'key') {
                                objectArray[key] = value;
                            }
                        });
                        choice.text = objectArray;
                        db_item = choice;
                    }
                }
            });
        } else if (
            itemkey.split('-').length == 2 &&
            element.hasOwnProperty('choices') &&
            element.name == itemkey.split('-')[0]
        ) {
            element.choices.map((choice) => {
                if (choice.value == itemkey.split('-')[1]) {
                    let newSurveyValues;
                    Object.keys(this.sheetData)
                        .filter((k) => !this.sheetItems.includes(k))
                        .map((k) => {
                            if (sheetKey == k) {
                                newSurveyValues = this.sheetData[k].data.find(
                                    (sa) => sa.key == itemkey
                                );
                            }
                        });
                    if (newSurveyValues) {
                        let objectArray = this.setDefaultValues(choice.text);
                        Object.entries(newSurveyValues).forEach(([key, value]) => {
                            if (key !== 'key') {
                                objectArray[key] = value;
                            }
                        });

                        choice.text = objectArray;
                        db_item = choice;
                    }
                }
            });
        } else if (
            itemkey.split('-').length < 2 &&
            element.hasOwnProperty('choices') &&
            element.name == itemkey.split('-')[0]
        ) {
            let newSurveyValues;
            Object.keys(this.sheetData)
                .filter((k) => !this.sheetItems.includes(k))
                .map((k) => {
                    if (sheetKey == k) {
                        newSurveyValues = this.sheetData[k].data.find((sa) => sa.key == itemkey);
                    }
                });

            if (newSurveyValues) {
                let objectArray = this.setDefaultValues(element.title);
                Object.entries(newSurveyValues).forEach(([key, value]) => {
                    if (key !== 'key') {
                        objectArray[key] = value;
                    }
                });
                element.title = objectArray;
            }
        } else if (itemkey.split('-').length >= 2 && element.hasOwnProperty('templateElements')) {
            element.templateElements.map((dyn_element) => {
                if (!db_item)
                    db_item = this.lookupSurveyElementItem(
                        dyn_element,
                        itemkey.substring(itemkey.indexOf('-') + 1)
                    );
            });
            if (db_item?.title) {
                let newSurveyValues;
                Object.keys(this.sheetData)
                    .filter((k) => !this.sheetItems.includes(k))
                    .map((k) => {
                        if (sheetKey == k) {
                            newSurveyValues = this.sheetData[k].data.find(
                                (sa) => sa.key == itemkey
                            );
                        }
                    });

                if (newSurveyValues) {
                    let objectArray = this.setDefaultValues(db_item.title);
                    Object.entries(newSurveyValues).forEach(([key, value]) => {
                        if (key !== 'key') {
                            objectArray[key] = value;
                        }
                    });
                    db_item.title = objectArray;
                }
            }
        }

        if (
            itemkey.split('-').length == 2 &&
            itemkey.split('-')[1] == 'tooltip' &&
            element.name == itemkey.split('-')[0]
        ) {
            let newSurveyValues;
            Object.keys(this.sheetData)
                .filter((k) => !this.sheetItems.includes(k))
                .map((k) => {
                    if (sheetKey == k) {
                        newSurveyValues = this.sheetData[k].data.find((sa) => sa.key == itemkey);
                    }
                });

            if (newSurveyValues) {
                let objectArray = this.setDefaultValues(element.tooltip);
                Object.entries(newSurveyValues).forEach(([key, value]) => {
                    if (key !== 'key') {
                        objectArray[key] = value;
                    }
                });
                element.tooltip = objectArray;
            }
        }

        if (
            itemkey.split('-').length == 2 &&
            element.name == itemkey.split('-')[0] &&
            (itemkey.split('-')[1] == 'minErrorText' ||
                itemkey.split('-')[1] == 'maxErrorText' ||
                itemkey.split('-')[1] == 'requiredErrorText')
        ) {
            let newSurveyValues;
            Object.keys(this.sheetData)
                .filter((k) => !this.sheetItems.includes(k))
                .map((k) => {
                    if (sheetKey == k) {
                        newSurveyValues = this.sheetData[k].data.find((sa) => sa.key == itemkey);
                    }
                });

            if (newSurveyValues) {
                let objectArray = this.setDefaultValues(element[itemkey.split('-')[1]]);
                Object.entries(newSurveyValues).forEach(([key, value]) => {
                    if (key !== 'key') {
                        objectArray[key] = value;
                    }
                });
                element[itemkey.split('-')[1]] = objectArray;
            }
        }

        if (
            itemkey.split('-').length == 2 &&
            itemkey.split('-')[1] == 'validators' &&
            element.name == itemkey.split('-')[0]
        ) {
            let newSurveyValues;
            Object.keys(this.sheetData)
                .filter((k) => !this.sheetItems.includes(k))
                .map((k) => {
                    if (sheetKey == k) {
                        newSurveyValues = this.sheetData[k].data.find((sa) => sa.key == itemkey);
                    }
                });

            if (newSurveyValues) {
                let objectArray = this.setDefaultValues(element.validators[0].text);
                Object.entries(newSurveyValues).forEach(([key, value]) => {
                    if (key !== 'key') {
                        objectArray[key] = value;
                    }
                });
                element.validators[0].text = objectArray;
            }
        }
        return db_item;
    }

    lookupSurveyElementItem(element, itemkey: string) {
        let db_item;
        if (element.name == itemkey) db_item = element;
        else if (
            itemkey.split('-').length == 2 &&
            element.hasOwnProperty('choices') &&
            element.name == itemkey.split('-')[0] &&
            'requiredErrorText' !== itemkey.split('-')[1] &&
            'validators' !== itemkey.split('-')[1] &&
            'minErrorText' !== itemkey.split('-')[1] &&
            'maxErrorText' !== itemkey.split('-')[1]
        ) {
            element.choices.map((choice) => {
                if (choice.value == itemkey.split('-')[1]) db_item = choice;
            });
        } else if (itemkey.split('-').length >= 2 && element.hasOwnProperty('templateElements')) {
            element.templateElements.map((dyn_element) => {
                if (!db_item)
                    db_item = this.lookupSurveyElementItem(
                        dyn_element,
                        itemkey.substring(itemkey.indexOf('-') + 1)
                    );
            });
        } else if (
            itemkey.split('-').length == 2 &&
            element.hasOwnProperty('choices') &&
            'requiredErrorText' == itemkey.split('-')[1] &&
            element.hasOwnProperty('requiredErrorText') &&
            element.name == itemkey.split('-')[0]
        ) {
            db_item = element.requiredErrorText;
        } else if (
            itemkey.split('-').length == 2 &&
            !element.hasOwnProperty('choices') &&
            element.name == itemkey.split('-')[0]
        ) {
            switch (itemkey.split('-')[1]) {
                case 'validators':
                    db_item = element.validators[0].text;
                    break;
                case 'minErrorText':
                    db_item = element.minErrorText;
                    break;
                case 'maxErrorText':
                    db_item = element.maxErrorText;
                    break;
                case 'requiredErrorText':
                    db_item = element.requiredErrorText;
                    break;
                default:
                // code block
            }
        }
        return db_item;
    }

    moveLaterally(db_config, split_key) {
        //Move laterally through the object
        let item = db_config;
        for (let idx = 0; idx < split_key.length; ++idx) {
            item = item[split_key[idx]];
        }
        return item;
    }

    //#endregion

    //#region Import Methods

    importTask(oldTask, newTask): Promise<any> {
        oldTask.label = newTask.label;

        let msg = {};
        Object.keys(newTask).map((k) => {
            if (
                k.includes('-new') ||
                k.includes('-before') ||
                ['id', 'matched', 'label'].includes(k)
            )
                return;
            msg[k] = newTask[k];
        });
        oldTask.message = JSON.stringify(msg);
        return this.task_svc.updateTaskPrototype(oldTask).toPromise();
    }

    importEmailTemplate(oldEmail: any, newEmail: any): Promise<any> {
        Object.keys(oldEmail).map((k) => {
            if (k == 'TemplateName') return;

            oldEmail[k] = newEmail[k];
        });

        return this.ddb_email_svc.updateTemplate(oldEmail).toPromise();
    }

    importRegistryConfig(db_config, regItems): Promise<any> {
        db_config.library = this.tryparsejson(db_config.library);
        db_config.registrationDetails = this.tryparsejson(db_config.registrationDetails);
        regItems.map((regItem) => {
            let split_key = regItem.key.split('.');
            split_key.splice(0, 1);

            let item = this.moveLaterally(db_config, split_key);

            // Update the actual reg item
            Object.keys(regItem).map((k) => {
                if (['key', 'matched'].includes(k) || k.includes('-before') || k.includes('-new'))
                    return;
                if (!regItem[k]) return;
                item[k] = regItem[k];
            });
        });

        db_config.library = JSON.stringify(db_config.library);
        db_config.registrationDetails = JSON.stringify(db_config.registrationDetails);

        return this.config_svc.createRegistryConfig(db_config).toPromise();
    }

    importSurvey(survey, sheetKey): Promise<any> {
        this.sheetData[sheetKey].data.map((newItem) => {
            let oldItem = this.mapSurveyKeyToItem2(survey, newItem.key, sheetKey);
            if (oldItem) {
                Object.keys(newItem).map((k) => {
                    if (
                        k.includes('-new') ||
                        k.includes('-before') ||
                        ['id', 'matched', 'key'].includes(k)
                    )
                        return;

                    let oldItemField = oldItem.title || oldItem.text || oldItem.html || oldItem;

                    if (typeof oldItemField == 'string') {
                        oldItem = { default: oldItemField, [k]: newItem[k] };
                    } else {
                        oldItemField = newItem[k];
                    }
                });
            } else {
                console.error('New survey Items not supported. SurveyID:', survey.id, newItem);
            }
        });
        survey.json = JSON.stringify(survey.json);
        return this.survey_svc.updateSurvey(survey).then((res) => {
            res.subscribe((result: any) => {});
        });
    }

    //#endregion
}
