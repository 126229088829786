<div *ngIf="mode === 'view'; else formModeTemplate">
    <div *ngIf="!disabled" class="pi-control-edit-mode">
        <app-pi-form-view-response
            [element]="element"
            [theFormControl]="formControl"
            [displayValue]="stringValue"
        ></app-pi-form-view-response>
    </div>
</div>
<ng-template #formModeTemplate>
    <div *ngIf="!disabled" class="row pi-control-form-mode">
        <app-element-title [element]="element"></app-element-title>

        <div>
            <input
                [name]="elementUID"
                type="range"
                [min]="element.min"
                [max]="element.max"
                [value]="50"
                class="slider"
                [attr.aria-label]="element.title"
                (change)="onSlide($event)"
            />
            <span>{{ _value }}</span>
        </div>

        <app-validator
            [control]="formControl"
            [customErrorMessages]="customErrorMessages"
            [warningControl]="warningControl"
            [customWarningMessages]="customWarningMessages"
        ></app-validator></div
></ng-template>
