import { Injectable } from '@angular/core';
import { RequestService } from './request.service';
import { CommonService } from './common.service';

@Injectable({
    providedIn: 'root',
})
export class AttributeService {
    site: string;

    constructor(
        private reqSvc: RequestService,
        private commonSvc: CommonService
    ) {}

    getUserAttributeDefinitions(envName: string) {
        const targetEnv = this.commonSvc.getTargetEnv(envName);
        const req: any = {
            operation: 'listUserAttributeDefinitions',
            data: {}
        };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBUserAttribute, req, targetEnv);
    }

    saveUserAttributeDefinition(envName: string, attributeDefinition: any) {
        const targetEnv = this.commonSvc.getTargetEnv(envName);
        var dataToSend = {
            attribute_definition: JSON.stringify(attributeDefinition)
        };
        const req: any = {
            operation: 'saveUserAttributeDefinition',
            data: dataToSend,
        };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBUserAttribute, req, targetEnv);
    }
    
    evaluateUserAttribute(envName: string, userId: string, attributeId: string) {
        const targetEnv = this.commonSvc.getTargetEnv(envName);
        var dataToSend = {
            user_id: userId,
            attribute_id: attributeId
        };
        const req: any = {
            operation: 'evaluateUserAttribute',
            data: dataToSend,
        };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBUserAttribute, req, targetEnv);
    }
    
    setUserAttribute(envName: string, userId: string, attributeId: string) {
        const targetEnv = this.commonSvc.getTargetEnv(envName);
        var dataToSend = {
            user_id: userId,
            attribute_id: attributeId
        };
        const req: any = {
            operation: 'setUserAttribute',
            data: dataToSend,
        };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBUserAttribute, req, targetEnv);
    }
}

export class AttributeDef {
	public id: string = '';
	public name: string = '';
	public type: string = '';
	public className: string = '';
	public assignment_condition: string = '';
	public assignment_value: string = '';
	public description: string = '';

	constructor(id?: string, name?: string, type?: string, className?: string, assignment_condition?: string, assignment_value?: string, description?: string){
		this.id = id || '';
		this.name = name || '';
		this.type = type || '';
		this.className = className || '';
		this.description = description || '';
		this.assignment_condition = assignment_condition || '';
		this.assignment_value = assignment_value || '';
	}

    public setType(type: string): void {
        this.type = type;
    }

    public toJson(): object {
        return {
            id: this.id,
            name: this.name,
            type: this.type,
            className: this.className,
            assignment_condition: this.assignment_condition,
            assignment_value: this.assignment_value,
            description: this.description,
        };
    }

    public toJsonString(): string {
        return JSON.stringify(this.toJson());
    }

    public static buildAttribute(values: object){
        return new AttributeDef(
            values["id"],
            values["name"],
            values["type"],
            values["class"],
            values["assignment_condition"],
            values["assignment_value"],
            values["description"]
        )
    }
}