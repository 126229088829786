import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@h20-services/auth.service';
import { ClaimService } from '@h20-services/claim.service';
import { CommonService } from '@h20-services/common.service';
import { ComposerRoleService } from '@h20-services/composer-role.service';
import { SurveyService } from '@h20-services/survey.service';
import { UserService } from '@h20-services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';

@Component({
    selector: 'app-survey-builder',
    templateUrl: './survey-builder.component.html',
    styleUrls: ['./survey-builder.component.scss'],
})
export class SurveyBuilderComponent implements OnInit {
    constructor(
        private svc: SurveyService,
        private route: ActivatedRoute,
        private userSvc: UserService,
        private http: HttpClient,
        private auth: AuthService,
        private common: CommonService,
        private router: Router,
        private claimService: ClaimService,
        private roleSvc: ComposerRoleService,
    ) {
        roleSvc.currentRole.subscribe(() => {
            this.userClaims = this.claimService.getUserClaims();
        });
    }
    update: number = 0;
    params;
    surveyUnderConstruction: any = {};
    jsonEditorSurveyData: any;
    jsonEditorOpen: boolean;
    jsonSurveyUnderConstruction: any;
    jsonEditorOptions: JsonEditorOptions;
    saveSurveyMessage: string;
    @ViewChild(JsonEditorComponent, { static: false }) editor: JsonEditorComponent;
    loading = false;
    userClaims;

    ngOnInit(): void {
        this.jsonEditorOptions = new JsonEditorOptions();
        this.jsonEditorOptions.modes = ['code', 'text', 'tree', 'view'];
        this.jsonEditorOptions.mode = 'code';
        this.route.params.subscribe((params) => {
            this.params = params;
            // If there is a survey_id, populate the survey with that, otherwise it is a new empty survey
            if (params.survey_id) {
                this.svc
                    .getSurveyById_Builder(
                        params.domain ? params.domain.toLowerCase() : '',
                        params.survey_id
                    )
                    .subscribe(
                        (result: any) => {
                            if (result[0]) {
                                this.surveyUnderConstruction = JSON.parse(result[0].json);
                                this.surveyUnderConstruction.id = result[0].id;
                                //creator.text = result[0].json.replace(/^\"/, '').replace(/\"$/, '');
                            }
                        },
                        (err: any) => {
                            console.error('cannot load survey');
                        }
                    );
            }
            //else need to start a new survey
            let newSurvey = {
                pages: [
                    {
                        name: 'page1',
                        elements: [
                            {
                                type: 'panel',
                                name: 'panel1',
                                elements: [],
                            },
                        ],
                    },
                ],
            };
            this.surveyUnderConstruction = newSurvey;
        });
        // this.http
        //     .get('assets/survey-builder/test-forms/regression-titles.json')
        //     .subscribe((data) => {
        //         this.surveyUnderConstruction = data;
        //     });
    }

    updateJsonEditorSurvey(data) {
        //have new valid survey data (not an error or event)
        if (data && data.hasOwnProperty('pages')) {
            this.jsonEditorSurveyData = data;
            this.surveyUnderConstruction = this.jsonEditorSurveyData;
        }
    }

    clickDesign() {
        if (this.jsonEditorOpen) {
            this.jsonEditorOpen = false;
            if (this.jsonEditorSurveyData !== null) {
                this.surveyUnderConstruction = this.jsonEditorSurveyData;
                this.jsonEditorSurveyData = null;
            }
        }
        this.updateAll();
    }

    clickTest() {
        if (this.jsonEditorOpen) {
            this.jsonEditorOpen = false;
            if (this.jsonEditorSurveyData !== null)
                this.surveyUnderConstruction = this.jsonEditorSurveyData;
        }
        this.updateAll();
    }

    clickJsonEditor() {
        this.jsonEditorOpen = true;
        //deep copy
        this.jsonSurveyUnderConstruction = JSON.parse(JSON.stringify(this.surveyUnderConstruction));
        delete this.jsonSurveyUnderConstruction.pages[0].elements[0].layout;        
        delete this.jsonSurveyUnderConstruction.json;
        this.jsonEditorSurveyData = null;
        this.updateAll();
    }

    updateAll() {
        this.update++;
    }

    backToSurveys() {
        this.router.navigate(['/surveys']);
    }

    saveSurvey() {
        this.loading = true;
        this.saveSurveyMessage = 'Saving...';
        this.svc.saveSurvey(this.surveyUnderConstruction).then((res: any) => {
            res.subscribe((result: any) => {
                if (result[0]) {
                    this.surveyUnderConstruction.id = result[0].id;
                    this.saveSurveyMessage = 'Saved!';
                    this.loading = false;
                }
            });
        }),
            (error: Error) => {
                this.saveSurveyMessage = 'Save failed.';
                console.error(error.message);
                this.loading = false;
            };
    }

    getText = this.common.getText;
}
