import { HttpClient } from '@angular/common/http';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
    selector: 'app-panel-designer',
    templateUrl: './panel-designer.component.html',
    styleUrls: ['./panel-designer.component.scss'],
})
export class PanelDesignerComponent implements OnInit{
    constructor(private http: HttpClient) {}

    @Input() panel: any;
    panelForm;

    ngOnInit(): void {
        this.http.get('assets/survey-builder/element-forms/panel.json').subscribe((data) => {
            this.panelForm = data;
        });
    }

    onPanelFormChanges(event: any) {
        this.panel = Object.assign(this.panel, event.value);
    }
    onElementLayoutChanges(event: any) {}

}
