import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

interface Action {
    label: String;
    isVisible: boolean;
    onClick: Function;
    iconClass?: string;
}
@Component({
    selector: 'app-custom-cell-renderer',
    templateUrl: './custom-cell-renderer.component.html',
    styleUrls: ['./custom-cell-renderer.component.scss'],
})
export class CustomCellRendererComponent implements ICellRendererAngularComp {
    componentType: string;
    additionalParams: Record<string, unknown>;
    actions: Action[];

    agInit(
        params: ICellRendererParams & {
            componentType: string;
            additionalParams: Record<string, unknown>;
        }
    ): void {
        this.componentType = params?.componentType;
        this.additionalParams = params?.additionalParams;
        this.actions = this.additionalParams['actions'] as Action[];
    }

    refresh(params: ICellRendererParams) {
        return false;
    }

    public anyActionVisible(actions: Action[]): boolean {
        return actions.some((action) => action.isVisible);
    }
}
