import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClaimService } from '@h20-services/claim.service';
import { ConfigService } from '@h20-services/config.service';
import { CommonService, EnvType } from '@h20-services/common.service';
import { ColDef } from 'ag-grid-community';
import { EnvironmentService } from '@h20-services/environment.service';
import { lastValueFrom } from 'rxjs';
@Component({
    selector: 'app-permissions',
    templateUrl: './permissions.component.html',
    styleUrls: ['./permissions.component.scss'],
})
export class PermissionsComponent implements OnInit{
    constructor(
        private claimService: ClaimService,
        private fb: FormBuilder,
        private configService: ConfigService,
        private commonSvc: CommonService,
        private environmentSvc: EnvironmentService
    ) {
        this.environmentSvc.currentSource.subscribe((environment) => {
            this.changeEnv(environment);
        });

    }

    envs = this.commonSvc.listSourceEnvs();
    targetEnv: string = EnvType.Devops;

    lstRegistries: any[];
    lstSiteRole: any[] = [];
    registrySiteRole: any[];
    selectedRegistry: any;
    selectedRegistryId: string;
    selectedSiteRole: string;
    selectedSite: string;
    selectedRole: string;

    registriesLoading: boolean = false;
    siteLoading: boolean = false;

    newClaims: any[] = [];
    roleClaims: any[] = [];
    viewNewClaimPanel = false;

    formGroup: FormGroup;
    newClaimForm: FormGroup;

    colDefs: ColDef[];

    ngOnInit(): void {
        this.loadRegistries().finally(() => {
            this.environmentSvc.currentRegistry.subscribe((environment) => {
                if(this.selectedRegistry) this.registryChange(environment);
                else {
                    this.selectedRegistry = environment;
                    this.registryChange(environment);
                }
            });
        });
        this.colDefs = this.getPermissionsTableColumns();
    }

    getPermissionsTableColumns() {
        return [
            {
                headerName: 'ID',
                field: 'id',
            },
            {
                headerName: 'Name',
                field: 'name',
            },
            {
                headerName: 'CLAIM_NO',
                cellDataType: 'dropdown',
                field: 'claim_no',
                cellStyle: {
                    height: '100%',
                    display: 'flex ',
                    'align-items': 'center ',
                },
                cellRendererParams: (params) => {
                    return {
                        value: params?.data.domain,
                        dropdownOptions: ['On', 'Off'],
                        onClick: (event) => this.updateChange(event, params?.data),
                    };
                },
            },
            {
                headerName: 'DELETE',
                field: 'id',
                cellDataType: 'iconBtns',
                cellRendererParams: (params) => {
                    return {
                        actions: [
                            {
                                label: 'Delete',
                                isVisible: true,
                                onClick: (event) => this.deleteClaim(event, params?.data),
                                iconClass: 'fas fa-times',
                                ['aria-label']: 'Delete Permission icon',
                            },
                        ],
                    };
                },
            },
        ];
    }


    loadRegistries(): Promise<void> {
        this.registriesLoading = true;
        return lastValueFrom(this.configService.listRegistryConfig(this.targetEnv))
            .then((res) => {
                this.lstRegistries = [...res];
                if (this.targetEnv === EnvType.Devops) {
                    this.lstRegistries.push({ registry_id: 'd19hg8da9jkb6n' });
                    this.lstRegistries.push({ registry_id: 'composer' });
                }
                this.registriesLoading = false;
            })
            .catch((err: any) => {
                this.setErrorHandler(err, this.targetEnv);
                this.registriesLoading = false;
            });
    }


    changeEnv(env) {
        this.targetEnv = env;
        this.selectedRegistry = null;
        this.selectedSiteRole = null;
        this.roleClaims = [];
        this.newClaims = [];
        this.loadRegistries();
    }

    registryChange(value: any): void {
        this.roleClaims = [];
        this.newClaims = [];
        this.selectedSiteRole = null;
        this.siteLoading = true;
        this.selectedRegistry = this.lstRegistries.find((reg) => reg.registry_id === value);
        this.selectedRegistryId = this.selectedRegistry.registry_id;
        this.claimService.listRoleClaims(this.targetEnv).subscribe(
            (res) => {
                this.registrySiteRole = res;
                this.lstSiteRole = [];
                res.Items.forEach((item) => {
                    let temp = item['registry_id#site_id#role'].split('#');
                    if (temp[0] == this.selectedRegistry.registry_id) {
                        this.lstSiteRole.push({
                            name: temp[1] + '#' + temp[2],
                        });
                    }
                });
                this.siteLoading = false;
            },
            (err: any) => {
                this.setErrorHandler(err, this.targetEnv);
                this.siteLoading = false;
            }
        );
    }

    siteChange(value) {
        this.selectedSiteRole = value;
        let selected = this.selectedSiteRole.split('#');
        this.selectedSite = selected[0];
        this.selectedRole = selected[1];
        const targetEnv = this.commonSvc.getTargetEnv(this.targetEnv);
        this.claimService
            .getRoleClaimByRole_DeployVersion(
                this.selectedRegistryId,
                this.selectedSite,
                this.selectedRole,
                targetEnv
            )
            .subscribe(
                (res: any) => {
                    if (res.Item) {
                        this.roleClaims = res.Item.claims;
                    } else this.roleClaims = [];

                    this.listNewClaims();
                },
                (err: any) => {
                    this.setErrorHandler(err, this.targetEnv);
                }
            );
    }

    showNewClaimPanel(event): void {
        this.viewNewClaimPanel = true;

        this.newClaimForm = this.fb.group({
            claimName: ['', [Validators.required]],
        });
    }

    cancelNewClaim(): void {
        this.viewNewClaimPanel = false;
    }

    listNewClaims(): void {
        this.claimService.listClaim(this.targetEnv).subscribe((res: any) => {
            const claims = res.Items.filter(
                (cl) => !this.roleClaims.some((rcl) => cl.name == rcl.name)
            );
            this.newClaims = claims.sort((a, b) => {
                return a.id - b.id;
            });
        });
    }

    addNewClaim(context): void {
        context.viewNewClaimPanel = false;
        const targetEnv = context.commonSvc.getTargetEnv(context.targetEnv);
        context.claimService
            .getRoleClaimByRole_DeployVersion(
                context.selectedRegistryId,
                context.selectedSite,
                context.selectedRole,
                targetEnv
            )
            .subscribe(
                (res: any) => {
                    if (res.Item) {
                        let claims = res.Item.claims;
                        let foundClaim = claims.find(
                            (cl) => cl.name == context.newClaimForm.value.claimName
                        );

                        if (!foundClaim) {
                            claims.push({
                                claim_on: true,
                                id: context.newClaims.find(
                                    (cl) => cl.name == context.newClaimForm.value.claimName
                                ).id,
                                name: context.newClaimForm.value.claimName,
                            });
                        }
                        const targetEnv = context.commonSvc.getTargetEnv(context.targetEnv);
                        context.claimService
                            .updateRoleClaim_DeployVersion(
                                context.selectedRegistryId,
                                context.selectedSite,
                                context.selectedRole,
                                claims,
                                targetEnv
                            )
                            .subscribe(
                                (res: any) => {
                                    context.siteChange(context.selectedSiteRole);
                                },
                                (err: any) => {
                                    console.error(err);
                                }
                            );
                    }
                },
                (err: any) => {
                    context.setErrorHandler(err, context.targetEnv);
                }
            );
    }

    updateChange(event, row): void {
        const targetEnv = this.commonSvc.getTargetEnv(this.targetEnv);
        row.claim_on = event.target.value == 'On' ? true : false;
        this.claimService
            .updateRoleClaim_DeployVersion(
                this.selectedRegistryId,
                this.selectedSite,
                this.selectedRole,
                this.roleClaims,
                targetEnv
            )
            .subscribe(
                (res: any) => {},
                (err: any) => {
                    this.setErrorHandler(err, this.targetEnv);
                }
            );
    }

    deleteClaim(event, row): any {
        const targetEnv = this.commonSvc.getTargetEnv(this.targetEnv);
        this.roleClaims = this.roleClaims.filter((claim) => claim.name != row.name);
        this.claimService
            .updateRoleClaim_DeployVersion(
                this.selectedRegistryId,
                this.selectedSite,
                this.selectedRole,
                this.roleClaims,
                targetEnv
            )
            .subscribe(
                (res: any) => {
                    this.siteChange(this.selectedSiteRole);
                },
                (err: any) => {
                    this.setErrorHandler(err, this.targetEnv);
                }
            );
    }

    setErrorHandler(err, env) {
        console.error(err);
        alert(`An error occurs on ${env}`);
    }
}
