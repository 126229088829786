import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';

import { ConfigService } from '@h20-services/config.service';
import { DateComparison } from '@h20-services/enums/dateTypes/DateComparison';
import { OpUnitType } from '@h20-services/enums/dateTypes/OpUnitType';
import { TaskType } from '@h20-services/enums/task/TaskType';
import { IScheduleData } from '@h20-services/models/schedule/IScheduleData';
import { ScheduledEmailPolicy } from '@h20-services/models/schedule/IScheduleEmailPolicy';
import { IScheduleListData } from '@h20-services/models/schedule/IScheduleListData';
import { TaskStatus } from '@h20-services/models/status';
import { ITaskPrototype } from '@h20-services/models/tasks/ITaskPrototype';
import { ScheduleService } from '@h20-services/schedule.service';
import { DDBEmailTemplates } from '@h20-services/ddb-email.service';
import { SiteService } from '@h20-services/site.service';
import { TaskService } from '@h20-services/task.service';
import { EnvType } from '@h20-services/common.service';
import { AttributeService, AttributeDef } from '@h20-services/attribute.service';
import { EvaluationRule, ScheduleEvent } from '@h20-services/models/schedule/ScheduleEvent';

@Component({
    selector: 'app-schedule-editor',
    templateUrl: './schedule-editor.component.html',
    styleUrls: ['./schedule-editor.component.scss'],
})
export class ScheduleEditorComponent implements OnInit {
    s_intervalType: string[] = Object.values(OpUnitType);
    s_ifTest = ['pass', 'fail', 'none'];
    s_participant_timeField = [
        'id',
        'status',
        'patient_email',
        'proxy_email',
        'proxy_id',
        'preferred_name',
        'site_ID',
        'contact',
        'allowEmails',
        'created',
        'modified',
        'activated_date',
        'consent_date',
        'pref_language',
        'email',
        'type',
    ];
    s_task_timeField: string[];
    s_repeatPolicy_models = ['task', 'baseline'];
    user_types = [
        {
            type: 'patient',
            label: 'Patient',
        },
        {
            type: 'proxy',
            label: 'Patient Proxy',
        },
        {
            type: 'deceased',
            label: 'Deceased Proxy',
        },
        {
            type: 'clinician',
            label: 'Clinician',
        },
    ];

    lstActions: string[] = ['deactivate_account', 'delete_account', 'reach_age_of_majority'];

    loadingSES: boolean;
    templateTypeList: any[] = [];

    loadingTasks: boolean;
    lstTasks: ITaskPrototype[];
    lstSiteTasks: ITaskPrototype[];
    lstTaskStatus: string[] = Object.values(TaskStatus);
    lstTaskDateComparison: string[] = Object.values(DateComparison);

    schedulesLoading: boolean;
    lstSchedules: string[];

    graphLoading: boolean;
    schedule: IScheduleData;
    lstScheduleTasks: string[];

    loadingSites: boolean;
    lstSites: string[];
    lstRegistries: string[];

    selectedElement;

    graphParsingErrors: string[];

    addNodeModal = false;
    addNodeModel;

    addEdgeModal = false;
    addEdgeModel;

    addNodeGroupModal = false;
    addNodeGroupModel;

    createScheduleModal = false;
    createScheduleModel;

    loadingSave: boolean;
    loadingDelete: boolean;

    advancedMode: boolean;
    jsonEditorOptions: JsonEditorOptions;
    @ViewChild(JsonEditorComponent, { static: false }) editor: JsonEditorComponent;

    constructor(
        private sched_svc: ScheduleService,
        private ddb_email_svc: DDBEmailTemplates,
        private task_svc: TaskService,
        private conf_svc: ConfigService,
        private site_svc: SiteService,
        private attr_svc: AttributeService,
        private route: Router
    ) {}

    ngOnInit(): void {
        this.schedulesLoading = true;
        this.sched_svc.listSchedules().subscribe((data: IScheduleListData[]) => {
            this.lstSchedules = data.map((s) => s.data.site_ID).sort();
            this.schedulesLoading = false;

            this.loadingSites = true;
            this.conf_svc.listRegistryConfig().subscribe((regConfigData) => {
                this.lstRegistries = regConfigData.map((reg) => reg.registry_id);
                const proms = [];
                this.lstRegistries.forEach((reg) => {
                    proms.push(
                        this.site_svc
                            .getSitesByRegistry(reg)
                            .toPromise()
                            .then((site_res) => {
                                return site_res.map((site) => site.name);
                            })
                            .catch((err) => {
                                console.warn(
                                    `${reg} failed to load sites... This is probably fine`,
                                    err
                                );
                                return [];
                            })
                    );
                });

                Promise.all(proms).then((res) => {
                    const sites = [];
                    res.forEach((sitesArray) => {
                        sitesArray.forEach((site) => {
                            sites.push(site);
                        });
                    });
                    this.lstSites = [...new Set(sites)]
                        .sort()
                        .filter((site) => !this.lstSchedules.includes(site));
                    this.loadingSites = false;
                });
            });
        });

        this.resetScheduleModel();

        this.jsonEditorOptions = new JsonEditorOptions();
        this.jsonEditorOptions.modes = ['code', 'text', 'tree', 'view'];


        this.loadingTasks = true;
        this.task_svc.listTaskPrototypes().subscribe((taskData) => {
            taskData.forEach((task) => {
                try {
                    task['nice_label'] = this.getText(JSON.parse(task.label));
                } catch (err) {
                    task['nice_label'] = task.label;
                }
            });
            this.lstTasks = taskData.sort((a, b) => {
                return (
                    a.site_ID.localeCompare(b.site_ID) ||
                    a.label.localeCompare(b.label) ||
                    a.type.localeCompare(b.type)
                );
            });

            this.s_task_timeField = Object.keys(this.lstTasks[0]);
            this.loadingTasks = false;

            const queryParams = this.route.parseUrl(this.route.url).queryParams;
            if (queryParams['site_id']) this.loadSchedule(queryParams['site_id']);
        });

        this.getAttributes();
    }

    async loadTemplateTypes(): Promise<void> {
        return this.ddb_email_svc
            .listTemplateTypes()
            .toPromise()
            .then((template) => {

                template.sort((a, b) => (a.id < b.id));
                template.forEach(t => {

                    this.templateTypeList.push({
                        id: t,
                        value: t.replace(/-/g, ' ')
                    })
                })
            });
    }

    getText(strOrLangs): string {
        if (!strOrLangs) return '';
        return strOrLangs['default'] || strOrLangs['en'] || strOrLangs;
    }

    async loadSchedule(site_ID: string): Promise<void> {
        this.graphLoading = true;
        this.usingMasterSchedule = false;

        this.sched_svc.getBySiteID(site_ID).subscribe((schedData) => {
            this.schedule = schedData;
            this.loadGraph(this.schedule);
            this.lstSiteTasks = this.lstTasks.filter((t) => t.site_ID === site_ID);
        });
        this.loadingSES = true;
        this.templateTypeList.length > 0 ? this.templateTypeList : await this.loadTemplateTypes();
        this.loadingSES = false;
    }

    loadGraph(schedule: IScheduleData): void {
        this.graphLoading = true;

        if (!this.schedule.data.graph.isActive) this.schedule.data.graph.isActive = true;
        if (!this.schedule.data.graph.fromAddress)
            this.schedule.data.graph.fromAddress = 'no-reply@healthie.net';
        if (!this.schedule.data.graph.taskMap) this.schedule.data.graph.taskMap = [];
        if (!this.schedule.data.graph.hasUniqueEmails)
            this.schedule.data.graph.hasUniqueEmails = false;
        if (!this.schedule.data.graph.emailMap) this.schedule.data.graph.emailMap = [];
        if (!this.schedule.data.graph.masterSchedule) {
            this.schedule.data.graph.masterSchedule = {
                usingMasterSchedule: false,
                masterScheduleId: '',
            };
        }

        // Parse off some useful set info
        this.lstScheduleTasks = this.schedule.data.graph.taskMap
            .map((t) => `${t.label} (${t.type})`)
            .filter((s) => s !== undefined)
            .sort();

        this.usingMasterSchedule = this.schedule.data.graph.masterSchedule?.usingMasterSchedule;
        this.masterScheduleId = this.schedule.data.graph.masterSchedule?.masterScheduleId;
        this.hasUniqueEmails =
            !this.usingMasterSchedule || this.schedule.data.graph.hasUniqueEmails;

        this.graphLoading = false;
        this.parseGraph();
    }

    /**
     * This is to update the model value when we dont care about the graph
     * @param model
     * @param dimension
     * @param value
     */
    valueChanged(model, field: string, value: string): void {
        model.data[field] = value;
    }
    dataValueChanged(model, field: string, value: string | boolean): void {
        model[field] = value;
    }

    enableAdvancedMode(): void {
        if (
            confirm(
                'By accepting this dialog you acknowledge that any changes you make, may make the graph unusable. Are you sure you want to use the advanced tools?'
            )
        ) {
            this.advancedMode = true;
        }
    }
    loadGraphFromJSON(): void {
        //@ts-ignore
        this.schedule.data = this.editor.get();
        this.loadGraph(this.schedule);
    }

    addEmailPolicy(): void {
        if (!this.selectedEvent.emailPolicy) {
            this.selectedEvent.emailPolicy = { emails: [] };
        }

        this.selectedEvent.emailPolicy.emails.push({
            emailKey: '',
            schedule: [],
            period: '',
            timeField: '',
            emailRef: '',
            ifTest: '',
        });
    }
    emailPolicyChange(policy, field: string, value): void {
        if (field === 'schedule') {
            try {
                const schedule = [];
                value.split(',').forEach((i) => {
                    const n = parseInt(i);
                    if (isNaN(n)) throw `Failed to cast ${i} to int`;
                    schedule.push(n);
                });
                policy[field] = schedule;
                delete policy.error;
            } catch (err) {
                console.error(err);
                policy.error = 'Expecting csv list of numbers';
            }
        } else {
            policy[field] = value;
        }
    }
    removeEmailPolicy(policy): void {
        const emailPolicyIndex = this.selectedEvent.emailPolicy.emails.indexOf(policy);
        if (emailPolicyIndex > -1) {
            this.selectedEvent.emailPolicy.emails.splice(emailPolicyIndex, 1);

            if (this.selectedEvent.emailPolicy.emails.length == 0) {
                delete this.selectedEvent.emailPolicy.emails;
            }
        }
    }

    policyChanged(policy, field: string, value): void {
        policy[field] = value;
    }
    repeatPolicyModelChanged(item, model): void {
        item.model = model;

        if (model === 'baseline') {
            item.baseline = {
                tasks: [],
                status: '',
            };
        } else {
            delete item.baseline;
        }
    }
    repeatPolicySurveySetChanged(set: string[], surveyName: string, checked: boolean): void {
        if (checked) {
            set.push(surveyName);
        } else {
            const idx = set.indexOf(surveyName);
            set.splice(idx, 1);
        }
    }
    repeatPolicyIncludesSet(policy, survey: string): boolean {
        if (policy?.timeBase?.baseline?.tasks?.length > 0) {
            return policy.timeBase.baseline.tasks.includes(survey);
        }
        return false;
    }
    addRepeatPolicy(): void {
        if (!this.selectedEvent.repeatPolicy) {
            this.selectedEvent.repeatPolicy = {
                dateBoundIterations: false,
                policies: [],
            };
        } else if (!this.selectedEvent.repeatPolicy.policies) {
            this.selectedEvent.repeatPolicy.policies = [];
        }

        this.selectedEvent.repeatPolicy.policies.push({
            maxIterations: 1,
            timeBase: {
                field: '',
                model: '',
            },
            intervals: [
                {
                    period: '',
                    interval: 0,
                },
            ],
        });
    }
    removeRepeatPolicy(item): void {
        this.selectedEvent.repeatPolicy.policies.splice(
            this.selectedEvent.repeatPolicy.policies.indexOf(item),
            1
        );

        if (!this.selectedEvent.repeatPolicy.policies.length) {
            delete this.selectedEvent.repeatPolicy;
        }
    }

    getTaskData(id: any): any {
        const task: ITaskPrototype = this.lstTasks.find((t) => t.id == (id as string));
        return {
            id: task.id,
            label: task.label,
            message: task.message,
            uri: `/surveys/${task.task_configuration}`,
            type: task.type,
            status: 'new',
            task_priority: task.task_priority,
            site_ID: task.site_ID,
        };
    }

    taskChange(task_type: string, value: any): void {
        this.selectedEvent.tasks[task_type].taskKey = value;
        return;

        this.selectedEvent.tasks[task_type] = this.getTaskData(value);
        this.lstScheduleTasks = this.schedule.data.graph.scheduleEvents
            .map(
                (sEvent) =>
                    sEvent.tasks?.patient?.label ||
                    sEvent.tasks?.proxy?.label ||
                    sEvent.tasks?.deceased?.label ||
                    sEvent.tasks?.clinician?.label
            )
            .filter((s) => s !== undefined)
            .sort();
    }
    addTask(task_type: string): void {
        if (!this.selectedEvent.tasks) this.selectedEvent.tasks = {};
        this.selectedEvent.tasks[task_type] = {
            id: '',
            label: '',
            message: '',
            status: '',
            task_priority: 0,
            type: '',
            uri: '',
            site_ID: '',
            taskKey: '',
            assignmentRules: [],
        };
    }
    removeTask(task_type: string): void {
        delete this.selectedEvent.tasks[task_type];
    }

    validateTaskPrototype(task: any, errorString: string) {
        if (!task.site_ID) {
            if (task.id === undefined) {
                this.graphParsingErrors.push(
                    `Invalid task attached to the schedule. No task.id: ${JSON.stringify(
                        task
                    )}. ${errorString}`
                );
            } else {
                task.site_ID = this.lstTasks.find((t) => t.id === task.id).site_ID;
            }
        }

        if (task.site_ID !== this.schedule.data.site_ID) {
            this.graphParsingErrors.push(
                `Invalid task attached to the schedule. site_ID doesnt match ${task.site_ID} != ${this.schedule.data.site_ID}. 
                Task: ${task.label}. ${errorString}`
            );
        }
    }

    scheduleValidator(): boolean {
        if (!this.usingMasterSchedule) {
            this.parseGraph();
            if (this.graphParsingErrors.length > 0) return false;
            else if (this.advancedMode)
                return confirm('Do you want to save this version anyway even with these errors?');

            return true;
        } else {
            this.graphParsingErrors = [];
            for (const tMap of this.taskMap) {
                const task = tMap.task;
                const errorString = `Map: ${task.label} (${task.id})`;
                this.validateTaskPrototype(task, errorString);
            }
            return this.graphParsingErrors.length == 0;
        }
    }
    async saveGraph(): Promise<void> {
        this.loadingSave = true;

        if (!this.scheduleValidator()) {
            this.loadingSave = false;
            return;
        }

        this.schedule.data.graph.taskMap = this.taskMap.filter((t) => t);
        this.schedule.data.graph.taskMap.forEach((tm) => {
            tm.id = tm.id.toString();
        });

        this.schedule.data.graph.hasUniqueEmails = this.hasUniqueEmails;
        this.schedule.data.graph.emailMap = this.emailMap.filter((e) => e);

        this.schedule.data.graph.masterSchedule = {
            usingMasterSchedule: this.usingMasterSchedule,
            masterScheduleId: this.masterScheduleId,
        };

        if (this.lstSchedules.includes(this.schedule.data.site_ID)) {
            this.sched_svc
                .updateSchedule(
                    this.schedule.data.site_ID,
                    this.schedule.data.title,
                    this.schedule.data.registry,
                    this.schedule.data.graph
                )
                .toPromise()
                .then((res) => {
                    this.loadingSave = false;
                });
        } else {
            this.sched_svc
                .createSchedule(
                    this.schedule.data.site_ID,
                    this.schedule.data.title,
                    this.schedule.data.registry,
                    this.schedule.data.graph
                )
                .toPromise()
                .then((res) => {
                    this.loadingSave = false;
                });
        }
    }
    async deleteGraph(): Promise<void> {
        if (confirm(`Are you sure you want to delete the ${this.schedule.data.site_ID} graph?`)) {
            this.loadingDelete = true;
            this.sched_svc
                .deleteSchedule(this.schedule.data.site_ID)
                .toPromise()
                .then((res) => {
                    this.loadingDelete = false;
                    this.route.navigateByUrl('/home', { skipLocationChange: true }).then(() => {
                        this.route.navigate(['/schedulemanagement']);
                    });
                });
        }
    }

    parseGraph(): void {
        this.graphParsingErrors = [];

        if (this.schedule.data.graph.taskMap) this.taskMap = this.schedule.data.graph.taskMap;

        if (this.schedule.data.graph.emailMap) this.emailMap = this.schedule.data.graph.emailMap;

        if (this.usingMasterSchedule) return;

        if (!this.schedule.data.graph.scheduleEvents) this.schedule.data.graph.scheduleEvents = [];

        this.schedule.data.graph.scheduleEvents.forEach((sEvent) => {
            // @ts-ignore
            if (sEvent.task) {
                sEvent.tasks = {};
                // @ts-ignore
                if (sEvent.task.type === TaskType.patient) {
                    // @ts-ignore
                    sEvent.tasks.patient = sEvent.task;
                    // @ts-ignore
                } else if (sEvent.task.type === TaskType.proxy) {
                    // @ts-ignore
                    sEvent.tasks.proxy = sEvent.task;
                    // @ts-ignore
                } else if (sEvent.task.type === TaskType.deceased) {
                    // @ts-ignore
                    sEvent.tasks.deceased = sEvent.task;
                }

                // @ts-ignore
                delete sEvent.task;
            } else if (sEvent.tasks) {
                // Object.keys(sEvent.tasks)
                this.taskMap.forEach((task_type) => {
                    const task = task_type.task; //sEvent.tasks[task_type];
                    const errorString = `sEvent: ${sEvent.id}`;
                    this.validateTaskPrototype(task, errorString);
                });
            }

            if (sEvent.repeatPolicy?.policies && !sEvent.tasks) {
                this.graphParsingErrors.push(
                    `Cannot apply repeatPolicy if no task is specified. sEvent: ${sEvent.id}`
                );
            }

            if (sEvent.taskPolicy) {
                const policy = sEvent.taskPolicy;

                // Update old task policy
                if (policy['createOnRepeat']) {
                    policy.allowCreation = policy['createOnRepeat'];
                    policy.defaultCreationStatus = TaskStatus.New;

                    policy.expiry = {
                        conditions: [
                            {
                                selfEval: true,
                                expiryDate: 'created',
                                comparisonRelativeToGivenDate: DateComparison.after,
                                intervals: [
                                    {
                                        period: policy['timeoutAfterLastNotification']['period'],
                                        interval:
                                            policy['timeoutAfterLastNotification']['schedule'][0],
                                    },
                                ],
                            },
                        ],
                        hasTrigger: false,
                        attribute: '',
                    };

                    delete policy['createOnRepeat'];
                    delete policy['timeoutAfterLastNotification'];
                    delete policy['openOrCreateOnTouch'];
                }

                if (policy.allowCreation && !policy.defaultCreationStatus) {
                    this.graphParsingErrors.push(
                        `Cannot apply taskPolicy allowCreation if no defaultCreationStatus status is provided. sEvent: ${sEvent.id}`
                    );
                }

                if (policy.activateIf) {
                    if (policy.activateIf.tasks.length == 0) {
                        this.graphParsingErrors.push(
                            `Cannot apply taskPolicy activateIf if no survey set is provided. sEvent: ${sEvent.id}`
                        );
                    }
                    // @ts-ignore
                    if (policy.activateIf.status == '') {
                        this.graphParsingErrors.push(
                            `Cannot apply taskPolicy activateIf if no status is provided. sEvent: ${sEvent.id}`
                        );
                    }
                }

                if (policy.expiry) {
                    policy.expiry.conditions.forEach((condition) => {
                        if (!condition.selfEval) {
                            if (condition.expiryDate) {
                                condition.selfEval = true;
                            } else if (condition.tasks) {
                                condition.selfEval = false;
                            }
                        }

                        //@ts-expect-error old interval style not used anymore
                        if (condition.interval) {
                            condition.intervals = [
                                {
                                    //@ts-ignore
                                    interval: condition.interval,
                                    //@ts-ignore
                                    period: condition.period,
                                },
                            ];

                            //@ts-ignore
                            delete condition.interval;
                            //@ts-ignore
                            delete condition.period;
                        }

                        if (condition.expiryDate) {
                            condition.intervals.forEach((interval) => {
                                if (interval.interval < 0) {
                                    this.graphParsingErrors.push(
                                        `Cannot apply taskPolicy expiry condition with a negative time interval. sEvent: ${sEvent.id}`
                                    );
                                }
                                // @ts-ignore
                                if (interval.period == '') {
                                    this.graphParsingErrors.push(
                                        `Cannot apply taskPolicy expiry condition without a period. sEvent: ${sEvent.id}`
                                    );
                                }
                            });

                            // @ts-ignore
                            if (condition.comparisonRelativeToGivenDate == '') {
                                this.graphParsingErrors.push(
                                    `Cannot apply taskPolicy expiry condition without the comparison operation. sEvent: ${sEvent.id}`
                                );
                            }
                        } else if (condition.tasks) {
                            // @ts-ignore
                            if (condition.taskStatus == '') {
                                this.graphParsingErrors.push(
                                    `Cannot apply taskPolicy expiry condition based on a survey set if no set status is provided. Node: ${sEvent.id}`
                                );
                            }
                            if (condition.tasks.length == 0) {
                                this.graphParsingErrors.push(
                                    `Cannot apply taskPolicy expiry condition based on a survey set if no set is provided. Node: ${sEvent.id}`
                                );
                            }
                        } else {
                            this.graphParsingErrors.push(
                                `Cannot apply taskPolicy expiry one or more required fields are missing. Node: ${sEvent.id}`
                            );
                        }
                    });
                }
            }

            if (sEvent.repeatPolicy?.policies) {
                if (!Array.isArray(sEvent.repeatPolicy.policies)) {
                    if (sEvent.repeatPolicy.policies['schedule']) {
                        //@ts-ignore
                        node.data.repeatPolicy.policies.intervals = [
                            {
                                interval: sEvent.repeatPolicy.policies['schedule'],
                                period: sEvent.repeatPolicy.policies['period'],
                            },
                        ];

                        delete sEvent.repeatPolicy.policies['schedule'];
                        delete sEvent.repeatPolicy.policies['period'];
                    }
                    sEvent.repeatPolicy.policies = [sEvent.repeatPolicy.policies];
                }

                sEvent.repeatPolicy.policies.forEach((policy) => {
                    if (policy.timeBase.model === 'baseline') {
                        if (policy.timeBase.baseline.tasks.length == 0) {
                            this.graphParsingErrors.push(
                                `Cannot apply repeatPolicy to baseline of a set if there is no SurveySet. sEvent: ${sEvent.id}`
                            );
                        }
                        // @ts-ignore
                        if (policy.timeBase.baseline.status === '') {
                            this.graphParsingErrors.push(
                                `Cannot apply repeatPolicy to a baseline of a set if there is no set status. sEvent: ${sEvent.id}`
                            );
                        }
                    }

                    if (!policy.precedence) {
                        policy.precedence = 0;
                    }
                });
            }

            // if (sEvent.type == 'newtask') {
            //     if (!sEvent.tasks || Object.keys(sEvent.tasks).length === 0) {
            //         this.graphParsingErrors.push(
            //             `newtask Nodes must have a task set. sEvent: ${sEvent.id}`
            //         );
            //     }
            // }

            if (sEvent.emailPolicy?.emails) {
                sEvent.emailPolicy.emails.forEach((em) => {
                    if (!em.hasRelevanceWindow) {
                        em.hasRelevanceWindow = false;
                    } else {
                        if (
                            em.hasRelevanceWindow &&
                            (!em.relevanceInterval || !em.relevancePeriod)
                        ) {
                            this.graphParsingErrors.push(
                                `Relevance window needs all fields to be set. sEvent: ${sEvent.id}, Policy: ${em.emailRef}`
                            );
                        }
                    }
                });
            }
        });
    }

    resetScheduleModel(): void {
        this.createScheduleModel = {
            site_ID: '',
            title: '',
        };
    }
    async createSchedule(ctx): Promise<void> {
        ctx.graphLoading = true;

        if (ctx.createScheduleModel.cloneSite) {
            ctx.sched_svc.getBySiteID(ctx.createScheduleModel.cloneSite).subscribe((schedData) => {
                ctx.schedule = schedData;

                ctx.schedule.data.site_id = ctx.createScheduleModel.site_ID;
                ctx.schedule.data.site_ID = ctx.createScheduleModel.site_ID;
                ctx.schedule.data.registry = ctx.createScheduleModel.site_ID;
                ctx.schedule.data.title = ctx.createScheduleModel.title;

                ctx.loadGraph(ctx.schedule);
                ctx.lstSiteTasks = ctx.lstTasks.filter(
                    (t) => t.site_ID === ctx.createScheduleModel.site_ID
                );
            });
        } else {
            ctx.schedule = {
                data: {
                    site_ID: ctx.createScheduleModel.site_ID,
                    registry: ctx.createScheduleModel.site_ID,
                    title: ctx.createScheduleModel.title,
                    graph: {},
                },
            };

            ctx.loadGraph(ctx.schedule);
            ctx.lstSiteTasks = ctx.lstTasks.filter(
                (t) => t.site_ID === ctx.createScheduleModel.site_ID
            );
        }
        ctx.createScheduleModal = false;
    }

    addTaskPolicy(): void {
        this.selectedEvent.taskPolicy = {
            allowCreation: true,
            defaultCreationStatus: TaskStatus.New,
        };
    }
    taskPolicyChange(item: string, value: any): void {
        if (item === 'allowCreation') {
            this.selectedEvent.taskPolicy.allowCreation = value === 'true';
        } else if (item === 'defaultCreationStatus') {
            this.selectedEvent.taskPolicy.defaultCreationStatus = value as TaskStatus;
        }
    }
    removeTaskPolicy(): void {
        delete this.selectedEvent.taskPolicy;
    }
    addTaskExpiry(): void {
        if (!this.selectedEvent.taskPolicy.expiry) {
            this.selectedEvent.taskPolicy.expiry = {
                conditions: [],
                hasTrigger: false,
                attribute: '',
            };
        }

        this.selectedEvent.taskPolicy.expiry.conditions.push({
            selfEval: true,
        });
    }
    taskExpiryTriggerChanged(field: string, value: any): void {
        switch (field) {
            case 'hasTrigger':
                this.selectedEvent.taskPolicy.expiry.hasTrigger = value;
                break;
            case 'attribute':
                this.selectedEvent.taskPolicy.expiry.attribute = value;
                break;
        }
    }
    taskExpiryConditionChanged(condition, field: string, value: any): void {
        if (field === 'interval') {
            condition[field] = parseInt(value);
        } else {
            condition[field] = value;
        }
    }
    taskExpiryConditionIncludesSet(condition, survey: string): boolean {
        if (condition?.tasks?.length > 0) {
            return condition.tasks.includes(survey);
        }
        return false;
    }
    removeTaskExpiryCondition(condition): void {
        const idx = this.selectedEvent.taskPolicy.expiry.conditions.indexOf(condition);
        if (idx > -1) {
            this.selectedEvent.taskPolicy.expiry.conditions.splice(idx, 1);
        }

        if (this.selectedEvent.taskPolicy.expiry.conditions.length === 0) {
            delete this.selectedEvent.taskPolicy.expiry;
        }
    }
    addTaskActivation(): void {
        this.selectedEvent.taskPolicy.activateIf = {
            tasks: [],
            status: TaskStatus.Pending,
        };
    }
    removeTaskActivation(): void {
        delete this.selectedEvent.taskPolicy.activateIf;
    }

    changeRelevanceWindow(policy: ScheduledEmailPolicy, field: string, value): void {
        if (field == 'hasRelevanceWindow') {
            policy['hasRelevanceWindow'] = value === true;
            policy['relevanceInterval'] = 0;
        } else if (field == 'relevanceInterval') {
            policy['relevanceInterval'] = parseInt(value);
        } else if (field == 'relevancePeriod') {
            policy['relevancePeriod'] = value;
        }
    }

    taskMap: any[] = [];

    addTaskMap(variator: number = 0) {
        const id = Date.now() + Math.round(variator * 3.14);

        this.taskMap.push({
            label: 'New Task Mapping',
            id: id.toString(),
            task: null,
        });
    }

    removeTaskMap(index: number) {
        delete this.taskMap[index];
    }

    taskMapChange(index: number, parameter: string, value: any) {
        switch (parameter) {
            case 'label':
                this.taskMap[index].label = value;
                break;
            case 'task':
                this.taskMap[index].task = this.getTaskData(value);
                if (!this.usingMasterSchedule) {
                    const label = this.taskMap[index].task.label;
                    try {
                        const jsonLabel = JSON.parse(label);
                        this.taskMap[index].label = jsonLabel['default'] ?? jsonLabel['en'];
                    } catch {
                        this.taskMap[index].label = label;
                    }
                    this.taskMap[index].label += ` (${this.taskMap[index].task.type})`;
                }
                break;
        }
    }

    emailMap: any[] = [];

    addEmailMap(variator: number = 0) {
        const id = Date.now() + Math.round(variator * 3.14);

        this.emailMap.push({
            label: 'New Email Mapping',
            id: id.toString(),
            email: null,
        });
    }

    removeEmailMap(index: number) {
        delete this.emailMap[index];
        this.emailMap = this.emailMap.filter((e) => e);
    }

    emailMapChange(index: number, parameter: string, value: any) {
        const label = value.split('-').join(' ');

        switch (parameter) {
            case 'label':
                if (!this.usingMasterSchedule) this.emailMap[index].label = label;
                break;
            case 'email':
                if (!this.usingMasterSchedule) this.emailMap[index].label = label;
                this.emailMap[index].email = value;
                break;
        }
    }

    loadingMasterSchedule: boolean = false;
    usingMasterSchedule: boolean = false;
    masterScheduleId: string;
    userAttributes: AttributeDef[] = [];
    hasUniqueEmails: boolean = true;

    setUsingMasterSchedule(value: boolean) {
        this.usingMasterSchedule = value;
    }

    setHasUniqueEmails(value: boolean) {
        this.hasUniqueEmails = value;
    }

    async masterScheduleChanged(site_ID: string): Promise<void> {
        this.loadingMasterSchedule = true;

        this.sched_svc.getBySiteID(site_ID).subscribe((schedData) => {
            this.taskMap = schedData.data.graph.taskMap;
            this.emailMap = schedData.data.graph.emailMap;
            this.masterScheduleId = site_ID;
            this.loadingMasterSchedule = false;
        });
    }

    getAttributes() {
        this.userAttributes = [];

        this.attr_svc.getUserAttributeDefinitions(EnvType.Devops).subscribe((resp: any) => {
            resp.forEach((pAry: any) => {
                this.userAttributes.push(AttributeDef.buildAttribute(pAry));
            });
        });
    }

    addDynamicAction(): void {
        if (!this.selectedEvent.dynamicActions) {
            this.selectedEvent.dynamicActions = [];
        }

        this.selectedEvent.dynamicActions.push({
            schedule: [],
            period: '',
            timeField: '',
            action: '',
            ifTest: '',
        });
    }

    dynamicActionChange(action, field: string, value): void {
        if (field === 'schedule') {
            try {
                const schedule = [];
                value.split(',').forEach((i) => {
                    const n = parseInt(i);
                    if (isNaN(n)) throw `Failed to cast ${i} to int`;
                    schedule.push(n);
                });
                action[field] = schedule;
                delete action.error;
            } catch (err) {
                console.error(err);
                action.error = 'Expecting csv list of numbers';
            }
        } else {
            action[field] = value;
        }
    }

    removeDynamicAction(action): void {
        const dynamicActionIndex = this.selectedEvent.dynamicActions.indexOf(action);
        if (dynamicActionIndex > -1) {
            this.selectedEvent.dynamicActions.splice(dynamicActionIndex, 1);

            if (this.selectedEvent.dynamicActions.length == 0) {
                delete this.selectedEvent.dynamicActions;
            }
        }
    }

    s_assignmentType: string[] = ['initial', 'reference-point'];
    s_assignOn: string[] = ['registration', 'activation', 'consent', 'reference_date'];

    addAssignmentRule(task): void {
        if (!task.assignmentRules) {
            task.assignmentRules = [];
        }

        task.assignmentRules.push({
            type: '',
            assign_on: '',
            attribute: '',
            ifTest: '',
        });
    }

    assignmentRuleChange(rule, field: string, value): void {
        if (field === 'schedule') {
            try {
                const schedule = [];
                value.split(',').forEach((i) => {
                    const n = parseInt(i);
                    if (isNaN(n)) throw `Failed to cast ${i} to int`;
                    schedule.push(n);
                });
                rule[field] = schedule;
                delete rule.error;
            } catch (err) {
                console.error(err);
                rule.error = 'Expecting csv list of numbers';
            }
        } else {
            rule[field] = value;
        }

        if (rule.type == 'initial') rule.attribute = null;
    }

    removeAssignmentRule(assignmentRules, rule): void {
        const assignmentRuleIndex = assignmentRules.indexOf(rule);
        if (assignmentRuleIndex > -1) {
            assignmentRules.splice(assignmentRuleIndex, 1);
        }
    }

    s_evaluationField: any[] = [
        'id',
        'status',
        'patient_email',
        'proxy_email',
        'proxy_id',
        'preferred_name',
        'site_ID',
        'contact',
        'allowEmails',
        'created',
        'modified',
        'activated_date',
        'consent_date',
        'pref_language',
        'email',
        'type',
    ];

    addEvaluationRule(task): void {
        if (!task.evaluationRules) {
            task.evaluationRules = [];
        }

        task.evaluationRules.push({
            testField: '',
            testValue: '',
            ifTest: '',
        });
    }

    evaluationRuleChange(rule, field: string, value): void {
        rule[field] = value;
    }

    removeEvaluationRule(evaluationRules, rule): void {
        const evaluationRuleIndex = evaluationRules.indexOf(rule);
        if (evaluationRuleIndex > -1) {
            evaluationRules.splice(evaluationRuleIndex, 1);
        }
    }

    new_schedule_event: ScheduleEvent = new ScheduleEvent();

    changeScheduleEvent(sEvent: ScheduleEvent, field: string, value: string) {
        switch (field) {
            case 'name':
                sEvent.name = value;
                break;
            case 'id':
                sEvent.id = value;
                break;
        }
    }

    addScheduleEvent() {
        if (!this.schedule.data.graph.scheduleEvents) this.schedule.data.graph.scheduleEvents = [];

        if (this.new_schedule_event.name) {
            this.new_schedule_event.id = Date.now().toString();
            this.schedule.data.graph.scheduleEvents.push(this.new_schedule_event);
            this.new_schedule_event = new ScheduleEvent();
        }
    }

    removeScheduleEvent(index: number) {
        this.schedule.data.graph.scheduleEvents.splice(index, 1);
    }

    selectedEvent: ScheduleEvent;

    changeSelectedEvent(sEvent: ScheduleEvent) {
        this.selectedEvent = sEvent;
    }
}
