import { Injectable } from '@angular/core';
import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Hub, ICredentials } from '@aws-amplify/core';
import { Subject, Observable } from 'rxjs';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { ConfigService } from './config.service';
import { UserService } from './user.service';

import { environment as env } from '@environment/environment';
import { TemplateTypes } from '@h20-services/models/emails/email_template_types';
import { RequestService } from './request.service';
import { PulseAuth } from './models/PulseAuth';
import { PulseParticipant } from '@h20-services/models/PulseParticipant';
import { ClaimService } from '@h20-services/claim.service';
import { ParticipantService } from '@h20-services/participant.service';

export interface NewUser {
    email: string;
    username: string;
    phone?: string;
    password: string;
    firstName?: string;
    lastName?: string;
    id?: string;
    role?: string;
    preferred_name?: string;
    nickname?: string;
    tableauUsername?: string;
}

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public loggedIn: boolean;
    private _authState: Subject<CognitoUser | any> = new Subject<CognitoUser | any>();
    authState: Observable<CognitoUser | any> = this._authState.asObservable();

    public static SIGN_IN_EVENT = 'signIn';
    public static SIGN_OUT_EVENT = 'signOut';
    public static FACEBOOK = CognitoHostedUIIdentityProvider.Facebook;
    public static GOOGLE = CognitoHostedUIIdentityProvider.Google;

    constructor(
        private reqSvc: RequestService,
        private configsvc: ConfigService,
        private userSvc: UserService,
        private claimSvc: ClaimService,
        private prtSvc: ParticipantService
    ) {
        Hub.listen('auth', (data) => {
            const { channel, payload } = data;
            if (channel === 'auth') {
                this._authState.next(payload.event);
            }
        });
    }

    forgotPassword(email: string): any {
        let url = window.location.href.split('/')[2];
        const clientMetadata = { registryId: this.configsvc.getTenant(), url: url };
        return Auth.forgotPassword(email, clientMetadata);
    }

    resetPassword(email: string, code: string, newPassword: string): any {
        return Auth.forgotPasswordSubmit(email, code, newPassword);
    }

    changePassword(user: CognitoUser, oldPassword: string, newPassword: string): any {
        return Auth.changePassword(user, oldPassword, newPassword);
    }

    sendActivationEmail(user: any): any {
        // Send data to email lambda
        return this.reqSvc.post(env.awsConfig.API.sendEmailTemplate, user);
    }

    sendAdminActivationEmail(user: any): any {
        // Send data to email lambda
        return this.reqSvc.post(env.awsConfig.API.sendEmailTemplate, user);
    }

    forgotPasswordEmail(email: any): any {
        // Submit the registration
        const reqObj: any = {
            email: email,
            registry_id: this.configsvc.getTenant(),
            templateType: TemplateTypes.forgot_password,
        };

        return this.reqSvc.post(env.awsConfig.API.sendEmailTemplate, JSON.stringify(reqObj));
    }

    changePasswordEmail(email: any, pref_language): any {
        // Submit the registration
        const reqObj: any = {
            email: email,
            registry_id: this.configsvc.getTenant(),
            pref_language: pref_language,
            templateType: TemplateTypes.change_password,
        };

        return this.reqSvc.post(env.awsConfig.API.sendEmailTemplate, JSON.stringify(reqObj));
    }

    getPortalLink(email, pref_language, templateType): any {
        const reqObj: any = {
            operation: 'getPortalLink',
            email: email,
            registry_id: this.configsvc.getTenant(),
            pref_language: pref_language,
            templateType: templateType,
        };

        return this.reqSvc.post(env.awsConfig.API.sendEmailTemplate, JSON.stringify(reqObj));
    }

    signUp(user: NewUser): Promise<CognitoUser | any> {
        const attributes: any = {
            email: user.email,
            nickname: user.nickname,
        };
        if (user.tableauUsername) {
            attributes['custom:tableau_username'] = user.tableauUsername;
        }

        return Auth.signUp({
            username: user.username,
            password: user.password,
            attributes,
        });
    }

    confirmSignUp(username: string, code: string, options: any = {}): any {
        return Auth.confirmSignUp(username, code, options);
    }

    signIn(username: string, password: string): Promise<CognitoUser | any> {
        return new Promise((resolve, reject) => {
            Auth.signIn(username, password, { registry_id: this.configsvc.getTenant() })
                .then((user: CognitoUser | any) => {
                    this.loggedIn = true;
                    resolve(user);
                })
                .catch((error: any) => reject(error));
        });
    }

    //Confirm sign in using MFA
    confirmSignIn(user, code, challengeName) {
        return new Promise((resolve, reject) => {
            Auth.confirmSignIn(user, code, challengeName, {
                registry_id: this.configsvc.getTenant(),
            })
                .then((loggedUser) => {
                    resolve(loggedUser);
                })
                .catch((e) => {
                    reject(e);
                });
        });
    }

    signOut(): Promise<any> {
        return Auth.signOut().then(() => (this.loggedIn = false));
    }

    socialSignIn(provider: CognitoHostedUIIdentityProvider): Promise<ICredentials> {
        return Auth.federatedSignIn({
            provider: provider,
        });
    }

    createToken(email: string): Observable<any> {
        const reqObj = { operation: 'create', data: { email } };
        return this.reqSvc.post(env.awsConfig.API.getToken, JSON.stringify(reqObj));
    }
    getTokenData(token: string) {
        const reqObj = { operation: 'get', data: { token } };
        return this.reqSvc.post(env.awsConfig.API.getToken, JSON.stringify(reqObj));
    }

    getPulseAuth(): Promise<PulseAuth> {
        return new Promise((resolve, reject) => {
            Auth.currentAuthenticatedUser()
                .then((cogUser) => {
                            
                            const pulseAuth = new PulseAuth(cogUser);

                            localStorage.setItem(
                                'prefTimeFormat',
                                pulseAuth.getPreferredTimeFormat()
                            );
                            localStorage.setItem(
                                'prefDateFormat',
                                pulseAuth.getPreferredDateFormat()
                            );
                            localStorage.setItem(
                                'preferredLanguage', 
                                pulseAuth.getPreferredLang()
                            );
                            resolve(pulseAuth);
                        },
                        (err: any) => {
                            reject(err);
                     
                })
                .catch((error: any) => reject(error));
        });
    }


    setPreferredMfa(user, pref_mfa): Promise<any> {
        return new Promise((resolve, reject) => {
            Auth.setPreferredMFA(user, pref_mfa)
                .then((data) => {
                    resolve(data);
                })
                .catch((error: any) => reject(error));
        });
    }

    verifyTotpToken(user, code): Promise<any> {
        return new Promise((resolve, reject) => {
            Auth.verifyTotpToken(user, code)
                .then((res) => {
                    resolve(res);
                })
                .catch((error: any) => reject(error));
        });
    }

    completeSetPulseAuth(cognitoUser): PulseAuth {
        

        const pulseAuth = new PulseAuth(cognitoUser);

        // Set current participant if the user has multiple patients
        if (pulseAuth.getParticipants().length > 0) {
            const participants: PulseParticipant[] = [];
            for (const p of pulseAuth.getParticipants()) {
                const pulsePrt = new PulseParticipant(p);
                participants.push(pulsePrt);
            }
            const latestPrt = PulseParticipant.getLastCreated(participants);
            this.prtSvc.setCurrentParticipant(latestPrt.getUserID());
            this.prtSvc.setCurrentParticipantName(latestPrt.getPreferredName());
        } else {
            this.prtSvc.clearCurrentParticipant();
        }

        // Set user claims in advance
        // ToDo: Can be replaced with PulseAuth claims properties.
        this.claimSvc.setUserClaims(pulseAuth.getClaims());

        return pulseAuth;
    }
}
