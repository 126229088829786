import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { IFormElement } from './models/forms/IFormElement';
import { IFormChoice } from './models/forms/IFormChoice';
import { SurveyService } from './survey.service';
import { PatientService } from './patient.service';
import { CDNService } from './cdn.service';
import { AuthService } from './auth.service';
import { PulseAuth } from './models/PulseAuth';

@Injectable({
    providedIn: 'root',
})
export class FormService {
    constructor(
        private fb: FormBuilder,
        private survey_svc: SurveyService,
        private patient_svc: PatientService,
        private cdn_svc: CDNService,
        private authService: AuthService
    ) {}

    flattenFormValues(group: FormGroup) {
        const values = {};

        Object.keys(group.value).forEach((key) => {
            if (group.value[key] && group.value[key].hasOwnProperty(key)) {
                values[key] = group.value[key][key];
            } else {
                values[key] = group.value[key];
            }
        });
        return values;
    }

    private doOp(op: string, val: any, values: any, b: any): boolean {
        let flag = false;
        if (op === '=') {
            flag = val === b;
        } else if (op === '<>') {
            flag = val !== b;
        } else if (op === 'empty') {
            flag = !Boolean(val);
        } else if (op === 'notempty') {
            flag = Boolean(val);
        } else if (op === '>') {
            flag = values[b] ? parseInt(val) > parseInt(values[b]) : val !== b;
        } else if (op === '<') {
            flag = values[b] ? parseInt(val) < parseInt(values[b]) : val !== b;
        } else if (op === '>=') {
            flag = values[b] ? parseInt(val) >= parseInt(values[b]) : val >= b;
        } else if (op === '<=') {
            flag = values[b] ? parseInt(val) <= parseInt(values[b]) : val <= b;
        } else if (op === 'contains' && b.length > 0) {
            flag = b.indexOf(val) > -1;
        } else if (op === 'notcontains' && b.length > 0) {
            flag = b.indexOf(val) === -1;
        } else if (op === 'anyof') {
            flag = JSON.parse(b).some((item: any) => {
                return String(item) === String(val);
            });
        } else if (op === 'allof') {
            flag = JSON.parse(b).every((item: any) => {
                return String(item) === String(val);
            });
        }
        return flag;
    }

    /**
     * per element group
     * @param element
     * @param group
     * @returns
     */

    processVisibilityLogic(element: IFormElement, values: any): boolean {
        if (!element.visibleIf) return true;

        try {
            return element.visibleIf.split(' or ').reduce((orFlag, ands) => {
                // split and clauses, reduce or clauses
                return (
                    orFlag ||
                    ands.split(' and ').reduce((andFlag, phrase) => {
                        // evaluate phrase
                        let flag = false;
                        const extraction =
                            /\{(?<a>[^\}]+)\} (?<op>[^ ]+)( [\{]*(?<b>[^\}]+)[\}]*)*/.exec(phrase);
                        if (extraction && extraction.groups) {
                            let a = extraction.groups.a;
                            //Dynamic panel handler
                            if (a.indexOf('.') > -1) {
                                a = a.split('.')[1];
                            }
                            let b: any;
                            b = extraction.groups.b;
                            const op = extraction.groups.op;
                            let val = values[a];
                            //this will pull out selections for things like checkboxes, which are a list of numeric keys
                            const subExtraction = /^\[\'*(?<key>[^\']+)\'*\]$/.exec(b);

                            let key = subExtraction?.groups?.key;

                            //some values will not exist if the question was disabled
                            if (val) {
                                if (key) {
                                    val = val[key]; //get the value at that key - true or false
                                    b = true; //if it was in the list of values that must be checked, b must be true
                                }
                                flag = this.doOp(op, val, values, b);
                            }
                        }
                        return andFlag && flag;
                    }, true)
                );
            }, false);
        } catch (err) {
            console.error(element.visibleIf, err);
        }
        return true;
    }

    createControl(element, surveyData: any): AbstractControl {
        if (!surveyData)
            return this.fb.control(null, {
                updateOn: 'change',
            });
        if (element.type === 'paneldynamic') {
            let value_array = [];
            if (surveyData) {
                for (var answer_row in surveyData[element.name]) {
                    value_array.push(surveyData[element.name][answer_row]);
                }
            }
            return this.fb.control(value_array);
        } else if (element.type === 'gesture-map-input') {
            return this.fb.control(surveyData[element.name] || [], {
                updateOn: 'change',
            });
        } else
            return this.fb.control(surveyData[element.name] || null, {
                updateOn: 'change',
            });
    }

    async addChoicesFromURL(element: IFormElement, patient_ID?, site_ID?) {
        if (!element.hasOwnProperty('choicesByUrl')) return;

        const choicesByUrl = element.choicesByUrl;
        if (choicesByUrl.hasOwnProperty('url'))
            // TODO We are not using the path at all when a URL is defined - but we may want to
            this.survey_svc
                .getChoicesFromURL(choicesByUrl.url)
                .subscribe((data: IFormChoice[]) => {
                    element.choices = data;
                });
        // no URL will default to our handler
        else {
            //TODO Move to the lambda?  would need to know patient ID etc.
            //     parse and handle paths like normal API:
            //     /table?name=
            //     /linked-vocabulary?name=

            //for now will use the path to be the service name
            //     table is a dynamo table (site_doctor)
            //     linked-vocabulary is a patient-level vocabulary set from existing data entry
            //  and the value name will be the table name or vocabulary name respectively

            if (choicesByUrl.hasOwnProperty('path') && choicesByUrl.hasOwnProperty('valueName')) {
                if (element.choicesByUrl.path === 'table') {
                   // let tableName = choicesByUrl.valueName;
                    //const pulseAuth: PulseAuth = await this.authService.getPulseAuth();
                    //const params = `?site_ID=${pulseAuth.getSiteID()}`;

                    //this.survey_svc.getChoicesFromTable(tableName, params).subscribe((doctors) => {
                   //     element.choices = [...doctors, ...element.choices];
                   // });
                } else if (
                    typeof patient_ID !== 'undefined' &&
                    typeof site_ID !== 'undefined' &&
                    choicesByUrl.path === 'linked-vocabulary'
                ) {
                    //if we have the patient ID and it is a linked vocabulary in the patient record
                    if (!element.hasOwnProperty('choices')) {
                        //initialize it
                        element.choices = [];
                    }

                    let vocabReferences = {};
                    let vocabSources = {};
                    this.patient_svc
                        .getPatientFileConfig(patient_ID)
                        .toPromise()
                        .then((resp) => {
                            if (resp.Item) {
                                //get the elements that need to pull from a reference
                                if (resp.Item.vocabularyReference) {
                                    vocabReferences = resp.Item.vocabularyReference;
                                    vocabSources = resp.Item.vocabularySource;
                                    if (vocabReferences.hasOwnProperty(element.name)) {
                                        //we need to pull the values
                                        return this.cdn_svc
                                            .loadVocabularyLinks(patient_ID, site_ID)
                                            .toPromise();
                                    }
                                }
                            }
                        })
                        .then((resp) => {
                            //process links and patch into existing choices
                            let vocabularies = JSON.parse(resp);

                            let vocabName = vocabReferences[element.name].vocabulary;
                            let curVocab = vocabularies[vocabName];

                            if (
                                curVocab &&
                                curVocab.current_values &&
                                curVocab.current_values.length > 0
                            ) {
                                let temp = [];
                                temp = temp.concat(element.choices);
                                //sort current values
                                let currentSorted = curVocab.current_values.sort((a, b) =>
                                    a.text > b.text ? 1 : b.text > a.text ? -1 : 0
                                );
                                element.choices = currentSorted.concat(temp);
                            } else {
                                throw new Error('Invalid vocabulary');
                            }
                        })
                        .catch((err) => {
                            element['noChoicesError'] = vocabReferences[element.name].error_message;
                        });
                }
            }
        }
    }

    private coerceType(element: any, first, second) {
        switch (element.inputType) {
            case 'date':
                first = new Date(first);
                second = new Date(second);

                // Carefully avoid the local issue
                if (this.dateHasTime(first)) {
                    first = new Date(new Date(first.toUTCString().substring(0, 25)).toDateString());
                }
                if (this.dateHasTime(second)) {
                    second = new Date(
                        new Date(second.toUTCString().substring(0, 25)).toDateString()
                    );
                }

                first = first.getTime();
                second = second.getTime();
                break;
        }
        return {
            f: first,
            s: second,
        };
    }

    private doCompare(op, first, second): boolean {
        let flag = false;
        switch (op) {
            case '<':
                flag = first < second;
                break;
            case '>':
                flag = first > second;
                break;
            case '=':
                flag = first == second;
                break;
            case '<=':
                flag = first <= second;
                break;
            case '>=':
                flag = first >= second;
                break;
            case '<>':
                flag = first != second;
                break;
        }
        return flag;
    }

    getValidators(element: any, form: FormGroup): any {
        const validator: ValidatorFn = (formGroup: FormGroup) => {
            // No validation
            if (!element.order || element.type === 'checkbox') {
                return null;
            }

            // Validation
            const errors: any = {};

            if (element.isRequired && Validators.required(formGroup)) {
                errors.required = true;
            }

            if (
                element.min &&
                formGroup.value &&
                parseFloat(element.min) > parseFloat(formGroup.value)
            ) {
                errors.min = true;
            }

            if (
                element.max &&
                formGroup.value &&
                parseFloat(element.max) < parseFloat(formGroup.value)
            ) {
                errors.max = true;
            }
            if (element.items || element.choices) {
                const items = element.items || element.choices;
                items.map((item: any) => {
                    const value = item.value || item.name || item;
                    if (item.isRequired && !formGroup.get(value).value) {
                        errors.required = true;
                    }
                });
            }

            // Other validation types
            if (element.validators) {
                for (const val of element.validators) {
                    if (
                        val.type === 'regex' &&
                        formGroup.value &&
                        !RegExp(val.regex).test(formGroup.value)
                    ) {
                        errors.pattern = true;
                    }
                    if (
                        element.inputType === 'date' &&
                        val.type === 'numeric' &&
                        val.minValue &&
                        formGroup.value
                    ) {
                        const now = new Date();
                        const bd = new Date(formGroup.value);
                        const years = now.getFullYear() - bd.getFullYear();
                        const months = now.getMonth() - bd.getMonth();
                        const days = now.getDate() - bd.getDate();
                        if (
                            years < val.minValue ||
                            (years === val.minValue && months < 0) ||
                            (years === val.minValue && months === 0 && days <= 0)
                        ) {
                            errors.age = true;
                        }
                    }
                    if (val.type === 'expression') {
                        // Split expression into logical parts
                        let passesValidation = val.expression
                            .split(' or ')
                            .reduce((orFlag, ands) => {
                                return (
                                    orFlag ||
                                    ands.split(' and ').reduce((andFlag, phrase) => {
                                        // Split expression into terms
                                        const extraction =
                                            /\{(?<a>[^\}]+)\} (?<op>[^ ]+)( [\{]*(?<b>[^\}]+)[\}]*)*/.exec(
                                                phrase
                                            );
                                        if (extraction && extraction.groups) {
                                            const a = extraction.groups.a;
                                            let b: any;
                                            b = extraction.groups.b;
                                            const op = extraction.groups.op;

                                            // Try get terms as elements
                                            let first = form.controls[a].value;
                                            let second = form.controls[b].value;

                                            // Terms are not elements re-eval them
                                            if (!first) {
                                                first = this.evaluateFunction(a);
                                            }
                                            if (!second) {
                                                second = this.evaluateFunction(b);
                                            }

                                            // coerce the terms to the expected type
                                            const newValues = this.coerceType(
                                                element,
                                                first,
                                                second
                                            );
                                            first = newValues.f;
                                            second = newValues.s;

                                            // Compare terms by operation
                                            let flag = this.doCompare(op, first, second);
                                            return andFlag && flag;
                                        }
                                    }, true)
                                );
                            }, false);

                        // Show errors if flag is set
                        if (!passesValidation) {
                            errors.expression = true;
                        }
                    }

                    if (
                        val.type === 'email' &&
                        !RegExp(/[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/).test(
                            formGroup.value
                        )
                    ) {
                        errors.email = true;
                    }
                }
            }

            // unitMeasure validation
            if (element.type === 'unitmeasure' && element.validators && formGroup.value) {
                for (const val of element.validators) {
                    if (formGroup.value.unit === 'kg') {
                        if (
                            val.type === 'numeric' &&
                            val.text.includes(formGroup.value.unit) &&
                            formGroup.value &&
                            val.minValue > formGroup.value.number
                        ) {
                            errors.min = true;
                            element.errorNum = 0;
                        }
                        if (
                            val.type === 'numeric' &&
                            val.text.includes(formGroup.value.unit) &&
                            formGroup.value &&
                            val.maxValue < formGroup.value.number
                        ) {
                            errors.max = true;
                            element.errorNum = 0;
                        }
                    } else if (formGroup.value.unit === 'lbs') {
                        if (
                            val.type === 'numeric' &&
                            val.text.includes(formGroup.value.unit) &&
                            formGroup.value &&
                            val.minValue > formGroup.value.number
                        ) {
                            errors.min = true;
                            element.errorNum = 1;
                        }
                        if (
                            val.type === 'numeric' &&
                            val.text.includes(formGroup.value.unit) &&
                            formGroup.value &&
                            val.maxValue < formGroup.value.number
                        ) {
                            errors.max = true;
                            element.errorNum = 1;
                        }
                    }
                }
            }
            return Object.keys(errors).length > 0 ? errors : null;
        };
        return validator;
    }

    evaluateFunction(term) {
        if (term == 'now()') {
            return new Date().toDateString();
        }
    }

    dateHasTime(date: Date) {
        let timeSplit = date.toTimeString().split(' ')[0].split(':');
        for (let i = 0; i < timeSplit.length; i++) {
            if (timeSplit[i] != '00') return true;
        }
        return false;
    }

    // Set answered field of each element and mark the controls after painting
    setAnswered(formGroup: FormGroup): any {
        Object.keys(formGroup.controls).forEach((elementKey) => {
            const elementControl = formGroup.controls[elementKey];
            if (!elementControl) return;

            // Get the list of controls, which are either the sub-controls or the element control
            const controls = Object.values(
                (elementControl as FormGroup).controls || {
                    elementControl,
                }
            );
            // Check if there are any values in the controls and set the control
            const foundValues = controls.find((control: AbstractControl) => {
                const unfilteredValues = control.value && Object.values(control.value);
                const values: any[] =
                    (unfilteredValues && unfilteredValues.filter((value: any) => value)) || [];
                // Mark the control
                if (values && values.length > 0) {
                    control.markAsDirty;
                    control.markAsTouched;
                } else {
                    control.markAsPristine;
                }
                return control.value;
            });
            // Set answered field of the element based on values in the element control or subcontrols
            // if (element.type === 'unitmeasure' && element.formControl.value) {
            //     element.answered =
            //         !element.order ||
            //         (element.formControl &&
            //             element.formControl.value.number !== 0 &&
            //             element.formControl.value.unit !== '' &&
            //             element.formControl.valid);
            //     if (
            //         element.formControl.value.number !== 0 ||
            //         element.formControl.value.unit !== ''
            //     ) {
            //         element.formControl.markAsDirty();
            //         element.formControl.markAsTouched();
            //     }
            // } else {
            //     element.answered =
            //         !element.order ||
            //         (element.formControl &&
            //             element.formControl.value &&
            //             element.formControl.valid) ||
            //         element.type === 'html';
            // }
        });
    }

    containsValue(formData: any, question_key): boolean {
        for (const [key, val] of Object.entries(formData)) {
            if (key === question_key && val !== null) {
                return true;
            }
            if (Array.isArray(val)) {
                for (let it in val) {
                    for (const [it_key, it_val] of Object.entries(val[it])) {
                        if (it_key === question_key && it_val !== null) return true;
                    }
                }
            }
        }
        return false;
    }

    findValues(formData: any, question_key: string): any[] {
        let toReturn = [];
        for (const [key, val] of Object.entries(formData)) {
            if (key === question_key) toReturn.push(val);
            if (Array.isArray(val)) {
                for (let it in val) {
                    for (const [it_key, it_val] of Object.entries(val[it])) {
                        if (it_key === question_key) toReturn.push(it_val);
                    }
                }
            }
        }
        return toReturn;
    }
}
