import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '@h20-services/common.service';
import { IFormElement } from '@h20-services/models/forms/IFormElement';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-element-title',
    templateUrl: './element-title.component.html',
    styleUrls: ['./element-title.component.scss'],
})
export class ElementTitleComponent {
    @Input() element: IFormElement;
    constructor(private com_svc: CommonService, private translate: TranslateService) {}
    // tooltipOptions = {
    //     placement: 'right',
    //     showDelay: 500,
    //     width: 500,
    //     maxWidth: 500,
    // };

    tooltipOptions = {
        pointerEvents: 'auto',
        placement: 'right',
        'show-delay': 500,
        'hide-delay': 500,
        maxWidth: '500px', // Default max width is 200px
        zIndex: 10021, // Nav bar is 1020, so just need to be over that //modal is at 10000
    };
    getText = this.com_svc.getText;
}
