// PulseAuth.ts
// Copyright 2023 Pulse Infoframe Inc

import { UserRole } from './role';
import { UserStatus } from './status';

export class PulseAuth {
    user_id: string = '';
    site_ID: string = '';
    emailAddr: string = '';
    tableau_username = '';
    isLoggedIn: boolean = false;
    mfa_required: boolean = false;
    mfa_status = '';
    authenticationID = '';
    preferredName = '';
    preferred_language = '';
    preferred_time_format = '';
    preferred_date_format = '';
    organizationID = '';
    type: string = '';
    status: string = '';
    isPatientProxy: boolean = false;
    greenlight_patient_ID: string = '';
    registryKey: string = '';
    claims: any;
    participants: any[] = [];

    constructor(cognitoUser) {
        
        if (cognitoUser) {
            this.isLoggedIn = true;
            const userData = JSON.parse(cognitoUser.signInUserSession.idToken.payload.user_data) ;
            if (userData) {
                this.user_id = userData.id || '';
                this.site_ID = userData.site_ID || '';
                this.emailAddr = userData.email || userData.username || '';
                this.type = userData.type || '';
                this.authenticationID = userData.authentication_ID || '';
                this.organizationID = userData.organization_ID || '';
                this.status = userData.status || '';
                this.mfa_required = userData.mfa_required;
                this.mfa_status = userData.mfa_status || '';
                this.preferredName = userData.preferred_name;
                this.preferred_language = userData.preferred_language || '';
                this.preferred_date_format = userData?.preferred_date_format || '';
                this.preferred_time_format = userData?.preferred_time_format || '';
                this.isPatientProxy = userData.isPatientProxy;
                this.greenlight_patient_ID = userData.greenlight_patient_ID;
                this.registryKey = userData.registry_key;
            }
            
            if (!this.preferredName) this.preferredName = cognitoUser.signInUserSession.idToken.payload.attributes?.nickname;
            this.tableau_username = cognitoUser.attributes?.['custom:tableau_username'] || '';
            this.claims = JSON.parse(cognitoUser.signInUserSession.idToken.payload.user_claims);
            this.participants = JSON.parse(cognitoUser.signInUserSession.idToken.payload.user_participants);
        }
    }

    public getUserID(): string {
        return this.user_id;
    }

    public getSiteID(): string {
        return this.site_ID;
    }

    public getEmailAddr(): string {
        return this.emailAddr;
    }

    public setEmailAddr(addr): PulseAuth {
        this.emailAddr = addr || '';
        return this;
    }

    public getTableauUsername(): string {
        return this.tableau_username;
    }

    public doesSiteMatch(s: string): boolean {
        return s == this.site_ID;
    }

    public getType(): any {
        return this.type;
    }

    public isType(t: UserRole): boolean {
        return this.type == t;
    }

    public getAuthenticationID(): string {
        return this.authenticationID;
    }

    public getPreferredName(): string {
        return this.preferredName;
    }

    public getOrganizationID(): string {
        return this.organizationID;
    }

    public getStatus(): string {
        return this.status;
    }

    public isStatusInArray(ary: UserStatus[]): boolean {
        if (!ary) return false;
        for (const us of ary) {
            if (this.status == us) return true;
        }
        return false;
    }

    public isTypeInArray(ary: UserRole[]): boolean {
        if (!ary) return false;
        for (const urs of ary) {
            if (this.type == urs) return true;
        }
        return false;
    }

    public isBothPatientAndProxy(): boolean {
        return this.isPatientProxy;
    }

    public getMFARequired(): boolean {
        return this.mfa_required;
    }

    public getMFAStatus(): string {
        return this.mfa_status;
    }

    public getGreenlightPatientId(): string {
        return this.greenlight_patient_ID;
    }

    public getRegistryKey(): string {
        return this.registryKey;
    }

    public getParticipants(): any[] {
        return this.participants;
    }

    public getClaims(): any {
        return this.claims;
    }

    public getPreferredTimeFormat(){
        return this.preferred_time_format;
    }

    public getPreferredDateFormat(){
        return this.preferred_date_format;
    }

    public getPreferredLang(){
        return this.preferred_language;
    }

    public static NotLoggedIn: PulseAuth = new PulseAuth(null);
}
