<div *ngIf="loading || (loading$ | async) || (loadingData$ | async)?.isLoading; else contentTpl">
    <h4 class="mt-3 text-center">
        <i attr.aria-label="Loading" [class]="class"></i>
        {{ text | translate }}
    </h4>
</div>

<ng-template #contentTpl>
    <ng-content></ng-content>
</ng-template>
