<input
    type="text"
    placeholder="Search for..."
    (keyup)="getUserInput($event)"
    id="userInput"
    list="dynmicData"
/>
<datalist id="dynmicData">
    <option *ngFor="let item of dataList" [value]="item">{{ item }}</option>
</datalist>
