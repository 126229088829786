import { Component } from '@angular/core';
import { DateCompareValidator, IFormElement } from '@h20-services/models/forms/IFormElement';
import { AbstractPiControlComponent } from '../abstract-pi-control/abstract-pi-control.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-pi-control-relative-date-validator-builder',
    templateUrl: './pi-control-relative-date-validator-builder.component.html',
    styleUrls: ['./pi-control-relative-date-validator-builder.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PiControlRelativeDateValidatorBuilderComponent,
        },
    ],
})
export class PiControlRelativeDateValidatorBuilderComponent extends AbstractPiControlComponent<
    IFormElement,
    any
> {
    dateCompareValidators: Array<DateCompareValidator>;
    operations = [
        'lessThan',
        'greaterThan',
        'equalTo',
        'lessThanOrEqualTo',
        'greaterThanOrEqualTo',
        'notEqualTo',
    ];

    writeValue(val: Array<DateCompareValidator>) {
        super.writeValue(val);
        this.dateCompareValidators = val;
    }

    remove(index: number) {
        this.dateCompareValidators.splice(index, 1);
    }

    add() {
        if (!this.dateCompareValidators) {
            this.dateCompareValidators = [{ compareValueName: '', operation: '' }];
            return;
        }
        this.dateCompareValidators.push({ compareValueName: '', operation: '' });
    }

    update() {
        super.writeValue(this.dateCompareValidators);
        super.notifyValueChange();
    }

    /**
     * Returns whether the term matches the item.
     * Used by the ng-select to customize how it
     * searches all items in the list to identify matches.
     * @param term the search term
     * @param item the element to check
     * @returns true if there is a match and false otherwise
     */
    elementSearchFn(term: string, item: IFormElement) {
        term = term.toLowerCase();

        let compareTitle = '';
        if (item.title['default']) compareTitle = item.title['default'];
        else if (item.title['en']) compareTitle = item.title['en'];
        else compareTitle += item.title;

        return (
            item.name.toLowerCase().indexOf(term) > -1 ||
            compareTitle.toLowerCase().indexOf(term) > -1
        );
    }
}
