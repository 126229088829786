import { Injectable } from '@angular/core';
import { environment as env } from '@environment/environment';
import { Observable, lastValueFrom } from 'rxjs';
import { ITaskPrototype } from './models/tasks/ITaskPrototype';
import { CommonService } from './common.service';
import { RequestService } from './request.service';
import { ITaskSet } from './models/tasks/ITaskSet';
import { TaskStatus } from './models/status';

@Injectable({
    providedIn: 'root',
})
export class TaskService {
    constructor(private reqSvc: RequestService, private commonSvc: CommonService) {}

    listTaskPrototypes(env: any = null): Observable<ITaskPrototype[]> {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        const data = {
            data: {},
        };
        const req: any = { operation: 'listTaskPrototypes', data };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBTask, req);
    }

    async listTaskPrototypesByRegistry(env: any = null, registry: string): Promise<ITaskPrototype[]> {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        const data = {
            registry: registry
        };
        const req: any = { operation: 'listTaskPrototypesByRegistry', data };

        return await lastValueFrom(this.reqSvc.post(targetEnv.awsConfig.API.writeToDBTask, req));
    }

    updateTaskPrototype(task: ITaskPrototype, env: any = null): Observable<any> {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        const data = { ...task };
        const req: any = { operation: 'updateTaskPrototype', data };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBTask, req);
    }

    versionSavedUpdateTaskPrototype(task: ITaskPrototype, env: any = null): Promise<any> {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        const data = { ...task };
        const req: any = { operation: 'versionSavedUpdateTaskPrototype', data };
        return lastValueFrom(this.reqSvc.post(targetEnv.awsConfig.API.writeToDBTask, req));
    }

    createTaskPrototype(task: ITaskPrototype, env: any = null): Observable<any> {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        const data = { ...task };
        const req: any = { operation: 'createTaskPrototype', data };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBTask, req);
    }

    getTaskPrototype(id, env: any = null): Observable<ITaskPrototype[]> {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        const data = {
            id: id,
        };
        const req: any = { operation: 'getTaskPrototype', data };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBTask, req);
    }

    getTaskPrototypeBySite(site_ID, env: any = null): Observable<ITaskPrototype[]> {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        const data = {
            site_ID: site_ID,
        };
        const req: any = { operation: 'getTaskPrototypeBySite', data };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBTask, req);
    }

    deleteTaskPrototype(id, env: any = null): Observable<any> {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        const data = {
            id: id,
        };
        const req: any = { operation: 'deleteTaskPrototype', data };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBTask, req);
    }

    assignTaskFromPrototype(proto_ID, user_ID, auth_ID): Observable<any> {
        const data = {
            id: proto_ID,
            user_ID: user_ID,
            auth_ID: auth_ID,
        };
        const req: any = { operation: 'assignTask', data };
        return this.reqSvc.post(env.awsConfig.API.writeToDBTask, req);
    }

    // Functions for task_set table
    listTaskSetData(env: any = null): Observable<any[]> {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        const data = {
            data: {},
        };
        const req: any = { operation: 'listTaskSetData', data };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBTask, req);
    }
    listTaskSetDataBySite(site_ID, env: any = null): Observable<any> {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        const data = {
            site_ID: site_ID,
        };
        const req: any = { operation: 'listTaskSetDataBySite', data };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBTask, req);
    }

    getTaskSetData(id, env: any = null): Observable<any> {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        const data = {
            id: id,
        };
        const req: any = { operation: 'getTaskSetData', data };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBTask, req);
    }

    createTaskSetData(taskSet: any, env: any = null): Observable<any> {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        const data = { ...taskSet };
        const req: any = { operation: 'createTaskSetData', data };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBTask, req);
    }
    updateTaskSetData(taskSet: any, env: any = null): Observable<any> {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        const data = { ...taskSet };
        const req: any = { operation: 'updateTaskSetData', data };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBTask, req);
    }

    // Not used right now. Code is commented out in survey-detail component
    updateTaskStatusAndOpenedById(status: TaskStatus, id: string) {
        const req: any = {
            operation: 'updateTaskStatusAndOpenedById',
            data: { status: status, id: id },
        };
        return this.reqSvc.post(env.awsConfig.API.writeToDBTask, req).subscribe();
    }

    listTaskSetBySite(site_id: string): Observable<ITaskSet[]> {
        const req: any = { operation: 'listTaskSetDataBySite', data: { site_ID: site_id } };
        return this.reqSvc.post(env.awsConfig.API.writeToDBTask, req);
    }
}
