import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PatientService } from '@h20-services/patient.service';
import { CDNService } from '@h20-services/cdn.service';
import { CommonService, EnvType } from '@h20-services/common.service';
import { SiteService } from '@h20-services/site.service';
import { UserStatus } from '@h20-services/models/status';
@Component({
    selector: 'app-delete-patients',
    templateUrl: './delete-patients.component.html',
    styleUrls: ['./delete-patients.component.scss'],
})
export class DeletePatientsComponent implements OnInit {
    constructor(
        private patSvc: PatientService,
        private cdn: CDNService,
        private siteService: SiteService,
        private fb: FormBuilder
    ) {}

    loading: boolean = false;
    loadingDeleteS3: boolean = false;
    loadingDeleteDB: boolean = false;
    isDeleteS3Disabled: boolean = true;
    isDeleteDBDisabled: boolean = true;
    message: string = '';

    totalCount;
    patients = [];

    envs = CommonService.ENVS;
    targetEnv: string = EnvType.Development;
    sites = [];
    targetSite: string;
    standardDate: string;
    dateForDisplay: string;

    getPatientForm: FormGroup = this.fb.group({
        targetEnv: [EnvType.Development],
        site: [''],
        standardDate: [''],
    });

    ngOnInit(): void {
        this.getSites();
    }

    getSites() {
        this.siteService.getSites('').subscribe((resp: any) => {
            resp.map((pAry: any) => {
                this.sites.push(pAry.name);
            });
        });
    }

    changeDateFormat(date): string {
        let changedDate;
        if (date) {
            changedDate = new Date(date).toISOString().slice(0, 19).replace('T', ' ');
            changedDate = changedDate.slice(0, 10) + ' 23:59:59';
        }
        return changedDate;
    }

    getRetentionPeriodPatients() {
        this.targetEnv = this.getPatientForm.value.targetEnv;
        this.targetSite = this.getPatientForm.value.site;
        this.standardDate = this.changeDateFormat(this.getPatientForm.value.standardDate);

        if (this.getPatientForm.value.standardDate) {
            let ddate = new Date(this.getPatientForm.value.standardDate);
            ddate.setMonth(ddate.getMonth() - 3);
            this.dateForDisplay = this.changeDateFormat(ddate);
        }

        const statusList = [
            UserStatus.New,
            UserStatus.Pending,
            UserStatus.Consent,
            UserStatus.Assent,
            UserStatus.Declined,
        ];
        if (this.targetSite && this.standardDate) {
            this.loading = true;
            this.patSvc
                .getPatientsForRetentionPeriod(
                    this.targetSite,
                    statusList,
                    this.standardDate,
                    this.targetEnv
                )
                .toPromise()
                .then((resp: any) => {
                    this.totalCount = resp.length;
                    this.patients = resp;
                    this.loading = false;
                    this.message = '';
                    //For checking
                    console.error(this.patients);

                    if (this.totalCount > 0) {
                        this.isDeleteS3Disabled = false;
                    }
                })
                .catch((err) => {
                    console.error(err);
                    this.message = `Error: Failed getting patients on ${this.targetEnv}. Please try again.`;
                    this.loading = false;
                });
        } else {
            this.message = 'Choose a site and standard date';
        }
    }

    clearGetPatientForm() {
        this.totalCount = null;
        this.patients = null;
        this.getPatientForm = this.fb.group({
            targetEnv: [EnvType.Development],
            site: [''],
            standardDate: [''],
        });
        this.isDeleteS3Disabled = true;
        this.isDeleteDBDisabled = true;
        this.dateForDisplay = '';
    }

    deleteS3Files() {
        if (this.patients && this.patients.length > 0) {
            if (
                confirm(
                    `This action is not reversible. Are you sure you want to delete S3 files in ${this.targetEnv}?`
                )
            ) {
                this.loadingDeleteS3 = true;
                for (let i = 0; i < this.patients.length; i++) {
                    this.cdn
                        .removeAllUserFiles(
                            this.patients[i].id,
                            this.patients[i].site_ID,
                            this.targetEnv
                        )
                        .toPromise()
                        .then((resp: any) => {
                            if (i == this.patients.length - 1) {
                                this.loadingDeleteS3 = false;
                                this.isDeleteS3Disabled = true;
                                this.message = `Done: Delete S3 Files`;
                                this.isDeleteDBDisabled = false;
                            }
                        })
                        .catch((err) => {
                            console.error(err);
                            this.loadingDeleteS3 = false;
                            this.isDeleteS3Disabled = true;
                            this.message = `Error: Failed deleting S3 Files on ${this.targetEnv}.`;
                        });
                }
            } else {
                this.clearGetPatientForm();
            }
        } else {
            this.message = 'Get target patients first';
        }
    }

    deletePatientsFromDB() {
        if (this.patients && this.patients.length > 0) {
            if (
                confirm(
                    `This action is not reversible. Are you sure you want to delete patients in ${this.targetEnv}?`
                )
            ) {
                this.loadingDeleteDB = true;
                for (let i = 0; i < this.patients.length; i++) {
                    let deleteData = {
                        patient_ID: this.patients[i].id,
                        patient_Auth_ID: this.patients[i].authentication_ID,
                        userName: this.patients[i].email,
                        status: 'expired-' + this.patients[i].status,
                    };

                    if (this.patients[i].authentication_ID == '') {
                        deleteData['patient_Auth_ID'] = null;
                    }

                    if (this.patients[i].proxy_ID && this.patients[i].proxy_ID != null) {
                        deleteData['proxy_ID'] = this.patients[i].proxy_ID;
                    }

                    this.patSvc
                        .hardDeletePatient(deleteData, this.targetEnv)
                        .toPromise()
                        .then((dbRes: any) => {
                            if (i == this.patients.length - 1) {
                                this.loadingDeleteDB = false;
                                this.isDeleteDBDisabled = true;
                                this.message = 'Done: Delete from DB';
                            }
                        })
                        .catch((err) => {
                            console.error(err);
                            this.loadingDeleteDB = false;
                            this.isDeleteDBDisabled = true;
                            this.message = `Error: Failed deleting from DB on ${this.targetEnv}.`;
                        });
                }
            } else {
                this.clearGetPatientForm();
            }
        } else {
            this.message = 'Get target patients first';
        }
    }
}
