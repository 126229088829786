<app-modal
    [saveBtnText]="isNewPrototype ? 'Add' : 'Edit'"
    *ngIf="viewAddPrototypePanel"
    [(visible)]="viewAddPrototypePanel"
    [header]="isNewPrototype ? 'Add/Duplicate Task Prototype' : 'Edit Task Prototype'"
    [context]="this"
    [saveFunc]="isNewPrototype ? addTaskPrototype : saveEditTaskPrototype"
    [closeFunc]="cancelNewPrototype"
    [modalWidth]="'40vw'"
    [modalHeight]="'70vh'"
    [saveBtnDisabled]="
        prototypeForm.controls.site_ID.invalid ||
        prototypeForm.controls.type.invalid ||
        prototypeForm.controls.task_type.invalid
    "
>
    <div class="container-fluid" [formGroup]="prototypeForm" #nuForm="ngForm">
        <div class="form-group row mt-2">
            <div class="col">
                <select
                    class="form-select"
                    aria-label="site_ID"
                    formControlName="site_ID"
                    id="site_ID"
                    required
                >
                    <option value="" disabled>Site --Please select--</option>
                    <option
                        *ngFor="let site of sites"
                        [value]="site.name"
                        [selected]="site.name === prototypeForm.value.site_ID"
                    >
                        {{ site.label }}
                    </option>
                </select>
                <div
                    *ngIf="
                        prototypeForm.controls.site_ID.errors?.required &&
                        (prototypeForm.controls.site_ID.dirty ||
                            prototypeForm.controls.site_ID.touched)
                    "
                    class="formError text-danger"
                >
                    Please select a site
                </div>
            </div>
        </div>

        <div class="form-group row mt-2">
            <div class="col">
                <select
                    class="form-select"
                    aria-label="type"
                    formControlName="type"
                    id="type"
                    required
                >
                    <option value="" disabled>Type --Please select--</option>
                    <option
                        *ngFor="let type of types"
                        [value]="type"
                        [selected]="type === prototypeForm.value.type"
                    >
                        {{ type }}
                    </option>
                </select>
                <div
                    *ngIf="
                        prototypeForm.controls.type.errors?.required &&
                        (prototypeForm.controls.type.dirty || prototypeForm.controls.type.touched)
                    "
                    class="formError text-danger"
                >
                    Please select a type
                </div>
            </div>
        </div>

        <div class="form-group row mt-2">
            <div class="col">
                <select
                    class="form-select"
                    aria-label="task_type"
                    formControlName="task_type"
                    id="task_type"
                    required
                >
                    <option value="" disabled>Task Type --Please select--</option>
                    <option
                        *ngFor="let taskType of taskTypes"
                        [value]="taskType"
                        [selected]="taskType === prototypeForm.value.task_type"
                    >
                        {{ taskType }}
                    </option>
                </select>
                <div
                    *ngIf="
                        prototypeForm.controls.task_type.errors?.required &&
                        (prototypeForm.controls.task_type.dirty ||
                            prototypeForm.controls.task_type.touched)
                    "
                    class="formError text-danger"
                >
                    Please select a task type
                </div>
            </div>
        </div>

        <div class="form-group row mt-2">
            <div class="col">
                <label>Task Configuration</label>
                <input
                    aria-label="task_configuration"
                    placeholder="e.g. survey_ID"
                    type="text"
                    class="form-control"
                    id="task_configuration"
                    formControlName="task_configuration"
                />
            </div>
        </div>

        <div class="form-group row mt-2">
            <div class="col">
                <label>Label</label>
                <textarea
                    aria-label="label"
                    rows="5"
                    class="form-control"
                    id="label"
                    formControlName="label"
                ></textarea>
            </div>
        </div>

        <div class="form-group row mt-2">
            <div class="col">
                <label>Message</label>
                <textarea
                    aria-label="message"
                    rows="5"
                    class="form-control"
                    id="message"
                    formControlName="message"
                ></textarea>
            </div>
        </div>

        <div class="form-group row mt-2">
            <div class="col">
                <label>Task Priority</label>
                <input
                    aria-label="task_priority"
                    placeholder="Add a number"
                    type="text"
                    class="form-control"
                    id="task_priority"
                    formControlName="task_priority"
                />
                <div
                    *ngIf="
                        prototypeForm.controls.task_priority.errors &&
                        (prototypeForm.controls.task_priority.dirty ||
                            prototypeForm.controls.task_priority.touched)
                    "
                    class="formError text-danger"
                >
                    Task Priority must be number.
                </div>
            </div>
        </div>
    </div>
</app-modal>

<app-modal
    [saveBtnText]="'Deploy'"
    *ngIf="viewCompareDeployPanel"
    [(visible)]="viewCompareDeployPanel"
    [header]="'Deploy'"
    [context]="this"
    [saveFunc]="deployTaskPrototypes"
    [closeFunc]="closeCompareItems"
    [modalWidth]="'40vw'"
    [modalHeight]="'70vh'"
>
    <app-loading-component [loading]="compareLoading">
        <div class="card-body">
            <div class="row">
                <div class="col-6">
                    <div class="card p-2" style="height: 350; width: 99%">
                        <label>Source: {{ sourceEnv }}</label>
                        <app-object-diff [obj]="sourceView"></app-object-diff>
                    </div>
                </div>
                <div class="col-6">
                    <div class="card p-2">
                        <label>Destination: {{ destinationEnv }}</label>
                        <app-object-diff [obj]="destView"></app-object-diff>
                    </div>
                </div>
            </div>
            <label>Different Values Only</label>
            <small class="form-text text-muted">
                Red: Source config & Green: Destination config</small
            >
            <app-object-diff [obj]="diffValueChanges"></app-object-diff>
            <label>All Values</label>
            <app-object-diff [obj]="diffView"></app-object-diff>
        </div>
    </app-loading-component>
</app-modal>

<div class="container-fluid panel-control">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body data-table-ctrl-1200">
                    <div>
                        <h1>Task Prototypes</h1>
                        <div *ngIf="message" class="text-center text-danger mb-2">
                            {{ message }}
                        </div>
                        <app-loading-component [loading]="loading" [text]="'loading'">
                            <div class="ms-3 d-flex">
                                <app-button
                                    *ngIf="sourceEnv === 'devops'"
                                    (action)="newTaskPrototype()"
                                    title="Only available in dev"
                                    [buttonText]="'Registry.AddTaskPrototype'"
                                />
                                <app-button
                                    class="mx-3"
                                    (action)="deployTaskPrototypes()"
                                    title="Deploy multiple Items"
                                    [buttonText]="'Registry.DeploySelectedPrototypes'"
                                />
                                <app-button
                                    (action)="openCompareDeploy()"
                                    title="Compare and deploy an item"
                                    [buttonText]="'Registry.Compare&Deploy'"
                                />
                            </div>
                        </app-loading-component>
                        <br />
                        <div>
                            <small class="text-muted">
                                * Deploy:Data will be added if not exist in the destination but
                                updated.<br />
                                * Match Env: Displays the env name if the item in the source matches
                                the one in others.<br />
                            </small>
                            <small class="text-danger">
                                * Please compare the data first before deploying.
                            </small>
                        </div>
                        <div class="d-flex justify-content-end">
                            <div class="ms-1 d-flex">
                                <app-button
                                    [iconConfig]="{ iconClasses: 'fa-regular fa-sliders-v' }"
                                    [buttonClasses]="'ms-1'"
                                    style="opacity: 0.5; cursor: default; margin-bottom: 8px"
                                    (action)="showToast()"
                                />
                            </div>
                        </div>
                    </div>
                    <app-loading-component [loading]="loadingTable" [text]="'loading'">
                    <app-table-component
                        rowSelection="multiple"
                        [columnDefs]="colDefs"
                        (selectionChanged)="onSelect($event)"
                        [rowData]="taskPrototypes"
                        [filterModelKey]="'task_proto'"
                        [showFilterRow]="false"
                    >
                    </app-table-component>
                </app-loading-component>

                </div>
            </div>
        </div>
    </div>
</div>
