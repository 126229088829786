import { Component, Input } from '@angular/core';
import { CommonService } from '@h20-services/common.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  @Input() tableauData: any[];
  @Input() showTableau: boolean;
  @Input() hasDashboardTab: boolean;
  @Input() tableauBaseUrl: string;
  @Input() viewTabs: any[];
  @Input() showEarlyBird: boolean;
  @Input() earlyTitle: string;
  @Input() earlyMessage: string;
  @Input() earlyImage: string;
  @Input() showHighcharts: boolean;
  @Input() highchartsData: any[];

  constructor(
    private commonSvc: CommonService,
    public translate: TranslateService
  ){}

  openViewTab(event, tabViewName) {
    this.showEarlyBird = false;
    this.showHighcharts = false;
    this.showTableau = false;

    this.tableauData = this.tableauData?.map((view) => {
        let tabTableauBtn = document.getElementById(`btn-${view.viewName}`);
        tabTableauBtn?.classList.remove('active');
        if (view.selected) {
            view.selected = false;
            let tabTableauView = document.getElementById(view.viewName);
            if (tabTableauView) tabTableauView.style.display = 'none';
        }
        return view;
    });
    let tabHighBtn = document.getElementById('btn-highcharts');
    tabHighBtn?.classList.remove('active');

    let tabEbBtn = document.getElementById('btn-early_bird');
    tabEbBtn?.classList.remove('active');

    event.target.classList.add('active');
    if (tabViewName === 'early_bird') {
        this.showEarlyBird = true;
    } else if (tabViewName === 'highcharts') {
        this.showHighcharts = true;
    } else {
        this.showTableau = true;
        const viewIndex = this.tableauData.findIndex((view) => view.viewName === tabViewName);
        this.tableauData[viewIndex].selected = true;
        let selectedTab = document.getElementById(this.tableauData[viewIndex].viewName);
        if (selectedTab) selectedTab.style.display = 'block';
    }
}

getText = this.commonSvc.getText;

}
