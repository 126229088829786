import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ModifyTemplatesComponent } from './modify-templates/modify-templates.component';
import { TemplatesComponent } from './templates/templates.component';


const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                pathMatch: 'full',
                component: TemplatesComponent,
            },
            {
                path: 'modify-template/:name',
                component: ModifyTemplatesComponent,
            },
            {
                path: 'modify-template',
                component: ModifyTemplatesComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class EmailTemplateRoutingModule {}
