<app-modal
    *ngIf="true"
    [saveBtnText]="'Admin.AssignNow'"
    [visible]="true"
    [header]="'Tasks.NewTask'"
    [context]="this"
    [saveFunc]="createTask"
    [closeFunc]="hideDialog"
    [modalWidth]="'40vw'"
    [modalHeight]="'30vh'"
>
    <app-loading-component [loading]="loading">
        <label
            >{{ 'Tasks.Task' | translate }}:
            <select class="form-control" (change)="changeTaskSelection($event.target.value)">
                <option selected disabled>
                    {{ 'Registry.SelectResponse' | translate }}
                </option>
                <option *ngFor="let task of taskList">
                    {{ task.label | translate }}
                </option>
            </select></label
        >
    </app-loading-component>
</app-modal>
