import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TemplatesComponent } from './templates/templates.component';
import { ModifyTemplatesComponent } from './modify-templates/modify-templates.component';
import { EmailTemplateRoutingModule } from './email-template-manager-routing.module';
import { SharedModule } from '@h20-shared/shared.module';
import { QuillModule } from 'ngx-quill';

@NgModule({
    declarations: [TemplatesComponent, ModifyTemplatesComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        EmailTemplateRoutingModule,
        SharedModule,
        QuillModule.forRoot({
            modules: {
                syntax: true,
                toolbar: [
                    [{ header: [1, 2, false] }],
                    ['bold', 'italic', 'underline'],
                    ['image', 'code-block'],
                ],
            },
        }),
    ],
})
export class emailtemplatemanagerModule {}
