<div *ngIf="mode === 'view'; else formModeTemplate">
    <div *ngIf="!disabled" class="pi-control-edit-mode">
        <app-pi-form-view-response
            [element]="element"
            [theFormControl]="formControl"
            [displayValue]="stringValue"
        ></app-pi-form-view-response>
    </div>
</div>
<ng-template #formModeTemplate>
    <div
        *ngIf="!hideDisabled || !disabled"
        class="w-100 row pi-control-form-mode"
        role="radiogroup"
    >
        <app-element-title [element]="element"></app-element-title>
        <div
            *ngIf="copiedToClip"
            class="alert alert-success alert-dismissible fade show"
            role="alert"
            alt
        >
            <h6 class="m-0">
                <i class="fa-solid fa-check"></i> {{ 'Surveys.CopiedToClip' | translate }}
            </h6>
            <button
                type="button"
                class="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
                (click)="hideCopied()"
            ></button>
        </div>

        <div class="input-group">
            <textarea #copyTextArea readonly class="form-control copy-area"
                >{{ element.beforeLink }} {{ link }}</textarea
            >
            <app-button
                [disabled]="!link"
                (action)="onCopyClick(copyTextArea)"
                class="copy-btn"
                [buttonText]="'Surveys.CopyText'"
                [iconConfig]="{ iconClasses: 'fa-light fa-copy' }"
            />
        </div>
    </div>
</ng-template>
