<div *ngIf="mode === 'view'; else formModeTemplate">
    <div *ngIf="!disabled" class="pi-control-edit-mode">
        <app-pi-form-view-response
            [element]="element"
            [theFormControl]="formControl"
            [displayValue]="stringValue"
        ></app-pi-form-view-response>
    </div>
</div>
<ng-template #formModeTemplate>
    <div *ngIf="!disabled" class="w-100 row pi-control-form-mode">
        <label class="form-label col-12 col-md-6 col-lg-8">
            <app-element-title [element]="element"></app-element-title>
        </label>
        <div class="col-12 col-md-6 col-lg-4"></div>
        <div class="col-12 col-md-10 col-lg-6 justify-items-end">
            <ng-container *ngIf="element">
                <div class="container-fluid p-0">
                    <div
                        class="d-flex flex-column align-items-center flex-md-row w-100 w-lg-75 gx-1"
                    >
                        <div class="text-center dropdown-center p-0 date-dropdown-container">
                            <div
                                class="date-dropdown-label"
                                [innerHtml]="getYearPromptText()"
                                id="EUID() + yearLabel"
                            ></div>
                            <button
                                class="m-0 dropdown-toggle date-dropdown-button"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                aria-labelledby="EUID() + yearLabel"
                                [class.dropdown-label-muted]="yearDisplayMuted"
                                [innerHtml]="getTranslation(currentYearDisplayValue)"
                            ></button>

                            <ul class="dropdown-menu date-dropdown-menu">
                                <div *ngIf="element.allowYearUnk">
                                    <li>
                                        <div
                                            [ngClass]="
                                                currentDateValue.isYearUnknown()
                                                    ? 'dropdown-selected'
                                                    : ''
                                            "
                                            class="dropdown-item"
                                            (click)="yearUnknown()"
                                        >
                                            {{ getText(unkText) }}
                                        </div>
                                    </li>
                                    <li><hr class="dropdown-divider" /></li>
                                </div>
                                <div class="dropdown-menu-scroll">
                                    <li *ngFor="let year of yearOptions">
                                        <div
                                            [ngClass]="
                                                currentYearDisplayValue === year
                                                    ? 'dropdown-selected'
                                                    : ''
                                            "
                                            class="dropdown-item"
                                            (click)="yearSelected(year)"
                                        >
                                            {{ year }}
                                        </div>
                                    </li>
                                </div>
                            </ul>
                        </div>

                        <div
                            *ngIf="!element.removeMonth"
                            class="text-center dropdown-center p-0 date-dropdown-container mt-2 mt-md-0 mx-md-2"
                        >
                            <div
                                class="date-dropdown-label"
                                [class.dropdown-disabled]="monthIsDisabled"
                                [innerHtml]="getMonthPromptText()"
                                id="EUID() + monthLabel"
                            ></div>
                            <button
                                class="date-display-text date-dropdown-button"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                aria-labelledby="EUID() + monthLabel"
                                [class.dropdown-label-muted]="monthDisplayMuted"
                                [class.dropdown-disabled]="monthIsDisabled"
                                [class.dropdown-toggle]="!monthIsDisabled"
                                [innerHtml]="getTranslation(currentMonthDisplayValue)"
                                [disabled]="monthIsDisabled"
                            ></button>

                            <ul class="dropdown-menu date-dropdown-menu">
                                <div *ngIf="element.allowMonthUnk">
                                    <li>
                                        <div
                                            [ngClass]="
                                                currentDateValue.isMonthUnknown()
                                                    ? 'dropdown-selected'
                                                    : ''
                                            "
                                            class="dropdown-item"
                                            (click)="monthUnknown()"
                                        >
                                            {{ getText(unkText) }}
                                        </div>
                                    </li>
                                    <li><hr class="dropdown-divider" /></li>
                                </div>
                                <div class="dropdown-menu-scroll">
                                    <li *ngFor="let month of currentMonthOptions">
                                        <div
                                            [ngClass]="
                                                currentDateValue.month === month.value
                                                    ? 'dropdown-selected'
                                                    : ''
                                            "
                                            class="dropdown-item"
                                            (click)="monthSelected(month)"
                                        >
                                            {{ month.text | translate }}
                                        </div>
                                    </li>
                                </div>
                            </ul>
                        </div>
                        <!-- If month is removed day will also be removed -->
                        <div
                            *ngIf="!element.removeMonth && !element.removeDay"
                            class="text-center dropdown-center p-0 date-dropdown-container mt-2 mt-md-0"
                            [disabled]="dayIsDisabled"
                        >
                            <div
                                class="date-dropdown-label"
                                [class.dropdown-disabled]="dayIsDisabled"
                                [innerHtml]="getDayPromptText()"
                                id="EUID() + dayLabel"
                            ></div>
                            <button
                                class="date-dropdown-button w-100"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                aria-labelledby="EUID() + dayLabel"
                                [innerHtml]="getTranslation(currentDayDisplayValue)"
                                [class.dropdown-label-muted]="dayDisplayMuted"
                                [class.dropdown-disabled]="dayIsDisabled"
                                [class.dropdown-toggle]="!dayIsDisabled"
                                [disabled]="dayIsDisabled"
                            ></button>

                            <ul class="dropdown-menu">
                                <div *ngIf="element.allowDayUnk">
                                    <li>
                                        <div
                                            [ngClass]="
                                                currentDateValue.isDayUnknown()
                                                    ? 'dropdown-selected'
                                                    : ''
                                            "
                                            class="dropdown-item"
                                            (click)="dayUnknown()"
                                        >
                                            {{ getText(unkText) }}
                                        </div>
                                    </li>
                                    <li><hr class="dropdown-divider" /></li>
                                </div>
                                <div class="dropdown-menu-scroll">
                                    <li *ngFor="let day of currentDayOptions">
                                        <div
                                            [ngClass]="
                                                currentDayDisplayValue === day
                                                    ? 'dropdown-selected'
                                                    : ''
                                            "
                                            class="dropdown-item"
                                            (click)="daySelected(day)"
                                        >
                                            {{ day }}
                                        </div>
                                    </li>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>

        <app-validator
            [control]="formControl"
            [customErrorMessages]="customErrorMessages"
            [warningControl]="warningControl"
            [customWarningMessages]="customWarningMessages"
            [delay]="validateDelay"
        ></app-validator>
    </div>
</ng-template>
