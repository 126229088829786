import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';
import { CommonService } from '@h20-services/common.service';
import { FormService } from '@h20-services/form.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-pi-form-progress-bar',
    templateUrl: './pi-form-progress-bar.component.html',
    styleUrls: ['./pi-form-progress-bar.component.scss'],
})
export class PiFormProgressBarComponent implements OnInit, OnChanges {
    @Input() panels: any;
    @Input() theFormGroup: UntypedFormGroup;
    @Input() activeElement: any;

    @Output() progressClick: EventEmitter<any> = new EventEmitter();

    progressBar: any[] = [];
    getText = this.com_svc.getText;

    constructor(
        private frm_svc: FormService,
        private com_svc: CommonService,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        if (this.theFormGroup) {
            this.panels.forEach((panel) => {
                let newPanel = { name: panel.title, elements: [] };

                panel.elements.forEach((el) => {
                    let control = this.theFormGroup.controls[el.name];
                    let newProgress = {
                        name: el.name,
                        text: this.getText(el.title),
                        progressState: 'empty',
                        active: false,
                        type: el.type,
                    };
                    this.syncPanelElementStates(newProgress, control);
                    newPanel.elements.push(newProgress);
                });
                this.progressBar.push(newPanel);
            });

            this.theFormGroup.valueChanges.subscribe(() => {
                this.ngOnChanges();
            });
        }
    }

    ngOnChanges() {
        this.progressBar.forEach((panel) => {
            panel.elements.forEach((el) => {
                let control = this.theFormGroup.controls[el.name];
                this.syncPanelElementStates(el, control);
            });
        });
    }

    syncPanelElementStates(element: any, control: AbstractControl) {
        element.enabled = control.enabled;

        if (element.type == 'html') element.progressState = 'info';
        else if (element.type === 'paneldynamic') {
            if (control.touched && !control.valid) element.progressState = 'error';
            else if (control.valid && control.value && control.value.length === 0)
                element.progressState = 'empty';
            else if (control.valid && control.value && control.value.length > 0) {
                if (Object.keys(control.value[0]).length === 0) {
                    element.progressState = 'empty';
                } else {
                    element.progressState = 'complete';
                }
            }
        } else {
            if (control.touched && !control.valid) element.progressState = 'error';
            else if (control.valid && control.value !== null) element.progressState = 'complete';
            else element.progressState = 'empty';
        }
    }
    setActiveElement(elementName) {
        this.progressClick.emit(elementName);
    }
}
