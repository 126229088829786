<app-modal
    *ngIf="createModalOpen"
    [(visible)]="createModalOpen"
    [context]="this"
    [saveFunc]="createSurvey"
    [saveBtnDisabled]="!nuForm.valid"
    [saveBtnText]="'Registry.Create'"
    [showCloseBtn]="false"
    [closeFunc]="closeCreateModal"
    [centerModal]="false"
    [appBtnWidth]="'97%'"
    [btnWidth]="'97%'"
    [modalWidth]="'35vw'"
    [modalHeight]="'95vh'"
    id="createSurveyModal"
    class="dynamic-panel-modal"
>
    <div class="card-body p-0 px-1" [formGroup]="newSurveyForm" #nuForm="ngForm">
        <div class="container-fluid px-0">
            <p
                style="
                    color: var(--colors-text-text-primary-900, #101828);
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 28px;
                    margin-bottom: 0px;
                "
            >
                Enter survey details
            </p>
            <p class="description">
                Required fields are noted with a <span class="required">*</span>
            </p>

            <div class="form-group row">
                <div class="col p-0">
                    <p class="input-title">Survey title <span class="required">*</span></p>
                    <input
                        aria-label="Survey Title"
                        placeholder="Enter a title"
                        type="text"
                        class="form-control"
                        id="title"
                        formControlName="title"
                        [ngClass]="{
                            'is-invalid':
                                newSurveyForm.get('title')?.invalid &&
                                (newSurveyForm.get('title')?.touched ||
                                    newSurveyForm.get('title')?.dirty),
                        }"
                        ngModel
                        required
                    />
                    <p
                        class="description mt-1"
                        [ngClass]="{
                            'text-danger':
                                newSurveyForm.get('title')?.invalid &&
                                (newSurveyForm.get('title')?.touched ||
                                    newSurveyForm.get('title')?.dirty),
                        }"
                    >
                        {{
                            newSurveyForm.get('title')?.invalid &&
                            (newSurveyForm.get('title')?.touched ||
                                newSurveyForm.get('title')?.dirty)
                                ? 'Please enter a title to create your survey'
                                : 'This is displayed in Composer to help you locate your survey'
                        }}
                    </p>
                </div>
            </div>

            <div class="form-group row mt-2">
                <div class="col p-0">
                    <p class="input-title">Survey display name <span class="required">*</span></p>
                    <input
                        aria-label="Survey Display Name"
                        placeholder="Enter a display name"
                        type="text"
                        class="form-control"
                        id="display-name"
                        formControlName="displayName"
                        [ngClass]="{
                            'is-invalid':
                                newSurveyForm.get('displayName')?.invalid &&
                                (newSurveyForm.get('displayName')?.touched ||
                                    newSurveyForm.get('displayName')?.dirty),
                        }"
                        ngModel
                    />
                    <p
                        class="description mt-1"
                        [ngClass]="{
                            'text-danger':
                                newSurveyForm.get('displayName')?.invalid &&
                                (newSurveyForm.get('displayName')?.touched ||
                                    newSurveyForm.get('displayName')?.dirty),
                        }"
                        style="min-height: 40px"
                    >
                        {{
                            newSurveyForm.get('displayName')?.invalid &&
                            (newSurveyForm.get('displayName')?.touched ||
                                newSurveyForm.get('displayName')?.dirty)
                                ? 'Please enter a display name if you want it different from the survey title'
                                : 'This is the name that will be displayed to users in Healthie. Enter a name if you want this to be different from the survey title.'
                        }}
                    </p>
                </div>
            </div>

            <div class="form-group row mt-2">
                <div class="col p-0">
                    <p class="input-title">Description</p>
                    <textarea
                        aria-label="Description"
                        placeholder="e.g. This survey will be used to collect your demographics"
                        class="form-control"
                        id="description"
                        formControlName="description"
                        ngModel
                    ></textarea>
                    <p class="description mt-1">
                        This description will be displayed to users in Healthie to give them
                        additional information regarding the content of the survey.
                    </p>
                </div>
            </div>

            <div class="form-group row mt-2">
                <div class="col p-0">
                    <p class="input-title">Copyright attribution</p>
                    <textarea
                        aria-label="Copyright"
                        placeholder="e.g. (c) Pulse Inforname. This survey is a trademark of Pulse Inforname"
                        type="text"
                        class="form-control"
                        id="copyright"
                        formControlName="copyright"
                        ngModel
                    ></textarea>
                    <p class="description mt-1">
                        Enter a copyright or attribution if you are using a validated instrument or
                        copyrighted survey tool.
                    </p>
                </div>
            </div>

            <div class="form-group row mt-2">
                <div class="col p-0">
                    <p class="input-title">Registry</p>
                    <select
                        aria-label="Registry"
                        class="form-control w-50"
                        id="registry"
                        formControlName="registry"
                        ngModel
                    >
                        <option value="" disabled>Select registry</option>
                        <option *ngFor="let registry of listRegistries" [value]="registry">
                            {{ registry }}
                        </option>
                    </select>
                    <p class="description mt-1">
                        Select which registry this survey will be applied to.
                    </p>
                </div>
            </div>
        </div>
    </div>
</app-modal>

<div class="container-fluid panel-control">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body data-table-ctrl-1200">
                    <div class="d-flex justify-content-between">
                        <h1>Surveys</h1>
                        <div class="d-flex justify-content-end mb-2">
                            <app-button
                                (click)="openCreateModal()"
                                class="mx-3"
                                [buttonText]="'Registry.BuildNew'"
                            />
                            <app-button
                                [iconConfig]="{ iconClasses: 'fa-regular fa-sliders-v' }"
                                style="opacity: 0.5; cursor: default; margin-bottom: 8px"
                                (action)="showToast()"
                            />
                        </div>
                    </div>
                    <div>
                        <app-loading-component [loading]="loading">
                            <app-table-component
                                pagination="true"
                                [paginationPageSize]="25"
                                [columnDefs]="colDefs"
                                [rowData]="surveys"
                                [filterModelKey]="'survey_builder'"
                            >
                            </app-table-component>
                        </app-loading-component>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
