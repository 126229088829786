<div *ngIf="mode === 'view'; else formModeTemplate">
    <div *ngIf="!disabled" class="pi-control-edit-mode">
        <app-pi-form-view-response
            [element]="element"
            [theFormControl]="formControl"
            [displayValue]="stringValue"
        ></app-pi-form-view-response>
    </div>
</div>
<ng-template #formModeTemplate>
    <div *ngIf="!disabled" class="w-100 row pi-control-form-mode">
        <label class="form-label col-12 col-md-6 col-lg-8">
            <app-element-title [element]="element"></app-element-title>
        </label>

        <div class="col-12 col-md-6 col-lg-4">
            <ng-container *ngIf="element && !element.dateRemoveDay; else noDay">
                <ngb-datepicker
                    #dp
                    [(ngModel)]="dateValue"
                    navigation="select"
                    [markDisabled]="isDisabled"
                    outsideDays="element?.DateIsDayRequired ? shown : hidden"
                    [weekdays]="element?.DateIsDayRequired"
                    [ngClass]="calendarStyleSwitcher(element)"
                    [minDate]="minDate"
                    [maxDate]="maxDisplayDate"
                    (dateSelect)="onDateSelect($event)"
                    (navigate)="textInput($event)"
                >
                </ngb-datepicker>
            </ng-container>

            <ng-template #noDay>
                <ng-container *ngIf="element && showPartialWithMonth; else noMonth">
                    <div class="d-flex gap-2">
                        <select
                            name="EUID()+monthControl"
                            class="form-select"
                            aria-label="Select month"
                            (change)="monthSelected($event)"
                            [value]="monthControl"
                        >
                            <option
                                class="text-muted"
                                value=""
                                [attr.selected]="currentMonthValue === '' ? '' : null"
                            >
                                Select Month
                            </option>
                            <option
                                *ngFor="let mon of monthOptions"
                                [attr.selected]="currentMonthValue === mon.value ? '' : null"
                                [value]="mon.value"
                            >
                                {{ mon.text }}
                            </option>
                        </select>
                        <select
                            name="EUID()+yearControl"
                            class="form-select"
                            aria-label="Default select example"
                            (change)="yearSelected($event)"
                            [value]="currentYearValue"
                        >
                            <option
                                class="text-muted"
                                value=""
                                [attr.selected]="currentYearValue === '' ? '' : null"
                            >
                                Select Year
                            </option>
                            <option
                                *ngFor="let year of yearOptions"
                                [attr.selected]="currentYearValue === year ? '' : null"
                                [value]="year"
                            >
                                {{ year }}
                            </option>
                        </select>
                    </div></ng-container
                >
            </ng-template>
            <ng-template #noMonth>
                <div class="d-flex">
                    <select
                        name="EUID()+yearControl"
                        class="form-select"
                        aria-label="Default select example"
                        (change)="yearSelected($event)"
                        [value]="currentYearValue"
                    >
                        <option
                            class="text-muted"
                            value=""
                            [attr.selected]="currentYearValue === '' ? '' : null"
                        >
                            Select Year
                        </option>
                        <option
                            *ngFor="let year of yearOptions"
                            [attr.selected]="currentYearValue === year ? '' : null"
                            [value]="year"
                        >
                            {{ year }}
                        </option>
                    </select>
                </div>
            </ng-template>
        </div>

        <app-validator
            [control]="formControl"
            [customErrorMessages]="customErrorMessages"
            [warningControl]="warningControl"
            [customWarningMessages]="customWarningMessages"
        ></app-validator>
    </div>
</ng-template>
