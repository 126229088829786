<div class="d-flex align-items-center justify-content-center mb-2">
  <div
    [ngClass]="[getBackgroundStyles(), getBackgroundSize()]"
    [ngStyle]="getStyles()"
  >
    <div [ngClass]="[getSizeClass()]">
      <i [class]="getIcon()"></i>
    </div>
  </div>
</div>
