import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScheduleEditorComponent } from './schedule-editor/schedule-editor.component';
import { ScheduleManagementRoutingModule } from './schedule-management-routing.module';
import { SharedModule } from '@h20-shared/shared.module';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { FormListEditorComponent } from './components/form-list-editor/form-list-editor.component';
import { ScheduledIntervalComponentComponent } from './components/scheduled-interval-component/scheduled-interval-component.component';

@NgModule({
    declarations: [
        ScheduleEditorComponent,
        FormListEditorComponent,
        ScheduledIntervalComponentComponent,
    ],
    imports: [CommonModule, ScheduleManagementRoutingModule, SharedModule, NgJsonEditorModule],
})
export class ScheduleManagementModule {}
