import { Component, OnInit } from '@angular/core';
import { ConfigService } from '@h20-services/config.service';
import { EnvironmentService } from '@h20-services/environment.service';
import { IRegistryConfiguration } from '@h20-services/models/registry_configuration/IRegistryConfiguration';

@Component({
    selector: 'app-thank-you',
    templateUrl: './thank-you.component.html',
    styleUrls: ['./thank-you.component.scss'],
})
export class ThankYouComponent implements OnInit {
    //#region Fields

    isInit: boolean;
    loading: boolean;
    saving: boolean;

    summaryScoreShort: string;
    PROScore: number;
    summaryScoreLong: string;
    content1: string;
    content2: string;
    taskCompleteMsg: string;

    listRegConfigs: IRegistryConfiguration[];
    listRegistries: string[];

    selectedRegistry: IRegistryConfiguration;

    //#endregion

    constructor(
        private config_svc: ConfigService,
        private environmentSvc: EnvironmentService,
        ) {}

    ngOnInit(): void {
        this.isInit = false;
        this.loading = true;

        this.PROScore = 9000;

        this.config_svc
            .listRegistryConfig()
            .toPromise()
            .then((regRes) => {
                this.listRegConfigs = regRes;
                this.listRegistries = regRes.map((reg) => reg.registry_id);

            this.environmentSvc.currentRegistry.subscribe((environment) => {
                if(environment)this.reloadRegistrationContent(environment);
            });

                this.loading = false;
            });

    }

    reloadRegistrationContent(event) {
        this.isInit = true;

        this.selectedRegistry = this.listRegConfigs.find((reg) => reg.registry_id === event);
        const tyItems = this.selectedRegistry.thankyou_content;

        this.summaryScoreShort = this.getText(tyItems?.summaryScoreShort);
        this.summaryScoreLong = this.getText(tyItems?.summaryScoreLong);
        this.content1 = this.getText(tyItems?.content1);
        this.content2 = this.getText(tyItems?.content2);
        this.taskCompleteMsg = this.getText(tyItems?.taskCompleteMsg);
    }

    onTextChange(item, key, evt) {
        if (!this.selectedRegistry.thankyou_content) this.selectedRegistry.thankyou_content = {};
        if (!this.selectedRegistry.thankyou_content[item])
            this.selectedRegistry.thankyou_content[item] = {};

        if (evt.target.value) {
            this.selectedRegistry.thankyou_content[item][key] = evt.target.value;
            this.selectedRegistry.thankyou_content[item]['en'] = evt.target.value;
        } else {
            delete this.selectedRegistry.thankyou_content[item];
        }
        this.reloadRegistrationContent(this.selectedRegistry.registry_id);
    }

    getText(strOrLangs): string {
        if (!strOrLangs) return '';
        return strOrLangs['default'] || strOrLangs['en'] || strOrLangs;
    }

    saveThankyouContent() {
        this.saving = true;
        this.config_svc
            .updateRegistryConfig(
                this.selectedRegistry,
                'thankyou_content',
                this.selectedRegistry.thankyou_content
            )
            .subscribe(() => {
                this.saving = false;
            });
    }
}
