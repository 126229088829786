<div class="card">
    <div class="card-header">Choices Manager</div>

    <div class="card-body">
        <div class="row mb-2">
            <label for="srchIn" class="col-form-label col">Search library: </label>
            <div class="col-8">
                <ng-container *ngIf="!loading && !loadFailed; else loadStatus">
                    <input
                        type="text"
                        class="form-control"
                        id="srchIn"
                        #srchIn
                        [value]="searchText"
                        (input)="searchInput(srchIn.value)"
                    />
                </ng-container>
                <ng-template #loadStatus>
                    <span>{{
                        loadFailed ? 'Failed to load choice library.' : 'Loading choice library...'
                    }}</span>
                    <app-button
                        *ngIf="loadFailed"
                        (action)="reloadDb()"
                        [design]="'secondary'"
                        [innerText]="'Retry'"
                    />
                </ng-template>
            </div>
            <app-button
                class="ms-2 col"
                (action)="searchInput('')"
                [design]="'secondary'"
                [innerText]="'Clear Search'"
            />
        </div>
        <div *ngFor="let result of results" class="card mb-2">
            <div class="card-header d-flex">
                <p>{{ result.name }}</p>
                <app-button
                    class="ms-auto"
                    (action)="result.toggle()"
                    [buttonText]="'Preview'"
                />
                <app-button
                    class="ms-2"
                    (action)="setFromDb(result.id)"
                    [buttonText]="'Add'"
                />
            </div>
            <div *ngIf="result.show" class="card-body">
                <ul>
                    <li *ngFor="let choice of result.item">{{ getText(choice.text) }}</li>
                </ul>
            </div>
        </div>
        <hr class="border border-3 border-dark" />
        <ng-container *ngFor="let ch of choices; index as i">
            <div class="d-flex flex-row justify-content-between align-item-end">
                <label for="cbtext" class="col-form-label"> Text: </label>
                <div class="col-3 ms-1 me-1">
                    <input
                        type="text"
                        class="form-control"
                        [(ngModel)]="ch.text"
                        (change)="update()"
                        name="cbtext"
                        id="cbtext"
                    />
                </div>
                <label class="col-form-label" for="cbval"> Value: </label>
                <div class="col-3 ms-1 me-1">
                    <input
                        type="text"
                        class="form-control col-2"
                        [(ngModel)]="ch.value"
                        (change)="update()"
                        name="cbval"
                        id="cbval"
                    />
                </div>

                <label class="col-form-label col-3" *ngIf="element.showIsExclusive">
                    isExclusive:
                    <input
                        type="checkbox"
                        class="form-check-input"
                        [(ngModel)]="ch.isExclusive"
                        (change)="update()"
                        name="cbexclusive"
                    />
                </label>
                <app-button
                    [design]="'destructive-primary'"
                    (action)="remove(i)"
                    [buttonText]="'Remove'"
                />
            </div>

            <hr />
        </ng-container>

        <button type="button" class="btn btn-success" (click)="add()">Add choice</button>
    </div>
</div>
