import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-object-diff',
    templateUrl: './object-diff.component.html',
    styleUrls: ['./object-diff.component.scss'],
    encapsulation: ViewEncapsulation.None, //required for styles to apply to the innerHTML
})
export class ObjectDiffComponent {
    @Input() public obj: any;
}
