import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@h20-services/auth.service';
import { CommonService, EnvType } from '@h20-services/common.service';
import { ComposerRoleService } from '@h20-services/composer-role.service';
import { ConfigService } from '@h20-services/config.service';
import { EnvironmentService } from '@h20-services/environment.service';
import { PulseAuth } from '@h20-services/models/PulseAuth';
import {Roles} from '@h20-services/enums/roles/roles';
import { ClaimService } from '@h20-services/claim.service';

@Component({
    selector: 'app-layout-nav',
    templateUrl: './layout-nav.component.html',
    styleUrls: ['./layout-nav.component.scss'],
})
export class LayoutNavComponent implements OnInit {
    userPreferredName: string;
    selectedRole: string;

    constructor(
        private router: Router, 
        private auth: AuthService, 
        private commonSvc: CommonService,
        private environmentSvc: EnvironmentService,
        private config_svc: ConfigService,
        private roleSvc: ComposerRoleService,
        private claimService: ClaimService,
    ) {
        roleSvc.currentRole.subscribe(() => {
            this.userClaims = this.claimService.getUserClaims();
            
            this.destinationEnvs = this.commonSvc.listTargetEnvs().filter((env) => {
                switch(env){
                    case EnvType.Development:
                        return this.userClaims.h20ComposerAdmin ? env : false;
                    case EnvType.Production:
                        return this.userClaims.productionDeployment_fullAccess ? env : false;
                    case EnvType.Preprod:
                        return this.userClaims.productionDeployment_fullAccess ? env : false;
                    default:
                        return env;
                }
            });
            
        });
    }
    sourceEnvs = this.commonSvc.listSourceEnvs();
    destinationEnvs;

    sourceIsSelected = false;
    destinationIsSelected = false; 
    registryIsSelected = false;

    selectedSourceEnv: string;
    selectedDestinationEnv: string; 
    selectedRegistry: string;

    //registry selector
    allRegistries: any[];
    listRegistries: string[];

    userClaims;
    canChangeRoles;
    roleEntries;
        

    ngOnInit(): void {
        this.canChangeRoles = this.roleSvc.getRoleClaims();
        this.roleEntries = this.roleSvc.getRoleEntries();
        this.auth
            .getPulseAuth()
            .then((user: PulseAuth) => {
                this.userPreferredName = user.getPreferredName();
            })
            .catch((err) => {
                console.error(err);
            });
            this.config_svc
            .listRegistryConfig()
            .toPromise()
            .then((regRes) => {
                this.listRegistries = regRes
                    .filter((reg) =>reg.status === "active")
                    .map((reg) => reg.registry_id);
                if(this.userClaims.h20ComposerAdmin) this.listRegistries.push("All")
            });

        this.environmentSvc.currentSource.subscribe( (environment) => this.selectedSourceEnv = environment);
        this.environmentSvc.currentDestination.subscribe( (environment) => this.selectedDestinationEnv = environment);
        this.environmentSvc.currentRegistry.subscribe((registry) => this.selectedRegistry = registry);
        this.roleSvc.currentRole.subscribe((role) => this.selectedRole = role);

        if(this.selectedSourceEnv) this.sourceIsSelected = true;
        if(this.selectedDestinationEnv) this.destinationIsSelected = true;
        if(this.selectedRegistry) this.registryIsSelected = true;

        
    }

    logout(): void {
        this.router.navigate(['/logout']);
    }

    updateSession(event: Event, whichEnv: string): void {
        const selectElement = event.target as HTMLSelectElement;
        const value = selectElement.value;

        if(whichEnv === "source"){
            this.sourceIsSelected = true;
            this.environmentSvc.changeSource(value);
        } else if(whichEnv === "destination"){
            this.destinationIsSelected = true;
            this.environmentSvc.changeDestination(value);
        } else if(whichEnv === "registry"){
            this.registryIsSelected = true;
            this.environmentSvc.changeRegistry(value);
        }
    }

    onRoleChange(event: Event): void {
        const inputElement = event.target as HTMLInputElement;
        this.selectedRole = inputElement.value;
        this.roleSvc.changeRole(this.selectedRole);
    }
}
