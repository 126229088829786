import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DeployService } from '@h20-services/deploy.service';
import { CommonService, EnvType } from '@h20-services/common.service';
import { IConfigTable } from '@h20-services/models/deploy/IConfigTable';
import { BadgeTooltip } from '@h20-shared/button/button.component';
import { ManageConfigMode } from '@h20-services/enums/deploy/ManageConfigMode';
import { ManageConfigDetailComponent } from '../manage-config-detail/manage-config-detail.component';
import { RowHeightCache } from '@swimlane/ngx-datatable';
import { EnvironmentService } from '@h20-services/environment.service';
import { IDeployTracking } from '@h20-services/models/deploy/IDeployTracking';
import { PulseAuth } from '@h20-services/models/PulseAuth';
import { AuthService } from '@h20-services/auth.service';
import { DeployStatus } from '@h20-services/enums/deploy/DeployStatus';
import { ObjectDiffService } from '@h20-shared/object-diff/object-diff.service';

@Component({
    selector: 'app-manage-config',
    templateUrl: './manage-config.component.html',
    styleUrls: ['./manage-config.component.scss'],
})
export class ManageConfigComponent implements OnInit {

    constructor(
        private router: Router, 
        private deployService: DeployService,
        private commonSvc: CommonService,
        private environmentSvc: EnvironmentService,
        private deploySvc: DeployService,
        private auth: AuthService,
        private objectDiff: ObjectDiffService,
    ) {
        this.deployItems = this.deployItems.bind(this);
        this.environmentSvc.currentSource.subscribe((environment) => {
            this.changeSourceEnv(environment);
        });
        this.environmentSvc.currentDestination.subscribe( (environment) => {
            this.changeDestEnv(environment);
        });
    }

    envs = this.commonSvc.listSourceEnvs();

    configTables: IConfigTable[];
    numberOfItems: number;
    configItems: any[];
    configTableAttributes = [];
    selectedTable: IConfigTable = { tableKey: '' };

    configsLoading: boolean = false;
    configItemsLoading: boolean = false;
    allVersionLoading: boolean = false;
    deleteItemLoading: boolean = false;

    message: string = '';
    canEdit: boolean = true;

    //View modal
    showView = false;
    sendChild: any;

    //Compare Modal
    showCompare = false;
    itemSelection: any;

    //table
    rows : any[] = [];
    colDefs : any[] = [];
    agGrid:any;

    // Filtering
    searchKey: string;
    filteredConfigItems: any[];

    trayTitle = 'Filtering Options';
    trayTitleTooltip: string;
    toggleFiltersScroll = false;
    togglePane = '';
    toggleFilters = false;
    toggleTooltip;
    filtersOn = 0;

    tipToggleOptions: BadgeTooltip = {
        display: true,
        text: 'Click to toggle filters',
        options: {
            placement: 'bottom',
            'show-delay': 300,
            'hide-delay': 50,
        },
    };
    tooltipOptions = {
        placement: 'right',
        'show-delay': 300,
        'hide-delay': 150,
    };

    // Deploy and Compare items

    sourceEnvs = this.commonSvc.listSourceEnvs();
    targetEnvs = this.commonSvc.listTargetEnvs();
    sourceEnv: string = EnvType.Devops;;
    destinationEnv: string = EnvType.Devops;;
    canDeploy: boolean = true;

    selectedTableKey: string = '';


    sourceConfigItems: any[];
    selectedItemKeys: string[] = [];
    sourceItem: any = {};
    destItem: any = {};

    // Variables for compare items
    sourceItemRest: any;
    destItemRest: any;
    sourceView;
    destView;
    diffView;
    diffValueChanges;

    // Loading
    loading: boolean = true;
    compareLoading: boolean = false;
    resultMsgList: string[] = [];
    showResultMsg: boolean = false;
    
    authUser: PulseAuth; // login user

    ngOnInit(): void {
        if (!!sessionStorage.getItem("configTableKey")) {
            this.selectedTable.tableKey = sessionStorage.getItem("configTableKey");
        }
        this.canEdit =
            this.sourceEnv == EnvType.Development || this.sourceEnv == EnvType.Devops
                ? true
                : false;

        if (!!sessionStorage.getItem("env")) {
            this.changeSourceEnv(sessionStorage.getItem("env"));
        } else{
            this.loadConfig();
        }
        this.auth.getPulseAuth().then((currAuthUser: PulseAuth) => {
            this.authUser = currAuthUser;
        });
        this.canDeploy = this.checkCanDeploy();
        
    }

    loadConfig() {
        this.message = '';
        this.configsLoading = true;
        this.deployService
            .listConfigTable(this.sourceEnv)
            .toPromise()
            .then((res) => {
                this.configTables = res;
                this.configsLoading = false;
                if (this.selectedTable.tableKey && this.selectedTable.tableKey !== '') {
                    this.loadConfigItems(this.selectedTable.tableKey);
                }
            })
            .catch((err) => {
                this.setErrorHandler(err, `Cannot load config tables on ${this.sourceEnv}`);
                if (this.configTables) this.configTables = [];
                this.configsLoading = false;
            });
    }


    createTable():boolean{
        if(!this.selectedTable.tableKey) return false;
        this.rows = this.loadRows();
        this.colDefs = this.loadColumns();
        return true;
    }

    listItems(){
        return this.configItems;
    }

    loadRows() {
       const items: any[] = this.configItems;
        const finishedItems = [];
    
        items.forEach((entry) => {
            const result = {};
            Object.entries(entry).forEach(([key, value]) => {
                if(key.includes('#') || key === 'id'){
                    const keyParts = key.split("#");
                    const valueParts = String(value).split("#");
        
                    keyParts.forEach((part, index) => {
                        result[part] = valueParts[index];
                    });

                } else {
                    result["otherValues"] += JSON.stringify(value);
                }
                result[key] = value;    
            });

            finishedItems.push(result);
        });
        return finishedItems;
    }
   
    loadColumns() {
        const columns = [];
        columns.push({
            headerName: '',
            field: '',
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            onselectionchange: this.onSelect,
            width: 50
        },{
            headerName: 'Actions',
            field: 'id',
            width: 270,
            cellDataType: 'iconBtns',
            cellRendererParams: (params) => {
                let actions = {actions: [{
                    label: 'View',
                    isVisible: true,
                    onClick: () => {
                        this.showView = true;
                        this.openView("view", params?.data); 
                    },
                    iconClass: 'fas fa-eye',
                    ['aria-label']: 'Send test email icon',
                }]};

                if(this.canEdit) {
                    actions['actions'].push({
                        label: 'Edit / view template',
                        isVisible: true,
                        onClick: () => this.openConfigItemDetail('edit', params?.data),
                        iconClass: 'fas fa-pencil-alt',
                        ['aria-label']: 'Edit template icon',
                    },
                    {
                        label: 'Delete',
                        isVisible: true,
                        onClick: () => this.deleteConfigItem(params?.data),
                        iconClass: 'fas fa-trash',
                        ['aria-label']: 'Delete template icon',
                    },
                    {
                        label: 'Copy',
                        isVisible: true,
                        onClick: () => this.openConfigItemDetail('duplicate', params?.data),
                        iconClass: 'fas fa-copy',
                        ['aria-label']: 'Copy template icon',
                    })
                }
                return actions;       
            },
        }); 

        const headers = this.selectedTable.partitionKeyName.split("#");    
        headers.forEach((header) => {
            switch (header) {
                case "id":
                    columns.push({
                        'headerName': 'Claim ID',
                        'field': 'id',
                        filter: 'agTextColumnFilter',
                    });
                    columns.push({
                        'headerName': 'Name',
                        'field': 'name',
                        filter: 'agTextColumnFilter',
                    });
                    columns.push({
                        'headerName': 'Description',
                        'field': 'description',
                        filter: 'agTextColumnFilter',
                    });
                    break;
                case "site_id":
                    columns.push({
                        'headerName': 'Site',
                        'field': 'site_id',
                        filter: 'agTextColumnFilter',
                    });
                    break;
                case "preferred_language":
                    columns.push({
                        'headerName': 'Language',
                        'field': 'preferred_language',
                        filter: 'agTextColumnFilter',
                    });
                    break;
                case "registry_id":
                    columns.push({
                        'headerName': 'Registry',
                        'field': 'registry_id',
                        filter: 'agTextColumnFilter',
                    });
                    break;
                case "organization":
                    columns.push({
                        'headerName': 'Organization',
                        'field': 'organization',
                        filter: 'agTextColumnFilter',
                    });
                    break;
                case "template":
                    columns.push({
                        'headerName': 'Template',
                        'field': 'template',
                        filter: 'agTextColumnFilter',
                    });
                    break;
                case "role":
                    columns.push({
                        'headerName': 'Role',
                        'field': 'role',
                        filter: 'agTextColumnFilter',
                    });
                    break;
                case "user_id":
                    columns.push({
                        'headerName': 'User ID',
                        'field': 'user_id',
                        filter: 'agTextColumnFilter',
                    });
                    break;
                
                default:
                    console.warn(`Unexpected header: ${header}`);
            }
        });
        columns.push({
            'headerName': 'Updated Date',
            'field':'created',
            filter:'agTextColumnFilter',
            wrapText:"false"
        });
        columns.push({
            'headerName': 'Item Values',
            'field':'otherValues',
            filter:'agTextColumnFilter',
            wrapText:"false"
        });
        return columns;
    }

    onSelect(event) {
        this.itemSelection = event.api.getSelectedRows();
        this.agGrid = event;
    }

    
    changeSourceEnv(env) {
        this.message = '';
        this.sourceEnv = env;
        this.canEdit =
            this.sourceEnv == EnvType.Development || this.sourceEnv == EnvType.Devops
                ? true
                : false;
        this.loadConfig();
        this.canDeploy = this.checkCanDeploy();
        this.clearSelectedData();
        this.loadConfig();
    }

    changeDestEnv(env) {
        this.destinationEnv = env;
        this.canDeploy = this.checkCanDeploy();
        this.clearSelectedData();
    }

    checkCanDeploy(): boolean {
        return (
            (this.sourceEnv == EnvType.Development && this.destinationEnv == EnvType.Devops) ||
            (this.sourceEnv == EnvType.Devops && this.destinationEnv == EnvType.Development) ||
            (this.sourceEnv == EnvType.Devops && this.destinationEnv == EnvType.Preprod) ||
            (this.sourceEnv == EnvType.Preprod && this.destinationEnv == EnvType.Production)
        );
    }

    loadConfigItems(configTableKey: string) {
        this.message = '';
        this.configItemsLoading = true;
        this.configTableAttributes = [];
        this.configTables.map((ct) => {
            if (ct.tableKey === configTableKey) this.selectedTable = ct;
        });
        this.deployService
            .getConfigItems(configTableKey, this.sourceEnv)
            .toPromise()
            .then((configRes) => {
                // Set attributes list
                let attrs = [];
                configRes.map((cr) => {
                    for (let key in cr) {
                        if (
                            key != this.selectedTable.partitionKeyName &&
                            key != this.selectedTable.sortKeyName &&
                            key != 'created'
                        ) {
                            attrs.push(key);
                        }
                    }
                });
                this.configTableAttributes = [...new Set(attrs)];

                // Sort items with partition key
                configRes.sort((a, b) => {
                    const nameA = a[this.selectedTable.partitionKeyName];
                    const nameB = b[this.selectedTable.partitionKeyName];
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                });

                // Display items as string on the list
                this.configItems = configRes;
                this.numberOfItems = this.configItems.length;
                this.filteredConfigItems = this.configItems;
                this.configItemsLoading = false;

                this.createTable();
                sessionStorage.setItem('configTableKey', configTableKey);
            })
            .catch((err) => {
                this.setErrorHandler(err, `Cannot load config items on ${this.sourceEnv}`);
                if (this.configItems) this.configItems = [];
                if (this.filteredConfigItems) this.filteredConfigItems = [];
                this.numberOfItems = 0;
                this.configItemsLoading = false;
            });
            this.selectedTableKey = this.selectedTable.tableKey;
    }

    // Filter -------------------------
    trayToggle($ev: string): any {
        this.togglePane = $ev;
        this.toggleFilters = !this.toggleFilters;
    }

    innerToggle($ev: boolean): any {
        this.togglePane = '';
        this.toggleFilters = $ev;
    }

    toggle(): void {}

    getSearchKey(event) {
        this.searchKey = event;
    }

    filterConfigItems() {
        if (!this.searchKey || this.searchKey === '') {
            if (this.selectedTable.tableKey) {
                this.filteredConfigItems = this.configItems;
            }
        } else {
            this.message = '';
            if (this.configItems && this.configItems.length !== 0) {
                this.filteredConfigItems = this.configItems.filter((dep: any) => {
                    const flag =
                        this.searchKey === '' ||
                        (dep[this.selectedTable.partitionKeyName] || '')
                            .toString()
                            .includes((this.searchKey || '').toString());
                    return flag;
                });
            }
            this.numberOfItems = this.filteredConfigItems ? this.filteredConfigItems.length : 0;
        }
    }
    // -------------------------

    deleteConfigItem(item) {
        this.message = '';
        if (
            confirm(
                `This will delete all versions of the item. Are you sure you want to delete it?`
            )
        ) {
            this.deleteItemLoading = true;
            this.deployService
                .deleteConfigItem(
                    this.selectedTable.tableKey,
                    item[this.selectedTable.partitionKeyName],
                    this.sourceEnv
                )
                .then((res) => {
                    this.message = 'Delete item successfully.';
                    this.deleteItemLoading = false;
                    this.loadConfigItems(this.selectedTable.tableKey);
                })
                .catch((err) => {
                    this.setErrorHandler(err, `Cannot delete the config item on ${this.sourceEnv}`);
                    this.deleteItemLoading = false;
                });
        }
    }

    openConfigItemDetail(mode, item?) {
        let partitionKey;
        if (mode === ManageConfigMode.create) {
            partitionKey = 'new';
        } else {
            partitionKey = item[this.selectedTable.partitionKeyName];
        }

        this.router.navigate([
            'deploymanagement',
            'manage-config',
            mode,
            this.sourceEnv,
            this.selectedTable.tableKey,
            this.selectedTable.partitionKeyName,
            partitionKey,
        ]);
    }

    openView(mode, item?){
        let partitionKey;
        if (mode === ManageConfigMode.create) {
            partitionKey = 'new';
        } else {
            partitionKey = item[this.selectedTable.partitionKeyName];
        }

        this.sendChild= {
            mode:mode,
            targetEnv :this.sourceEnv,
            selectedTable:this.selectedTable.tableKey,
            partitionKeyName:this.selectedTable.partitionKeyName,
            partitionKey:partitionKey,
        };
    }
    // ------------- Deploy Config --------------------- 
    deployItems = async (): Promise<void> => {
        this.message = '';
        this.resultMsgList = [];
        const itemKeys = this.getSelectedItems();
        if (itemKeys.length === 0) {
            this.message = 'Please Select Item(s).';
        } else {
            if (confirm(`Are you sure you want to deploy the items to ${this.destinationEnv}?`)) {
                this.configItemsLoading = true;
                try {
                    for (let i = 0; i < itemKeys.length; i++) {
                        let pKey = itemKeys[i];
                        const sItem = this.configItems.find(
                            (sc) => sc[this.selectedTable.partitionKeyName] == pKey
                        );

                        const dItem = await this.deploySvc
                            .getConfigItem(this.selectedTableKey, pKey, this.destinationEnv)
                            .toPromise();

                        sItem.latest_version = parseFloat(sItem.latest_version);
                        let deployResult;
                        if(this.destinationEnv == EnvType.Development) {
                            deployResult = await this.deploySvc.versionSavedDeployConfigItem(
                                this.selectedTable.tableKey,
                                this.selectedTable.partitionKeyName,
                                dItem.version,
                                sItem,
                                dItem,
                                this.destinationEnv
                            )
                        } else {
                            deployResult = await this.deploySvc.deployConfigItem(
                                this.selectedTable,
                                sItem,
                                this.destinationEnv
                            );
                        }
                        this.resultMsgList.push(deployResult.message);

                        if (deployResult.done) {
                            let diffValue;
                            if (dItem) {
                                const diff = this.objectDiff.diff(
                                    this.getRestProperty(sItem),
                                    this.getRestProperty(dItem)
                                );
                                diffValue = this.objectDiff
                                    .toJsonDiffView(diff)
                                    .replace(/(<([^>]+)>)/gi, '');
                            } else {
                                diffValue = 'new item';
                            }

                            const deployTracking: IDeployTracking = {
                                database_type: 'dynamodb',
                                deploy_status: DeployStatus.complete,
                                created_by: this.authUser.getEmailAddr(),
                                deployed_by: this.authUser.getEmailAddr(),
                                deployed: new Date(Date.now())
                                    .toISOString()
                                    .slice(0, 19)
                                    .replace('T', ' '),
                                source_env: this.sourceEnv,
                                destination_env: this.destinationEnv,
                                table_key: this.selectedTable.tableKey,
                                primary_key_name: this.selectedTable.partitionKeyName,
                                primary_key: pKey,
                                sort_key_name: 'version',
                                sort_key: sItem.latest_version || sItem.version,
                                change_set: diffValue,
                            };
                            await this.deploySvc.createDeployTracking(deployTracking).toPromise();
                        }
                        if (i === itemKeys.length - 1) {
                            this.showResultMsg = true;
                            this.configItemsLoading = false;
                            this.clearSelectedData();
                        }
                    }
                } catch (err) {
                    this.setErrorHandler(err, 'An error occurs.');
                    this.configItemsLoading = false;
                    this.clearSelectedData();
                }
            }
        }
    }
    getSelectedItems(): any[] {
        this.selectedItemKeys = [];
        for (let key in this.itemSelection) {
            if (this.itemSelection[key]) {
                this.selectedItemKeys.push(this.itemSelection[key][this.selectedTable.partitionKeyName]);
            }
        }
        return this.selectedItemKeys;
    }

    clearSelectedData(isClearSelection: boolean = true) {
        if (isClearSelection){
            this.itemSelection = {};
            this.selectedItemKeys = [];
            if(this.agGrid) this.agGrid.api.deselectAll();
        } 

        this.sourceItem = {};
        this.sourceItemRest = {};
        this.destItem = {};
        this.destItemRest = {};

        this.sourceView = '';
        this.destView = '';
        this.diffView = '';
        this.diffValueChanges = '';
    }

    getRestProperty(item): any {
        const {
            [this.selectedTable.partitionKeyName]: removePK,
            ['version']: removeSK,
            ['latest_version']: removeLatestVersion,
            ['created']: removeCreated,
            ...rest
        } = item;
        return rest;
    }

    showCompareModal(){
        this.message = '';
        const itemKeys = this.getSelectedItems();
        if (itemKeys.length !== 1) {
            this.message = 'Select Only 1 item';
        } else {
            this.sourceItem = itemKeys;
            this.showCompare = true;
            this.checkCompareItems(this);
        }
    }

    // Compare Items
    checkCompareItems(context) {
        this.deconstruct(context);
        const itemKeys = this.getSelectedItems();
        context.loadCompareItems(itemKeys[0], context);
        
         
    }
    

    loadCompareItems(selectedItemKey) {
        this.compareLoading = true;    
        for (let item of this.configItems) {
            if (item[this.selectedTable.partitionKeyName] === selectedItemKey) {
                this.sourceItem = item;
            }
        }

        this.sourceItemRest = this.getRestProperty(this.sourceItem);
        this.deploySvc
            .getConfigItem(this.selectedTableKey, selectedItemKey, this.destinationEnv)
            .toPromise()
            .then((confRes) => {
                if (confRes) {
                    this.destItem = confRes;
                    this.destItemRest = this.getRestProperty(this.destItem);
    
                    this.sourceView = this.objectDiff.objToJsonView(this.sourceItemRest);
                    this.destView = this.objectDiff.objToJsonView(this.destItemRest);
                    let diff = this.objectDiff.diff(this.destItemRest, this.sourceItemRest);
                    this.diffView = this.objectDiff.toJsonView(diff);
                    this.diffValueChanges = this.objectDiff.toJsonDiffView(diff);
                } else {
                    this.sourceView = this.objectDiff.objToJsonView(this.sourceItemRest);
                    this.message = `The config item doesn't exist on ${this.destinationEnv}`;
                }
                this.compareLoading = false;
            })
            .catch((err) => {
                this.message = `Error on getting config item on ${this.destinationEnv}`;
                this.compareLoading = false;
            });
    }

    closeCompareModal() {
        this.showCompare = false;
        this.itemSelection = undefined;
        this.selectedItemKeys = undefined;
    }

    deconstruct(context) {
        this.selectedTable = context.selectedTable;
        this.configItems = context.configItems;
        this.selectedTableKey = context.selectedTable.tableKey;
        this.sourceEnv = context.sourceEnv;
        this.destinationEnv = context.destinationEnv;
        this.objectDiff = context.objectDiff;
        this.authUser = context.authUser;
        this.message = context.message;
        this.showCompare = context.showCompare;
        this.itemSelection = context.itemSelection;
        this.selectedItemKeys = context.selectedItemKeys;
        this.sourceItem = context.sourceItem;
        this.destItem = context.destItem;
        this.sourceItemRest = context.sourceItemRest;
        this.destItemRest = context.destItemRest;
        this.sourceView = context.sourceView;
        this.destView = context.destView;
        this.diffView = context.diffView;
        this.diffValueChanges = context.diffValueChanges;
        this.compareLoading = context.compareLoading;
    }
    
    


    setErrorHandler(err, msg?) {
        console.error(err);
        if (msg) this.message = msg;
    }
}
