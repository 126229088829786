import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Hub } from '@aws-amplify/core';
import { AuthService } from '@h20-services/auth.service';
import { ITracker } from '@h20-services/models/ITracker';
import { TrackerService } from '@h20-services/tracker.service';
import { PulseAuth } from '@h20-services/models/PulseAuth';
import { LogService } from '@h20-services/log.service';

@Component({
    selector: 'app-tracker',
    templateUrl: './tracker.component.html',
    styleUrls: ['./tracker.component.scss'],
})
export class TrackerComponent implements OnInit {
    subscriptions: Subscription[] = [];
    tracker: ITracker = {};

    constructor(
        private activatedRoute: ActivatedRoute,
        private authSvc: AuthService,
        private trackSvc: TrackerService,
        private logSvc: LogService
    ) {}

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe((params) => {
            if (params && params.hasOwnProperty('partner')) {
                this.tracker.partner = params.partner;
            }

            this.tracker.referrer = window.document.referrer || window.parent.location.href;
            this.tracker.browser = window.navigator.userAgent.toLowerCase();

            this.trackSvc
                .create(this.tracker)
                .toPromise()
                .then((tracker: any) => {
                    if (tracker) this.tracker = tracker.tracker;
                })
                .catch((error: any) => {});
        });

        Hub.listen('auth', (data) => {
            if (data.payload.event === AuthService.SIGN_IN_EVENT) {
                this.authSvc.getPulseAuth().then((pulseAuth: PulseAuth) => {
                    if (pulseAuth) {
                        this.tracker.email = pulseAuth.getEmailAddr();
                        this.tracker.user_id = pulseAuth.getUserID();
                        this.trackSvc
                            .update(this.tracker)
                            .toPromise()
                            .then((tracker: any) => {
                                // ToDo: Need to revisit audit log process
                                let auditLog = this.logSvc.auditLogInfo;
                                auditLog.action = `Logged in.`;
                                auditLog.user_ID = pulseAuth.getUserID();
                                this.logSvc.createAuditLog(auditLog).subscribe(() => {});
                            })
                            .catch((error: any) => {});
                    }
                });
            }
        });
    }
}
