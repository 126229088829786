import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClaimService } from '@h20-services/claim.service';
import { CommonService, EnvType } from '@h20-services/common.service';
import { EnvironmentService } from '@h20-services/environment.service';
import { ColDef } from 'ag-grid-community';

@Component({
    selector: 'app-claims',
    templateUrl: './claims.component.html',
    styleUrls: ['./claims.component.scss'],
})
export class ClaimsComponent implements OnInit {
    constructor(
        private claimService: ClaimService,
        private fb: FormBuilder,
        private commonSvc: CommonService,
        private environmentSvc: EnvironmentService
    ) {
        this.environmentSvc.currentSource.subscribe((environment) => {
            this.changeEnv(environment);
        });
    }

    envs = this.commonSvc.listSourceEnvs();
    targetEnv: string = EnvType.Devops;

    claims: any[];
    claimId: string;
    claimsLoading: boolean = false;

    viewNewClaimPanel = false;
    isNewClaim = false;
    formGroup: FormGroup;
    newClaimForm: FormGroup;

    loading = false;

    colDefs: ColDef[];

    ngOnInit(): void {
        this.loadClaims();
        this.colDefs = this.getClaimsTableColumns();
    }

    getClaimsTableColumns() {
        return [
            {
                headerName: 'ID',
                field: 'id',
            },
            {
                headerName: 'Name',
                field: 'name',
            },
            {
                headerName: 'Description',
                field: 'description',
            },
            {
                headerName: 'Required By',
                field: 'required_by',
            },
            {
                headerName: 'Edit',
                field: 'id',
                cellDataType: 'iconBtns',
                cellRendererParams: (params) => {
                    return {
                        actions: [
                            {
                                label: 'Edit',
                                isVisible: true,
                                onClick: () => this.editClaim(params?.data),
                                iconClass: 'fas fa-pencil-alt',
                                ['aria-label']: 'Edit Claim icon',
                            },
                        ],
                    };
                },
            },
            {
                headerName: 'DELETE',
                field: 'id',
                cellDataType: 'iconBtns',
                cellRendererParams: (params) => {
                    return {
                        actions: [
                            {
                                label: 'Delete',
                                isVisible: true,
                                onClick: () => this.deleteClaim(params?.data),
                                iconClass: 'fas fa-times',
                                ['aria-label']: 'Delete Claim icon',
                            },
                        ],
                    };
                },
            },
        ];
    }

    loadClaims() {
        this.claimsLoading = true;
        this.claimService.listClaim(this.targetEnv).subscribe(
            (res) => {
                this.claims = res.Items.sort((a, b) => {
                    return a.id - b.id;
                });
                this.claimsLoading = false;
            },
            (err: any) => {
                this.setErrorHandler(err, this.targetEnv);
                this.claimsLoading = false;
            }
        );
    }

    changeEnv(env) {
        this.claims = [];
        this.targetEnv = env;
        this.loadClaims();
    }

    cancelNewClaim(): void {
        this.viewNewClaimPanel = false;
        this.claimId = null;
    }

    newClaim(): void {
        this.newClaimForm = this.fb.group({
            required_by: this.fb.group({
                h20platform: [true],
                composer: [false],
            }),
            name: ['', [Validators.required]],
            description: ['', [Validators.required]],
        });
        this.viewNewClaimPanel = true;
        this.isNewClaim = true;
    }

    addNewClaim(context): void {
        this.loading = true;
        context.viewNewClaimPanel = false;
        const claimName = context.newClaimForm.value.name;
        const claimDescription = context.newClaimForm.value.description;
        const claimRequired_by = [];
        if (context.newClaimForm.value.required_by.h20platform) {
            claimRequired_by.push('h20platform');
        }
        if (context.newClaimForm.value.required_by.composer) {
            claimRequired_by.push('composer');
        }
        context.claimService
            .createClaim(claimName, claimDescription, ...claimRequired_by, context.targetEnv)
            .subscribe(
                (res: any) => {
                    context.loadClaims();
                    this.loading = false;
                },
                (err: any) => {
                    context.setErrorHandler(err, context.targetEnv);
                    this.loading = false;
                }
            );
    }

    editClaim(row): void {
        let isH20platform = false;
        let isComposer = false;
        if (row.required_by) {
            isH20platform = row.required_by.find((req) => req == 'h20platform');
            isComposer = row.required_by.find((req) => req == 'composer');
        }

        this.newClaimForm = this.fb.group({
            required_by: this.fb.group({
                h20platform: [isH20platform],
                composer: [isComposer],
            }),
            name: [row.name, [Validators.required]],
            description: [row.description, [Validators.required]],
        });
        this.claimId = row.id;
        this.viewNewClaimPanel = true;
        this.isNewClaim = false;
    }

    updateClaim(context): void {
        this.loading = true;
        context.viewNewClaimPanel = false;
        const claimName = context.newClaimForm.value.name;
        const claimDescription = context.newClaimForm.value.description;
        const claimRequired_by = [];
        if (context.newClaimForm.value.required_by.h20platform) {
            claimRequired_by.push('h20platform');
        }
        if (context.newClaimForm.value.required_by.composer) {
            claimRequired_by.push('composer');
        }
        context.claimService
            .updateClaim(
                context.claimId,
                claimName,
                claimDescription,
                claimRequired_by,
                context.targetEnv
            )
            .subscribe(
                (res: any) => {
                    context.loadClaims();
                    this.loading = false;
                },
                (err: any) => {
                    context.setErrorHandler(err, context.targetEnv);
                    this.loading = false;
                }
            );
    }

    deleteClaim(row): any {
        if (confirm(`Are you sure you want to delete the claim '${row.name}'?`)) {
            this.claimService.deleteClaim(row.id, this.targetEnv).subscribe(
                (res: any) => {
                    this.loadClaims();
                },
                (err: any) => {
                    this.setErrorHandler(err, this.targetEnv);
                }
            );
        }
    }

    setErrorHandler(err, env) {
        console.error(err);
        alert(`An error occurs on ${env}`);
    }
}
