<div class="row border-bottom">
    <div class="col-12 col-md-8"><app-element-title [element]="element"></app-element-title></div>
    <div *ngIf="theFormControl" class="col-12 col-md-4 text-end">
        <div
            *ngIf="
                (!theFormControl && !theFormControl.value)|| (!displayValue || displayValue === 'null') || theFormControl?.value?.length === 0;
                else valueTemplate
            "
            class=""
        >
            {{ 'Registry.NoAnswer' | translate }}
        </div>
        <ng-template #valueTemplate>
            <div *ngIf="!download">
                {{ displayValue }}
            </div>
        </ng-template>
        <div *ngIf="download">
            <ul class="list-group list-group-flush">
                <li
                    *ngFor="let fileItem of theFormControl"
                    class="list-group-item border-white px-0 pt-0"
                >
                    {{ fileItem.fileName || fileItem.name }}
                    <span
                        tooltip="{{
                            isRestrictedDownload
                                ? ('Surveys.DownloadRestricted' | translate)
                                : ('Withdraw.Download' | translate)
                        }}"
                    >
                        <app-button
                            *ngIf="fileItem.status !== 'deleted' && showDownload"
                            (action)="downloadClicked(fileItem.id || fileItem.fileId)"
                            [disabled]="isRestrictedDownload"
                            [buttonText]="'Withdraw.Download'"
                        />
                    </span>
                    {{ fileItem.status === 'deleted' ? '[deleted]' : '' }}
                </li>
            </ul>
        </div>
    </div>
</div>
