<div>
    <h1>Dashboard Management</h1>
    <app-loading-component [loading]="registriesLoading" [text]="'Loading Registries'">
        <div class="mb-3">
            <label>
                Select Env
                <select
                    class="w-100 form-select"
                    id="targetEnv"
                    name="targetEnv"
                    (change)="changeEnv($event.target.value)"
                >
                    <option *ngFor="let env of envs">
                        {{ env }}
                    </option>
                </select>
            </label>
            <label class="mx-3">
                Select Registry
                <select
                    class="w-100 form-select"
                    (change)="registryChange($event.target.value)"
                    name="reistry_id"
                >
                    <option value="" selected disabled>--Please Select--</option>
                    <option *ngFor="let reg of lstRegistries" [value]="reg.registry_id">
                        {{ reg.registry_id }}
                    </option>
                </select>
            </label>
            <app-loading-component
                *ngIf="selectedRegistry"
                [loading]="siteLoading"
                [text]="'loading organizations, sites & roles'"
            >
                <label>
                    Organization#Site#Role
                    <select
                        class="w-100 form-select"
                        id="sideId"
                        (change)="siteChange($event.target.value)"
                        name="sideId"
                    >
                        <option value="" selected disabled>--Please select--</option>
                        <option *ngFor="let site of lstSiteRole">
                            {{ site.name }}
                        </option>
                    </select>
                </label>
                <label>
                    <app-button (action)="addConfig()" [buttonText]="'Registry.AddNewConfig'" />
                </label>
            </app-loading-component>
        </div>
        <app-loading-component
            *ngIf="highchartsConfig || tableauConfig"
            [loading]="loading"
            [text]="'loading organizations, sites & roles'"
        >
            <div class="row">
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <h5 class="card-title">Highcharts Configuration</h5>
                        </div>
                        <div class="card-body">
                            <div class="mb-2 d-flex">
                                <select
                                    id="tileSelect"
                                    class="form-select"
                                    aria-label="Tile Select"
                                >
                                    <option *ngFor="let tile of tileList">
                                        {{ tile }}
                                    </option>
                                </select>
                                <app-button
                                    (action)="addTile()"
                                    style="width: 8em"
                                    [buttonText]="'Registry.AddTile'"
                                />
                                <app-button
                                    class="ms-auto"
                                    (action)="saveHighcharts()"
                                    style="width: 15em"
                                    [buttonText]="'Registry.SaveHighchartsConfig'"
                                />
                            </div>
                            <json-editor
                                [options]="options"
                                [data]="highchartsConfig.rows"
                                (change)="jsonEdit($event)"
                            ></json-editor>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <h5 class="card-title">Tableau Configuration</h5>
                        </div>
                        <div class="card-body">
                            <div>
                                <app-button
                                    class="mb-2"
                                    (action)="saveTableau()"
                                    [buttonText]="'Registry.SaveTableauConfig'"
                                />
                            </div>
                            <h5 class="card-title mb-2">
                                Views
                                <app-button
                                    class="float-end"
                                    (action)="addTableauView()"
                                    [buttonText]="'Registry.AddView'"
                                />
                            </h5>
                            <div class="mt-4" *ngIf="tableauConfig && tableauConfig.views">
                                <div
                                    class="d-flex mb-2"
                                    *ngFor="let view of tableauConfig.views; let i = index"
                                >
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="View Name"
                                        [value]="view.viewName"
                                        (input)="view.viewName = $event.target.value"
                                    />
                                    <input
                                        type="text"
                                        class="form-control mx-2"
                                        placeholder="Visual URL"
                                        [value]="view.visualUrl"
                                        (input)="view.visualUrl = $event.target.value"
                                    />
                                    <button
                                        class="btn-close"
                                        (click)="removeTableauView(i)"
                                    ></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </app-loading-component>
    </app-loading-component>
</div>
