import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { CommonService } from '@h20-services/common.service';

@Component({
    selector: 'app-survey-designer',
    templateUrl: './survey-designer.component.html',
    styleUrls: ['./survey-designer.component.scss'],
})
export class SurveyDesignerComponent implements OnInit, OnChanges {
    constructor(private http: HttpClient, private common: CommonService) {}

    @Input() survey: any;
    surveyPanels: any;
    formForm: any;

    ngOnInit(): void {
        this.http.get('assets/survey-builder/element-forms/form.json').subscribe((data) => {
            this.formForm = data;
        });
    }

    ngOnChanges(): void {
        if (this.survey && this.survey.pages) this.surveyPanels = this.survey.pages[0].elements;
    }

    onFormChanges(event: any) {
        this.survey = Object.assign(this.survey, event.value);
    }

    addPanel() {
        let newPanel = {
            type: 'panel',
            elements: [],
        };
        this.survey.pages[0].elements.push(newPanel);
    }

    getText = this.common.getText;
}
