import { Component, Injector, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractPiControlComponent } from '../abstract-pi-control/abstract-pi-control.component';
import { SurveyService } from '@h20-services/survey.service';
import { CommonService } from '@h20-services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { UuidService } from '@h20-services/uuid.service';
import { IFormLinkGenerator } from '@h20-services/models/forms/types/IFormLinkGenerator';

@Component({
    selector: 'app-pi-control-link-generator',
    templateUrl: './pi-control-link-generator.component.html',
    styleUrls: ['./pi-control-link-generator.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PiControlLinkGeneratorComponent,
        },
    ],
})
export class PiControlLinkGeneratorComponent
    extends AbstractPiControlComponent<IFormLinkGenerator, any>
    implements OnInit
{
    link: string;
    copiedToClip: boolean = false;

    constructor(
        public injector: Injector,
        protected com_svc: CommonService,
        protected uuid_svc: UuidService,
        protected translate: TranslateService,
        protected surveySvc: SurveyService
    ) {
        super(injector, com_svc, uuid_svc, translate);
    }

    ngOnInit(): void {
        const pos = window.location.href.search(/[a-z0-9]\/[a-z0-9]/);
        this.link = `${window.location.href.substring(0, pos + 1)}/${this.element.linkUrl}`;

        if (this.formControl.value === null) {
            this.writeValue('');
            this.surveySvc.createAnonSurveyToken().then((token) => {
                this.writeValue(token);
                this.link += token;
                this.notifyValueChange();
            });
        } else {
            this.link += this.formControl.value;
        }
    }

    setStringValue(): void {
        let el = this.element;
        this.stringValue = el.beforeLink + this.link;
    }

    async onCopyClick(textAreaToCopy) {
        if (navigator.clipboard && window.isSecureContext) {
            await navigator.clipboard.writeText(textAreaToCopy.value);
            this.copiedToClip = true;
        } else {
            textAreaToCopy.select();
            try {
                document.execCommand('copy');
                textAreaToCopy.setSelectionRange(0, 0);
                this.copiedToClip = true;
            } catch (error) {
                console.error(error);
            }
        }
        this.markAsTouched();
        this.notifyValueChange();
    }

    hideCopied() {
        this.copiedToClip = false;
    }
}
