<ng-container [ngSwitch]="tile.type">
    <ng-container
        *ngSwitchCase="'html'"
        [ngTemplateOutlet]="htmlTemplate"
        [ngTemplateOutletContext]="{ $implicit: tile }"
    ></ng-container>
    <ng-container
        *ngSwitchCase="'map'"
        [ngTemplateOutlet]="mapTemplate"
        [ngTemplateOutletContext]="{ $implicit: tile }"
    ></ng-container>
    <ng-container
        *ngSwitchCase="'pie'"
        [ngTemplateOutlet]="pieTemplate"
        [ngTemplateOutletContext]="{ $implicit: tile }"
    ></ng-container>
    <ng-container
        *ngSwitchCase="'bar'"
        [ngTemplateOutlet]="barTemplate"
        [ngTemplateOutletContext]="{ $implicit: tile }"
    ></ng-container>
    <ng-container
        *ngSwitchCase="'counter'"
        [ngTemplateOutlet]="counterTemplate"
        [ngTemplateOutletContext]="{ $implicit: tile }"
    ></ng-container>
</ng-container>

<!-- Template Definitions -->
<ng-template #htmlTemplate let-tile>
    <app-html-tile [tile]="tile" [formData]="formData"></app-html-tile>
</ng-template>

<ng-template #mapTemplate let-tile>
    <app-map-tile [tile]="tile" [formData]="formData"></app-map-tile>
</ng-template>

<ng-template #pieTemplate let-tile>
    <app-pie-tile [tile]="tile" [formData]="formData"></app-pie-tile>
</ng-template>

<ng-template #barTemplate let-tile>
    <app-bar-tile [tile]="tile" [formData]="formData"></app-bar-tile>
</ng-template>

<ng-template #counterTemplate let-tile>
    <app-counter-tile [tile]="tile" [formData]="formData"></app-counter-tile>
</ng-template>

<!-- Default -->
<ng-template #default1Template let-tile>
    {{ tile.type }} - UnMapped text type: {{ tile.inputType }}
</ng-template>

<ng-template #defaultTemplate let-tile>
    {{ tile.type }}
</ng-template>
