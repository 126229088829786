import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class EnvironmentService {
    private sourceEnv = new BehaviorSubject(sessionStorage.getItem('sourceEnv'));
    private destinationEnv = new BehaviorSubject(sessionStorage.getItem('destinationEnv'));
  private registry = new BehaviorSubject(sessionStorage.getItem('registry'));
    currentSource = this.sourceEnv.asObservable();
    currentDestination = this.destinationEnv.asObservable();
  currentRegistry = this.registry.asObservable();

    constructor() {}

  changeSource(newSource: string) {
    sessionStorage.setItem('sourceEnv',newSource );
    this.sourceEnv.next(newSource);
  }
  changeDestination(newDestination:string){
    sessionStorage.setItem('destinationEnv', newDestination);
    this.destinationEnv.next(newDestination);
  }
  changeRegistry(newRegistry){
    sessionStorage.setItem('registry', newRegistry);
    this.registry.next(newRegistry);
  }

}
