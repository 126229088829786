<div>
	<label>
		Schedule Interval:
		<input
			type="number"
			[value]="model.interval"
			class="form-control"
			(change)="modelChanged('interval', $event.target.value)"
		/>
		<!-- <span *ngIf="policy.error" class="form-text text-muted text-danger">{{
			policy.error
		}}</span> -->
	</label>
	<label>
		Schedule Interval Type:
		<select
			[value]="model.period"
			class="form-select"
			(change)="modelChanged('period', $event.target.value)"
		>
			<option [selected]="!model.period" disabled>please select</option>
			<option *ngFor="let type of s_intervalType" [selected]="type === model.period">
				{{ type }}
			</option>
		</select>
	</label>
</div>