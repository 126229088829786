import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { from, lastValueFrom, Observable, throwError } from 'rxjs';
import { from, Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { Auth } from '@aws-amplify/auth';
import { environment } from '@environment/environment';

@Injectable({
    providedIn: 'root',
})
export class RequestService {
    constructor(private http: HttpClient) {}

    post(url: string, requestBody: any, env?: any): Observable<any> {
        return from(Auth.currentSession())
            .pipe(
                mergeMap((session) => {
                    const options = {};
                    if (env) {
                        options['headers'] = {
                            ...env.awsConfig.API.headers,
                        };
                    } else {
                        options['headers'] = {
                            ...environment.awsConfig.API.headers,
                        };
                    }

                    options['headers']['Authorization'] = session.getIdToken().getJwtToken();

                    return this.http.post(url, requestBody, options).pipe(
                        catchError((e: Error) => {
                            return throwError(() => e);
                        })
                    );
                })
            )
            .pipe(
                catchError((e) => {
                    try {
                        if (
                            !e ||
                            (!e.hasOwnProperty('status') && e.toLowerCase() === 'no current user')
                        ) {
                            const options = {};
                            options['headers'] = {
                                ...environment.awsConfig.API.headers,
                            };

                            return this.http.post(url, requestBody, options).pipe(
                                catchError((e: Error) => {
                                    return throwError(() => e);
                                })
                            );
                        }
                    } catch (err) {
                        console.error('unable to handle exception type', err);
                    }
                    return throwError(() => e);
                })
            );
    }

    async put(url, data, env?): Promise<any> {
        const source$ = from(Auth.currentSession())
            .pipe(
                mergeMap((session) => {
                    const options = {};

                    return this.http.put(url, data, options).pipe(
                        catchError((e: Error) => {
                            return throwError(() => e);
                        })
                    );
                })
            )
            .pipe(
                catchError((e) => {
                    try {
                        if (
                            !e ||
                            (!e.hasOwnProperty('status') && e.toLowerCase() === 'no current user')
                        ) {
                            const options = {};

                            return this.http.put(url, data, options).pipe(
                                catchError((e: Error) => {
                                    return throwError(() => e);
                                })
                            );
                        }
                    } catch (err) {
                        console.error('unable to handle exception type', err);
                    }
                    return throwError(() => e);
                })
            );
        //return await lastValueFrom(source$);
        return await source$.toPromise();
    }

    async get(url, env?): Promise<any> {
        const source$ = from(Auth.currentSession())
            .pipe(
                mergeMap((session) => {
                    const options = {};
                    options['responseType'] = 'blob';

                    return this.http.get(url, options).pipe(
                        catchError((e: Error) => {
                            return throwError(() => e);
                        })
                    );
                })
            )
            .pipe(
                catchError((e) => {
                    try {
                        if (
                            !e ||
                            (!e.hasOwnProperty('status') && e.toLowerCase() === 'no current user')
                        ) {
                            const options = {};
                            options['responseType'] = 'blob';

                            return this.http.get(url, options).pipe(
                                catchError((e: Error) => {
                                    return throwError(() => e);
                                })
                            );
                        }
                    } catch (err) {
                        console.error('unable to handle exception type', err);
                    }
                    return throwError(() => e);
                })
            );
        // return await lastValueFrom(source$);
        return await source$.toPromise();
    }
}
