import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardManagementComponent } from './dashboard-management/dashboard-management.component';
import { LibraryComponent } from './library/library.component';
import { RegistrationComponent } from './registration/registration.component';
import { ThankYouComponent } from './thank-you/thank-you.component';


const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                pathMatch: 'full',
                component: RegistrationComponent,
            },
            {
                path: 'manage-registration',
                component: RegistrationComponent,
            },
            {
                path: 'manage-thankyou',
                component: ThankYouComponent,
            },
            {
                path: 'manage-library',
                component: LibraryComponent,
            },
            {
                path: 'manage-dashboard',
                component: DashboardManagementComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ContentManagementRoutingModule {}
