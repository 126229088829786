import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '@h20-services/common.service';
import { BaseTileComponent } from '../base-tile/base-tile.component';

@Component({
    selector: 'app-html-tile',
    templateUrl: './html-tile.component.html',
    styleUrls: ['./html-tile.component.scss'],
})
export class HtmlTileComponent extends BaseTileComponent implements OnInit {
    @Input() tile;
    html;
    constructor(com_svc: CommonService) {
        super(com_svc);
    }

    ngOnInit(): void {
        this.html = this.getText(this.tile.html);
    }

    getText = this.com_svc.getText;
}
