import { Component, Injector, OnChanges, OnInit } from '@angular/core';
import {
    AbstractControl,
    FormControl,
    NG_VALUE_ACCESSOR,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { IFormDatePicker } from '@h20-services/models/forms/types/IFormDatePicker';
import { AbstractPiControlComponent } from '../abstract-pi-control/abstract-pi-control.component';
import { CommonService } from '@h20-services/common.service';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { UuidService } from '@h20-services/uuid.service';

@Component({
    selector: 'app-pi-control-date-picker',
    templateUrl: './pi-control-date-picker.component.html',
    styleUrls: ['./pi-control-date-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PiControlDatePickerComponent,
        },
    ],
})
export class PiControlDatePickerComponent
    extends AbstractPiControlComponent<IFormDatePicker, any>
    implements OnInit
{
    showPartialWithMonth: boolean;
    constructor(
        public injector: Injector,
        protected com_svc: CommonService,
        protected uuid_svc: UuidService,
        protected translate: TranslateService,
        private calendar: NgbCalendar,
        private date_parser: NgbDateParserFormatter
    ) {
        super(injector, com_svc, uuid_svc, translate);
    }

    minDate: NgbDate;
    maxDate: NgbDate;
    maxDisplayDate: NgbDate;

    monthOptions: any = [
        { value: '01', text: 'January' },
        { value: '02', text: 'February' },
        { value: '03', text: 'March' },
        { value: '04', text: 'April' },
        { value: '05', text: 'May' },
        { value: '06', text: 'June' },
        { value: '07', text: 'July' },
        { value: '08', text: 'August' },
        { value: '09', text: 'September' },
        { value: '10', text: 'October' },
        { value: '11', text: 'November' },
        { value: '12', text: 'December' },
    ];

    yearOptions: any[];

    //used for full date
    currentDateValue: NgbDate;

    //when there is no day - we use:
    currentMonthValue;
    currentYearValue;

    ngOnInit() {
        //super.ngOnInit();  will add incorrect validators
        this.setMinMax();

        if (this.element.isRequired) {
            this.formControl.addValidators(Validators.required);
            if (this.element.requiredErrorText !== undefined) {
                this.customErrorMessages['required'] = this.element.requiredErrorText;
            }
        }
        this.formControl.addValidators(this.validators());

        if (this.element.dateRemoveDay) {
            //this.yearControl = new FormControl('');
            this.currentYearValue = '';

            //need year list
            let minYear = this.calendar.getPrev(this.calendar.getToday(), 'y', 150).year;
            let maxYear = this.calendar.getNext(this.calendar.getToday(), 'y', 5).year;

            if (this.minDate) {
                minYear = this.minDate.year;
            }
            if (this.maxDate) {
                maxYear = this.maxDate.year;
            }
            this.yearOptions = this.range(minYear, maxYear).reverse();

            if (this.element.dateIsMonthRequired) {
                this.showPartialWithMonth = true;
                //this.monthControl = new FormControl('');
                this.currentMonthValue = '';
            }
        }
    }

    writeValue(value: any) {
        super.writeValue(value);
        //TODO this is not getting the value if the survey starts with data - it will set to the max date
        if (this._value && this.date_parser.parse(this._value)) {
            this.currentDateValue = new NgbDate(
                this.date_parser.parse(this._value).year,
                this.date_parser.parse(this._value).month,
                this.date_parser.parse(this._value).day
            );

            this.currentMonthValue = this.currentDateValue.month;
            this.currentYearValue = this.currentDateValue.year;
        } else if (this._value?.split('-').length > 0) {
            const tokens = this._value.split('-');
            this.currentYearValue = parseInt(tokens[0]);
            if (tokens.length > 1) {
                this.currentMonthValue = parseInt(tokens[1]);
            }
        } else {
            this.currentDateValue = null;
            this.currentMonthValue = '';
            this.currentYearValue = '';
        }
        this.setStringValue();
    }

    updateValue() {
        if (
            !this.element.dateRemoveDay &&
            this.currentDateValue &&
            this.currentDateValue?.year !== null
        ) {
            //wait until it is touched to set value as
            //it loads the max date as the start value if none is provided
            let newStringDate = '';
            newStringDate += this.currentDateValue.year;

            if (this.element.dateRemoveMonth !== true) {
                newStringDate += '-';
                newStringDate += this.currentDateValue.month;

                if (this.element.dateRemoveDay !== true) {
                    newStringDate += '-';
                    newStringDate += this.currentDateValue.day;
                }
            }

            this.markAsTouched();
            this.writeValue(newStringDate);
            this.notifyValueChange();
        }
        //make sure we are expecting partial dates
        else if (this.element.dateRemoveDay) {
            let newStringDate = '';
            //this could be empty which will give '' or '-99' values
            newStringDate += this.currentYearValue;
            if (this.element.dateRemoveMonth !== true) {
                newStringDate += '-';
                newStringDate += this.currentMonthValue;
            }

            this.markAsTouched();
            this.writeValue(newStringDate);
            this.notifyValueChange();
        } else {
            //we should not have another case
            console.error('invalid control format');
        }
    }

    setMinMax() {
        const currentDate = this.calendar.getToday();
        let elementMin = this.element.min?.toString();
        let elementMax = this.element.max?.toString();
        if (elementMin) {
            if (elementMin === '0') {
                this.minDate = currentDate;
            } else if (elementMin.search('d') !== -1) {
                const min = parseInt(elementMin.split('d')[0]);
                this.minDate = this.calendar.getPrev(currentDate, 'd', min);
            } else if (elementMin.search('y') !== -1) {
                const min = parseInt(elementMin.split('y')[0]);
                this.minDate = this.calendar.getPrev(currentDate, 'y', min);
            }
        }

        if (elementMax) {
            if (elementMax === '0') {
                this.maxDate = currentDate;
            } else if (elementMax.search('d') !== -1) {
                const max = parseInt(elementMax.split('d')[0]);
                this.maxDate = this.calendar.getNext(currentDate, 'd', max);
            } else if (elementMax.search('y') !== -1) {
                const max = parseInt(elementMax.split('y')[0]);
                this.maxDate = this.calendar.getNext(currentDate, 'y', max);
            }

            this.maxDisplayDate = new NgbDate(this.maxDate.year, 12, 31);
        }
    }

    validators() {
        const rangeValidator: ValidatorFn = (control: AbstractControl) => {
            const errors: any = {};
            let minDateCompare = this.date_parser.parse(control.value);
            let maxDateCompare = this.date_parser.parse(control.value);
            if (minDateCompare) {
                if (!minDateCompare.day) minDateCompare.day = this.minDate?.day;
                if (!minDateCompare.month) minDateCompare.month = this.minDate?.month;
                if (this.minDate?.after(minDateCompare)) {
                    errors.min = {};
                    errors.min.min = this.date_parser.format(this.minDate);
                }
            }
            if (maxDateCompare) {
                if (!maxDateCompare.day) maxDateCompare.day = this.maxDate?.day;
                if (!maxDateCompare.month) maxDateCompare.month = this.maxDate?.month;
                if (this.maxDate?.before(maxDateCompare)) {
                    errors.max = {};
                    errors.max.max = this.date_parser.format(this.maxDate);
                }
            }
            return Object.keys(errors).length > 0 ? errors : null;
        };

        const requiredValidator: ValidatorFn = (control: AbstractControl) => {
            const errors: any = {};
            //needs to be parsable if the date is required
            let dateCompare = this.date_parser.parse(control.value);
            if (!dateCompare) {
                errors.required = {};
            }
            return Object.keys(errors).length > 0 ? errors : null;
        };

        const compareDateValidatorFn: ValidatorFn = () => {
            const errors: any = this.compareDateValidator();
            return Object.keys(errors).length > 0 ? errors : null;
        };

        return [rangeValidator, requiredValidator, compareDateValidatorFn];
    }

    calendarStyleSwitcher() {
        let styles = 'custom-datepicker w-100';
        if (this.element.dateRemoveMonth === true) {
            styles += ' datepicker-hideMonthSelect';
        }
        if (this.element.dateRemoveDay === true) {
            styles += ' datepicker-hideDays';
        }
        return styles;
    }

    onDateSelect(event: NgbDate) {
        this.currentDateValue = event;
        this.updateValue();
    }

    textInput(event: any) {
        //this gets called on load if the max date is set
        // TODO: Make this not so ugly (unsure if it works anytime not used on add-pat page, for prepop)
        this.currentDateValue = this._value === null && event.current === null ? null : event.next;
        this.updateValue();
    }

    monthSelected(event: any) {
        if (event.target.value != '') this.currentMonthValue = parseInt(event.target.value);
        else this.currentMonthValue = '';
        this.updateValue();
    }

    yearSelected(event: any) {
        if (event.target.value != '') this.currentYearValue = parseInt(event.target.value);
        else this.currentYearValue = '';
        this.updateValue();
    }

    range(start, end) {
        return Array.apply(0, Array(end - start + 1)).map((element, index) => index + start);
    }
}
