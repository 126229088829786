import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-generic-badge',
  templateUrl: './generic-badge.component.html',
  styleUrls: ['./generic-badge.component.scss']
})
export class GenericBadgeComponent {
    constructor(private translate: TranslateService){}
  @Input() size: 'sm' | 'md' | 'lg' = 'md'; 
  @Input() icon: 'false' | 'dot' = 'false';
  @Input() color: 'gray' | 'blue' | 'red' | 'yellow' | 'green' = 'gray';
  @Input() text = "Icons.Beta";
}
