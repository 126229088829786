import { Component, Injector, OnInit } from '@angular/core';
import { UntypedFormGroup, NG_VALUE_ACCESSOR, ValidatorFn, Validators } from '@angular/forms';
import { IFormElement } from '@h20-services/models/forms/IFormElement';
import { AbstractPiControlComponent } from '../abstract-pi-control/abstract-pi-control.component';
import { CommonService } from '@h20-services/common.service';
import {
    NgbCalendar,
    NgbDate,
    NgbPeriod,
    NgbDateParserFormatter,
    NgbDateStruct,
    NgbDateAdapter,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { UuidService } from '@h20-services/uuid.service';
import { DatePipe } from '@angular/common';
import { CustomAdapter, CustomDateParserFormatter } from '../custom-date-adapter';

@Component({
    selector: 'app-pi-control-date',
    templateUrl: './pi-control-date.component.html',
    styleUrls: ['./pi-control-date.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PiControlDateComponent,
        },
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
        DatePipe,
    ],
})
export class PiControlDateComponent
    extends AbstractPiControlComponent<IFormElement, Date>
    implements OnInit
{
    minDate: NgbDate;
    maxDate: NgbDate;
    selectedDateFormat = localStorage.getItem('prefDateFormat') || 'mm/dd/yyyy';

    datePickerValue: NgbDateStruct = null;

    current = new Date();
    minDropdownDate = { year: this.current.getFullYear() - 90, month: 1, day: 1 };
    maxDropdownDate = {
        year: this.current.getFullYear()+100,
        month: this.current.getMonth() + 1,
        day: this.current.getDate(),
    };

    constructor(
        public injector: Injector,
        protected com_svc: CommonService,
        protected uuid_svc: UuidService,
        protected translate: TranslateService,
        private calendar: NgbCalendar,
        private date_parser: NgbDateParserFormatter,
        private datePipe: DatePipe
    ) {
        super(injector, com_svc, uuid_svc, translate);
        this.inputDateFormat();
    }
    ngOnInit() {
        //super.ngOnInit();  will add incorrect validators

        this.setMinMax();
        if (this.element.isRequired) {
            this.formControl.addValidators(Validators.required);
            if (this.element.requiredErrorText !== undefined) {
                this.customErrorMessages['required'] = this.element.requiredErrorText;
            }
        }
        this.formControl.addValidators(this.validators());
    }

    override writeValue(value: Date): void {
        super.writeValue(value);
        if (value != null){
            const partsYYYYMMDD = value.toString().split('-');
            if (partsYYYYMMDD.length === 3) {
                if (this.selectedDateFormat === 'dd/mm/yyyy') {
                    this.stringValue = `${parseInt(partsYYYYMMDD[2], 10)}/${parseInt(partsYYYYMMDD[1], 10)}/${parseInt(partsYYYYMMDD[0], 10)}`
                }
                else if (this.selectedDateFormat === 'mm/dd/yyyy') {
                    this.stringValue = `${parseInt(partsYYYYMMDD[1], 10)}/${parseInt(partsYYYYMMDD[2], 10)}/${parseInt(partsYYYYMMDD[0], 10)}`
                }
                else if(this.selectedDateFormat === 'yyyy/mm/dd'){
                    this.stringValue = `${parseInt(partsYYYYMMDD[0],10)}/${parseInt(partsYYYYMMDD[1],10)}/${parseInt(partsYYYYMMDD[2],10)}`
                }
            }
        }
    }

    private calcMinMaxDate(currentDate: NgbDate, specval: string, isMin: boolean): NgbDate {
        if (!specval) return null;

        if (specval === '0') return currentDate;
        // expecting strings like ("5d" or "10y") ?
        let unit: NgbPeriod | null = null;
        if (specval.includes('d')) unit = 'd';
        else if (specval.includes('min'))
            // avoid ambiguity found in some existing data
            unit = null;
        else if (specval.includes('m'))
            // assume months
            unit = 'm';
        else if (specval.includes('y')) unit = 'y';

        if (unit) {
            const delta = parseInt(specval.split(unit)[0]);
            if (isMin) return this.calendar.getPrev(currentDate, unit, delta);
            else return this.calendar.getNext(currentDate, unit, delta);
        }
        return null;
    }

    setMinMax() {
        const currentDate = this.calendar.getToday();
        let elementMin = this.element.min?.toString();
        let elementMax = this.element.max?.toString();

        if (elementMin) {
            if (elementMin === '0') {
                this.minDate = currentDate;
            } else if (elementMin.search('d') !== -1) {
                const min = parseInt(elementMin.split('d')[0]);
                this.minDate = this.calendar.getPrev(currentDate, 'd', min);
            } else if (elementMin.search('y') !== -1) {
                const min = parseInt(elementMin.split('y')[0]);
                this.minDate = this.calendar.getPrev(currentDate, 'y', min);
            }
        }

        if (elementMax) {
            if (elementMax === '0') {
                this.maxDate = currentDate;
            } else if (elementMax.search('d') !== -1) {
                const max = parseInt(elementMax.split('d')[0]);
                this.maxDate = this.calendar.getNext(currentDate, 'd', max);
            } else if (elementMax.search('y') !== -1) {
                const max = parseInt(elementMax.split('y')[0]);
                this.maxDate = this.calendar.getNext(currentDate, 'y', max);
            }
        }
    }

    setStringValue(): void {
        if (this._value) {
            let value = new Date(this._value);

            value.setDate(value.getDate() + 1);
            this.stringValue = new Date(value).toLocaleDateString();

            if (this.selectedDateFormat === 'dd/mm/yyyy') {
                this.stringValue = this.datePipe.transform(value, 'dd-MM-yyyy');
            } else if (this.selectedDateFormat === 'mm/dd/yyyy') {
                this.stringValue = this.datePipe.transform(value, 'MM-dd-yyyy');
            } else if (this.selectedDateFormat === 'yyyy/mm/dd') {
                this.stringValue = this.datePipe.transform(value, 'yyyy-MM-dd');
            }
        }
    }

    validators() {
        const minMaxValidator: ValidatorFn = (formGroup: UntypedFormGroup) => {
            const errors: any = {};
            let value = new Date(this._value);
            const dateValue = {
                year: value.getFullYear(),
                month: value.getMonth() + 1, 
                day: value.getDate()
            };
            if (this.minDate?.after(dateValue)) {
                errors.min = {};
                errors.min.min = this.date_parser.format(this.minDate);
                if (this.element.minErrorText)
                    this.customErrorMessages['min'] = this.element.minErrorText;
            }

            if (this.maxDate?.before(dateValue)) {
                errors.max = {};
                errors.max.max = this.date_parser.format(this.maxDate);
                if (this.element.maxErrorText)
                    this.customErrorMessages['max'] = this.element.maxErrorText;
            }

            return Object.keys(errors).length > 0 ? errors : null;
        };
        const compareDateValidatorFn: ValidatorFn = () => {
            const errors: any = this.compareDateValidator();
            return Object.keys(errors).length > 0 ? errors : null;
        };
        return [minMaxValidator, compareDateValidatorFn];
    }

    inputDateFormat() {
        this.date_parser.format = (date: NgbDateStruct) => {
            if (!date) {
                return '';
            }
            const day = date.day.toString().padStart(2, '0');
            const month = date.month.toString().padStart(2, '0');
            const year = date.year.toString();

            const formatMapping: { [key: string]: string } = {
                'dd/mm/yyyy': `${day}/${month}/${year}`,
                'mm/dd/yyyy': `${month}/${day}/${year}`,
                'yyyy/mm/dd': `${year}/${month}/${day}`,
            };

            return formatMapping[this.selectedDateFormat] || `${day}/${month}/${year}`;
        };
    }

    onDateSelect(event: any) {
        const formDate = `${event.year}-${this.padZero(event.month)}-${this.padZero(event.day)}`;
        const date = this.datePipe.transform(formDate, 'yyyy-MM-dd');
        // @ts-expect-error
        // writeValue has definition to expect Date but only works when we send date strings
        this.writeValue(date);
        this.notifyValueChange();
    }

    onDateChanged(newValue: string) {
        const dateFormat = this.selectedDateFormat || 'mm/dd/yyyy';
        let day: number, month: number, year: number;
        switch (dateFormat) {
            case 'mm/dd/yyyy':
                const partsMMDDYYYY = newValue.split('/');
                if (partsMMDDYYYY.length === 3) {
                    month = parseInt(partsMMDDYYYY[0], 10);
                    day = parseInt(partsMMDDYYYY[1], 10);
                    year = parseInt(partsMMDDYYYY[2], 10);
                }
                break;
            case 'dd/mm/yyyy':
                const partsDDMMYYYY = newValue.split('/');
                if (partsDDMMYYYY.length === 3) {
                    day = parseInt(partsDDMMYYYY[0], 10);
                    month = parseInt(partsDDMMYYYY[1], 10);
                    year = parseInt(partsDDMMYYYY[2], 10);
                }
                break;
            case 'yyyy/mm/dd':
                const partsYYYYMMDD = newValue.split('/');
                if (partsYYYYMMDD.length === 3) {
                    year = parseInt(partsYYYYMMDD[0], 10);
                    month = parseInt(partsYYYYMMDD[1], 10);
                    day = parseInt(partsYYYYMMDD[2], 10);
                }
                break;
        }

        if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
            const validDate = new NgbDate(year, month, day);
            this.onDateSelect(validDate);
        }
    }

    dateString(date: any): string {
        const paddedMonth = String(date.month).padStart(2, '0');
        const paddedDay = String(date.day).padStart(2, '0');
        return `${date.year}-${paddedMonth}-${paddedDay}`;
    }

    padZero(value: number): string {
        return value.toString().padStart(2, '0');
    }
}
