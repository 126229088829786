import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
    // Input properties
    @Input() buttonText: string = 'Select File';
    @Input() disabled: boolean = false; // To control the disabled state
    // Output events to notify parent component
    @Output() fileSelected = new EventEmitter<File>(); // Emit when a file is selected
    @Output() fileValidationStatus = new EventEmitter<{ isValid: boolean, minSize: number, maxSize: number}>();
    @Input() uploadFileWarningMsg: string = 'test';
    @Input() isEditing; // Indicates if the file is being edited
    @Input() fileSelectedEdit: File | null = null;
    @Input() supportedFileTypes: string[];
    // Local state for selected file and warnings
    selectedFileName: File | null = null; // Store the selected file name
    uploadPatientFile: boolean = false;
    uploadedFile: File | null = null;
    maxFileSize: number;
    minFileSize:number;
    constructor(
      public translate: TranslateService,
    ){}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['fileSelectedEdit'] && changes['fileSelectedEdit'].currentValue) {
            // If a file is passed from the parent, set it as the selected file
            //this.uploadedFile = changes['fileSelectedEdit'].currentValue;
            this.uploadedFile = this.fileSelectedEdit;
        }
    }

    onFileSelected(event: Event) {
      const input = event.target as HTMLInputElement;
      if (input.files && input.files.length > 0) {
          const file = input.files[0];
          const fileExtension = file.name.split('.').pop()?.toLowerCase();
  
          if (!fileExtension || (this.supportedFileTypes && !this.supportedFileTypes.includes(fileExtension))) {
              this.uploadFileWarningMsg = this.translate.instant('Registry.FileFormatNotSupported')
              this.uploadedFile = null;
              this.fileValidationStatus.emit({ isValid: true,
                minSize: this.minFileSize,
                maxSize: this.maxFileSize
              });
          }
          else{
          const fileSizeKB = file.size / 1024; // Convert to KB
  
          if (this.minFileSize && fileSizeKB < this.minFileSize || this.maxFileSize && fileSizeKB > this.maxFileSize) {
              this.uploadFileWarningMsg = `${this.translate.instant('Registry.FileNotSavedError')} ${this.minFileSize} KB and ${this.maxFileSize} KB`;
              this.uploadedFile = null;
              this.fileValidationStatus.emit({ isValid: false,
                minSize: this.minFileSize,
                maxSize: this.maxFileSize
              });
              this.fileSelected.emit(file); 
          }  else {
              this.uploadFileWarningMsg = ''; 
              this.uploadedFile = file; 
              this.fileSelected.emit(file); 
              this.fileValidationStatus.emit({ isValid: true,
                minSize: this.minFileSize,
                maxSize: this.maxFileSize
              });
          }
      }
    }
  }
  

  
}
