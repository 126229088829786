<div *ngIf="mode === 'view'; else formModeTemplate">
    <div *ngIf="!disabled" class="pi-control-edit-mode">
        <app-pi-form-view-response
            [element]="element"
            [theFormControl]="formControl"
            [displayValue]="stringValue"
        ></app-pi-form-view-response>
    </div>
</div>
<ng-template #formModeTemplate>
    <div *ngIf="!disabled" class="row pi-control-form-mode">
        <label
            class="form-label"
            [ngClass]="
                !element.orientation || element.orientation === 'horizontal'
                    ? 'col-12 col-md-6 col-lg-8'
                    : 'col-12'
            "
            [attr.for]="elementUID"
        >
            <app-element-title [element]="element"></app-element-title>
        </label>
        <div class="col-12 col-md-6 col-lg-4">
            <button
                [name]="elementUID"
                type="button"
                class="btn btn-light"
                [attr.aria-label]="element.title"
                (click)="onToggle($event)"
            >
                <span
                    class="fa-solid fa-2x"
                    [ngClass]="_value ? 'fa-toggle-on' : 'fa-toggle-off'"
                    [attr.aria-label]="stringValue"
                ></span>
            </button>
        </div>

        <app-validator
            [control]="formControl"
            [customErrorMessages]="customErrorMessages"
            [warningControl]="warningControl"
            [customWarningMessages]="customWarningMessages"
        ></app-validator></div
></ng-template>
