<div [ngClass]="state">
  <textarea
    id="fileDescription"
    class="form-control responsive-textarea custom-textarea textarea-wrapper"
    [placeholder]="placeholder"
    [maxlength]="maxLength"
    [(ngModel)]="textAreaValue"
    (input)="onTextAreaInput($event)"
  ></textarea>
  <small class="text-muted mr-1">{{ textAreaValue.length }}/{{ maxLength }}</small>

  <!-- Hint text -->
  <small class="hint pl-1" *ngIf="hintText" style="padding-left: 8px;">{{ hintText }}</small>

  <!-- Error text 
  <div class="error" *ngIf="errorText">{{ errorText }}</div> -->
</div>
