import { Component, Input } from '@angular/core';
import { CommonService } from '@h20-services/common.service';

@Component({
    selector: 'app-early-bird',
    templateUrl: './early-bird.component.html',
    styleUrls: ['./early-bird.component.scss'],
})
export class EarlyBirdComponent {
    @Input() message;
    @Input() title;
    @Input() image;

    constructor(protected com_svc: CommonService) {}

    getText = this.com_svc.getText;
}
