<ng-container [ngSwitch]="layout">
    <ng-container *ngSwitchCase="'flat'">
        <app-pi-form-layout-flat
            class="pi-form-layout"
            [panels]="panels"
            [progressType]="progressType"
            [theFormGroup]="formGroup"
            [formData]="formData"
            [entryType]="entryType"
            [mode]="mode"
            [(logicalContext)]="logicalContext"
            [parentList]="parentList"
            (changes)="formChanges($event)"
            (stageFileUploadChanges)="stageFileUploadChangesHandlerFunction($event)"
            (stageFileDeleteChanges)="stageFileDeleteChangesHandlerFunction($event)"
            (downloadButtonClicked)="downloadEventHandlerFunction($event)"
            [patientId]="patientId"
            [surveyId]="surveyId"
        >
        </app-pi-form-layout-flat>
    </ng-container>
    <ng-container *ngSwitchCase="'one-by-one'">
        <app-pi-form-layout-one-by-one
            class="pi-form-layout"
            [panels]="panels"
            [theFormGroup]="formGroup"
            [progressType]="progressType"
            [formData]="formData"
            [entryType]="entryType"
            [parentList]="parentList"
            [mode]="mode"
            [(logicalContext)]="logicalContext"
            (changes)="formChanges($event)"
            (downloadButtonClicked)="downloadEventHandlerFunction($event)"
        >
        </app-pi-form-layout-one-by-one
    ></ng-container>
    <ng-container *ngSwitchDefault> Default layout not defined </ng-container>
</ng-container>
<app-loading-component [loading]="loading"></app-loading-component>
