import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-icon-card',
    templateUrl: './icon-card.component.html',
    styleUrls: ['./icon-card.component.scss'],
})
export class IconCardComponent implements OnInit {
    @Input() iconSize = 'small';
    @Input() fontChar = 'fa-square';
    @Input() classInner = '';
    @Input() heading = '';
    @Input() isHeading: boolean;
    @Input() isSubHeading: boolean;
    @Input() isContent: boolean;
    @Input() subHeading = '';
    @Input() content: string;
    @Input() iconStyles = '';
    @Input() headingStyle = {};
    @Input() subHeadingStyle = {};
    @Input() contentStyle = {};
    @Input() disabled = false;
    @Input() active = false;
    isActive;

    @Input() type = 'primary';
    iconClass: any = '';
    ngOnInit(): void {
        this.iconClass = this.fontChar;
    }
}
