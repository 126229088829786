<div *ngIf="mode === 'view'; else formModeTemplate">
    <div *ngIf="!disabled" class="pi-control-edit-mode">
        <app-pi-form-view-response
            [element]="element"
            [theFormControl]="formControl"
            [displayValue]="stringValue"
        ></app-pi-form-view-response>
    </div>
</div>
<ng-template #formModeTemplate>
    <div *ngIf="!formControl.disabled" class="pi-control-form-mode">
        <label>{{ getText(element.title) }}: </label>
        <app-required [element]="element"></app-required>

        <div class="overflow-auto">
            <div class="img-overlay-wrap">
                <img id="gestureImage" [src]="element.imageurl" usemap="#image-map" />
                <svg *ngIf="areas">
                    <polygon
                        *ngFor="let area of areas"
                        [ngClass]="{
                            'overlay-poly-selected': formControl?.value === area.id
                        }"
                        [id]="area.id"
                        class="overlay-poly"
                        (click)="clickArea(area.id)"
                        [attr.points]="area.points"
                    >
                        <title alt="area.title">{{ area.title }}</title>
                    </polygon>
                </svg>
            </div>
        </div>

        <app-validator
             [control]="formControl"
            [customErrorMessages]="customErrorMessages"
            [warningControl]="warningControl"
            [customWarningMessages]="customWarningMessages"
        ></app-validator></div
></ng-template>
