<div>
    <highcharts-chart
        id="container"
        [Highcharts]="Highcharts"
        [constructorType]="chartConstructor"
        [options]="chartOptions"
        style="width: 100%; height: 500px; display: block"
    >
    </highcharts-chart>
</div>
