import { Component, Input } from '@angular/core';
import { FormService } from '@h20-services/form.service';

@Component({
    selector: 'app-highcharts-layout',
    templateUrl: './highcharts-layout.component.html',
    styleUrls: ['./highcharts-layout.component.scss'],
})
export class HighchartsLayoutComponent {
    @Input() rows: [];
    layout: any;

    constructor(private frm_svc: FormService) {}
}
