<div *ngIf="mode === 'view'; else formModeTemplate">
    <div *ngIf="!disabled" class="pi-control-edit-mode">
        <app-pi-form-view-response
            [element]="element"
            [theFormControl]="formControl"
            [displayValue]="formattedDisplay"
        ></app-pi-form-view-response>
    </div>
</div>
<ng-template #formModeTemplate
    ><div *ngIf="!disabled" class="w-100 row row pi-control-form-mode">
        <label
            class="form-label"
            [ngClass]="
                !element.orientation || element.orientation === 'horizontal'
                    ? 'col-12 col-md-4 col-lg-6'
                    : 'col-12'
            "
            [attr.for]="elementUID"
        >
            <app-element-title [element]="element"></app-element-title>
        </label>

        <div class="col-12 col-md-8 col-lg-6 d-flex">
            <select
                class="form-select"
                (change)="selectInput($event)"
                [attr.disabled]="disabled || element?.readOnly"
                [attr.aria-label]="'Surveys.PhoneCountryAccessibleLabel' | translate"
            >
                <option disabled [attr.selected]="!this.selectedCountryCode ? true : null">
                    {{ 'Surveys.PhoneSelectCountry' | translate }}
                </option>
                <option
                    *ngFor="let country of countries"
                    [value]="country.code"
                    [attr.selected]="this.selectedCountryCode === country.code ? true : null"
                >
                    {{ country.friendlyName | translate }} (+{{ country.callingCode }})
                </option>
            </select>
            <input
                [attr.id]="elementUID"
                class="p-1 form-control ms-3"
                type="tel"
                [name]="elementUID"
                [value]="textFieldState"
                [attr.disabled]="disabled || element?.readOnly"
                (change)="textInput($event)"
            />
        </div>
        <app-validator
            [control]="formControl"
            [customErrorMessages]="customErrorMessages"
            [warningControl]="warningControl"
            [customWarningMessages]="customWarningMessages"
        ></app-validator>
    </div>
</ng-template>
