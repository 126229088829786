<div>
    <h1 class="mt-3 mx-3 mb-3">Translation Import</h1>

    <div class="form-group mx-3 btn-primary">
        <input
            type="file"
            class="file-upload"
            (change)="changeFile($event.target.files)"
            accept=".xls,.xlsx,.cvs"
            (click)="clickFileButtom()"
        />
        <app-button
            class="mx-3"
            [disabled]="disableReadFile"
            (action)="submitFile()"
            [buttonText]="'Registry.ReadFile'"
        />
        <app-button
            *ngIf="workbook"
            (action)="tryMatch()"
            [buttonText]="'Registry.MatchVerificationDiff'"
        />
        <br />
        <hr />
        <div id="warningMessage" class="row mt-3 mx-1" style="color: black">
            {{ warningMessage }}
        </div>
        <div *ngIf="loading">
            <h4 class="mt-3 text-center">
                <i attr.aria-label="Loading" class="fas fa-spinner fa-spin"></i>
                Loading
            </h4>
            <p>Waiting on:</p>
            <ul>
                <li *ngIf="loadingRegConfig">Registry_config</li>
                <li *ngIf="loadingSES">Email Templates</li>
                <li *ngIf="loadingTasks">Tasks</li>
                <li *ngIf="loadingSurveys">Surveys</li>
            </ul>
        </div>
        <div class="row mt-3 mx-1" *ngIf="workbook">
            <h4 class="mb-2">Legend:</h4>
            <table class="table">
                <tbody>
                    <tr>
                        <td class="table-danger">unable to match to an existing record</td>
                        <td class="table-warning">something changed</td>
                        <td class="table-success">new lang added</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <hr />

        <app-loading-component [loading]="loadingMatches">
            <div *ngIf="workbook">
                <div class="row">
                    <app-button
                        class="mb-3 col mx-3"
                        (action)="importAll()"
                        [buttonText]="'Registry.ImportAll'"
                    />
                </div>
                <div class="row">
                    <label *ngFor="let sheet of workbook.SheetNames" class="form-control col mx-3"
                        ><input
                            type="checkbox"
                            class="mx-3"
                            (change)="changeSheet(sheet, $event.target.checked)"
                        />{{ sheet }}</label
                    >
                </div>
                <div class="row" *ngFor="let sheet of workbook.SheetNames">
                    <div *ngIf="sheetSelection[sheet]">
                        <h2 class="mt-3 mb-2">{{ sheet }}:</h2>
                        <table class="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th *ngFor="let head of sheetData[sheet].headers" scope="col">
                                        {{ head }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of sheetData[sheet].data">
                                    <td
                                        *ngFor="let head of sheetData[sheet].headers"
                                        [class.table-danger]="data['matched'] === false"
                                        [class.table-warning]="
                                            data[head + '-before'] &&
                                            hasDiff(data[head], data[head + '-before'])
                                        "
                                        [class.table-success]="data[head + '-new']"
                                    >
                                        <div
                                            *ngIf="
                                                !data[head + '-before'] ||
                                                !hasDiff(data[head], data[head + '-before'])
                                            "
                                        >
                                            {{ data[head] }}
                                        </div>
                                        <div
                                            *ngIf="
                                                data[head + '-before'] &&
                                                hasDiff(data[head], data[head + '-before'])
                                            "
                                        >
                                            <app-diff
                                                [before]="data[head + '-before']"
                                                [after]="data[head]"
                                            ></app-diff>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </app-loading-component>
    </div>
</div>
