import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebassetsComponent } from './webassets/webassets.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WebassetsRoutingModule } from './webassets-routing.module';
import { SharedModule } from '@h20-shared/shared.module';
@NgModule({
    declarations: [WebassetsComponent],
    imports: [
        CommonModule,
        NgxDatatableModule,
        WebassetsRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
    ],
})
export class WebassetsModule {}
