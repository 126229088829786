import { Injectable } from '@angular/core';
import { environment as env } from '@environment/environment';
import { Observable } from 'rxjs';
import { RequestService } from './request.service';

@Injectable({
    providedIn: 'root',
})
export class DDBEmailTemplates {
    constructor(private reqSvc: RequestService) {}

    listTemplates(): Observable<any> {
        const req: any = { operation: 'list' };
        return this.reqSvc.post(env.awsConfig.API.DDBEmailTemplates, req);
    }

    listTemplateTypes(): Observable<any> {
        const req: any = {
            operation: 'list-templates',
        };
        return this.reqSvc.post(env.awsConfig.API.DDBEmailTemplates, req);
    }

    createTemplate(template: any): Observable<any> {
        const req: any = { operation: 'create', data: template };
        return this.reqSvc.post(env.awsConfig.API.DDBEmailTemplates, req);
    }

    getTemplate(pkey: string): Observable<any> {
        let dataToSend = {
            'registry_id#organization#site_id#template#language': pkey,
        };
        const req: any = { operation: 'get', data: dataToSend };
        return this.reqSvc.post(env.awsConfig.API.DDBEmailTemplates, req);
    }

    updateTemplate(template: any) {
        const req: any = { operation: 'update', data: template };
        return this.reqSvc.post(env.awsConfig.API.DDBEmailTemplates, req);
    }

    deleteTemplate(pkey: string): Observable<any> {
        let dataToSend = {
            'registry_id#organization#site_id#template#language': pkey,
        };
        const req: any = { operation: 'delete', data: dataToSend };
        return this.reqSvc.post(env.awsConfig.API.DDBEmailTemplates, req);
    }

    buildHTML(params: any): Observable<any> {
        let dataToSend = {
            ...params,
        };
        const req: any = { operation: 'build', data: dataToSend };
        return this.reqSvc.post(env.awsConfig.API.DDBEmailTemplates, req);
    }

    previewHTML(params: any): Observable<any> {
        let dataToSend = {
            ...params,
        };
        const req: any = { operation: 'preview', data: dataToSend };
        return this.reqSvc.post(env.awsConfig.API.DDBEmailTemplates, req);
    }

    sendEmail(params: any) {
        let dataToSend = {
            ...params,
        };
        const req: any = { operation: 'send', data: dataToSend };
        return this.reqSvc.post(env.awsConfig.API.DDBEmailTemplates, req);
    }
}
