import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IFormElement } from '@h20-services/models/forms/IFormElement';
import { IFormEntryType } from '@h20-services/models/forms/types/IFormEntryType';

@Component({
    selector: 'app-pi-control-switch',
    templateUrl: './pi-control-switch.component.html',
    styleUrls: ['./pi-control-switch.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PiControlSwitchComponent,
        },
    ],
})
export class PiControlSwitchComponent {
    @Input() element: IFormElement;
    @Input() theFormControl: FormControl;
    @Input() formControlList: FormControl[];
    @Input() elementList: IFormElement[];
    @Input() hideDisabled: boolean = true;
    @Input() mode: 'form' | 'view' = 'form'; //options are form, view
    @Input() formData: any;
    @Input() entryType: IFormEntryType;
    @Input() parentList: any[]; //specific to survey builder - logic builder
    @Input() patientId: any;
    @Input() surveyId: any;

    @Input() options: any;

    @Output() downloadButtonClicked: EventEmitter<string> = new EventEmitter<string>();
    downloadEventHandlerFunction(valueEmitted) {
        this.downloadButtonClicked.emit(valueEmitted);
    }

    @Output() stageFileUploadChanges: EventEmitter<any> = new EventEmitter();

    stageFileUploadChangesHandlerFunction(files) {
        this.stageFileUploadChanges.emit(files);
    }

    @Output() stageFileDeleteChanges: EventEmitter<any> = new EventEmitter();

    stageFileDeleteChangesHandlerFunction(files) {
        this.stageFileDeleteChanges.emit(files);
    }
}
