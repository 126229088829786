<div *ngIf="mode === 'view'; else formModeTemplate">
    <div *ngIf="!disabled" class="pi-control-edit-mode">
        <app-pi-form-view-response
            [element]="element"
            [theFormControl]="formControl"
            [displayValue]="stringValue"
        ></app-pi-form-view-response>
    </div>
</div>
<ng-template #formModeTemplate
    ><div *ngIf="!disabled" class="row pi-control-form-mode">
        <label class="form-label col-12 col-md-6 col-lg-8" [attr.for]="elementUID">
            <app-element-title [element]="element"></app-element-title>
        </label>
        <div class="col-12 col-md-6 col-lg-4 p-0 m-0">
            <app-loading-component [loading]="loading">
                <select
                    [name]="elementUID"
                    (change)="selectionMade($event)"
                    class="form-control form-select"
                    [value]="currentChoice"
                    [attr.id]="elementUID"
                    [attr.aria-label]="getText(element.title)"
                    [attr.disabled]="element.readOnly"
                >
                    <option value="" [attr.selected]="currentChoice === '' ? '' : null">
                        {{ element.optionsCaption || 'Registry.SelectResponse' | translate }}
                    </option>
                    <option
                        *ngFor="let choice of element.choices"
                        [value]="choice.value || choice"
                        [disabled]="choice.status && choice.status === 'deactivated'"
                        [attr.selected]="currentChoice === choice.value ? '' : null"
                    >
                        {{ choice.name || getText(choice.text) || choice }}
                    </option>
                    <!-- TODO NO SUPPORT FOR CHOICE LEVEL TOOLTIP-->
                </select>
            </app-loading-component>
        </div>
        <app-validator
            [control]="formControl"
            [customErrorMessages]="customErrorMessages"
            [warningControl]="warningControl"
            [customWarningMessages]="customWarningMessages"
        ></app-validator>
    </div>
</ng-template>
