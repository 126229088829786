<div>
    <h1 class="text-center mt-3 mb-3">{{ mode }} config item</h1>
    <app-loading-component [loading]="loading">
        <div class="container">
            <div>
                Env: {{ targetEnv }} & Table: {{ selectedTableKey }}
                <hr />
                <div [formGroup]="configForm" #cfForm="ngForm">
                    <div class="w-50">
                        <label
                            >PK: {{ partitionKeyName }}
                            <small
                                *ngIf="isNewConfig"
                                class="form-text text-muted float-right text-danger"
                            >
                                *This value cannot be changed once the item is created</small
                            ></label
                        >
                        <input
                            *ngIf="(!isNewConfig && configItem) || isNewConfig"
                            type="text"
                            class="form-control"
                            placeholder="Partition Key"
                            [readonly]="!isNewConfig"
                            id="partition_key"
                            formControlName="partition_key"
                        />
                        <div
                            *ngIf="
                                isNewConfig &&
                                configForm.controls.partition_key.errors &&
                                (configForm.controls.partition_key.dirty ||
                                    configForm.controls.partition_key.touched)
                            "
                        >
                            <div
                                *ngIf="configForm.controls.partition_key.errors?.required"
                                class="formError text-danger"
                            >
                                Please enter a pk
                            </div>
                        </div>
                    </div>
                    <br />
                    <div class="w-50">
                        <label>Latest Version</label>
                        <input
                            *ngIf="!isNewConfig && configItem"
                            type="text"
                            class="form-control"
                            placeholder="Version"
                            readonly
                            id="latest_version"
                            formControlName="latest_version"
                        />
                        <small *ngIf="!isNewConfig" class="form-text text-muted"
                            >Latest version. It will be incremented by 1</small
                        >
                        <small *ngIf="isNewConfig" class="form-text text-muted">
                            : The latest version will be 1.</small
                        >
                    </div>
                    <div *ngIf="!isNewConfig && configItem" class="w-50">
                        <label>Created</label>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Created"
                            readonly
                            id="created"
                            formControlName="created"
                        />
                    </div>
                </div>
                <hr />
                <div *ngIf="message" class="text-center text-danger mb-2">
                    {{ message }}
                </div>
                <app-loading-component [loading]="submitLoading">
                    <div class="form-group mt-3">
                        <label>Item</label>
                        <small
                            *ngIf="isNewConfig"
                            class="form-text text-muted float-right text-danger"
                        >
                            *</small
                        >
                        <json-editor
                            [options]="jsonEditorOptions"
                            [data]="configItemRest"
                        ></json-editor>

                        <div class="mt-2 mb-2 float-end">
                            <app-button
                                *ngIf="mode !== 'view'"
                                class="m-2"
                                (action)="submit()"
                                [disabled]="!cfForm.valid"
                                [buttonText]="'Registry.Submit'"
                            />
                            <app-button
                                *ngIf="!isModalOpened"
                                class="mx-2"
                                routerLink="/deploymanagement/manage-config"
                                [design]="'secondary'"
                                [buttonText]="'Registry.Back'"
                            />
                        </div>
                    </div>
                </app-loading-component>
            </div>
        </div>
    </app-loading-component>
</div>
