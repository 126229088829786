import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SurveyRoutingModule } from './survey-routing.module';
import { SurveysComponent } from './surveys/surveys.component';
import { DeploySurveyComponent } from './deploy-survey/deploy-survey.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '@h20-shared/shared.module';
@NgModule({
    declarations: [SurveysComponent, DeploySurveyComponent],
    imports: [
        CommonModule,
        SurveyRoutingModule,
        NgxDatatableModule,
        ClipboardModule,
        FormsModule,
        ReactiveFormsModule,

        SharedModule,
    ],
})
export class SurveyModule {}
