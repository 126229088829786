export enum UserRole {
    Patient = 'patient',
    Proxy = 'proxy',
    SiteAdmin = 'site-admin',
    Investigator = 'investigator',
    NKFAdmin = 'nkf-admin',
    RegistryAdmin = 'registry-admin',
    Admin = 'admin',
    Clinical = 'clinical',
    Pv = 'pv',
    Sponsor = 'sponsor',
    Monitor = 'monitor',
}

export const DataEntryRoles = [
    UserRole.SiteAdmin,
    UserRole.Admin,
    UserRole.Clinical,
    UserRole.RegistryAdmin,
];

export const RegistryWideRoles = [
    UserRole.RegistryAdmin,
    UserRole.Pv,
    UserRole.Investigator,
    UserRole.Sponsor,
    UserRole.Monitor,
];
