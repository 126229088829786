import { Component, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IFormElement } from '@h20-services/models/forms/IFormElement';
import { AbstractPiControlComponent } from '../abstract-pi-control/abstract-pi-control.component';

@Component({
    selector: 'app-pi-control-euroqol-slider',
    templateUrl: './pi-control-euroqol-slider.component.html',
    styleUrls: ['./pi-control-euroqol-slider.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PiControlEuroqolSliderComponent,
        },
    ],
})
export class PiControlEuroqolSliderComponent extends AbstractPiControlComponent<IFormElement, any> {
    hoverLabelEQ: number;
    hoverLabelShow = false;

    onSlide(value: number) {
        this.markAsTouched();
        this._value = value;
        this.notifyValueChange();
    }

    showHoverVal = (e) => {
        this.hoverLabelEQ = Math.trunc(
            (e.offsetX / (e.target.clientWidth - 8)) * parseInt(e.target.getAttribute('max'), 10)
        );
        let x = e.offsetX + 12 + 'px';
        this.hoverLabelShow = true;

        if (this.hoverLabelEQ > 100 || this.hoverLabelEQ < 0) {
            this.hoverLabelShow = false;
        }
        document.getElementById('hover-labelEQ').style.left = x;
    };

    hideHoverVal = (e) => {
        this.hoverLabelShow = false;
    };
}
