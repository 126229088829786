import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageMap } from '@ngx-pwa/local-storage';
import { environment as env } from '@environment/environment';
import { ConfigService } from './config.service';
import { CommonService } from './common.service';
import { RequestService } from './request.service';

@Injectable({
    providedIn: 'root',
})
export class SiteService {
    constructor(
        private reqSvc: RequestService,
        public storage: StorageMap,
        private configsvc: ConfigService,
        private commonSvc: CommonService
    ) {}
    
    getRegistryLevelSite(): string {
        return `${this.configsvc.getTenant()}-registry`;
    }

    getSites(id: string, env: any = null): any {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        let dataToSend = {
            id: id,
        };
        const req: any = { operation: 'getSite', data: dataToSend };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBSite, req);
    }

    getSitesByRegistry(
        registry_id: string = this.configsvc.getTenant()
    ): Observable<any> {
        let dataToSend = {
            registryKey: registry_id,
        };
        const req: any = { operation: 'getSitesByRegistry', data: dataToSend };
        return this.reqSvc.post(env.awsConfig.API.writeToDBSite, req);
    }

    createSite(data: any, env: any = null) {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        const dataToSend = {
            id: data.site_ID || null,
            registryID: data.registry_ID,
            siteData: data
        };

        const req: any = { operation: 'createSite', data: dataToSend };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBSite, req);
    }

    deleteSite(id: string, env: any = null) {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        const dataToSend = {
            id: id,
        };

        const req: any = { operation: 'deleteSite', data: dataToSend };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBSite, req);
    }
    
    getOrganizationsByRegistry(
        registry_id: string = this.configsvc.getTenant()
    ): Observable<any> {
        let dataToSend = {
            registryKey: registry_id,
        };
        const req: any = { operation: 'getOrganizationsByRegistry', data: dataToSend };
        return this.reqSvc.post(env.awsConfig.API.writeToDBSite, req);
    }

    updateSite(site: any, env: any = null) {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        const dataToSend = {
            siteData: site
        };

        const req: any = { operation: 'updateSite', data: dataToSend };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBSite, req);
    }

    listSiteTypes(env: any = null) {
        const targetEnv = this.commonSvc.getTargetEnv(env);
        const req: any = { operation: 'listSiteTypes' };
        return this.reqSvc.post(targetEnv.awsConfig.API.writeToDBSite, req);
    }
}
