<div class="card-body">
  <h2> {{ 'Registry.Dashboard' | translate }}</h2>
  
  <div *ngIf="!hasDashboardTab">
      <div *ngIf="showTableau" class="tab">
          <button
              *ngFor="let view of tableauData; first as isFirst"
              (click)="openViewTab($event, view.viewName)"
              id="btn-{{ view.viewName }}"
              [ngClass]="{ active: isFirst === true }"
          >
              {{ getText(view.viewName) }}
          </button>
      </div>
  </div>
  <div *ngIf="hasDashboardTab">
      <div class="tab">
          <button
              *ngFor="let vTab of viewTabs; first as isFirst"
              (click)="openViewTab($event, vTab.viewName)"
              id="btn-{{ vTab.viewName }}"
              [ngClass]="{ active: isFirst === true }"
          >
              {{ getText(vTab.tabName) }}
          </button>
      </div>
  </div>

  <div *ngFor="let view of tableauData">
      <div
          *ngIf="view.selected !== undefined"
          [id]="view.viewName"
          class="tabcontent"
      >
          <app-tableau
              *ngIf="showTableau"
              divId="viz-{{ view.viewName }}"
              vizUrl="{{ tableauBaseUrl }}{{ view.visualUrl }}"
              [jwt]="view.token"
          ></app-tableau>
      </div>
  </div>
  <app-early-bird
      *ngIf="showEarlyBird"
      [title]="earlyTitle"
      [message]="earlyMessage"
      [image]="earlyImage"
  ></app-early-bird>
  <app-highcharts-layout
      *ngIf="showHighcharts"
      [rows]="highchartsData"
  ></app-highcharts-layout>
</div>
